import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setBuscaData } from "../../redux/actions/buscaActions";

import Radio from "../Radio/radio";
import BotaoOrdenar from "../botao-ordenar";
import Filtro from "../Filtro/filtro";
import styles from "./filtros.module.css";
import axios from "axios";
import { getTrainerServices } from "../../services/server/TrainerServices";

const Filtros = (props) => {
  const dispatch = useDispatch();
  const filtros = useSelector((state) => state.busca);
  const [openFilter, setOpenFilter] = useState(true);
  const [menuMobile, setMenuMobile] = useState(false);
  const [openOrder, setOpenOrder] = useState(false);
  const [verObjetivos, setVerObjetivos] = useState(5);
  const [valoresRange, setValoresRange] = useState({
    min: null,
    max: null,
  });
  const [objetivosOptions, setObjetivosOptions] = useState([
    "Apoio nutricional",
    "Emagrecimento",
    "Músculo e Definição",
    "Fitness Total e Dinâmico",
    "Preparo para Eventos",
    "Flexibilidade e bem-estar",
    "Ginástica laboral",
    "Maternidade Ativa",
    "Treinamento esportivo",
    "Treino Sênior",
    "Fitness Infantil e adolescente",
  ])
  const [order, setOrder] = useState("");
  // console.log(filtros)
  useEffect(() => {
    getTrainerServices().then(servs => setObjetivosOptions(servs.map(serv => serv.title)))
  }, [])

  const objetivosArr = [];

  for (let i = 0; i < verObjetivos; i++) {
    objetivosArr.push(
      <Filtro
        radio={filtros?.services?.includes(objetivosOptions[i])}
        onClick={() => {
          handleFiltros("services", objetivosOptions[i]);
        }}
        key={i}
        text={`${objetivosOptions[i]}`}
      ></Filtro>
    );
  }

  const handleClickLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const lat = position.coords.latitude;
        const long = position.coords.longitude;
        fetch(
          `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${long}`
        )
          .then((response) => response.json())
          .then(async (data) => {
            const cidade = data.address.city_district;

            const resLocation = await axios.get(
              `${process.env.REACT_APP_API_URL}/api/location?search=${cidade}`
            );

            const city = resLocation.data.data[0];
            dispatch(
              setBuscaData({
                ...filtros,
                address: {
                  city: city.municipality,
                  id: city._id,
                  uf: city.state,
                },
              })
            );

            history.push(`/busca`);
          })
          .catch((error) => console.error(error));
      },
      (error) => {
        console.error(error);
      },
      { timeout: 30000 }
    );
  };

  const handleFiltros = (prop, value) => {
    if (Array.isArray(filtros[prop])) {
      if (filtros[prop].includes(value)) {
        const propArr = filtros[prop];
        const vIndex = filtros[prop].findIndex((v) => v === value);
        propArr.splice(vIndex, 1);

        dispatch(
          setBuscaData({
            ...filtros,
            [prop]: propArr,
          })
        );
      } else {
        dispatch(
          setBuscaData({
            ...filtros,
            [prop]: [...filtros[prop], value],
          })
        );
      }
    }
  };

  const filtrarAvailableTime = (time) => {
    dispatch(
      setBuscaData({
        ...filtros,
        availableTime: {
          morning:
            time == "morning"
              ? !filtros.availableTime.morning
              : filtros.availableTime.morning,
          afternoon:
            time == "afternoon"
              ? !filtros.availableTime.afternoon
              : filtros.availableTime.afternoon,
          evening:
            time == "evening"
              ? !filtros.availableTime.evening
              : filtros.availableTime.evening,
        },
      })
    );
  };

  const filtrarPreco = () => {
    dispatch(
      setBuscaData({
        ...filtros,
        pricing: {
          min: valoresRange?.min || null,
          max: valoresRange?.max || null,
        },
      })
    );

    window.scrollTo(0, 0);
  };

  useEffect(() => {
    if (window.innerWidth > 991) {
      setOpenFilter(true);
      setOpenOrder(false);
    } else {
      setOpenFilter(false);
      setOpenOrder(false);
    }
  }, [window.innerWidth]);

  const handleOrder = (order) => {};

  return (
    <div className={` ${styles["filtros"]} `}>
      <div className={styles["busca-mobile"]}>
        <button onClick={handleClickLocation} className={styles["botao-local"]}>
          <img
            alt="localI316"
            src="/external/localI316-k4ac.svg"
            className={styles["local"]}
          />
        </button>
        <div className={styles["container"]}>
          <div className={styles["input-busca"]}>
            <input
              type="text"
              placeholder="Cidade buscada - UF"
              className={styles["textinput"]}
            />
            <img
              alt="Caminho13169"
              src="/external/caminho13169-phir.svg"
              className={styles["lupaicon"]}
            />
          </div>
          <div className={styles["localizacao-atual"]}>
            <div className={styles["elementos"]}>
              <span className={styles["text"]}>
                <span>Usar localização atual</span>
              </span>
              <img
                alt="local3168"
                src="/external/local3168-77zg.svg"
                className={styles["localicon"]}
              />
            </div>
          </div>
          <div className={styles["brasil-todo"]}>
            <div className={styles["elementos1"]}>
              <span className={styles["text02"]}>Todo o Brasil</span>
              <Radio></Radio>
            </div>
          </div>
        </div>
      </div>
      <span className={styles["text-mobile"]}>
        <span>
          {filtros?.address?.city &&
            `Personal trainers em ${filtros.address.city}`}
        </span>
      </span>
      <div className={styles["filtrar-ordenar-mobile"]}>
        <button
          onClick={() => {
            setOpenOrder(false);
            setOpenFilter(!openFilter);
          }}
          className={styles["botao-filtrar"]}
        >
          <span className={styles["text04"]}>
            <span>Filtrar</span>
            <br></br>
          </span>
          <img
            alt="filtrarI807"
            src="/external/filtrari807-fa7v.svg"
            className={styles["filtrar"]}
          />
        </button>
        <div className={`botao-ordenar-container ${props.rootClassName} `}>
          <button
            onClick={() => {
              setOpenFilter(false);
              setOpenOrder(!openOrder);
            }}
            className="botao-ordenar-botao-ordenar"
          >
            <span className="botao-ordenar-text">
              <span className="">
                Ordenar
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            <img
              alt="OrdenarI269"
              src="/external/ordenari269-l1wb.svg"
              className="botao-ordenar-ordenar"
            />
          </button>
        </div>
      </div>
      {openOrder && (
        <div className="botao-ordenar-ordenar-aberto">
          <div className="botao-ordenar-data">
            <span className="botao-ordenar-text02">
              <span className="">Data</span>
            </span>
            <div data-thq="thq-dropdown" className="botao-ordenar-thq-dropdown">
              <div
                style={{ gap: 10 }}
                data-thq="thq-dropdown-toggle"
                className="botao-ordenar-dropdown-toggle"
                onClick={() => {
                  setOpenOrder(false);
                  handleFiltros("status", "newest");
                }}
              >
                <span className="botao-ordenar-text04">Mais recente</span>
              </div>
            </div>
          </div>
        </div>
      )}
      {openFilter && (
        <div className={styles["filtros1"]}>
          <div className={styles["filtrar-limpar"]}>
            <span className={styles["text07"]}>
              <span>Filtrar por:</span>
            </span>
            <button
              className={styles["buttons-limpar"]}
              onClick={() => {
                dispatch(
                  setBuscaData({
                    appointmentType: [],
                    availableTime: {
                      morning: null,
                      afternoon: null,
                      evening: null,
                    },
                    services: [],
                    pricing: {
                      min: null,
                      max: null,
                    },
                    periodos: [],
                    gender: [],
                    locationType: [],
                    genderPreference: [],
                    skillLevel: [],
                    ageGroup: [],
                    address: {
                      city: null,
                      uf: null,
                    },
                  })
                );
              }}
            >
              <span className={styles["text09"]}>
                <span>Limpar filtros</span>
              </span>
            </button>
          </div>
          <div className={styles["objetivo-modalidade"]}>
            <div className={styles["objetivo"]}>
              <span className={styles["text11"]}>
                <span>Objetivo</span>
              </span>
              <div className={styles["opcoes"]}>
                {objetivosArr}
                <div
                  className={styles["vermaismenos"]}
                  onClick={() => {
                    if (objetivosOptions.length <= verObjetivos) {
                      setVerObjetivos(5);
                    } else {
                      setVerObjetivos(verObjetivos + 3);
                    }
                  }}
                >
                  <div className={styles["linha-mobile"]}></div>
                  <button className={styles["buttons"]}>
                    <span className={styles["text13"]}>
                      <span>
                        Ver{" "}
                        {objetivosOptions.length <= verObjetivos
                          ? "menos"
                          : "mais"}
                      </span>
                    </span>
                    <img
                      alt="Group1171276758I371"
                      src="/external/group1171276758i371-o1fa.svg"
                      className={styles["arrowmais"]}
                      style={
                        objetivosOptions.length <= verObjetivos
                          ? { transform: "rotate(180deg)" }
                          : {}
                      }
                    />
                  </button>
                  <div className={styles["linha-desk"]}></div>
                </div>
              </div>
            </div>
            {false && (
              <div className={styles["modalidade"]}>
                <span className={styles["text15"]}>
                  <span>Modalidade</span>
                </span>
                <div className={styles["buscar-modalidade"]}>
                  <div className={styles["buscar"]}>
                    <div className={styles["box-input"]}>
                      <input
                        type="text"
                        placeholder="Buscar modalidade"
                        className={styles["textinput1"]}
                      />
                    </div>
                  </div>
                  <button className={styles["botao-buscar"]}>
                    <img
                      alt="LupaI412"
                      src="/external/lupai412-yduq.svg"
                      className={styles["lupa"]}
                    />
                  </button>
                </div>
                <div className={styles["categorias"]}>
                  <div className={styles["forca"]}>
                    <div className={styles["elementos2"]}>
                      <span className={styles["text17"]}>
                        <span>Força e condicionamento</span>
                      </span>
                      <img
                        alt="Group11712767584124"
                        src="/external/group11712767584124-7u6f.svg"
                        className={styles["mostrarmais"]}
                      />
                      <img
                        alt="Group11712767584124"
                        src="/external/group11712767584124-85c6.svg"
                        className={styles["mostrarmenos"]}
                      />
                    </div>
                  </div>
                  <div className={styles["relaxamento"]}>
                    <div className={styles["elementos3"]}>
                      <span className={styles["text19"]}>
                        <span>Bem-estar e relaxamento</span>
                      </span>
                      <img
                        alt="Group11712767584124"
                        src="/external/group11712767584124-vlf.svg"
                        className={styles["mostrarmais1"]}
                      />
                      <img
                        alt="Group11712767584124"
                        src="/external/group11712767584124-85c6.svg"
                        className={styles["mostrarmenos1"]}
                      />
                    </div>
                  </div>
                  <div className={styles["opcoes-esportes"]}>
                    <div className={styles["esportes"]}>
                      <div className={styles["elementos4"]}>
                        <span className={styles["text21"]}>
                          <span>Esportes</span>
                        </span>
                        <img
                          alt="Group11712767584124"
                          src="/external/group11712767584124-vlf.svg"
                          className={styles["mostrarmais2"]}
                        />
                        <img
                          alt="Group11712767584124"
                          src="/external/group11712767584124-85c6.svg"
                          className={styles["mostrarmenos2"]}
                        />
                      </div>
                    </div>
                    <Filtro text="Futebol"></Filtro>
                    <Filtro text="Basquete"></Filtro>
                    <Filtro text="Maratona"></Filtro>
                  </div>
                  <div className={styles["vermaismenos1"]}>
                    <div className={styles["linha-mobile1"]}></div>
                    <button className={styles["buttons1"]}>
                      <span className={styles["text23"]}>
                        <span>Ver mais</span>
                      </span>
                      <img
                        alt="Group1171276758I371"
                        src="/external/group1171276758i371-o1fa.svg"
                        className={styles["arrowmais1"]}
                      />
                      <img
                        alt="Group1171276758I371"
                        src="/external/image-removebg-preview-200h.png"
                        className={styles["arrowmenos1"]}
                      />
                    </button>
                    <div className={styles["linha-desk1"]}></div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className={styles["tipo-atendimento"]}>
            <span className={styles["text25"]}>
              <span>Tipo de atendimento</span>
              <br></br>
            </span>
            <div className={styles["opcoes1"]}>
              <Filtro
                radio={filtros?.appointmentType?.includes("Presencial")}
                text="Presencial"
                onClick={() => {
                  handleFiltros("appointmentType", "Presencial");
                }}
              ></Filtro>
              <Filtro
                radio={filtros?.appointmentType?.includes("On-line")}
                text="On-line"
                onClick={() => {
                  handleFiltros("appointmentType", "On-line");
                }}
              ></Filtro>
              <Filtro
                text="Atende mulheres"
                radio={filtros?.genderPreference?.includes("Mulheres")}
                onClick={() => {
                  handleFiltros("genderPreference", "Mulheres");
                }}
              ></Filtro>
              <Filtro
                text="Atende homens"
                radio={filtros?.genderPreference?.includes("Homens")}
                onClick={() => {
                  handleFiltros("genderPreference", "Homens");
                }}
              ></Filtro>
            </div>
          </div>
          <div className={styles["valorhora"]}>
            <span className={styles["text28"]}>
              <span>Valor por hora</span>
            </span>
            <div className={styles["busca"]}>
              <div className={styles["minimo"]}>
                <div className={styles["box-input1"]}>
                  <input
                    type="number"
                    placeholder="Min"
                    onChange={(e) => {
                      setValoresRange({
                        ...valoresRange,
                        min: e.target.value,
                      });
                    }}
                    className={styles["textinput2"]}
                  />
                </div>
              </div>
              <div className={styles["maximo"]}>
                <div className={styles["box-input2"]}>
                  <input
                    type="number"
                    placeholder="Max"
                    onChange={(e) => {
                      setValoresRange({
                        ...valoresRange,
                        max: e.target.value,
                      });
                    }}
                    className={styles["textinput3"]}
                  />
                </div>
              </div>
              <button
                className={styles["botao-buscar1"]}
                onClick={filtrarPreco}
              >
                <img
                  alt="LupaI371"
                  src="/external/lupai371-6hmn.svg"
                  className={styles["lupa1"]}
                />
              </button>
            </div>
          </div>
          <div className={styles["periodo-atendimento"]}>
            <span className={styles["text30"]}>
              <span>Período de atendimento</span>
              <br></br>
            </span>
            <div className={styles["opcoes2"]}>
              <Filtro
                radio={filtros?.availableTime?.morning}
                text="Manhã"
                onClick={() => {
                  filtrarAvailableTime("morning");
                }}
              ></Filtro>
              <Filtro
                radio={filtros?.availableTime?.afternoon}
                text="Tarde"
                onClick={() => {
                  filtrarAvailableTime("afternoon");
                }}
              ></Filtro>
              <Filtro
                radio={filtros?.availableTime?.evening}
                text="Noite"
                onClick={() => {
                  filtrarAvailableTime("evening");
                }}
              ></Filtro>
            </div>
          </div>
          <div className={styles["genero"]}>
            <span className={styles["text33"]}>Gênero (Personal)</span>
            <div className={styles["opcoes3"]}>
              <Filtro
                radio={filtros?.gender?.includes("Feminino")}
                text="Mulher"
                onClick={() => {
                  handleFiltros("gender", "Feminino");
                }}
              ></Filtro>
              <Filtro
                radio={filtros?.gender?.includes("Masculino")}
                text="Homem"
                onClick={() => {
                  handleFiltros("gender", "Masculino");
                }}
              ></Filtro>
            </div>
          </div>
          <div className={styles["onde-atende"]}>
            <span className={styles["text34"]}>
              <span>Onde atende</span>
              <br></br>
            </span>
            <div className={styles["opcoes4"]}>
              <Filtro
                radio={filtros?.locationType?.includes("Academias")}
                text="Academia"
                onClick={() => {
                  handleFiltros("locationType", "Academias");
                }}
              ></Filtro>
              <Filtro
                radio={filtros?.locationType?.includes("Local próprio")}
                text="Local próprio"
                onClick={() => {
                  handleFiltros("locationType", "Local próprio");
                }}
              ></Filtro>
              <Filtro
                radio={filtros?.locationType?.includes("Casa do aluno")}
                text="Casa do aluno"
                onClick={() => {
                  handleFiltros("locationType", "Casa do aluno");
                }}
              ></Filtro>
              <Filtro
                radio={filtros?.locationType?.includes("Local público")}
                text="Local público"
                onClick={() => {
                  handleFiltros("locationType", "Local público");
                }}
              ></Filtro>
            </div>
          </div>
          <div className={styles["nivel-aluno"]}>
            <span className={styles["text37"]}>
              <span>Nível (Aluno)</span>
              <br></br>
            </span>
            <div className={styles["opcoes5"]}>
              <Filtro
                radio={filtros?.skillLevel?.includes("Iniciante")}
                text="Iniciante"
                onClick={() => {
                  handleFiltros("skillLevel", "Iniciante");
                }}
              ></Filtro>
              <Filtro
                radio={filtros?.skillLevel?.includes("Intermediário")}
                text="Intermediário"
                onClick={() => {
                  handleFiltros("skillLevel", "Intermediário");
                }}
              ></Filtro>
              <Filtro
                text="Avançado"
                radio={filtros?.skillLevel?.includes("Avançado")}
                onClick={() => {
                  handleFiltros("skillLevel", "Avançado");
                }}
              ></Filtro>
            </div>
          </div>
          <div className={styles["idade-aluno"]}>
            <span className={styles["text40"]}>
              <span>Faixa Etária (Alunos)</span>
              <br></br>
            </span>
            <div className={styles["opcoes6"]}>
              <Filtro
                text="Crianças (12-17)"
                radio={filtros?.ageGroup?.includes("Jovens")}
                onClick={() => {
                  handleFiltros("ageGroup", "Jovens");
                }}
              ></Filtro>
              <Filtro
                text="Adultos (18-50)"
                radio={filtros?.ageGroup?.includes("Adultos")}
                onClick={() => {
                  handleFiltros("ageGroup", "Adultos");
                }}
              ></Filtro>
              <Filtro
                text="Idosos (51 acima)"
                radio={filtros?.ageGroup?.includes("Idosos")}
                onClick={() => {
                  handleFiltros("ageGroup", "Idosos");
                }}
              ></Filtro>
            </div>
          </div>
          <button className={` ${styles["botao-filtrar-mobile"]} button `}>
            <span className={styles["text43"]}>
              <span>Filtrar</span>
            </span>
            <img
              alt="filtrarI361"
              src="/external/filtrari361-5nt.svg"
              className={styles["filtrar1"]}
            />
          </button>
        </div>
      )}
    </div>
  );
};

export default Filtros;
