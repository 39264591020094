import styles from '../regulamento.module.css'
import ImagemPromocao from './ImagemPromocao'
import TextCinza from './TextCinza'

const SecaoComoParticipar = () => (
    <div className={`${styles['secao-como-participar']} ${styles['secao']}`}>
        <h3 className={styles['titulo-secao']}>Como participar</h3>

        <ul>
            <li><TextCinza>Cadastre-se no site www.personaltrainer.com.br.</TextCinza></li>
            <li><TextCinza>Verifique seu perfil e mantenha seu anúncio ativo até a data do sorteio.</TextCinza></li>
            <li><TextCinza>Publique a arte da promoção no seu Instagram (feed) em modo público, incluindo a hashtag #personaltrainer.com.br. Arte disponível para download no botão abaixo</TextCinza></li>
        </ul>

        <div className={styles['group-img-como-participar']}>
            <ImagemPromocao title="Feed" isFeed={true} imgUrl="/external/foto-promocao-feed.png" altText="Arte de divulgação da promoção para feed do Instagram" />
            <ImagemPromocao title="Stories" isFeed={false} imgUrl="/external/foto-promocao-stories.png" altText="Arte de divulgação da promoção para stories do Instagram" />
        </div>

        <TextCinza>Válido para maiores de 18 anos, residentes no Brasil.</TextCinza>
        <TextCinza>Data do Sorteio: 28 de dezembro de 2024, através do nosso Instagram: @personalTrainer.com.br.</TextCinza>

    </div>
)

export default SecaoComoParticipar