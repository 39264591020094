import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setBuscaData } from "../../redux/actions/buscaActions";
import { Autocomplete } from "@mui/joy";
import axios from "axios";
import { toast } from "react-toastify";
import { searchMunicipios } from "../../services/brasilApi";

import Radio from "../Radio/radio";
import styles from "./busca.module.css";

const options = [
  { value: null, label: "Todo o Brasil" },
  { value: "Norte", label: "Região Norte" },
  { value: "Nordeste", label: "Região Nordeste" },
  { value: "Centro-Oeste", label: "Região Centro-Oeste" },
  { value: "Sudeste", label: "Região Sudeste" },
  { value: "Sul", label: "Região Sul" },
  { value: "AC", label: "Acre - AC" },
  { value: "AL", label: "Alagoas - AL" },
  { value: "AP", label: "Amapá - AP" },
  { value: "AM", label: "Amazonas - AM" },
  { value: "BA", label: "Bahia - BA" },
  { value: "CE", label: "Ceará - CE" },
  { value: "DF", label: "Distrito Federal - DF" },
  { value: "ES", label: "Espírito Santo - ES" },
  { value: "GO", label: "Goiás - GO" },
  { value: "MA", label: "Maranhão - MA" },
  { value: "MT", label: "Mato Grosso - MT" },
  { value: "MS", label: "Mato Grosso do Sul - MS" },
  { value: "MG", label: "Minas Gerais - MG" },
  { value: "PA", label: "Pará - PA" },
  { value: "PB", label: "Paraíba - PB" },
  { value: "PR", label: "Paraná - PR" },
  { value: "PE", label: "Pernambuco - PE" },
  { value: "PI", label: "Piauí - PI" },
  { value: "RJ", label: "Rio de Janeiro - RJ" },
  { value: "RN", label: "Rio Grande do Norte - RN" },
  { value: "RS", label: "Rio Grande do Sul - RS" },
  { value: "RO", label: "Rondônia - RO" },
  { value: "RR", label: "Roraima - RR" },
  { value: "SC", label: "Santa Catarina - SC" },
  { value: "SP", label: "São Paulo - SP" },
  { value: "SE", label: "Sergipe - SE" },
  { value: "TO", label: "Tocantins - TO" },
];
const estadosMap = {
  Acre: "AC",
  Alagoas: "AL",
  Amapá: "AP",
  Amazonas: "AM",
  Bahia: "BA",
  Ceará: "CE",
  "Distrito Federal": "DF",
  "Espírito Santo": "ES",
  Goiás: "GO",
  Maranhão: "MA",
  "Mato Grosso": "MT",
  "Mato Grosso do Sul": "MS",
  "Minas Gerais": "MG",
  Pará: "PA",
  Paraíba: "PB",
  Paraná: "PR",
  Pernambuco: "PE",
  Piauí: "PI",
  "Rio de Janeiro": "RJ",
  "Rio Grande do Norte": "RN",
  "Rio Grande do Sul": "RS",
  Rondônia: "RO",
  Roraima: "RR",
  "Santa Catarina": "SC",
  "São Paulo": "SP",
  Sergipe: "SE",
  Tocantins: "TO",
};

const Busca = (props) => {
  const history = useHistory();
  const filtros = useSelector((state) => state.busca);
  const dispatch = useDispatch();
  const [BuscaMobileAberto, setBuscaMobileAberto] = useState(false);
  const [isHome, setIsHome] = useState(props.isHome || false);
  const [atendimentoTipo, setAtendimentoTipo] = useState("Presencial");
  const [opcoesOpen, setOpcoesOpen] = useState(false);
  const inputRef = useRef(null);
  const [cidadeDigitada, setCidadeDigitada] = useState("");
  const [cidadesFiltradas, setCidadesFiltradas] = useState([]);
  const [opcaoSelecionada, setOpcaoSelecionada] = useState(options[0]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedCity, setSelectedCity] = useState("");
  const loading = open && cidadesFiltradas.length === 0;

  // useEffect(() => {
  //   console.log(cidadeDigitada, cidadesFiltradas);
  // }, [cidadesFiltradas])

  const handleClickLocation = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const lat = position.coords.latitude;
        const long = position.coords.longitude;
        fetch(
          `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${long}`
        )
          .then((response) => response.json())
          .then(async (data) => {
            const cidade = data.address.city;

            const resLocation = await axios.get(
              `${process.env.REACT_APP_API_URL}/api/location?search=${cidade}&strict=true`
            );
            const city = resLocation.data.data[0];

            dispatch(
              setBuscaData({
                ...filtros,
                address: {
                  city: city.municipality,
                  cidadeNome: cidade,
                  uf: estadosMap[data.address.state],
                },
              })
            );

            history.push(`/busca`);
          })
          .catch((error) => console.error(error));
      },
      (error) => {
        console.error(error);
      },
      { timeout: 30000 }
    );
  };

  const handleTipoAtendimentoClick = (tipo) => {
    setAtendimentoTipo(tipo);
    setOpcoesOpen(false);
    setCidadeDigitada("");
    setDropdownOpen(false);
  };

  const handleBusca = (index) => {
    // console.log('handle busca')
    if (cidadeDigitada == "" && isHome && atendimentoTipo == "Presencial") {
      toast.error("Você deve digitar a cidade.");
      return;
    }
    let updated = {
      ...filtros,
      appointmentType: [atendimentoTipo]
    };
    if (cidadesFiltradas && cidadesFiltradas.length > 0) {
      console.log(cidadesFiltradas[index]);
      updated.address = {
        city: cidadesFiltradas[index].municipality,
        uf: cidadesFiltradas[index].state,
        cidadeNome: cidadesFiltradas[index].municipality,
      };

      setCidadeDigitada("");
      setCidadesFiltradas([]);
    }
    dispatch(setBuscaData(updated));
    history.push("/busca");
  };

  useEffect(() => {
    (async function () {
      if (cidadeDigitada !== "" && cidadeDigitada.length >= 3) {
        try {
          const response = await searchMunicipios(cidadeDigitada);
          if (response.length > 0) {
            // console.log(response);
            setCidadesFiltradas(response);
          }
          if (response.length === 0 || response[0].score < 0.25)
            setCidadesFiltradas([
              { emptyStateMsg: "Não encontramos anúncios nessa cidade" },
            ]);
        } catch (error) {
          console.error("Erro ao buscar dados das cidades: ", error);
        }
      } else {
        setCidadesFiltradas([]);
      }
    })();
  }, [cidadeDigitada]);

  useEffect(() => {
    if (!open) {
      setCidadesFiltradas([]);
    }
  }, [open]);

  useEffect(() => {
    if (BuscaMobileAberto && inputRef.current) {
      inputRef.current.focus();
    }
  }, [BuscaMobileAberto]);

  const DropdownRegioes = () => (
    <div className={styles["container"]}>
      <div className={styles["dropdown"]}>
        <div
          className={styles["option-padro"]}
          onClick={() => {
            setDropdownOpen(!dropdownOpen);
          }}
        >
          <span className={styles["todo-brasil"]}>
            <span>{opcaoSelecionada.label}</span>
          </span>
          <img
            src="/external/group11712767583613-pnss.svg"
            alt="Group11712767583613"
            className={styles["chevron"]}
          />
        </div>
        {dropdownOpen && (
          <div className={styles["lista"]}>
            {options.map((option, index) => (
              <span
                onClick={() => {
                  const isState =
                    option.value && option.value.length === 2;
                  setOpcaoSelecionada(option);
                  dispatch(
                    setBuscaData({
                      ...filtros,
                      address: {
                        region: isState ? null : option.value,
                        regionName: option.label,
                        state: isState ? option.value : null,
                      },
                    })
                  );
                  setDropdownOpen(false);
                }}
                key={index}
                value={option.value}
                className={styles["text1"]}
              >
                {option.label}
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  )

  return (
    <div
      className={`${styles["busca"]} ${isHome ? styles["buscaHome"] : ""}`}
      style={!isHome && window.innerWidth < 1250 ? { marginLeft: "auto" } : {}}
    >
      {isHome && (
        <div className={styles["busca-home"]}>
          <div className={styles["opcoes"]}>
            <div
              className={`${styles["online"]} ${atendimentoTipo === "On-line" ? styles["ativo"] : ""
                }`}
              onClick={() => {
                setAtendimentoTipo("On-line");
                setCidadeDigitada("");
              }}
            >
              <span className={styles["text"]}>
                <span>On-line</span>
              </span>
              <Radio valor={atendimentoTipo === "On-line"}></Radio>
            </div>
            <div
              className={`${styles["presencial"]} ${atendimentoTipo === "Presencial" ? styles["ativo"] : ""
                }`}
              onClick={() => {
                setAtendimentoTipo("Presencial");
                setCidadeDigitada("");
              }}
            >
              <span className={styles["text02"]}>Presencial</span>
              <Radio valor={atendimentoTipo === "Presencial"}></Radio>
            </div>
          </div>
          <div className={styles["cidades"]}>
            {atendimentoTipo === "Presencial" ? (
              <div className={styles["container"]}>
                <div className={styles["cidades1"]}>
                  <div className={styles["presencial1"]}>
                    <input
                      type="text"
                      placeholder="Cidade"
                      value={cidadeDigitada}
                      onChange={(e) => setCidadeDigitada(e.target.value)}
                      className={styles["textinput"]}
                    // onBlur={() => setCidadesFiltradas([])} esse blur faz o clicar em cidades do dropdown ficar inconsistente
                    />

                    <img
                      onClick={handleClickLocation}
                      alt="local2273"
                      src="/external/localicon.svg"
                      className={styles["localicon"]}
                    />
                  </div>
                  {cidadesFiltradas && cidadesFiltradas.length > 0 && (
                    <ul className={styles["lista-cidades"]}>
                      {cidadesFiltradas.map((cidade, index) => (
                        <li
                          onClick={() => {
                            if (cidade?.municipality) handleBusca(index);
                            setCidadesFiltradas([]);
                          }}
                          key={cidade.municipality ? `${cidade.municipality}-${cidade.state}` : cidade.emptyStateMsg}
                        >
                          {!cidade?.emptyStateMsg
                            ? `${cidade?.municipality} (${cidade?.state})`
                            : cidade.emptyStateMsg}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                {dropdownOpen && (
                  <div className={styles["lista"]}>
                    {cidadesFiltradas.map((option, index) => (
                      <span
                        onClick={() => {
                          setOpcaoSelecionada(option);
                          setDropdownOpen(false);
                        }}
                        key={index}
                        value={option.value}
                        className={styles["text1"]}
                      >
                        {option.label}
                      </span>
                    ))}
                  </div>
                )}
              </div>
            ) : (
              <DropdownRegioes/>
              // <div className={styles["container"]}>
              //   <div className={styles["dropdown"]}>
              //     <div
              //       className={styles["option-padro"]}
              //       onClick={() => {
              //         setDropdownOpen(!dropdownOpen);
              //       }}
              //     >
              //       <span className={styles["todo-brasil"]}>
              //         <span>{opcaoSelecionada.label}</span>
              //       </span>
              //       <img
              //         src="/external/group11712767583613-pnss.svg"
              //         alt="Group11712767583613"
              //         className={styles["chevron"]}
              //       />
              //     </div>
              //     {dropdownOpen && (
              //       <div className={styles["lista"]}>
              //         {options.map((option, index) => (
              //           <span
              //             onClick={() => {
              //               const isState =
              //                 option.value && option.value.length === 2;
              //               setOpcaoSelecionada(option);
              //               dispatch(
              //                 setBuscaData({
              //                   ...filtros,
              //                   address: {
              //                     region: isState ? null : option.value,
              //                     regionName: option.label,
              //                     state: isState ? option.value : null,
              //                   },
              //                 })
              //               );
              //               setDropdownOpen(false);
              //             }}
              //             key={index}
              //             value={option.value}
              //             className={styles["text1"]}
              //           >
              //             {option.label}
              //           </span>
              //         ))}
              //       </div>
              //     )}
              //   </div>
              // </div>
            )}
          </div>
          <div className={styles["frame1171276991"]}>
            <button
              className={styles["buttons"]}
              onClick={() => handleBusca(0)}
            >
              <span className={styles["text09"]}>
                <span>Buscar</span>
              </span>
              <img
                alt="LupaI361"
                src="/external/lupai361-ouxw.svg"
                className={styles["lupa"]}
              />
            </button>
          </div>
        </div>
      )}
      {!isHome && (
        <div className={styles["busca-header"]}>
          <div
            className={styles["busca-desk"]}
            style={
              opcoesOpen
                ? { borderRadius: "8px 8px 8px 0" }
                : cidadesFiltradas && cidadesFiltradas.length > 0
                  ? { borderRadius: "8px 8px 0 0", borderBottomWidth: "0px" }
                  : {}
            }
          >
            <div
              className={styles["opcoes1"]}
              onClick={() => {
                if (!opcoesOpen === true) {
                  setCidadesFiltradas([]);
                }

                setOpcoesOpen(!opcoesOpen);
              }}
            >
              <span className={styles["text11"]}>
                <span>{atendimentoTipo}</span>
              </span>
              {!opcoesOpen && (
                <img
                  alt="Group11712767582273"
                  src="/external/group11712767582273-3ujc.svg"
                  className={styles["arrowicon"]}
                />
              )}
              {opcoesOpen && (
                <img
                  alt="Group11712767582273"
                  src="/external/group11712767582273-3ujc.svg"
                  className={styles["arrowicon-up"]}
                />
              )}
              {opcoesOpen && (
                <div
                  className={[
                    styles["container"],
                    styles["drop-presencial-online"],
                  ].join(" ")}
                >
                  <span
                    className={styles["text13"]}
                    onClick={() => handleTipoAtendimentoClick("Presencial")}
                  >
                    Presencial
                  </span>
                  <span
                    className={styles["text14"]}
                    onClick={() => handleTipoAtendimentoClick("On-line")}
                  >
                    On-line
                  </span>
                </div>
              )}
            </div>
            {atendimentoTipo === "Presencial" ?
              (<div className={styles["cidades1"]}>
                <div className={styles["presencial1"]}>
                  <input
                    type="text"
                    placeholder="Cidade"
                    value={cidadeDigitada}
                    onChange={(e) => setCidadeDigitada(e.target.value)}
                    className={styles["textinput"]}
                  />

                  <img
                    onClick={handleClickLocation}
                    alt="local2273"
                    src="/external/localicon.svg"
                    className={styles["localicon"]}
                  />
                </div>
                {cidadesFiltradas && cidadesFiltradas.length > 0 && (
                  <ul className={styles["lista-cidades"]}>
                    {cidadesFiltradas.map((cidade, index) => (
                      <li
                        onClick={() => {
                          handleBusca(index);
                          setCidadesFiltradas([]);
                        }}
                        key={index}
                      >
                        {cidade?.municipality} ({cidade?.state})
                      </li>
                    ))}
                  </ul>
                )}
              </div>)
              :
              (
                <DropdownRegioes/>
                // <div className={styles["container"]}>
                //   <div className={styles["dropdown"]}>
                //     <div
                //       className={styles["option-padro"]}
                //       onClick={() => {
                //         setDropdownOpen(!dropdownOpen);
                //       }}
                //     >
                //       <span className={styles["todo-brasil"]}>
                //         <span>{opcaoSelecionada.label}</span>
                //       </span>
                //       <img
                //         src="/external/group11712767583613-pnss.svg"
                //         alt="Group11712767583613"
                //         className={styles["chevron"]}
                //       />
                //     </div>
                //     {dropdownOpen && (
                //       <div className={styles["lista"]}>
                //         {options.map((option, index) => (
                //           <span
                //             onClick={() => {
                //               const isState =
                //                 option.value && option.value.length === 2;
                //               setOpcaoSelecionada(option);
                //               dispatch(
                //                 setBuscaData({
                //                   ...filtros,
                //                   address: {
                //                     region: isState ? null : option.value,
                //                     regionName: option.label,
                //                     state: isState ? option.value : null,
                //                   },
                //                 })
                //               );
                //               setDropdownOpen(false);
                //             }}
                //             key={index}
                //             value={option.value}
                //             className={styles["text1"]}
                //           >
                //             {option.label}
                //           </span>
                //         ))}
                //       </div>
                //     )}
                //   </div>
                // </div>
              )
            }
            <button
              className={styles["botao-busca"]}
              onClick={() => handleBusca(0)}
            >
              <img
                alt="LupaI227"
                src="/external/lupai227-pa3x.svg"
                className={styles["lupa1"]}
              />
            </button>
          </div>
          {!BuscaMobileAberto && (
            <button
              onClick={() => {
                setBuscaMobileAberto(!BuscaMobileAberto);
              }}
              className={` ${styles["botao-busca-mobile"]} button`}
            >
              <img
                alt="lupaI864"
                src="/external/lupai864-g9vg.svg"
                className={styles["lupa2"]}
              />
            </button>
          )}
          {BuscaMobileAberto && (
            <div className={styles["busca-mobile"]}>
              <div className={styles["container1"]}>
                <div className={styles["opcoes2"]}>
                  <div className={styles["online3"]}>
                    <span className={styles["text21"]}>
                      <span>On-line</span>
                    </span>
                  </div>
                  <div className={styles["presencial2"]}>
                    <span className={styles["text23"]}>Presencial</span>
                  </div>
                </div>
                <div className={styles["cidades2"]}>
                  <input
                    ref={inputRef}
                    type="text"
                    placeholder="Cidade"
                    className={styles["input-presencial1"]}
                    onBlur={() => {
                      setTimeout(() => {
                        setBuscaMobileAberto(false);
                      }, 200);
                    }}
                  />
                  <div className={styles["container"]}>
                    <div className={styles["dropdown"]}>
                      <div className={styles["option-padro"]}>
                        <span className={styles["todo-brasil"]}>
                          <span>Todo o Brasil</span>
                        </span>
                        <img
                          src="/group11712767583613-pnss.svg"
                          alt="Group11712767583613"
                          className={styles["chevron"]}
                        />
                      </div>
                      <div className={styles["lista"]}>
                        {options.map((option, index) => (
                          <span
                            key={index}
                            value={option.value}
                            className={styles["text1"]}
                          >
                            {option.label}
                          </span>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  className={styles["buttons1"]}
                  onClick={() => handleBusca(0)}
                >
                  <span className={styles["text30"]}>
                    <span>Buscar</span>
                  </span>
                  <img
                    alt="LupaI361"
                    src="/external/lupai361-ouxw.svg"
                    className={styles["lupa3"]}
                  />
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Busca;
