import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setBuscaData } from "../../redux/actions/buscaActions";
import axios from "axios";
import { toast } from "react-toastify";

import BotaoOrdenar from "../../components/botao-ordenar";
import Filtros from "../../components/Filtros/filtros";
import Header from "../../components/Header/header";
import Rodape from "../../components/Rodape/rodape";
import styles from "./personaltrainers.module.css";
import { getTrainerServices } from "../../services/server/TrainerServices";

const TrainersList = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [trainerServices, setTrainerServices] = useState();
  const filtros = useSelector((state) => state.busca);
  const [anuncios, setAnuncios] = useState(null);
  const [anunciosFiltrados, setAnunciosFiltrados] = useState(null);
  const [showFiltros, setShowFiltros] = useState(true);
  const [pages, setPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const obterPrecoFormatado = (anuncio) => {
    if (!anuncio?.planos?.valor) {
      return "Valor a combinar";
    }

    const { presencial, online } = anuncio.planos.valor;

    if (typeof presencial !== "number" && typeof online !== "number") {
      return "Valor a combinar";
    }

    let valorMenor =
      typeof presencial !== "number"
        ? online
        : typeof online !== "number"
        ? presencial
        : Math.min(presencial, online);

    return (
      <>
        A partir de{" "}
        <strong>
          R$ {new Intl.NumberFormat("pt-BR").format(valorMenor)}/h
        </strong>
      </>
    );
  };
  const fetchPersonais = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/trainer-ads`,
        {
          params: {
            page: currentPage,
            region: filtros?.address?.region || null,
            state: filtros?.address?.state || null,
            city: filtros?.address?.city,
            services: filtros?.services?.toString() || null,
            appointmentType: filtros?.appointmentType?.toString() || null,
            availableTime: {
              morning: filtros?.availableTime?.morning ?? null,
              afternoon: filtros?.availableTime?.afternoon ?? null,
              evening: filtros?.availableTime?.evening ?? null,
            },
            minPrice: filtros?.pricing?.min || null,
            maxPrice: filtros?.pricing?.max || null,
            locationType:
              filtros?.locationType?.toString().split(",")[0] || null,
            // periodo: filtros?.periodos || null,
            gender: filtros?.gender || null,
            locationType:
              filtros?.locationType?.toString().split(",")[0] || null,
            skillLevel: filtros?.skillLevel?.toString().split(",")[0] || null,
            ageGroup: filtros?.ageGroup?.toString().split(",")[0] || null,
            genderPreference:
              filtros?.genderPreference?.toString().split(",")[0] || null,
          },
        }
      );
      console.log(response);
      const totalPages = response?.data?.totalPages ?? 1; //Math.ceil(response.data.results / 10);

      const p = [];
      for (let i = 0; i < totalPages; i++) {
        p.push(i + 1);
      }
      setPages(p);
      if (currentPage > totalPages) setCurrentPage(1);

      //filtrar fora o apoio nutricional no frontend (Precisa de uma limpada/refatorada/comentada)---------------------------------------------
      if (filtros?.services?.includes("Apoio nutricional")) {
        const idApoioNutricional = trainerServices?.filter(
          (serv) => serv.title === "Apoio nutricional"
        )[0]._id;
        let anunciosAPI = response.data.data;

        //filtrar Apoio nutricional da lista de serviços de Personais que não tem plano Premium
        anunciosAPI.forEach((ad) => {
          const podeTerApoioNutricional = ad.planDetails.premiumServices;
          if (!podeTerApoioNutricional && ad.services?.length > 0)
            ad.services = ad.services.filter(
              (servId) => servId !== idApoioNutricional
            );
        });

        //filtrar efetivamente profissionais que não tenham Apoio Nutricional
        const filtrosServicosIds = filtros.services.map(
          (nomeServ) =>
            trainerServices.find(
              (trainderServ) => trainderServ.title === nomeServ
            )?._id
        );
        anunciosAPI = anunciosAPI.filter((ad) =>
          ad.services?.some((serv) => filtrosServicosIds.includes(serv))
        );

        //colocar lista filtrada na resposta da API para ser atualizada no state Anuncions
        response.data.data = anunciosAPI;
      }

      //fim de filtro----------------------------------------------------------------------------------------------------
      setAnuncios(response.data);
    } catch (err) {
      console.log(err);
      setAnuncios(null);
    }
  };

  //efeito para pegar serviços do db
  useEffect(() => {
    getTrainerServices()
      .then((res) => setTrainerServices(res))
      .catch((err) => toast.error(err));
  }, []);

  useEffect(() => {
    console.log("Filtros: ", filtros);
    if (filtros) {
      (async function () {
        await fetchPersonais();
      })();
    }
  }, [filtros, currentPage]);

  return (
    <div className={styles["container"]}>
      <Header />
      <div className={styles["container1"]}>
        <div className={styles["title-ordenar-desk"]}>
          <span className={styles["text"]}>
            {filtros.address &&
            (filtros.address.cidadeNome || filtros.address.regionName) ? (
              <span>
                Personal trainers em{" "}
                {filtros.address.regionName ||
                  `${filtros.address.cidadeNome}-${filtros.address.uf}`}
              </span>
            ) : (
              <span>Personal trainers no Brasil</span>
            )}
          </span>
          {/* <BotaoOrdenar></BotaoOrdenar> */}
        </div>
        <div className={styles["conteudo"]}>
          <Filtros></Filtros>
          <div className={styles["lista"]}>
            <div className={styles["trainers"]}>
              {anuncios?.data &&
                anuncios?.data?.map((anuncio, index) => (
                  <Link
                    to={`/personal/${anuncio?.username}`}
                    key={index}
                    className={styles["trainer"]}
                    style={{
                      borderColor: anuncio?.planDetails.featuredAd
                        ? "rgba(251, 166, 0, 1)"
                        : null,
                      cursor: "pointer",
                    }}
                  >
                    {anuncio?.planDetails.featuredAd && (
                      <div className={styles["destaque"]}>
                        <span className={styles["text02"]}>
                          <span>DESTAQUE</span>
                        </span>
                      </div>
                    )}
                    {anuncio?.planDetails.verifiedContact && (
                      <div className={styles["verificado"]}>
                        <span className={styles["text04"]}>
                          <span>Verificado</span>
                        </span>
                        <img
                          alt="registrosI341"
                          src="/external/registrosi341-3nfq.svg"
                          className={styles["registros"]}
                        />
                      </div>
                    )}
                    <img
                      alt="pastedImage"
                      src={anuncio?.photo}
                      className={styles["foto"]}
                    />
                    <span className={styles["nome"]}>
                      {anuncio?.personalName}
                    </span>
                    <span className={styles["descricao"]}>
                      <span>
                        {anuncio?.briefDescription &&
                        anuncio.briefDescription.length > 104
                          ? anuncio.briefDescription.substring(0, 104) + "..."
                          : anuncio?.briefDescription}
                      </span>
                    </span>
                    <div className={styles["extras"]}>
                      <div className={styles["infos"]}>
                        {anuncio?.rating && anuncio?.rating.quantity > 0 && (
                          <div className={styles["avaliacao2"]}>
                            <img
                              alt="VectorI341"
                              src="/external/vectori341-6ccc.svg"
                              className={styles["estrelaicon"]}
                            />
                            <span className={styles["textnota"]}>
                              {anuncio?.rating.average}
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: " ",
                                }}
                              />
                            </span>
                            <span className={styles["textqnt"]}>
                              <span>({anuncio?.rating.quantity})</span>
                            </span>
                          </div>
                        )}

                        {anuncio?.planDetails.premiumServices &&
                          anuncio.services.includes("000000010000000000000000") && (
                            <div className={styles["apoio-nutricional"]}>
                              <img
                                alt="VectorI341"
                                src="/external/vectori341-b41.svg"
                                className={styles["appleicon"]}
                              />
                              <span className={styles["text08"]}>
                                <span>Apoio nutricional</span>
                              </span>
                            </div>
                          )}
                      </div>
                      <div className={styles["valor"]}>
                        <span className={styles["text10"]}>
                          {obterPrecoFormatado(anuncio)}
                        </span>
                      </div>
                    </div>
                  </Link>
                ))}
            </div>
            {anuncios === null || anuncios.data.length === 0 ? (
              <div className={styles["trainers"]}>
                <span className={styles["text"]}>
                  Nenhum personal encontrado.
                </span>
              </div>
            ) : (
              ""
            )}
            <div className={styles["paginacao"]}>
              {pages.length > 0 &&
                pages.map((p) => {
                  return (
                    <div
                      onClick={() => setCurrentPage(p)}
                      className={
                        currentPage === p
                          ? styles["page-atual"]
                          : styles["page"]
                      }
                    >
                      <span className={styles["texte18"]}>{p}</span>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <Rodape></Rodape>
    </div>
  );
};

export default TrainersList;
