const YoutubeEmbed = ({ src, className="" }) => (
        <iframe
            width="100%"
            height="100%"
            src={src}
            className={className}
            // frameBorder="0"
            // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            // allowFullScreen
            // title="Embedded youtube"
        />
    // <div className="video-responsive">
    // </div>
);

export default YoutubeEmbed