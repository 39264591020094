import React, { useState, useRef } from "react";
import { toast } from "react-toastify";
import axios from "axios";

import MenuLogado from "../../../components/MenuLogado/menu-logado";
import Input from "../../../components/Input/input";
import Rodape from "../../../components/Rodape/rodape";
import Header from "../../../components/Header/header";

import styles from "./suporte.module.css";
import { useSelector } from "react-redux";

const Suporte = (props) => {
  const [assunto, setAssunto] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [descricao, setDescricao] = useState("");
  const [imagem, setImagem] = useState({});
  const [loading, setLoading] = useState(false);
  const inputArquivoRef = useRef(null);
  const usuario = useSelector((state) => state.usuario);

  const handleAssuntoChange = (e) => {
    setAssunto(e);
  };

  const handleWhatsAppChange = (e) => {
    setWhatsapp(e);
  };

  const handleDescricaoChange = (e) => {
    setDescricao(e);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const file = inputArquivoRef.current.files[0];
    const formData = new FormData();
    formData.append("attachment", file);

    try {
      // if (file) {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/email/send-contact-email`,
        {
          email: usuario.email,
          subject: assunto,
          whatsapp: whatsapp,
          description: descricao,
          attachment: formData ? formData : null,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("ptr_145769130149")}`,
          },
        }
      );

      toast.success("E-mail enviado com sucesso.");
      // } else {
      //   throw new Error("Insira uma imagem que descreva seu problema.");
      // }
    } catch (error) {
      toast.error(
        error.response && error.response.data && error.response.data.error
          ? error.response.data.error
          : error.message ?? "Erro ao enviar e-mail"
      );
    } finally {
      setLoading(false);
      setAssunto("");
      setDescricao("");
      setWhatsapp("");
      setImagem({});
      inputArquivoRef.current.value = "";
    }
  };

  const uploadImagem = async (event) => {
    const file = event.target.files[0];
    try {
      const fileName = file.name;
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = async () => {
        const arrayBuffer = reader.result;
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/upload/imagem`,
            arrayBuffer,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "x-file-name": fileName,
                "Content-Type": "application/octet-stream",
              },
            }
          );

          setImagem({
            url: response.data.imageUrl,
            nome: fileName,
          });
        } catch (error) {
          if (error?.response?.data?.error) {
            toast.error(error.response.data.error);
          } else {
            toast.error("Algo deu errado");
          }
          console.error(error);
        }
      };
    } catch (error) {
      toast.error("Erro ao ler o arquivo");
      console.error(error);
    }
  };

  return (
    <div className={styles["container"]}>
      <Header />
      <div className={styles["container1"]}>
        {window.innerWidth > 991 && <MenuLogado></MenuLogado>}
        <div className={styles["container2"]}>
          <div className={styles["suporte"]}>
            <div className={styles["cabecalho"]}>
              <span className={styles["text"]}>Suporte</span>
            </div>
            <div className={styles["infos"]}>
              <span className={styles["text1"]}>
                Descreva seu problema e envie para o nosso suporte. Você
                receberá suas respostas pelo e-mail cadastrado.
              </span>
              <div className={styles["enviar-email"]}>
                <div className={styles["inputs"]}>
                  <Input
                    Label="Assunto"
                    value={assunto}
                    onChange={handleAssuntoChange}
                  ></Input>
                  <Input
                    Label="Whatsapp"
                    value={whatsapp}
                    mask="+55 (99) 99999-9999"
                    Placeholder="+55 (00) 00000-0000"
                    onChange={handleWhatsAppChange}
                  ></Input>
                  <Input
                    Label="Descrição"
                    tipo={2}
                    value={descricao}
                    onChange={handleDescricaoChange}
                  ></Input>
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    ref={inputArquivoRef}
                    style={{ display: "none" }}
                  />
                  {/* <div className={styles["btninfo"]}>
                    <button
                      className={styles["botao-enviar1"]}
                      onClick={() => {
                        inputArquivoRef.current.click();
                      }}
                    >
                      <span className={styles["text158"]}>
                        {imagem?.nome ? "Alterar" : "Anexar"} imagem
                      </span>
                      <img
                        alt="pastedImage"
                        src="/external/pastedimage-i96s-200h.png"
                        className={styles["arrowicon2"]}
                      />
                      <img
                        alt="pastedImage"
                        src="/external/pastedimage-e4pp-200h.png"
                        className={styles["plusicon1"]}
                      />
                    </button>
                    <span className={styles["text-config1"]}>
                      <span>
                        {imagem?.nome
                          ? imagem.nome
                          : "Configuração da imagem: PNG, JPG, até 2 mb."}
                      </span>
                    </span>
                  </div> */}
                </div>
              </div>
              <button
                className={`${styles["botao-enviar"]} ${
                  loading ? styles["disabled"] : ""
                }`}
                onClick={(e) => handleSubmit()}
              >
                <span className={styles["text2"]}>
                  <span>{loading ? "Enviando..." : "Enviar"}</span>
                </span>
                {loading && <div className="spinner"></div>}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Rodape></Rodape>
    </div>
  );
};

export default Suporte;
