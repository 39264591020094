import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";

import Input from "../Input/input";
import styles from "./popup-alterar-senha.module.css";

const PopupAlterarSenha = (props) => {
  const [senha, setSenha] = useState("");
  const [confirmarSenha, setConfirmarSenha] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [passwordCheck, setPasswordCheck] = useState({
    uppercase: false,
    lowercase: false,
    number: false,
    special: false,
    minimal: false
  });

  const handleAlterarSenha = async () => {
    if (senha !== confirmarSenha) {
      toast.error("As senhas não conferem");
      if (props.closePopup) props.closePopup();
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/users/change-password`,
        {
          currentPassword,
          confirmNewPassword: confirmarSenha,
          newPassword: senha,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("ptr_145769130149")}`,
          },
        }
      );
      console.log(response);
      if (response.status === 200) {
        toast.success("Senha alterada com sucesso!");
        props.closePopup();
      }
    } catch (error) {
      toast.error("Erro ao alterar a senha.");
      props.closePopup();
    }
  };

  const handlePasswordChecking = (value) => {
    let checking = passwordCheck;

    if (value == "") {
      setPasswordCheck({
        uppercase: false,
        lowercase: false,
        number: false,
        special: false,
        minimal: false,
      });
      setSenha("");
    } else {
      // Check Número
      if (value.match(/[0-9]/g) == null) {
        checking.number = false;
      } else {
        checking.number = true;
      }

      // Check Letra maiúscula
      if (value.match(/[A-Z]/g) == null) {
        checking.uppercase = false;
      } else {
        checking.uppercase = true;
      }

      // Check Letra minúscula
      if (value.match(/[a-z]/g) == null) {
        checking.lowercase = false;
      } else {
        checking.lowercase = true;
      }

      // Check Caractere especial
      if (value.match(/[^a-zA-Z0-9_]/g) == null) {
        checking.special = false;
      } else {
        checking.special = true;
      }

      // Check 8 caracteres
      if (value.length < 8) {
        checking.minimal = false;
      } else {
        checking.minimal = true;
      }

      setPasswordCheck(checking);
      setSenha(value);
    }
  };

  return (
    <div className={styles["pagarcomcarto"]}>
      <div className={styles["container"]}>
        <div className={styles["titulo"]}>
          <span className={styles["text"]}>Alterar senha</span>
          <img
            alt="Vector4897"
            src="/external/vector4897-4cpm.svg"
            className={styles["closeicon"]}
            onClick={() => {
              props.closePopup();
            }}
          />
        </div>
        <div className={styles["form"]}>
          <Input
            Label="Senha atual"
            isPass="true"
            onChange={setCurrentPassword}
            value={currentPassword}
          ></Input>
          <Input
            Label="Insira a nova senha"
            isPass="true"
            onChange={(props) => {
              handlePasswordChecking(props);
            }}
            value={senha}
          ></Input>
          <div
            className={
              senha.length > 0
                ? styles["password-hints"]
                : styles["password-hints-hide"]
            }
          >
            <div
              className={
                passwordCheck.minimal
                  ? styles["password-ok"]
                  : styles["password-not-ok"]
              }
            >
              8 caracteres
            </div>
            <div
              className={
                passwordCheck.uppercase
                  ? styles["password-ok"]
                  : styles["password-not-ok"]
              }
            >
              Letra maiúscula
            </div>
            <div
              className={
                passwordCheck.lowercase
                  ? styles["password-ok"]
                  : styles["password-not-ok"]
              }
            >
              Letra minúscula
            </div>
            <div
              className={
                passwordCheck.number
                  ? styles["password-ok"]
                  : styles["password-not-ok"]
              }
            >
              Número {passwordCheck.length}
            </div>
            <div
              className={
                passwordCheck.special
                  ? styles["password-ok"]
                  : styles["password-not-ok"]
              }
            >
              Caractere especial
            </div>
          </div>
          <Input
            Label="Confirmar nova senha"
            isPass="true"
            onChange={setConfirmarSenha}
            value={confirmarSenha}
          ></Input>
          <button
            className={styles["botao-comprar"]}
            onClick={handleAlterarSenha}
          >
            <span className={styles["text1"]}>
              <span>Salvar</span>
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopupAlterarSenha;
