import axios from "axios";

export async function postFreePlanUpdate() {

  const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/users/update-free-plan`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "ptr_145769130149"
          )}`,
        },
      }
  );
  
  return response
}


