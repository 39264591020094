import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./anuncioPendente.module.css";
import axios from "axios";
import TagPlan from "../TagPlan";

const AnuncioPendente = (props) => {

  const user = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/adm/ad/${props.ad.username}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("ptr_145769130149")}`,
          },
        }
      );

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    (async function () {
      await user();
    })();
  }, [props.username]);

  return (
    <div className={styles["anuncio-novo"]}>
      <div>
        <img src={props.ad.photo} className={styles["foto-perfil"]}/>
      </div>
      <div className={styles["infos"]}>
        <span className={styles["text-nome"]}>
          <span>{props.ad.personalName}</span>
        </span>
        <span className={styles["text-descricao"]}>
          <span>{props.ad.briefDescription}</span>
        </span>
      </div>
      <div className={styles["botoes"]}>
        <div className={styles["status"]}>
          <span className={styles["text03"]}>
            {props.ad.isNew ? "Novo" : "Atualização"}
          </span>
        </div>
        <TagPlan plan={props.plan}/>
        <button
          onClick={() => props.handleSeeAd(props.ad)}
          className={styles["botao-ver"]}
        >
          <span className={styles["text05"]}>
            <span style={{ color: "white" }}>Ver anúncio</span>
          </span>
          <img
            alt="pastedImage"
            src="/external/pastedimage-s49-200h.png"
            className={styles["arrowicon"]}
          />
        </button>
      </div>
    </div>
  );
};

AnuncioPendente.defaultProps = {
  rootClassName: "",
};

AnuncioPendente.propTypes = {
  rootClassName: PropTypes.string,
};

export default AnuncioPendente;
