import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import Header from "../../components/Header/header";
import Busca from "../../components/Busca/busca";
import BannerInaugural from "../../components/Banner/banner";
import { Link } from 'react-router-dom';
import Rodape from "../../components/Rodape/rodape";
import styles from "./home.module.css";

import { useDispatch } from "react-redux";
import { setBuscaData } from "../../redux/actions/buscaActions";
import { getTrainerServices } from "../../services/server/TrainerServices"

import axios from "axios";
import { toast } from "react-toastify";
import palavrasChaves from './palavrasChave.json'

const Home = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const [searchs, setSearchs] = useState(null);
  const [searchs, setSearchs] = useState(palavrasChaves);

  const objetivosPadrao = [
    {
      "_id": "65c3e3093e9ab568dd9500b7",
      "title": "Emagrecimento",
      "subtitle": "Reduza o peso de forma saudável e eficaz",
      "description": "Focada na perda de peso de forma saudável e eficaz. Promova resultados duradouros melhorando a autoestima e bem-estar.",
      "photo": "",
      "premium": false,
      "createdAt": "2024-02-07T18:02:17.500Z",
      "updatedAt": "2024-02-07T18:02:17.500Z",
      "__v": 0,
      "descriptionHomePage": "Inicie uma jornada de transformação focada na perda de peso saudável. Com métodos comprovados, alcance resultados duradouros e melhore sua autoestima e bem-estar."
    },
    {
      "_id": "65c4c070b5831f719cff388d",
      "title": "Músculo e Definição",
      "subtitle": "Treinos para construir e esculpir músculos",
      "description": "Treinos elaborados para trabalhar os mais variados grupos musculares e favorecer a hipertrofia muscular.",
      "photo": "",
      "premium": false,
      "createdAt": "2024-02-07T18:02:17.500Z",
      "updatedAt": "2024-02-07T18:02:17.500Z",
      "__v": 0,
      " descriptionHomePage": "Desenvolva e defina músculos com programas customizados, voltados para ganho de massa e definição. Supere seus limites e conquiste o físico desejado."
    },
    {
      "_id": "65c4c0b6b5831f719cff388e",
      "title": "Fitness Total e Dinâmico",
      "subtitle": "Resistência, força e agilidade em um só lugar",
      "description": "Treinos que combinam diferentes modalidades, garantindo condicionamento completo, melhorando sua resistência, força e agilidade.",
      "photo": "",
      "premium": false,
      "createdAt": "2024-02-07T18:02:17.500Z",
      "updatedAt": "2024-02-07T18:02:17.500Z",
      "__v": 0,
      "descriptionHomePage": "Explore treinos que combinam diferentes modalidades, garantindo condicionamento completo, melhorando sua resistência, força e agilidade."
    },
    {
      "_id": "65c4c104b5831f719cff388f",
      "title": "Preparo para Eventos",
      "subtitle": "Foco em competições, maratonas e concursos (TAF).",
      "description": "Treinamentos focados em preparação para competições, maratonas e concursos (teste de aptidão física).",
      "photo": "",
      "premium": false,
      "createdAt": "2024-02-07T18:02:17.500Z",
      "updatedAt": "2024-02-07T18:02:17.500Z",
      "__v": 0,
      "descriptionHomePage": "Treinamento especializado para atletas e entusiastas que buscam se destacar em eventos específicos. Preparação, foco e resultados incríveis."
    },
    {
      "_id": "65c4c11fb5831f719cff3890",
      "title": "Flexibilidade e bem-estar",
      "subtitle": "Correção postural e mobilidade com práticas especiais",
      "description": "Para aprimoramento de postura, flexibilidade e equilíbrio, através de métodos como Yoga, Pilates e outros.",
      "photo": "",
      "premium": false,
      "createdAt": "2024-02-07T18:02:17.500Z",
      "updatedAt": "2024-02-07T18:02:17.500Z",
      "__v": 0,
      "descriptionHomePage": "Aprimore sua postura, flexibilidade e equilíbrio. Seja através do Yoga, Pilates ou outros métodos, sinta-se renovado e ágil."
    },
    {
      "_id": "65c4c144b5831f719cff3891",
      "title": "Ginástica laboral",
      "subtitle": "Atividade física no ambiente de trabalho",
      "description": "A ginástica laboral é uma prática de exercícios físicos realizados no ambiente de trabalho, com o objetivo de prevenir lesões e promover saúde.",
      "photo": "",
      "premium": false,
      "createdAt": "2024-02-07T18:02:17.500Z",
      "updatedAt": "2024-02-07T18:02:17.500Z",
      "__v": 0,
      "descriptionHomePage": "A ginástica laboral é uma prática de exercícios físicos realizados no ambiente de trabalho, com o objetivo de prevenir lesões e promover saúde."
    },
    {
      "_id": "65c4c15fb5831f719cff3892",
      "title": "Maternidade Ativa",
      "subtitle": "Segurança e eficácia para gestantes e pré e pós-parto",
      "description": "Treinos seguros e eficazes para gestantes e mães recentes, mantendo a saúde em dia.\n",
      "photo": "",
      "premium": false,
      "createdAt": "2024-02-07T18:02:17.500Z",
      "updatedAt": "2024-02-07T18:02:17.500Z",
      "__v": 0,
      "descriptionHomePage": "A maternidade é um momento único. Oferecemos treinos seguros e eficazes para gestantes e mães recentes, mantendo a saúde em dia."
    },
    {
      "_id": "65c4c18ab5831f719cff3893",
      "title": "Treinamento esportivo",
      "subtitle": "Aprimore habilidades em esportes específicos",
      "description": "Eleva atletas e entusiastas ao próximo nível, focando em técnicas e desempenho para esportes específicos.",
      "photo": "",
      "premium": false,
      "createdAt": "2024-02-07T18:02:17.500Z",
      "updatedAt": "2024-02-07T18:02:17.500Z",
      "__v": 0,
      "descriptionHomePage": "Eleva atletas e entusiastas ao próximo nível, focando em técnicas e desempenho para esportes específicos."
    },
    {
      "_id": "65c4c1a5b5831f719cff3894",
      "title": "Treino Sênior",
      "subtitle": "Exercícios para o bem-estar acima de 50 anos",
      "description": "Promova saúde e vitalidade na terceira idade. Nossos treinos são criados pensando no conforto, segurança e benefícios para os idosos.",
      "photo": "",
      "premium": false,
      "createdAt": "2024-02-07T18:02:17.500Z",
      "updatedAt": "2024-02-07T18:02:17.500Z",
      "__v": 0,
      "descriptionHomePage": "Promova saúde e vitalidade na terceira idade. Nossos treinos são criados pensando no conforto, segurança e benefícios para os idosos."
    },
    {
      "_id": "65c4c1cab5831f719cff3895",
      "title": "Fitness Infantil e adolescente",
      "subtitle": "Adequado para crianças e adolescentes (até 17 anos)",
      "description": "Treinos adequados para crianças e adolescentes (até 17 anos), promovem diversão e desenvolvimento físico.",
      "photo": "",
      "premium": false,
      "createdAt": "2024-02-07T18:02:17.500Z",
      "updatedAt": "2024-02-07T18:02:17.500Z",
      "__v": 0,
      "descriptionHomePage": "Crianças também precisam se mover! Nossos treinos promovem diversão e desenvolvimento físico, garantindo que cresçam saudáveis e ativas."
    }
  ]
  const [objetivos, setObjetivos] = useState(objetivosPadrao)

  //////////////////////////////////////////////// A seção comentada abaixo é para pegar as buscas mais frequentes
  // const fetchSearch = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_API_URL}/api/search-stats`
  //     );
  //     console.log(response);
  //     console.log(response.data);
  //     setSearchs(response.data.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   (async function () {
  //     if (searchs === null) {
  //       await fetchSearch();
  //     }
  //   })();
  // }, [searchs]);
  /////////////////////////////////////////////////////////

  const buscaPorObjetivo = (objetivo) => {
    console.log([objetivo]);
    if (objetivo) {
      dispatch(
        setBuscaData({
          appointmentType: [],
          services: [objetivo],
          pricing: {
            min: null,
            max: null,
          },
          periodos: [],
          gender: [],
          locationType: [],
          genderPreference: [],
          skillLevel: [],
          ageGroup: [],
          address: {
            city: null,
            id: null,
            uf: null,
            region: null,
          },
        })
      );

      history.push("/busca");
    }
  };

  useEffect(() => {
    getTrainerServices()
      .then(res => {
        const objetivosFiltrados = res.filter(obj => !obj.premium)
        setObjetivos(objetivosFiltrados)
      })
      .catch(err => console.error('Erro de conexão'))
  }, [])

  return (
    <div className={styles["container"]}>
      <Header />
      <div className={styles["container1"]}>
        {/* {<BannerInaugural />} */}
        <div className={styles['btn-banner-sorteio']}>
          <Link to="/seja-anunciante" onClick={() => {
            window.scrollTo(0, 0);
          }}>
            <div>
              <img src="https://romper-personal-trainer.s3.sa-east-1.amazonaws.com/banner-anuncie+gr%C3%A1tis.png"></img>
            </div>
          </Link>
        </div>
        <div className={styles["busca-personal"]}>
          <div className={styles["contrate"]}>
            <span className={styles["text"]}>
              Contrate o personal trainer ideal, on-line ou presencial
            </span>
            <span className={styles["text001"]}>
              Compare preços, explore técnicas e confira avaliações reais
            </span>
            <Busca isHome="true"></Busca>
          </div>
        </div>
        <div className={styles["categorias"]}>
          <div className={styles["categorias1"]}>
            <span className={styles["text002"]}>
              <span>Conheça as categorias de objetivos</span>
            </span>
            <div className={styles["conteudo"]}>
              {objetivos?.map(obj => (
                <div
                  className={styles["categoria"]}
                  onClick={() => buscaPorObjetivo(obj.title)}
                  key={obj.title}
                >
                  <img
                    alt={obj.title}
                    src={obj.photo}
                    className={styles["imagemcategoria"]}
                  />
                  <div className={styles["texts"]}>
                    <span className={styles["text004"]}>{obj.title}</span>
                    <span className={styles["text005"]}>
                      {obj.subtitle}
                    </span>
                    <span className={styles["text006"]}>
                      {obj.descriptionHomePage ?? obj.description}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={styles["voce-sabia"]}>
          <span className={styles["text061"]}>Você sabia?</span>
          <div className={styles["conteudos"]}>
            <div className={styles["info"]}>
              <span className={styles["text062"]}>
                <span>Personal Trainer Online: Vale o Investimento?</span>
              </span>
              <span className={styles["text064"]}>
                <span>
                  Contratar um personal trainer é um investimento direto em sua
                  saúde e bem-estar. O auxílio especializado ajuda você a
                  alcançar seus objetivos de maneira mais eficaz, evitando
                  lesões e otimizando seu tempo. Os resultados, frequentemente,
                  são mais rápidos e duradouros. Pense no valor, não apenas no
                  preço.
                </span>
              </span>
            </div>
            <div className={styles["info1"]}>
              <span className={styles["text066"]}>
                <span>Preço de Personal Trainer: O que Considerar?</span>
              </span>
              <span className={styles["text068"]}>
                <span>
                  O valor de um personal trainer varia conforme experiência,
                  especialização e localização. Mas lembre-se: você está
                  investindo em resultados personalizados e um acompanhamento
                  dedicado. Em nossa plataforma, compare preços e encontre a
                  opção perfeita para seu bolso e necessidades.
                </span>
              </span>
            </div>
            <div className={styles["info2"]}>
              <span className={styles["text070"]}>
                <span>Emagrecimento com Personal: Benefícios e Resultados</span>
              </span>
              <span className={styles["text072"]}>
                <span>
                  O emagrecimento guiado por um personal trainer traz inúmeros
                  benefícios. A abordagem é personalizada, considerando suas
                  metas, ritmo e alimentação. Evita-se métodos prejudiciais ou
                  ineficazes, garantindo perda de peso saudável, sustentável e
                  com orientações que previnem o efeito sanfona.
                </span>
              </span>
            </div>
            <div className={styles["info3"]}>
              <span className={styles["text074"]}>
                <span>Personal Trainer na Gravidez: Dicas e Vantagens</span>
              </span>
              <span className={styles["text076"]}>
                A gravidez é uma fase repleta de mudanças, e um personal trainer
                especializado pode ser essencial. Ele proporciona treinos
                seguros, que ajudam na preparação para o parto, aliviam
                desconfortos e promovem a recuperação pós-parto mais rápida. É
                cuidado e suporte para mãe e bebê.
              </span>
            </div>
          </div>
        </div>

        {/* Buscas populares */}
        <div className={styles["buscas-populares"]}>
          <div className={styles["conteudo1"]}>
            <span className={styles["text077"]}>
              <span>Buscas mais populares</span>
            </span>
            <div className={styles["box"]}>
              <div className={styles["texts10"]}>
                {searchs?.map((s) => {
                  return (
                    <span className={styles["text079"]}>{s.searchTerm}</span>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        {/* BLOG */}
        {/* <div className={styles["mundo-personal"]}>
          <span className={styles["text189"]}>Mundo Personal</span>
          <div className={styles["conteudo2"]}>
            <div className={styles["box1"]}>
              <div className={styles["video"]}>
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-1hbc-200h.png"
                  className={styles["playvideo"]}
                />
              </div>
              <div className={styles["text190"]}>
                <span className={styles["text191"]}>
                  <span>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor
                  </span>
                  <br></br>
                </span>
                <div className={styles["botaovermais"]}>
                  <button className={styles["buttons"]}>
                    <span className={styles["text194"]}>
                      <span>Ver mais</span>
                    </span>
                    <img
                      alt="pastedImage"
                      src="/external/pastedimage-7ili-200h.png"
                      className={styles["iconvermais"]}
                    />
                  </button>
                </div>
              </div>
            </div>
            <div className={styles["box2"]}>
              <div className={styles["video1"]}>
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-1hbc-200h.png"
                  className={styles["playvideo1"]}
                />
              </div>
              <div className={styles["text196"]}>
                <span className={styles["text197"]}>
                  <span>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor
                  </span>
                  <br></br>
                </span>
                <div className={styles["botaovermais1"]}>
                  <button className={styles["buttons1"]}>
                    <span className={styles["text200"]}>
                      <span>Ver mais</span>
                    </span>
                    <img
                      alt="pastedImage"
                      src="/external/pastedimage-7ili-200h.png"
                      className={styles["iconvermais1"]}
                    />
                  </button>
                </div>
              </div>
            </div>
            <div className={styles["box3"]}>
              <div className={styles["video2"]}>
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-1hbc-200h.png"
                  className={styles["playvideo2"]}
                />
              </div>
              <div className={styles["text202"]}>
                <span className={styles["text203"]}>
                  <span>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor
                  </span>
                  <br></br>
                </span>
                <div className={styles["botaovermais2"]}>
                  <button className={styles["buttons2"]}>
                    <span className={styles["text206"]}>
                      <span>Ver mais</span>
                    </span>
                    <img
                      alt="pastedImage"
                      src="/external/pastedimage-7ili-200h.png"
                      className={styles["iconvermais2"]}
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <button className={styles["botao-mais-conteudos"]}>
            <span className={styles["text208"]}>
              <span>Veja mais conteúdos</span>
            </span>
            <img
              alt="pastedImage"
              src="/external/pastedimage-7ili-200h.png"
              className={styles["iconvermais3"]}
            />
          </button>
        </div> */}
      </div>
      <Rodape></Rodape>
    </div>
  );
};

export default Home;
