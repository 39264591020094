import React, { useState, useEffect } from 'react'
import { useParams, useHistory, useLocation, Link } from 'react-router-dom';
import { MoonLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import axios from 'axios'

import Input from '../../components/Input/input'
import styles from './avaliacao.module.css'

const Avaliacao = (props) => {
  const history = useHistory();
  const { uid } = useParams();
  const { search } = useLocation()
  const [data, setData] = useState({})
  const [nome, setNome] = useState('');
  const [comentario, setComentario] = useState('');
  const [avaliacao, setAvaliacao] = useState(0);
  const [avaliacaoHover, setAvaliacaoHover] = useState(0);
  const [ad, setAd] = useState({});

  const regex = /(?:[?&])auth=([^&]+)/;
  const match = search.match(regex);
  const auth = match[1]

  const publicarAvaliacao = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/reviews/submit-review`, {
        invitationToken: auth,
        name: nome,
        rating: avaliacao,
        comment: comentario,
      })
      toast.success("Avaliação publicada!")
      history.push('/')
    } catch (error) {
      console.log(error)
      toast.error("Erro ao publicar avaliação!")
    }
  };

  const fetchReview = async () => {
    console.log(uid)
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/reviews/`, {
        params: {
          id: uid
        }
      })
      console.log(response)
      setData(response.data.data.review);
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    (async function () {
      await fetchReview();
      if (data?.published) {
        history.push("/");
      }
    })();
  }, []);

  // if(Object.values(data).length === 0 ) {
  //   return <div style={ { display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100%' } }>
  //       <MoonLoader size={32} color={"#fba600"} loading={true} />
  //   </div>
  // }

  return (
    <div className={styles['container']}>
      <div className={styles['container1']}>
        <div className={styles['cabecalho']}>
          <Link to="/">
            <img
              alt="pastedImage"
              src="/external/pastedimage-3g-1500h.png"
              className={styles['logo']}
            />
          </Link>
        </div>
        <div className={styles['avaliar']}>
          <div className={styles['container2']}>
            <span className={styles['text']}>
              <span>{!data?.published ? `Avaliação de ${data?.trainerAd?.personalName}` : 'Avaliar'}</span>
            </span>
          </div>
          <div className={styles['info-personal']}>
            <div className={styles['foto-perfil']}>
              <img
                alt="pastedImage"
                src={data?.trainerAd?.photo}
                className={styles['imgpersonal']}
              />
            </div>
            <span className={styles['text2']}>
              <span>{data?.trainerAd?.personalName}</span>
            </span>
          </div>
          <div className={styles['estrelas-avaliar']}>
            {[...Array(5)].map((_, indice) => (
              <div
                key={indice}
                className={styles['container-estrela']}
                onMouseEnter={() => {
                  if (!data.published) {
                    setAvaliacaoHover(indice + 1)
                  }
                }}
                onMouseLeave={() => {
                  if (!data.published) {
                    setAvaliacaoHover(0)
                  }
                }}
                onClick={() => {
                  if (!data.published) {
                    setAvaliacao(indice + 1)
                  }
                }}
              >
                {(avaliacaoHover > indice || avaliacao > indice) ? (
                  <img
                    alt="Estrela Preenchida"
                    src="/external/pastedimage-vxlv-200h.png"
                    className={styles['staryellow']}
                  />
                ) : (
                  <img
                    alt="Estrela"
                    src="/external/estrelaavaliaoi512-ot5x.svg"
                    className={styles['starempty']}
                  />
                )}
              </div>
            ))}
          </div>
          <div className={styles['form']}>
            <Input
              value={nome}
              readOnly={data?.status === "publicado" ? true : false}
              onChange={setNome}
              Placeholder="Seu nome"
            />
            <Input
              tipo={2}
              value={comentario}
              onChange={setComentario}
              readOnly={data?.status === "publicado" ? true : false}
              TextAreaPlaceholder="Deixe um comentário sobre sua experiência"
              bloquearQuebraLinha={true}
              Limite={data?.status === "publicado" ? null : 190}
            ></Input>
          </div>
          {!data?.published && (
            <button className={styles['botao-publicar']} onClick={publicarAvaliacao}>
              <span className={styles['text4']}>Publicar avaliação</span>
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default Avaliacao
