export function formatCurrency(num, currencyType = "R$ ") {
    // Convert number to string
    let numStr = num.toFixed(2).toString();
    
    // Split the number into integer and fractional parts
    let parts = numStr.split('.');
    
    let integerPart = parts[0];
    let fractionalPart = parts[1];

    if(fractionalPart && fractionalPart.length === 2) {
        
        // Add thousand separators to the integer part
        integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        
        // Format the number with the desired separators
        let formattedNumber = `${integerPart},${fractionalPart}`;
        
        return `${currencyType}${formattedNumber}`;
    }

    return `${currencyType}${numStr}`
}

export function capitalizeWords(str) {
    return str.trim().toLowerCase().replace(/(?:^|\s)(\S)/g, function(char, p1) {
        return char.toUpperCase();
    });
}

export function formatPhoneNumber(number) {
    // Split the number into its components
    const country_code = number.slice(0, 2);
    const area_code = number.slice(2, 4);
    const first_part = number.slice(4, 5);
    const second_part = number.slice(5, 9);
    const last_part = number.slice(9);

    // Format the components into the desired format
    // const formatted_number = "+" + country_code + " (" + area_code + ") " + first_part + " " + second_part + "-" + last_part;
    const formatted_number = "(" + area_code + ") " + first_part + " " + second_part + "-" + last_part;

    return formatted_number;
}