import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAnuncioData } from "../../../redux/actions/anuncioActions";
import axios from "axios";

import MenuLogado from "../../../components/MenuLogado/menu-logado";
import Rodape from "../../../components/Rodape/rodape";
import PopupPagar from "../../../components/PopupPagar/popup-pagar";
import Header from "../../../components/Header/header";

import styles from "./planos.module.css";
import { toast } from "react-toastify";
import { CheckOutPage } from "./checkoutPage";
import { formatCurrency } from "../../../utils";
import { postFreePlanUpdate } from "../../../services/server/postUpdatePlan";
import { useHistory } from "react-router-dom";

const Planos = (props) => {
  const dispatch = useDispatch();
  const anuncio = useSelector((state) => state.anuncio);
  const usuario = useSelector((state) => state.usuario);
  const [popupPagarVisible, setPopupPagarVisible] = useState(false);
  const [plans, setPlans] = useState(null);
  const [userPlan, setUserPlan] = useState("");
  const [planCheckoutPage, setPlanCheckoutPage] = useState(false);
  const [currentPlan, setCurrentPlan] = useState({});
  const containerRef = useRef();
  const [selectedTime, setSelectedTime] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [paymentMethod, setPaymentMethod] = useState({});

  const history = useHistory();

  const closePopup = (leaveCheckOut = false) => {
    setPopupPagarVisible(false);
    setPlanCheckoutPage(!leaveCheckOut);
  };

  useEffect(() => {
    if (popupPagarVisible) {
      document.querySelector("body").style.overflow = "hidden";
    } else {
      document.querySelector("body").style = "";
    }
  }, [popupPagarVisible]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/anuncios`, //pending: endpoint não existe
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        dispatch(setAnuncioData(response.data));
      } catch (error) {
        console.error(error);
      }
    };

    if (!anuncio) {
      fetchData();
    }
  }, [anuncio]);

  useEffect(() => {
    (async function () {
      if (plans === null) {
        await fetchPlans();
      }
    })();
  }, [plans]);

  useEffect(() => {
    getUserPlan();
  }, [plans]);

  const fetchPlans = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/plans/plans`
      );
      setPlans(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getUserPlan = () => {
    if (plans && usuario.userPlan) {
      for (let p of plans) {
        if (p._id === usuario.userPlan.planId) {
          setUserPlan(p);
        }
      }
    }
  };

  const getPlanByName = (planName) => {
    let plan = false;

    if (plans) {
      for (let p of plans) {
        if (p.name === planName) {
          plan = p;
        }
      }
    }

    return plan;
  };

  const handleSelectPlan = (planName) => {
    if (
      userPlan &&
      userPlan.name !== "Plano Free" &&
      planName !== userPlan.name
    )
      toast.info(
        "Você já tem um plano ativo. Se contratar outro seu plano ativo será desativado"
      );

    if (planName === "Plano Free") {
      postFreePlanUpdate()
        .then((res) => {
          setCurrentPlan(res.data.plan);
          setUserPlan(res.data.plan);
        })
        .catch((err) => toast.error(err.message));

      return;
    }

    if (usuario.cpf && usuario.address && usuario.phone) {
      const searchedPlan = getPlanByName(planName);

      if (searchedPlan) {
        setCurrentPlan(searchedPlan);
        setPlanCheckoutPage(true);
      }
    } else {
      history.push("minha-conta");
      toast.info(
        "Antes de contratar um plano é necessário preencher as informações da sua conta."
      );
    }
  };

  const handlePay = () => {
    if (!paymentMethod.method || !paymentMethod.planTime) {
      toast.error("Selecione um meio de pagamento");
      return;
    }
    setPopupPagarVisible(true);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    const containerElement = containerRef.current;
    const targetDiv = containerElement.children[page - 1];
    containerElement.scrollTo({
      left: (targetDiv.offsetLeft - 16),
      behavior: "smooth",
    });
  };

  const handlePaymentMethod = () => { };

  return (
    <div className={styles["container"]}>
      <Header />
      {!planCheckoutPage && (
        <div className={styles["container01"]}>
          {window.innerWidth > 991 && <MenuLogado></MenuLogado>}
          <div className={styles["container02"]}>
            <div className={styles["planos"]}>
              <div className={styles["cabecalho"]}>
                <span className={styles["text"]}>Planos</span>
                <span className={styles["text001"]}>
                  <span className={styles["text002"]}>Aproveite as&nbsp;</span>
                  <span className={styles["text003"]}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: " ",
                      }}
                    />
                  </span>
                  <span className={styles["text004"]}>
                    promoções de lançamento!
                  </span>
                </span>
              </div>
              <div className={styles["pag-mobile"]}>
                <div className={styles["planos1"]}>
                  <div
                    onClick={() => handlePageChange(1)}
                    className={styles["plano-free"]}
                  >
                    {currentPage === 1 ? (
                      <img
                        alt="pastedImage"
                        src="/external/pastedimage-y14-200h.png"
                        className={styles["atual"]}
                      />
                    ) : (
                      <img
                        alt="pastedImage"
                        src="/external/pastedimage-lf5q-200h.png"
                        className={styles["nao-atual"]}
                      />
                    )}
                  </div>
                  <img
                    alt="pastedImage"
                    src="/external/pastedimage-4uq3-200h.png"
                    className={styles["linha"]}
                  />
                  <div
                    onClick={() => handlePageChange(2)}
                    className={styles["plano-aquecimento"]}
                  >
                    {currentPage === 2 ? (
                      <img
                        alt="pastedImage"
                        src="/external/pastedimage-y14-200h.png"
                        className={styles["atual"]}
                      />
                    ) : (
                      <img
                        alt="pastedImage"
                        src="/external/pastedimage-lf5q-200h.png"
                        className={styles["nao-atual"]}
                      />
                    )}
                  </div>
                  <img
                    alt="pastedImage"
                    src="/external/pastedimage-4uq3-200h.png"
                    className={styles["linha1"]}
                  />
                  <div
                    onClick={() => handlePageChange(3)}
                    className={styles["plano-elite"]}
                  >
                    {currentPage === 3 ? (
                      <img
                        alt="pastedImage"
                        src="/external/pastedimage-y14-200h.png"
                        className={styles["atual"]}
                      />
                    ) : (
                      <img
                        alt="pastedImage"
                        src="/external/pastedimage-lf5q-200h.png"
                        className={styles["nao-atual"]}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className={styles["plans-container"]}>
                <div ref={containerRef} className={styles["opcoes"]}>
                  <div className={styles["plano-card"]}>
                    <div className={styles["cabecalho1"]}>
                      <img
                        alt="Vector4766"
                        src="/external/vector4766-0toy.svg"
                        className={styles["pesoicon"]}
                      />
                      <span className={styles["text005"]}>Plano Free</span>
                    </div>
                    <div className={styles["pacote"]}>
                      <div className={styles["container03"]}>
                        <div className={styles["topico"]}>
                          <img
                            alt="aprovado3812"
                            src="/external/aprovado3812-8n9o.svg"
                            className={styles["aprovado"]}
                          />
                          <span className={styles["text006"]}>
                            <span className={styles["text007"]}>
                              Posição do anúncio:
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: " ",
                                }}
                              />
                            </span>
                            <span>3º</span>
                          </span>
                        </div>
                        <div className={styles["topico01"]}>
                          <img
                            alt="recusado3812"
                            src="/external/recusado3812-4ayo.svg"
                            className={styles["recusado"]}
                          />
                          <span className={styles["text009"]}>
                            <span>Selo de contato verificado</span>
                          </span>
                        </div>
                        <div className={styles["topico02"]}>
                          <img
                            alt="aprovado3812"
                            src="/external/aprovado3812-sv58.svg"
                            className={styles["aprovado01"]}
                          />
                          <span className={styles["text011"]}>
                            <span>Atendimento presencial</span>
                          </span>
                        </div>
                        <div className={styles["topico03"]}>
                          <img
                            alt="recusado3812"
                            src="/external/recusado3812-11bp.svg"
                            className={styles["recusado1"]}
                          />
                          <span className={styles["text013"]}>
                            <span>Atendimento on-line</span>
                          </span>
                        </div>
                        <div className={styles["topico04"]}>
                          <img
                            alt="aprovado3812"
                            src="/external/aprovado3812-a7gr.svg"
                            className={styles["aprovado02"]}
                          />
                          <span className={styles["text015"]}>
                            <span className={styles["text016"]}>
                              Até
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: " ",
                                }}
                              />
                            </span>
                            <span className={styles["text017"]}>1</span>
                            <span> categorias de treino</span>
                          </span>
                        </div>
                        <div className={styles["topico05"]}>
                          <img
                            alt="aprovado3812"
                            src="/external/aprovado3812-3vhi.svg"
                            className={styles["aprovado03"]}
                          />
                          <span className={styles["text019"]}>
                            <span className={styles["text020"]}>1</span>
                            <span> idioma</span>
                          </span>
                        </div>
                        <div className={styles["topico06"]}>
                          <img
                            alt="recusado3812"
                            src="/external/recusado3812-upr.svg"
                            className={styles["recusado2"]}
                          />
                          <span className={styles["text022"]}>
                            <span>Link para suas redes</span>
                          </span>
                        </div>
                        <div className={styles["topico07"]}>
                          <img
                            alt="aprovado3812"
                            src="/external/aprovado3812-hv6g.svg"
                            className={styles["aprovado04"]}
                          />
                          <span className={styles["text024"]}>
                            <span className={styles["text025"]}>
                              Galeria (até
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: " ",
                                }}
                              />
                            </span>
                            <span className={styles["text026"]}>4</span>
                            <span> fotos)</span>
                          </span>
                        </div>
                        <div className={styles["topico08"]}>
                          <img
                            alt="recusado3812"
                            src="/external/recusado3812-syg.svg"
                            className={styles["recusado3"]}
                          />
                          <span className={styles["text028"]}>
                            <span>Anúncio em destaque</span>
                          </span>
                        </div>
                        <div className={styles['topico08']}>
                          <img
                            alt="recusado3812"
                            src="/external/recusado3812-4ayo.svg"
                            className={styles['recusado3']}
                          />
                          <span className={styles['text028']}>
                            <span>Sem alunos avançados</span>
                          </span>
                        </div>
                        <div className={styles["topico09"]}>
                          <img
                            alt="recusado3812"
                            src="/external/recusado3812-86lg.svg"
                            className={styles["recusado4"]}
                          />
                          <span className={styles["text030"]}>
                            <span>Oferece apoio nutricional</span>
                          </span>
                          <img
                            alt="Vector3812"
                            src="/external/vector3812-l75p.svg"
                            className={styles["appleicon"]}
                          />
                        </div>
                        <div className={styles["topico10"]}>
                          <img
                            alt="recusado3812"
                            src="/external/recusado3812-66j.svg"
                            className={styles["recusado5"]}
                          />
                          <span className={styles["text032"]}>
                            <span>Página exclusiva</span>
                          </span>
                        </div>
                      </div>
                      <div className={styles["valor"]}>
                        <span className={styles["text034"]}>
                          <span>Gratuito</span>
                        </span>
                        <button
                          disabled={userPlan.name === "Plano Free"}
                          className={`${styles["botao-escolher"]} ${userPlan.name === "Plano Free"
                            ? styles["plano-escolhido"]
                            : ""
                            }`}
                          onClick={() => {
                            handleSelectPlan("Plano Free");
                          }}
                        >
                          <span className={styles["text036"]}>
                            <span>
                              {userPlan.name === "Plano Free"
                                ? "Escolhido"
                                : "Escolher"}
                            </span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className={styles["plano-card"]}>
                    <div className={styles["cabecalho2"]}>
                      <img
                        alt="Vector4766"
                        src="/external/vector4766-llha.svg"
                        className={styles["pesoicon1"]}
                      />
                      <span className={styles["text038"]}>
                        <span>Plano Aquecimento</span>
                      </span>
                    </div>
                    <div className={styles["pacote1"]}>
                      <div className={styles["container04"]}>
                        <div className={styles["topico11"]}>
                          <img
                            alt="aprovado3812"
                            src="/external/aprovado3812-yoj8.svg"
                            className={styles["aprovado05"]}
                          />
                          <span className={styles["text040"]}>
                            <span className={styles["text041"]}>
                              Posição do anúncio:
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: " ",
                                }}
                              />
                            </span>
                            <span>2º</span>
                          </span>
                        </div>
                        <div className={styles["topico12"]}>
                          <img
                            alt="aprovado3812"
                            src="/external/aprovado3812-8d75.svg"
                            className={styles["aprovado06"]}
                          />
                          <span className={styles["text043"]}>
                            <span>Selo de contato verificado</span>
                          </span>
                        </div>
                        <div className={styles["topico13"]}>
                          <img
                            alt="aprovado3812"
                            src="/external/aprovado3812-1ff.svg"
                            className={styles["aprovado07"]}
                          />
                          <span className={styles["text045"]}>
                            <span>Atendimento presencial</span>
                          </span>
                        </div>
                        <div className={styles["topico14"]}>
                          <img
                            alt="aprovado3812"
                            src="/external/aprovado3812-ncpd.svg"
                            className={styles["aprovado08"]}
                          />
                          <span className={styles["text047"]}>
                            <span>Atendimento on-line</span>
                          </span>
                        </div>
                        <div className={styles["topico15"]}>
                          <img
                            alt="aprovado3812"
                            src="/external/aprovado3812-kge.svg"
                            className={styles["aprovado09"]}
                          />
                          <span className={styles["text049"]}>
                            <span className={styles["text050"]}>
                              Até
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: " ",
                                }}
                              />
                            </span>
                            <span className={styles["text051"]}>4</span>
                            <span> categorias de treino</span>
                          </span>
                        </div>
                        <div className={styles["topico16"]}>
                          <img
                            alt="aprovado3812"
                            src="/external/aprovado3812-iw99.svg"
                            className={styles["aprovado10"]}
                          />
                          <span className={styles["text053"]}>
                            <span className={styles["text054"]}>Até</span>
                            <span className={styles["text055"]}> 2</span>
                            <span> idiomas</span>
                          </span>
                        </div>
                        <div className={styles["topico17"]}>
                          <img
                            alt="Vector3812"
                            src="/external/vector3812-vrw.svg"
                            className={styles["vector"]}
                          />
                          <span className={styles["text057"]}>
                            <span>Link para:</span>
                          </span>
                          <div className={styles["redes"]}>
                            <img
                              alt="image1333812"
                              src="/external/image1333812-gwfn-200h.png"
                              className={styles["tiktok"]}
                            />
                            <img
                              alt="image1343812"
                              src="/external/image1343812-6fjk-200h.png"
                              className={styles["linkedin"]}
                            />
                            <img
                              alt="pastedImage"
                              src="/external/pastedimage-0gl2-200h.png"
                              className={styles["rede"]}
                            />
                          </div>
                        </div>
                        <div className={styles["topico18"]}>
                          <img
                            alt="aprovado3812"
                            src="/external/aprovado3812-sdd5.svg"
                            className={styles["aprovado11"]}
                          />
                          <span className={styles["text059"]}>
                            <span className={styles["text060"]}>
                              Galeria (até
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: " ",
                                }}
                              />
                            </span>
                            <span className={styles["text061"]}>6</span>
                            <span className={styles["text062"]}>
                              {" "}
                              fotos e
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: " ",
                                }}
                              />
                            </span>
                            <span className={styles["text063"]}>2</span>
                            <span> vídeos)</span>
                          </span>
                        </div>
                        <div className={styles['topico08']}>
                          <img
                            alt="recusado3812"
                            src="/external/recusado3812-4ayo.svg"
                            className={styles['recusado3']}
                          />
                          <span className={styles['text028']}>
                            <span>Sem alunos avançados</span>
                          </span>
                        </div>
                        <div className={styles["topico19"]}>
                          <img
                            alt="recusado3812"
                            src="/external/recusado3812-59ob.svg"
                            className={styles["recusado6"]}
                          />
                          <span className={styles["text065"]}>
                            <span>Anúncio em destaque</span>
                          </span>
                        </div>
                        <div className={styles["topico20"]}>
                          <img
                            alt="recusado3812"
                            src="/external/recusado3812-49n.svg"
                            className={styles["recusado7"]}
                          />
                          <span className={styles["text067"]}>
                            <span>Oferece apoio nutricional</span>
                          </span>
                          <img
                            alt="Vector3812"
                            src="/external/vector3812-u5vq.svg"
                            className={styles["appleicon1"]}
                          />
                        </div>
                        <div className={styles["topico21"]}>
                          <img
                            alt="recusado3812"
                            src="/external/recusado3812-w8vd.svg"
                            className={styles["recusado8"]}
                          />
                          <span className={styles["text069"]}>
                            <span>Página exclusiva</span>
                          </span>
                        </div>
                      </div>
                      <div className={styles["valor1"]}>
                        <div className={styles["preco"]}>
                          <div className={styles["valor-anterior"]}>
                            <span className={styles["text071"]}>
                              <span>A partir de </span>
                              <span className={styles["text072"]}>
                                {plans
                                  ? formatCurrency(
                                    plans.find(
                                      (plano) =>
                                        plano.name === "Plano Aquecimento"
                                    )?.price?.quarterly / 3
                                  )
                                  : ""}
                              </span>
                            </span>
                          </div>
                          <span className={styles["text074"]}>
                            <span className={styles["text075"]}>
                              {plans
                                ? formatCurrency(
                                  plans.find(
                                    (plano) =>
                                      plano.name === "Plano Aquecimento"
                                  )?.pricePromotion?.annually / 12
                                )
                                : ""}
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: " ",
                                }}
                              />
                            </span>
                            <span className={styles["text076"]}>/mês</span>
                          </span>
                        </div>
                        <button
                          disabled={userPlan.name === "Plano Aquecimento"}
                          className={`${styles["botao-escolher"]} ${userPlan.name === "Plano Aquecimento"
                            ? styles["plano-escolhido"]
                            : ""
                            }`}
                          onClick={() => {
                            handleSelectPlan("Plano Aquecimento");
                          }}
                        >
                          <span className={styles["text036"]}>
                            <span>
                              {userPlan.name === "Plano Aquecimento"
                                ? "Escolhido"
                                : "Escolher"}
                            </span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className={styles["plano-card"]}>
                    <div className={styles["cabecalho3"]}>
                      <img
                        alt="Vector4766"
                        src="/external/vector4766-xpmv.svg"
                        className={styles["pesoicon2"]}
                      />
                      <span className={styles["text079"]}>
                        <span>Plano Elite</span>
                      </span>
                    </div>
                    <div className={styles["pacote2"]}>
                      <div className={styles["container05"]}>
                        <div className={styles["topico22"]}>
                          <img
                            alt="aprovado3812"
                            src="/external/aprovado3812-r9jwh.svg"
                            className={styles["aprovado12"]}
                          />
                          <span className={styles["text081"]}>
                            <span className={styles["text082"]}>
                              Posição do anúncio:
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: " ",
                                }}
                              />
                            </span>
                            <span>1º</span>
                          </span>
                        </div>
                        <div className={styles["topico23"]}>
                          <img
                            alt="aprovado3812"
                            src="/external/aprovado3812-8e.svg"
                            className={styles["aprovado13"]}
                          />
                          <span className={styles["text084"]}>
                            <span>Selo de contato verificado</span>
                          </span>
                        </div>
                        <div className={styles["topico24"]}>
                          <img
                            alt="aprovado3812"
                            src="/external/aprovado3812-y2h.svg"
                            className={styles["aprovado14"]}
                          />
                          <span className={styles["text086"]}>
                            <span>Atendimento presencial</span>
                          </span>
                        </div>
                        <div className={styles["topico25"]}>
                          <img
                            alt="aprovado3812"
                            src="/external/aprovado3812-to9v.svg"
                            className={styles["aprovado15"]}
                          />
                          <span className={styles["text088"]}>
                            <span>Atendimento on-line</span>
                          </span>
                        </div>
                        <div className={styles["topico26"]}>
                          <img
                            alt="aprovado3812"
                            src="/external/aprovado3812-f2ws.svg"
                            className={styles["aprovado16"]}
                          />
                          <span className={styles["text090"]}>
                            <span className={styles["text091"]}>
                              Até
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: " ",
                                }}
                              />
                            </span>
                            <span className={styles["text092"]}>8</span>
                            <span> categorias de treino</span>
                          </span>
                        </div>
                        <div className={styles["topico27"]}>
                          <img
                            alt="aprovado3812"
                            src="/external/aprovado3812-r0ro.svg"
                            className={styles["aprovado17"]}
                          />
                          <span className={styles["text094"]}>
                            <span className={styles["text095"]}>Até</span>
                            <span className={styles["text096"]}> 4</span>
                            <span> idiomas</span>
                          </span>
                        </div>
                        <div className={styles["topico28"]}>
                          <img
                            alt="Vector3812"
                            src="/external/vector3812-2eja.svg"
                            className={styles["vector1"]}
                          />
                          <span className={styles["text098"]}>
                            <span>Link para:</span>
                          </span>
                          <div className={styles["redes1"]}>
                            <img
                              alt="image1303812"
                              src="/external/image1303812-013-200h.png"
                              className={styles["insta"]}
                            />
                            <img
                              alt="image1313812"
                              src="/external/image1313812-i59-200h.png"
                              className={styles["face"]}
                            />
                            <img
                              alt="image1333812"
                              src="/external/image1333812-c6j8-200h.png"
                              className={styles["tiktok1"]}
                            />
                            <img
                              alt="image1343812"
                              src="/external/image1343812-sgt-200h.png"
                              className={styles["linkedin1"]}
                            />
                            <img
                              alt="pastedImage"
                              src="/external/pastedimage-4tuk-200h.png"
                              className={styles["rede1"]}
                            />
                          </div>
                        </div>
                        <div className={styles["topico29"]}>
                          <img
                            alt="aprovado3812"
                            src="/external/aprovado3812-3td.svg"
                            className={styles["aprovado18"]}
                          />
                          <span className={styles["text100"]}>
                            <span className={styles["text101"]}>
                              Galeria (até
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: " ",
                                }}
                              />
                            </span>
                            <span className={styles["text102"]}>20</span>
                            <span className={styles["text103"]}>
                              {" "}
                              fotos e
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: " ",
                                }}
                              />
                            </span>
                            <span className={styles["text104"]}>8</span>
                            <span> vídeos)</span>
                          </span>
                        </div>
                        <div className={styles['topico08']}>
                          <img
                            src="/external/aprovado3812-8n9o.svg"
                            alt="aprovado3812"
                            className={styles['aprovado15']}
                          />
                          <span className={styles['text088']}>
                            <span>Anuncie para alunos avançados</span>
                          </span>
                        </div>
                        <div className={styles["topico30"]}>
                          <img
                            alt="aprovado3812"
                            src="/external/aprovado3812-uuuo.svg"
                            className={styles["aprovado19"]}
                          />
                          <div className={styles["frame1171277085"]}>
                            <span className={styles["text106"]}>
                              <span>Anúncio em</span>
                            </span>
                            <div className={styles["frame1171277054"]}>
                              <span className={styles["text108"]}>
                                <span>DESTAQUE</span>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className={styles["topico31"]}>
                          <img
                            alt="aprovado3812"
                            src="/external/aprovado3812-vyj.svg"
                            className={styles["aprovado20"]}
                          />
                          <span className={styles["text110"]}>
                            <span>Oferecer apoio nutricional</span>
                          </span>
                          <img
                            alt="Vector3812"
                            src="/external/vector3812-qz22d.svg"
                            className={styles["appleicon2"]}
                          />
                        </div>
                        <div className={styles["topico32"]}>
                          <img
                            alt="aprovado3812"
                            src="/external/aprovado3812-5n5p.svg"
                            className={styles["aprovado21"]}
                          />
                          <span className={styles["text112"]}>
                            <span>Página exclusiva</span>
                          </span>
                          <button
                            className={styles["buttons"]}
                            onClick={() => {
                              if (!userPlan?.exclusivePage)
                                return toast.error(
                                  "Seu plano não tem acesso à página exclusiva"
                                );
                              if (usuario?.trainerAd?.username)
                                return history.push(
                                  `/exclusive-ad/${usuario.trainerAd.username}`
                                );
                              toast.info(
                                "Você não tem anuncio configurado para poder ver a página exclusiva"
                              );
                            }}
                          >
                            <span className={styles["text114"]}>
                              <span>Ver página</span>
                            </span>
                            <div
                              className={styles["vuesaxlineararrowcircleright"]}
                            >
                              <div
                                className={
                                  styles["vuesaxlineararrowcircleright1"]
                                }
                              >
                                <div className={styles["arrowcircleright"]}>
                                  <img
                                    alt="VectorI489"
                                    src="/external/vectori489-882.svg"
                                    className={styles["vector2"]}
                                  />
                                  <img
                                    alt="VectorI489"
                                    src="/external/vectori489-t7cp.svg"
                                    className={styles["vector3"]}
                                  />
                                  <img
                                    alt="VectorI489"
                                    src
                                    className={styles["vector4"]}
                                  />
                                </div>
                              </div>
                            </div>
                          </button>
                        </div>
                      </div>
                      <div className={styles["valor2"]}>
                        <div className={styles["preco1"]}>
                          <div className={styles["valor-anterior1"]}>
                            <span className={styles["text116"]}>
                              <span>A partir de </span>
                              <span className={styles["text072"]}>
                                {plans
                                  ? formatCurrency(
                                    plans.find(
                                      (plano) => plano.name === "Plano Elite"
                                    )?.price?.annually / 12
                                  )
                                  : ""}
                              </span>
                            </span>
                          </div>
                          <span className={styles["text119"]}>
                            <span className={styles["text120"]}>
                              {plans
                                ? formatCurrency(
                                  plans.find(
                                    (plano) => plano.name === "Plano Elite"
                                  )?.pricePromotion?.annually / 12
                                )
                                : ""}
                            </span>
                            <span className={styles["text121"]}>/mês</span>
                          </span>
                        </div>
                        <button
                          disabled={userPlan.name === "Plano Elite"}
                          className={`${styles["botao-escolher"]} ${userPlan.name === "Plano Elite"
                            ? styles["plano-escolhido"]
                            : ""
                            }`}
                          onClick={() => {
                            handleSelectPlan("Plano Elite");
                          }}
                        >
                          <span className={styles["text036"]}>
                            <span>
                              {userPlan.name === "Plano Elite"
                                ? "Escolhido"
                                : "Escolher"}
                            </span>
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {planCheckoutPage && (
        <CheckOutPage
          currentPlan={currentPlan}
          handlePay={handlePay}
          paymentMethod={paymentMethod}
          selectedTime={selectedTime}
          setPaymentMethod={setPaymentMethod}
          setSelectedTime={setSelectedTime}
        />
      )}
      <Rodape></Rodape>
      {popupPagarVisible && (
        <PopupPagar
          isPix={paymentMethod.method === "pix"}
          closePopup={closePopup}
          Valor={currentPlan.pricePromotion[paymentMethod.planTime]}
          currentPlan={currentPlan}
          paymentMethod={paymentMethod}
        ></PopupPagar>
      )}
    </div>
  );
};

export default Planos;
