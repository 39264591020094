import axios from "axios";
import { toast } from "react-toastify";
const { compareTwoStrings } = require("string-similarity");

const http = axios.create({
  baseURL: "https://brasilapi.com.br/api",
  headers: {
    "Content-Type": "application/json",
  },
});

export const getMunicipios = async (siglaUF) => {
  const url = `/ibge/municipios/v1/${siglaUF}`;

  try {
    const res = await http.get(url);

    return res.data.map((municipio) => municipio.nome);
  } catch (error) {
    console.error(error);
    if (error.message) return toast.error(error.message);
    toast.error("Erro ao consultar UF");
  }
};

export const searchMunicipios = async (query, limit = true) => {
  try {
    // Tentar pegar municípios do local storage
    let municipios = [];
    let validade = localStorage.getItem("municipios_validade");
    let hoje = new Date();
    if (
      !localStorage.getItem("municipios") ||
      !validade ||
      Number(validade) < new Date().getTime() // passou de um 1 ano, renova a lista
    ) {
      try {
        let response = await axios.get(
          "https://servicodados.ibge.gov.br/api/v1/localidades/municipios?view=nivelado&orderBy=nome"
        );
        let cidades = response.data.map((item) => {
          return {
            municipality: item["municipio-nome"],
            state: item["UF-sigla"],
            region: item["regiao-nome"],
          };
        });

        localStorage.setItem("municipios", JSON.stringify(cidades));
        localStorage.setItem(
          "municipios_validade",
          hoje.setFullYear(hoje.getFullYear() + 1)
        ); // setar para daqui a 1 ano
        console.info("Municípios atualizados no armazenamento local.");
        municipios = cidades;
      } catch (error) {
        throw new Error("Erro ao atualizar cidades: " + error.message);
      }
    } else {
      try {
        municipios = JSON.parse(localStorage.getItem("municipios"));
      } catch (error) {
        throw new Error("Erro ao recuperar cidades: " + error.message);
      }
    }

    // Filtrar pelo nome da cidade e mostrar primeiros 7 resultados
    query = query
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
    let result = municipios.map((item) => {
      return {
        ...item,
        score:
          query != ""
            ? compareTwoStrings(
                query,
                item.municipality
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .toLowerCase()
              )
            : 0,
      };
    });
    if (limit) {
      result = result
        .sort((a, b) => {
          return b.score - a.score;
        })
        .slice(0, 7);
    } else {
      result = result.sort((a, b) => {
        if (a.municipality < b.municipality) {
          return -1;
        }
        if (a.municipality > b.municipality) {
          return 1;
        }
        return 0;
      })
      .sort((a, b) => {
        if (a.state < b.state) {
          return -1;
        }
        if (a.state > b.state) {
          return 1;
        }
        return 0;
      });
    }

    return result;
  } catch (error) {
    return toast.error(error.message);
  }
};
