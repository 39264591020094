import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setAnuncioData } from "../../../redux/actions/anuncioActions";

import MenuLogado from "../../../components/MenuLogado/menu-logado";
import Rodape from "../../../components/Rodape/rodape";
import Header from "../../../components/Header/header";
import Input from "../../../components/Input/input";

import styles from "./avaliacoes.module.css";

const Avaliacoes = (props) => {
  const usuario = useSelector((state) => state.usuario);
  const [emailCliente, setEmailCliente] = useState("");
  const [avaliacoes, setAvaliacoes] = useState();
  
  const enviarLink = async () => {
    try {
      const token = localStorage.getItem("ptr_145769130149");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/reviews/create-review`,
        {
          email: emailCliente,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("E-mail enviado com sucesso");
      setEmailCliente('')
    } catch (error) {
      toast.error(error?.response?.data?.errors?.message || "Erro ao gerar link");
      console.log(error?.response?.data?.errors);
      console.error("Erro ao gerar o link", error);
    }
  };

  const getPublishedReviews = () => {
    let reviews = [];

    if (usuario.reviews) {
      for (let a of usuario.reviews) {
        if (a.published) {
          reviews.push(a);
        }
      }

      setAvaliacoes(reviews);
    }
  };

  useEffect(() => {
    getPublishedReviews();
  }, []);

  return (
    <div className={styles["container"]}>
      <Header />
      <div className={styles["container1"]}>
      {window.innerWidth > 991 && <MenuLogado></MenuLogado>}
        <div className={styles["container2"]}>
          <div className={styles["avaliacoes"]}>
            <div className={styles["cabecalho"]}>
              <span className={styles["text"]}>Avaliações</span>
            </div>
            <div className={styles["link-avaliar"]}>
              <span className={styles["text-titulo"]}>
                <span>Link para nova avaliação</span>
              </span>
              <span className={styles["text02"]}>
                Gere um link de avaliação para cada cliente seu.
              </span>
              <div className={styles["gerar-link"]}>
                <div className={styles["container3"]}>
                  <Input
                    Label="E-mail do cliente"
                    value={emailCliente}
                    onChange={setEmailCliente}
                  ></Input>
                  <button
                    className={styles["botao-gerar"]}
                    onClick={enviarLink}
                  >
                    <span className={styles["text03"]}>
                      <span>Enviar link</span>
                    </span>
                  </button>
                </div>
              </div>
              <span className={styles["text08"]}>
                <span className={styles["text09"]}>Passo a passo</span>
                <br className={styles["text10"]}></br>
                <span className={styles["text11"]}>
                  • Insira o e-mail do seu cliente e clique em Enviar link.
                </span>
                <br className={styles["text12"]}></br>
                <span className={styles["text13"]}> • </span>
                <span className={styles["text14"]}>
                  Enviamos um e-mail para ele com o link gerado para que ele
                  possa avaliar seu trabalho.
                </span>
                <br></br>
              </span>
            </div>
            {avaliacoes && avaliacoes.length > 0 && (
              <div className={styles["lista-avaliacoes"]}>
                <div className={styles["cabecalho1"]}>
                  <span className={styles["text16"]}>
                    <span>Avaliações</span>
                  </span>
                  <div className={styles["nota"]}>
                    <div className={styles["estrelas"]}>
                      <img
                        alt="Vector5125"
                        src="/external/vector5125-vnq4.svg"
                        className={styles["staricon"]}
                      />
                      <span className={styles["text18"]}>
                        {/* <span>
                          {(anuncio?.rating || 0)
                            .toFixed(1)
                            .replace(".", ",")}
                          <span
                            dangerouslySetInnerHTML={{
                              __html: " ",
                            }}
                          />
                        </span> */}
                      </span>
                    </div>
                    {/* <span className={styles["textqntd"]}>
                      {anuncio?.avaliacoes?.quantidade || 0} avaliações
                    </span> */}
                  </div>
                </div>
                <div className={styles["lista"]}>
                  {avaliacoes.map((avaliacao, index) => (
                    <div key={index} className={styles["avaliacao"]}>
                      <div className={styles["infouser"]}>
                        <span className={styles["text-nome"]}>
                          <span>{avaliacao?.reviewer?.name}</span>
                        </span>
                        <div className={styles["nota1"]}>
                          <img
                            alt="Vector5125"
                            src="/external/vector5125-kdc.svg"
                            className={styles["staricon1"]}
                          />
                          <span className={styles["text21"]}>
                            <span>
                              {avaliacao?.rating ? avaliacao.rating + ",0" : ""}
                            </span>
                          </span>
                        </div>
                      </div>
                      <span className={styles["text23"]}>
                        <span>{avaliacao?.comment}</span>
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Rodape></Rodape>
    </div>
  );
};

export default Avaliacoes;
