import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { MoonLoader } from 'react-spinners';

const AuthRedirect = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('uid');
    if (token) {
      localStorage.setItem('ptr_145769130149', token);
      history.push('/');
    } else {
      history.push('/login');
    }
  }, [location, history]);

  return (
    <div style={ { display:'flex', justifyContent:'center', alignItems:'center', height:'100vh', width:'100%' } }>
      <MoonLoader size={32} color={"#fba600"} loading={true} />
    </div>
  )
};

export default AuthRedirect;