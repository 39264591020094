import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header/header";
import Rodape from "../../components/Rodape/rodape";
import styles from "./seja-anunciante.module.css";
import YoutubeEmbed from "../../components/YoutubeEmbed/YoutubeEmbed";

const SejaAnunciante = (props) => {
  return (
    <div className={styles["container"]}>
      <Header />
      <div className={styles["container1"]}>
        <div className={styles["bem-vindo"]}>
          <YoutubeEmbed
            src="https://www.youtube.com/embed/nu1DURLBwoU?si=QW-L2JCfet1vNIjz"
            className={styles["video-mobile"]}
          />
          <div className={styles["column"]}>
            <span className={styles["text-titulo"]}>
              <span className={styles["text"]}>
                Bem-vindo ao
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span className={styles["text001"]}>Personal</span>
              <span className={styles["text002"]}>trainer</span>
              <span className={styles["text003"]}>!</span>
            </span>
            <span className={styles["text004"]}>
              <span className={styles["text005"]}>
                Você é um personal trainer talentoso buscando destacar-se em um
                mercado competitivo? Está cansado de tentar criar e gerenciar
                inúmeras contas em redes sociais, gastar com anúncios e ainda
                assim não ver sua agenda cheia?
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span className={styles["text006"]}>Personaltrainer</span>
              <span className={styles["text007"]}>.com.br</span>
              <span> é solução!</span>
              <br></br>
              <br></br>
              <span>
                Criamos uma plataforma exclusiva para profissionais como você,
                onde é possível divulgar seus serviços, técnicas e diferenciais
                para milhares de potenciais alunos em todo o Brasil. E o melhor,
                mesmo os anúncios gratuitos têm visibilidade garantida!
              </span>
            </span>
            <Link to="/cadastro" className={styles["botao-anunciar"]}>
              <span className={styles["text012"]}>
                <span>Criar meu anúncio agora</span>
              </span>
            </Link>
          </div>
          <YoutubeEmbed
            src="https://www.youtube.com/embed/nu1DURLBwoU?si=QW-L2JCfet1vNIjz"
            className={styles["video-desk"]}
          />
        </div>
        <div className={styles["motivos-anunciar"]}>
          <span className={styles["text-titulo1"]}>
            <span className={styles["text014"]}>
              Por que anunciar no
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span className={styles["text015"]}>Personal</span>
            <span className={styles["text016"]}>trainer</span>
            <span className={styles["text017"]}>?</span>
          </span>
          <div className={styles["motivos"]}>
            <div className={styles["card"]}>
              <div className={styles["icon"]}>
                <img
                  alt="image"
                  src="/external/iconcommunity.svg"
                  className={styles["iconcommunity"]}
                />
              </div>
              <span className={styles["text018"]}>
                <span>
                  Acesse uma vasta rede de potenciais alunos buscando por um
                  profissional com você
                </span>
              </span>
            </div>
            <div className={styles["card01"]}>
              <div className={styles["icon1"]}>
                <img
                  alt="image"
                  src="/external/iconstar.svg"
                  className={styles["iconstar"]}
                />
              </div>
              <span className={styles["text020"]}>
                <span className={styles["text021"]}>
                  Anuncie gratuitamente ou escolha um plano
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <span className={styles["text022"]}>premium</span>
                <span> com benefícios adicionais</span>
              </span>
            </div>
            <div className={styles["card02"]}>
              <div className={styles["icon2"]}>
                <img
                  alt="image"
                  src="/external/iconrocket.svg"
                  className={styles["iconrocket"]}
                />
              </div>
              <span className={styles["text024"]}>
                <span>
                  Promovemos seu perfil em campanhas nas principais redes
                  sociais e plataformas de anúncios
                </span>
              </span>
            </div>
            <div className={styles["card03"]}>
              <div className={styles["icon3"]}>
                <img
                  alt="image"
                  src="/external/iconspeaker.svg"
                  className={styles["iconspeaker"]}
                />
              </div>
              <span className={styles["text026"]}>
                <span>
                  Poupe tempo e dinheiro: Deixe a publicidade conosco e foque no
                  que você faz de melhor!
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className={styles["nao-perca-tempo"]}>
          <span className={styles["text-titulo2"]}>
            <span>Não perca mais tempo!</span>
          </span>
          <span className={styles["text029"]}>
            <span>
              Seja parte da revolução que está mudando a forma como personal
              trainers se conectam com alunos em todo o país.
            </span>
          </span>
          <Link to="/cadastro" className={styles["botao-anunciar1"]}>
            <span className={styles["text031"]}>
              <span>Criar meu anúncio agora</span>
            </span>
          </Link>
        </div>
        <div className={styles["cards-vantagens"]}>
          <div className={styles["cards"]}>
            <div className={styles["card04"]}>
              <span className={styles["text033"]}>
                <span>Destaque-se em um mercado competitivo</span>
              </span>
              <span className={styles["text035"]}>
                <span className={styles["text036"]}>
                  Sabemos que o mercado de personal trainers é vasto e
                  diversificado. A concorrência é feroz, e destacar-se pode ser
                  um desafio. Com o
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <span className={styles["text037"]}>Personaltrainer</span>
                <span>.com.br</span>
                <span>
                  , você ganha uma vitrine exclusiva para seus serviços,
                  alcançando pessoas especificamente interessadas em treinamento
                  personalizado.
                </span>
              </span>
            </div>
            <div className={styles["card05"]}>
              <span className={styles["text040"]}>
                <span>Presença digital sem esforço</span>
              </span>
              <span className={styles["text042"]}>
                <span>
                  A era digital trouxe inúmeras oportunidades, mas também
                  desafios. Criar e manter um site, gerenciar múltiplas redes
                  sociais e investir em publicidade pode ser oneroso e tomar
                  muito do seu tempo. Aqui, cuidamos de todo esse processo para
                  você. Sua única preocupação? Fornecer um serviço excepcional
                  para seus novos alunos.
                </span>
              </span>
            </div>
            <div className={styles["card06"]}>
              <span className={styles["text044"]}>
                <span>Alcance nacional com filtro local</span>
              </span>
              <span className={styles["text046"]}>
                <span className={styles["text047"]}>
                  Seu perfil na
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <span className={styles["text048"]}>Personaltrainer</span>
                <span>.com.br</span>
                <span>
                  {" "}
                  pode ser visto por pessoas de todo o Brasil. No entanto,
                  graças aos nossos filtros avançados, alunos potenciais podem
                  encontrar você com base em sua localização e especialidades.
                  Seja reconhecido em sua cidade, estado ou até nacionalmente!
                </span>
              </span>
            </div>
            <div className={styles["card07"]}>
              <span className={styles["text051"]}>
                <span>Opções flexíveis para todos os perfis</span>
              </span>
              <span className={styles["text053"]}>
                <span>
                  Seja você um personal trainer recém-formado ou um profissional
                  experiente, temos opções para todos. Anuncie gratuitamente e
                  desfrute de benefícios essenciais ou opte por um dos nossos
                  planos premium e potencialize ainda mais sua visibilidade.
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className={styles["banner-anunciar"]}>
          <img
            alt="PhoneAngleMockup0211070"
            src="/external/phoneanglemockup0211070-cgp.png"
            className={styles["img-phone"]}
          />
          <Link to="/cadastro" className={styles["botao-anunciar2"]}>
            <span className={styles["text055"]}>
              <span>Quero anunciar e transformar minha carreira!</span>
            </span>
          </Link>
        </div>
        <div className={styles["nao-perca-tempo1"]}>
          <span className={styles["text-titulo3"]}>
            <span>Não perca mais tempo!</span>
          </span>
          <div className={styles["cards1"]}>
            <div className={styles["card08"]}>
              <div className={styles["icon4"]}>
                <img
                  alt="image"
                  src="/external/iconuser.svg"
                  className={styles["iconuser"]}
                />
              </div>
              <span className={styles["text058"]}>
                <span>Conexões verdadeiras e direcionadas</span>
              </span>
              <span className={styles["text060"]}>
                <span>
                  Não é apenas sobre ter um espaço online; é sobre criar
                  conexões significativas. Nossa plataforma foi meticulosamente
                  projetada para conectar personal trainers a alunos que
                  verdadeiramente se alinham às suas especialidades e métodos de
                  treinamento.
                </span>
              </span>
            </div>
            <div className={styles["card09"]}>
              <div className={styles["icon5"]}>
                <img
                  alt="image"
                  src="/external/iconanalysis.svg"
                  className={styles["iconanalysis"]}
                />
              </div>
              <span className={styles["text062"]}>
                <span>Análise de desempenho e feedback</span>
              </span>
              <span className={styles["text064"]}>
                <span>
                  Receba insights valiosos sobre seu perfil. Quantas pessoas
                  visitaram? Quantos entraram em contato? Use esses dados para
                  ajustar sua abordagem, entender o que está funcionando e onde
                  você pode melhorar.
                </span>
              </span>
            </div>
            <div className={styles["card10"]}>
              <div className={styles["icon6"]}>
                <img
                  alt="image"
                  src="/external/iconbook.svg"
                  className={styles["iconbook"]}
                />
              </div>
              <span className={styles["text066"]}>
                <span>Evolução constante</span>
              </span>
              <span className={styles["text068"]}>
                <span className={styles["text069"]}>
                  A
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <span className={styles["text070"]}>Personal</span>
                <span className={styles["text071"]}>trainer</span>
                <span>
                  {" "}
                  não é apenas um espaço de anúncio. É uma comunidade.
                  Beneficie-se de artigos, webinars e cursos pensados para
                  ajudar personal trainers a aprimorar suas habilidades e a se
                  manterem atualizados sobre as últimas tendências do setor.
                </span>
              </span>
            </div>
            <div className={styles["card11"]}>
              <div className={styles["icon7"]}>
                <img
                  alt="image"
                  src="/external/iconsecurity.svg"
                  className={styles["iconsecurity"]}
                />
              </div>
              <span className={styles["text073"]}>
                <span>Segurança e confiabilidade</span>
              </span>
              <span className={styles["text075"]}>
                <span>
                  Seus dados e os de seus alunos são nossa prioridade. Com
                  sistemas de segurança robustos, garantimos que sua informação
                  esteja protegida, permitindo que você se concentre no que faz
                  de melhor: Treinar seus alunos.
                </span>
              </span>
            </div>
          </div>
        </div>
        <Link to="/planos" className={styles["botao-comparar-planos"]}>
          <span className={styles["text077"]}>
            <span className={styles["text078"]}>
              Compare nossos planos de anúncio
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span className={styles["text079"]}>gratuito</span>
            <span className={styles["text080"]}>
              {" "}
              e
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span className={styles["text081"]}>premium</span>
          </span>
          <img
            alt="pastedImage"
            src="/external/pastedimage-7ili-200h.png"
            className={styles["iconvermais"]}
          />
        </Link>
      </div>
      <div className={styles["cadastre-se"]}>
        <div className={styles["container2"]}>
          <span className={styles["text-titulo4"]}>
            <span className={styles["text082"]}>
              Cadastre-se e comece sua jornada com o
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span className={styles["text083"]}>Personal</span>
            <span className={styles["text084"]}>trainer</span>
            <span className={styles["text085"]}>!</span>
          </span>
          <span className={styles["text086"]}>
            <span className={styles["text087"]}>Por que esperar?</span>
            <span className={styles["text088"]}>
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span className={styles["text089"]}>
              A cada minuto, alunos em todo o Brasil estão buscando pelo
              treinador perfeito.
            </span>
            <span className={styles["text090"]}>
              Seu próximo aluno pode estar a apenas um clique de distância!
            </span>
          </span>
          <span className={styles["text091"]}>
            <span className={styles["text092"]}>
              Cada treino que você oferece muda uma vida. No
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span className={styles["text093"]}>Personaltrainer</span>
            <span>.com.br</span>
            <span>
              , valorizamos sua habilidade de transformar e inspirar. Venha,
              faça parte da nossa missão de conectar talentos com aqueles que
              buscam uma vida mais saudável e ativa.
            </span>
            <br></br>
            <span>
              A hora é agora. Não deixe para amanhã o sucesso que você pode
              começar a construir hoje.
            </span>
          </span>
          <Link to="/cadastro" className={styles["botao-anunciar3"]}>
            <span className={styles["text098"]}>
              <span>Quero anunciar</span>
            </span>
          </Link>
        </div>
      </div>
      <Rodape></Rodape>
    </div>
  );
};

export default SejaAnunciante;
