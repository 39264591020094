import React from 'react'

import Header from '../../components/Header/header'
import Rodape from '../../components/Rodape/rodape'
import styles from './termosde-uso.module.css'

const TermosdeUso = (props) => {
  return (
    <div className={styles['container']}>
      <Header />
      <div className={styles['container1']}>
        <div className={styles['conteudo']}>
          <span className={styles['text']}>
            <span>Termos de uso</span>
            <br></br>
          </span>
          <span className={styles['text03']}>
          Os serviços do <em><strong>personaltrainer.com.br</strong></em> são fornecidos pela pessoa jurídica de direito privado <strong>ROMPER ADMINISTRADORA DE MARCAS EIRELI</strong>, neste ato denominada, somente, ROMPER, inscrita no CNPJ sob o nº 18.461.120/0001-51, titular da propriedade intelectual sobre software, website, aplicativos, conteúdos e demais ativos relacionados à plataforma. 
          <br></br><br></br>
          Por meio destes <em>termos e condições gerais de uso</em>, a ROMPER, apresenta as condições essenciais para o uso da plataforma de anúncios. 
          Ao utilizarem o personaltrainer.com.br, os usuários se submetem às condições destes termos e condições gerais de uso, bem como, a todos os documentos anexos a estes.
          <br></br><br></br><br></br>
          <strong>1. DO OBJETO</strong>
          <br></br>
          A plataforma visa anunciar os personais trainers disponíveis em uma determinada área geográfica. 
          Através do sistema, é possível que o cliente encontre um profissional, filtrando informações relevantes, tais como, sua localidade, modalidade de atendimento, objetivos, dentre outros. 
          O contato e/ ou contratação do profissional é feito pelos canais fornecidos pelo mesmo, como, por exemplo, whatsapp, redes sociais e afins, podendo destacar, por oportuno, inexistir qualquer responsabilidade da plataforma pela eventual falha na prestação de serviços, bem como, qualquer outro vício. 
          <br></br><br></br><br></br>
          <strong>2. DA ACEITAÇÃO</strong>
          <br></br>
          O presente termo estabelece obrigações contratadas de livre e espontânea vontade, por tempo indeterminado, entre a plataforma e as pessoas físicas ou jurídicas, usuárias do site ou aplicativo.
          Ao utilizar a plataforma o usuário aceita integralmente as presentes normas e compromete-se a observá-las, sob o risco de aplicação das penalidades cabíveis.
          A aceitação do presente instrumento é imprescindível para o acesso e para a utilização da plataforma. Caso não concorde com as disposições deste instrumento, o usuário não deve utilizá-los.
          <br></br><br></br><br></br>
          <strong>3. DO ACESSO DOS USUÁRIOS</strong>
          <br></br>
          Serão utilizadas todas as soluções técnicas à disposição do responsável pela plataforma para permitir o acesso ao serviço 24 (vinte e quatro) horas por dia, 7 (sete) dias por semana. No entanto, a navegação na plataforma ou em alguma de suas páginas poderá ser interrompida, limitada ou suspensa para atualizações, modificações ou qualquer ação necessária ao seu bom funcionamento, sem aviso prévio.
          <br></br><br></br><br></br>
          <strong>4. DO CADASTRO</strong>
          <br></br>
          O acesso às funcionalidades da plataforma exigirá a realização de um cadastro prévio e, em se tratando do fornecedor de serviços (personal trainer), poderá ser exigido pagamento de determinado valor a depender do plano contratado. 
          <br></br><br></br>
          Haverá modalidade de anúncio grátis. 
          <br></br><br></br>
          Os Serviços estão disponíveis somente para pessoas físicas regularmente inscritas nos cadastros públicos que tenham capacidade legal para contratá-los. 
          <br></br><br></br>
          Ficam, desde já, os usuários advertidos das sanções legais cominadas no Código Civil.
          <br></br><br></br>
          O cadastro deve ser realizado diretamente na Plataforma, mediante o preenchimento dos dados obrigatórios solicitados.
          <br></br><br></br>
          Ao se cadastrar o usuário deverá informar dados completos, recentes e válidos, sendo de sua exclusiva responsabilidade manter referidos dados atualizados, bem como, se compromete com a veracidade dos dados fornecidos, sob as penas da lei.
          <br></br><br></br>
          O usuário se compromete a não informar seus dados cadastrais e/ou de acesso à plataforma a terceiros, responsabilizando-se integralmente pelo uso que deles seja feito.
          <br></br><br></br>
          Mediante a realização do cadastro o usuário declara e garante expressamente ser plenamente capaz, podendo exercer e usufruir livremente dos serviços ofertados.
          <br></br><br></br>
          O usuário deverá fornecer um endereço de e-mail válido, através do qual o site realizará todas comunicações necessárias.
          <br></br><br></br>
          Após a confirmação do cadastro, o usuário possuirá um login e uma senha pessoal, a qual assegura ao usuário o acesso individual à mesma. Desta forma, compete ao usuário exclusivamente a manutenção de referida senha de maneira confidencial e segura, evitando o acesso indevido às informações pessoais.
          <br></br><br></br>
          Toda e qualquer atividade realizada com o uso da senha será de responsabilidade do usuário, que deverá informar prontamente a plataforma em caso de uso indevido da respectiva senha.
          <br></br><br></br>
          Caberá ao usuário assegurar que o seu equipamento seja compatível com as características técnicas que viabilize a utilização da plataforma e dos serviços ou produtos.
          <br></br><br></br>
          O usuário poderá, a qualquer tempo, requerer o cancelamento de seu cadastro junto ao site ou aplicativo. O seu descadastramento será realizado o mais rapidamente possível, desde que não sejam verificados débitos em aberto. 
          <br></br><br></br>
          Em nenhuma hipótese será permitida a cessão, a venda, o aluguel ou outra forma de transferência da conta. Não se permitirá, ainda, a criação de novos cadastros por pessoas cujos cadastros originais tenham sido cancelados por infrações às políticas da empresa.
          <br></br><br></br>
          A empresa pode, unilateralmente, excluir o cadastro dos usuários quando verificado que a conduta deste é ou será prejudicial ou ofensiva a empresa, outros usuários, seus funcionários ou a terceiros.
          <br></br><br></br><br></br>
          <strong>5. DOS SERVIÇOS</strong>
          <br></br>
          A plataforma poderá disponibilizar para o anunciante um conjunto específico de funcionalidades e ferramentas para otimizar o uso do serviço de anúncio.
          <br></br><br></br>
          Na plataforma os serviços oferecidos estão descritos e apresentados com o maior grau de exatidão, contendo informações sobre sua composição e respectivo preço. 
          <br></br><br></br>
          O usuário/ anunciante poderá ter somente 1 (um) anúncio. 
          <br></br><br></br><br></br>
          <strong>6. DOS PREÇOS</strong>
          <br></br>
          A plataforma se reserva no direito de reajustar unilateralmente, a qualquer tempo, os valores do serviço sem consulta ou anuência prévia do usuário.
          <br></br><br></br>
          Os valores aplicados são aqueles que estão em vigor no momento de contratação do serviço de anúncio.
          <br></br><br></br>
          Os preços são indicados em reais.
          <br></br><br></br>
          Na contratação, a plataforma poderá solicitar as informações financeiras do anunciante, como CPF, endereço de cobrança e dados de cartões. Ao inserir referidos dados, o usuário concorda que sejam cobrados, de acordo com a forma de pagamento que venha a ser escolhida, os preços então vigentes e informados quando da contratação. Referidos dados financeiros poderão ser armazenados para facilitar acessos e contratações futuras.
          <br></br><br></br><br></br>
          <strong>7. DO SUPORTE</strong>
          <br></br>
          Em caso de qualquer dúvida, sugestão ou problema com a utilização da plataforma, o usuário poderá entrar em contato com o suporte, através do botão “suporte”, situado no painel do usuário ou pelo e-mail atendimento@personaltrainer.com.br. 
          <br></br><br></br>
          Estes serviços de atendimento ao usuário estarão disponíveis de segunda-feira a sexta-feira, das 09h às 18h. 
          <br></br><br></br><br></br>
          <strong>8. DAS RESPONSABILIDADES</strong>
          <br></br>
          O usuário e anunciante são responsáveis e se obrigam a:
          <br></br><br></br>
          a)	Utilizar-se das funcionalidades oferecidas pela Plataforma em estrita conformidade com a legislação vigente e aplicável à atividade exercida, sendo, pois, <em><strong>o único e exclusivo responsável pela licitude e adequação de suas atividades.</strong></em>
          <br></br><br></br>
          b)	Reconhecer que todos os acessos realizados por meio de sua Conta de Acesso na Plataforma são interpretados como tendo sido feitos pelo próprio usuário e/ou seus responsáveis legais de forma incontestável e de sua integral responsabilidade;
          <br></br><br></br>
          c)	Dispor de dispositivos e equipamentos tecnológicos, serviços de conexão à Internet, com antivírus e firewall habilitados, softwares devidamente atualizados, além da adoção de medidas de segurança mínimas para acesso a Plataforma;
          <br></br><br></br>
          d)	 Deixar seus sistemas de antispam, filtros similares ou configurações de redirecionamento de mensagens ajustados de modo que não interfiram no recebimento dos comunicados e materiais da Plataforma, não sendo aceitável nenhuma escusa caso não tenha tido acesso a algum e-mail ou mensagem eletrônica em virtude dos recursos mencionados;
          <br></br><br></br>
          e)	As funcionalidades da Plataforma são oferecidas na forma de licença de uso, não conferindo aos usuários nenhum direito sobre o software utilizado pela EMPRESA ou sobre suas estruturas de informática que o sustentam.
          <br></br><br></br><br></br>
          <strong>8.1. DAS ISENÇÕES E LIMITAÇÕES DE RESPONSABILIDADE DA EMPRESA</strong>
          <br></br>
          Os usuários e anunciantes reconhecem e concordam que: 
          <br></br><br></br>
          A EMPRESA anunciante não se responsabiliza por fraudes, nem por qualquer dano causado por anunciantes aos usuários e/ou vice-versa;
          <br></br><br></br>
          A EMPRESA não será responsável por erros ou interrupções no fornecimento de informações por sistemas independentes de prestação de serviços, como os sistemas de pagamento e os servidores, ou por sites integrados gerenciados por terceiros. Tais sistemas e sites integrados somente buscam trazer maior conveniência aos usuários, estando ciente que a EMPRESA não tem qualquer influência ou responsabilidade sobre o conteúdo disponibilizado e/ ou o serviço oferecido;
          <br></br><br></br>
          A EMPRESA não se responsabiliza por qualquer dano, prejuízo e/ou perda sofridos pelo usuário ou anunciante; 
          <br></br><br></br>
          A EMPRESA não será responsável por qualquer vírus, trojan, malware, spyware ou qualquer software que possa danificar, alterar as configurações ou infiltrar o equipamento do usuário, profissional e demais usuários em decorrência do acesso, da utilização ou da navegação na internet, ou, ainda, como consequência da transferência de quaisquer dados e informações;
          <br></br><br></br><br></br>
          <strong>9. DAS VEDAÇÕES</strong>
          <br></br>
          Fica vedada qualquer tentativa, direta ou indireta, bem-sucedida ou não, de realizar alguma das atividades listadas abaixo. A constatação de tal intento tornará o autor, bem como seus mandantes, defensores, encorajadores e ajudantes, passível das sanções previstas no ordenamento jurídico vigente. Tais atividades incluem, mas não se limitam a:
          <br></br><br></br>
          a) Incomodar, agredir, caluniar, injuriar, difamar, perseguir ou assediar qualquer indivíduo;
          <br></br><br></br>
          b) Infringir os direitos de qualquer pessoa ou entidade, incluindo, sem limitação, os direitos de propriedade intelectual, privacidade, publicidade ou contratual;
          <br></br><br></br>
          c) Copiar, armazenar, ou de qualquer forma acessar qualquer informação contida na plataforma ou serviços para fins que não sejam expressamente autorizados por estes Termos;
          <br></br><br></br>
          d) Utilizar a plataforma ou serviços para transmitir, distribuir, publicar ou enviar qualquer informação relativa a qualquer outra pessoa ou entidade, incluindo, sem limitação, fotografias de outras pessoas sem sua permissão, informações de contato pessoal ou de crédito, débito, cartão telefônico ou números de contas;
          <br></br><br></br>
          e) Utilizar a plataforma ou serviços para qualquer comercial ou para outros fins que não sejam expressamente autorizados por estes Termos;
          <br></br><br></br>
          f) Usar software manual ou automatizado, dispositivos, scripts robôs, ou outros meios ou processos para acessar, “scrape”, “crawl” ou “spider” qualquer parte da plataforma, serviços;
          <br></br><br></br>
          g) Modificar, copiar, distribuir, transmitir, exibir, realizar, reproduzir, publicar, licenciar, criar trabalhos derivados, exibir em outra webpage, utilizar em qualquer outro website, transferir ou vender qualquer informação, software, listas de usuários, banco de dados ou outras listas, produtos ou serviços prestados por ou obtidos pela EMPRESA, incluindo, mas não limitado a, práticas envolvendo “screen scraping”, “database scraping”, ou qualquer outra atividade com o propósito de obter listas de usuários ou outras informações;
          <br></br><br></br>
          h) Usar scripts automatizados para coletar informações ou de outra forma interagir com a plataforma ou serviços;
          <br></br><br></br>
          i) Acessar, testar, adulterar, interferir ou danificar a plataforma ou qualquer sistema da EMPRESA, dos usuários ou de terceiros através do uso de vírus, cancelar bots, cavalos de Tróia, códigos nocivos, flood pings, ataques de negação de serviço, pacote ou falsificação de IP, roteamento forjado ou informações de endereço eletrônico ou métodos similares ou de tecnologia;
          <br></br><br></br>
          j) Tentar detectar, varrer, ou testar a vulnerabilidade de qualquer sistema ou rede EMPRESA ou violar qualquer segurança ou medidas de autenticação;
          <br></br><br></br>
          k) Evitar, ignorar, remover, desativar, prejudicar, decodificar, ou evitar qualquer medida tecnológica implementada pela EMPRESA ou qualquer dos provedores desta ou qualquer outro terceiro para proteger a plataforma, serviços;
          <br></br><br></br>
          l) Forjar qualquer pacote de cabeçalho TCP/IP ou qualquer parte da informação do cabeçalho em qualquer publicação, e-mail ou newsgroup, ou de qualquer forma utilizar a plataforma, serviços ou conteúdos, para enviar fonte de identificação alterada, enganosa ou falsa informação;
          <br></br><br></br>
          m) Sistematicamente recuperar dados ou outro conteúdo da nossa plataforma ou serviços para criar ou compilar, direta ou indiretamente, em downloads únicos ou múltiplos, a coleta, compilação, banco de dados, diretório ou similar, seja por métodos manuais, através da utilização de bots, crawlers, ou spiders, ou de qualquer outra forma;
          <br></br><br></br>
          n) Tentar decifrar, descompilar, desmontar ou fazer engenharia reversa de qualquer software usado, para fornecer a plataforma, serviços ou conteúdos;
          <br></br><br></br>
          o) Personificar qualquer pessoa ou entidade, falsificar ou adulterar a si mesmo ou sua afiliação com qualquer pessoa ou entidade;
          <br></br><br></br><br></br>
          <strong>10. DA PROPRIEDADE INTELECTUAL</strong>
          <br></br>
          O presente Termo de Uso concede aos usuários e anunciantes uma licença não exclusiva, não transferível e não sublicenciável, para acessar e fazer uso da plataforma e dos serviços por ela disponibilizados.
          <br></br><br></br>
          A estrutura do site ou aplicativo, as marcas, logotipos, nomes comerciais, layouts, gráficos e design de interface, imagens, ilustrações, fotografias, apresentações, vídeos, conteúdos escritos e de som e áudio, programas de computador, banco de dados, arquivos de transmissão e quaisquer outras informações e direitos de propriedade intelectual da EMPRESA observados os termos da Lei da Propriedade Industrial (Lei nº 9.279/96), Lei de Direitos Autorais (Lei nº 9.610/98) e Lei do Software (Lei nº 9.609/98), estão devidamente reservados.
          <br></br><br></br>
          Os usuários e/ ou anunciantes <u><strong>NÃO</strong></u> tem o direito de: 
          <br></br><br></br>
          a)	Sublicenciar os direitos de licença concedidos nesta seção; 
          <br></br><br></br>
          b)	Usar, copiar, adaptar, modificar, preparar trabalhos derivados com base, distribuir, licenciar, vender, transferir, exibir publicamente, executar publicamente, transmitir ou explorar a Plataforma, Serviços ou Conteúdos, exceto se expressamente permitido nestes Termos;
          <br></br><br></br>
          c)	Remover, alterar ou ocultar quaisquer direitos autorais, marca registrada, marca de serviço ou outros direitos de propriedade incorporadas ou acompanhando a Plataforma, Serviços ou Conteúdos. 
          <br></br><br></br>
          Nenhuma licença ou direitos são concedidos por implicação ou sob quaisquer direitos de propriedade intelectual pertencentes ou controlados pela EMPRESA ou de seus licenciadores, exceto para as licenças e direitos expressamente concedidos nestes Termos.
          <br></br><br></br>
          Este Termos de Uso não cede ou transfere ao usuário qualquer direito, de modo que o acesso não gera qualquer direito de propriedade intelectual ao usuário.
          <br></br><br></br>
          O uso da plataforma pelo usuário é pessoal, individual e intransferível, sendo vedado qualquer uso não autorizado, comercial ou não-comercial. Tais usos consistirão em violação dos direitos de propriedade intelectual da EMPRESA, puníveis nos termos da legislação aplicável.
          <br></br><br></br>
          Qualquer intromissão, tentativa de, ou atividade que viole ou contrarie as leis de direito de propriedade intelectual e/ou as proibições estipuladas nestes Termos de Uso, tornarão o responsável passível das ações legais pertinentes, bem como das sanções aqui previstas, sendo ainda responsável pelas indenizações por eventuais danos causados. 
          <br></br><br></br><br></br>
          <strong>11. DAS PENALIDADES</strong>
          <br></br>
          O usuário/ anunciante que infringir quaisquer previsões dos termos e condições gerais de uso estará sujeito as penalidades legais competentes, sem prejuízos daquelas tomadas em âmbito administrativo. 
          <br></br><br></br>
          O usuário/ anunciante poderá ter seu anúncio bloqueado a qualquer tempo. 
          <br></br><br></br>
          Uma vez bloqueado, extingue-se o direito de uso da plataforma, sendo vedada a realização de um novo cadastro. 
          <br></br><br></br><br></br>
          <strong>12. DAS ALTERAÇÕES</strong>
          <br></br>
          Os itens descritos no presente instrumento poderão sofrer alterações, unilateralmente e a qualquer tempo, por parte da EMPRESA para adequar ou modificar os serviços, bem como para atender novas exigências legais. As alterações serão veiculadas pelo site ou aplicativo e o usuário poderá optar por aceitar o novo conteúdo ou não.
          <br></br><br></br><br></br>
          <strong>13. DA POLÍTICA DE PRIVACIDADE</strong>
          <br></br>
          Além do presente Termo, o usuário deverá consentir com as disposições contidas na respectiva Política de Privacidade a ser apresentada a todos os interessados dentro da interface da plataforma.
          <br></br><br></br><br></br>
          <strong>14. DO FORO</strong>
          <br></br>
          Para a solução de controvérsias decorrentes do presente instrumento será aplicado integralmente o Direito brasileiro. Outrossim, os eventuais litígios deverão ser apresentados no foro da comarca de Cascavel/PR. 


          </span>
        </div>
      </div>
      <Rodape></Rodape>
    </div>
  )
}

export default TermosdeUso
