import React from 'react'

import Header from '../../components/Header/header'
import Rodape from '../../components/Rodape/rodape'
import styles from './faq.module.css'

const FAQ = (props) => {
  return (
    <div className={styles['container']}>
      <Header />
      <div className={styles['container1']}>
        <div className={styles['conteudo']}>
          <span className={styles['text']}>
            <span>Perguntas frequentes</span>
          </span>
          <div className={styles['listafaqs']}>
            <div className={styles['pergunta']}>
              <span className={styles['text02']}>
                <span>Personal Trainer Online: Vale o Investimento?</span>
              </span>
              <span className={styles['text04']}>
                <span>
                  Contratar um personal trainer é um investimento direto em sua
                  saúde e bem-estar. O auxílio especializado ajuda você a
                  alcançar seus objetivos de maneira mais eficaz, evitando
                  lesões e otimizando seu tempo. Os resultados, frequentemente,
                  são mais rápidos e duradouros. Pense no valor, não apenas no
                  preço.
                </span>
              </span>
            </div>
            <div className={styles['pergunta01']}>
              <span className={styles['text06']}>
                <span>Preço de Personal Trainer: O que Considerar?</span>
              </span>
              <span className={styles['text08']}>
                <span>
                  O valor de um personal trainer varia conforme experiência,
                  especialização e localização. Mas lembre-se: você está
                  investindo em resultados personalizados e um acompanhamento
                  dedicado. Em nossa plataforma, compare preços e encontre a
                  opção perfeita para seu bolso e necessidades.
                </span>
              </span>
            </div>
            <div className={styles['pergunta02']}>
              <span className={styles['text10']}>
                <span>Emagrecimento com Personal: Benefícios e Resultados</span>
              </span>
              <span className={styles['text12']}>
                <span>
                  O emagrecimento guiado por um personal trainer traz inúmeros
                  benefícios. A abordagem é personalizada, considerando suas
                  metas, ritmo e alimentação. Evita-se métodos prejudiciais ou
                  ineficazes, garantindo perda de peso saudável, sustentável e
                  com orientações que previnem o efeito sanfona.
                </span>
              </span>
            </div>
            <div className={styles['pergunta03']}>
              <span className={styles['text14']}>
                <span>Personal Trainer na Gravidez: Dicas e Vantagens</span>
              </span>
              <span className={styles['text16']}>
                <span>
                  A gravidez é uma fase repleta de mudanças, e um personal
                  trainer especializado pode ser essencial. Ele proporciona
                  treinos seguros, que ajudam na preparação para o parto,
                  aliviam desconfortos e promovem a recuperação pós-parto mais
                  rápida. É cuidado e suporte para mãe e bebê.
                </span>
              </span>
            </div>
            <div className={styles['pergunta04']}>
              <span className={styles['text18']}>
                <span>Personal Trainer na Gravidez: Dicas e Vantagens</span>
              </span>
              <span className={styles['text20']}>
                <span>
                  A gravidez é uma fase repleta de mudanças, e um personal
                  trainer especializado pode ser essencial. Ele proporciona
                  treinos seguros, que ajudam na preparação para o parto,
                  aliviam desconfortos e promovem a recuperação pós-parto mais
                  rápida. É cuidado e suporte para mãe e bebê.
                </span>
              </span>
            </div>
            <div className={styles['pergunta05']}>
              <span className={styles['text22']}>
                <span>Personal Trainer na Gravidez: Dicas e Vantagens</span>
              </span>
              <span className={styles['text24']}>
                <span>
                  A gravidez é uma fase repleta de mudanças, e um personal
                  trainer especializado pode ser essencial. Ele proporciona
                  treinos seguros, que ajudam na preparação para o parto,
                  aliviam desconfortos e promovem a recuperação pós-parto mais
                  rápida. É cuidado e suporte para mãe e bebê.
                </span>
              </span>
            </div>
            <div className={styles['pergunta06']}>
              <span className={styles['text26']}>
                <span>Personal Trainer na Gravidez: Dicas e Vantagens</span>
              </span>
              <span className={styles['text28']}>
                <span>
                  A gravidez é uma fase repleta de mudanças, e um personal
                  trainer especializado pode ser essencial. Ele proporciona
                  treinos seguros, que ajudam na preparação para o parto,
                  aliviam desconfortos e promovem a recuperação pós-parto mais
                  rápida. É cuidado e suporte para mãe e bebê.
                </span>
              </span>
            </div>
            <div className={styles['pergunta07']}>
              <span className={styles['text30']}>
                <span>Personal Trainer na Gravidez: Dicas e Vantagens</span>
              </span>
              <span className={styles['text32']}>
                <span>
                  A gravidez é uma fase repleta de mudanças, e um personal
                  trainer especializado pode ser essencial. Ele proporciona
                  treinos seguros, que ajudam na preparação para o parto,
                  aliviam desconfortos e promovem a recuperação pós-parto mais
                  rápida. É cuidado e suporte para mãe e bebê.
                </span>
              </span>
            </div>
            <div className={styles['pergunta08']}>
              <span className={styles['text34']}>
                <span>Personal Trainer na Gravidez: Dicas e Vantagens</span>
              </span>
              <span className={styles['text36']}>
                <span>
                  A gravidez é uma fase repleta de mudanças, e um personal
                  trainer especializado pode ser essencial. Ele proporciona
                  treinos seguros, que ajudam na preparação para o parto,
                  aliviam desconfortos e promovem a recuperação pós-parto mais
                  rápida. É cuidado e suporte para mãe e bebê.
                </span>
              </span>
            </div>
            <div className={styles['pergunta09']}>
              <span className={styles['text38']}>
                <span>Personal Trainer na Gravidez: Dicas e Vantagens</span>
              </span>
              <span className={styles['text40']}>
                <span>
                  A gravidez é uma fase repleta de mudanças, e um personal
                  trainer especializado pode ser essencial. Ele proporciona
                  treinos seguros, que ajudam na preparação para o parto,
                  aliviam desconfortos e promovem a recuperação pós-parto mais
                  rápida. É cuidado e suporte para mãe e bebê.
                </span>
              </span>
            </div>
            <div className={styles['pergunta10']}>
              <span className={styles['text42']}>
                <span>Personal Trainer na Gravidez: Dicas e Vantagens</span>
              </span>
              <span className={styles['text44']}>
                <span>
                  A gravidez é uma fase repleta de mudanças, e um personal
                  trainer especializado pode ser essencial. Ele proporciona
                  treinos seguros, que ajudam na preparação para o parto,
                  aliviam desconfortos e promovem a recuperação pós-parto mais
                  rápida. É cuidado e suporte para mãe e bebê.
                </span>
              </span>
            </div>
            <div className={styles['pergunta11']}>
              <span className={styles['text46']}>
                <span>Personal Trainer na Gravidez: Dicas e Vantagens</span>
              </span>
              <span className={styles['text48']}>
                <span>
                  A gravidez é uma fase repleta de mudanças, e um personal
                  trainer especializado pode ser essencial. Ele proporciona
                  treinos seguros, que ajudam na preparação para o parto,
                  aliviam desconfortos e promovem a recuperação pós-parto mais
                  rápida. É cuidado e suporte para mãe e bebê.
                </span>
              </span>
            </div>
            <div className={styles['pergunta12']}>
              <span className={styles['text50']}>
                <span>Personal Trainer na Gravidez: Dicas e Vantagens</span>
              </span>
              <span className={styles['text52']}>
                <span>
                  A gravidez é uma fase repleta de mudanças, e um personal
                  trainer especializado pode ser essencial. Ele proporciona
                  treinos seguros, que ajudam na preparação para o parto,
                  aliviam desconfortos e promovem a recuperação pós-parto mais
                  rápida. É cuidado e suporte para mãe e bebê.
                </span>
              </span>
            </div>
            <div className={styles['pergunta13']}>
              <span className={styles['text54']}>
                <span>Personal Trainer na Gravidez: Dicas e Vantagens</span>
              </span>
              <span className={styles['text56']}>
                <span>
                  A gravidez é uma fase repleta de mudanças, e um personal
                  trainer especializado pode ser essencial. Ele proporciona
                  treinos seguros, que ajudam na preparação para o parto,
                  aliviam desconfortos e promovem a recuperação pós-parto mais
                  rápida. É cuidado e suporte para mãe e bebê.
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <Rodape></Rodape>
    </div>
  )
}

export default FAQ
