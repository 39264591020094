import { SET_BUSCA_DATA } from '../actions/buscaActions';

const initialState = {
    appointmentType:[],
    availableTime: {
      morning: null,
      afternoon: null,
      evening: null,
    },
    services:[],
    pricing:{
        min:null,
        max:null
    },
    periodos:[],
    gender:[],
    locationType:[],
    genderPreference:[],
    skillLevel:[],
    ageGroup:[],
    address:{
        city:null,
        id: null,
        uf:null,
        region: null
    }
};

const buscaReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BUSCA_DATA:
      if (action.payload !== undefined) {
        return action.payload;
      }
      return state;
    default:
      return state;
  }
};


export default buscaReducer;