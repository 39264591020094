import styles from './TagPlan.module.css'

function TagPlan(props) {
    
    // console.log(props?.plan)
    return (
        <div
            className={
              props?.plan?.name === "Plano Free"
                ? styles["plano"]
                : styles["plano-elite"]
            }
          >
            {props?.plan?.name === "Plano Free" && (
              <img
                alt="image"
                src="/external/vector4766-0toy.svg"
                className={styles["icon-free"]}
              />
            )}
            {props?.plan?.name === "Plano Aquecimento" && (
              <img
                alt="Vector4766"
                src="/external/pastedimage-0egb.png"
                className={styles["pesoaqueciicon"]}
              />
            )}
            {props?.plan?.name === "Plano Elite" && (
              <img
                alt="Vector4766"
                src="/external/vector4766-i44.svg"
                className={styles["pesoeliteicon"]}
              />
            )}
            <span
              className={
                props?.plan?.name === "Plano Free" ? styles["text1"] : styles["text"]
              }
            >
              <span>{props?.plan?.name?.replace("Plano", "") || "Admin"}</span>
            </span>
          </div>
    )
}

export default TagPlan