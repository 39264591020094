import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Header from '../../components/Header/header'
import Rodape from '../../components/Rodape/rodape'
import styles from './planos.module.css'

import axios from "axios";
import { formatCurrency } from "../../utils";

const PlanosPublico = (props) => {
  const [plans, setPlans] = useState(null);

  const fetchPlans = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/plans/plans`
      );
      setPlans(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    (async function () {
      if (plans === null) {
        await fetchPlans();
      }
    })();
  }, [plans]);

  return (
    <div className={styles['container']}>
      <Header />
      <h3 className={styles['page-title']}>Planos</h3>
      <div className={styles["plans-container"]}>
        <div className={styles['opcoes']}>
          <div className={styles['plano-card']}>
            <div className={styles['cabecalho']}>
              <img
                alt="Vector4766"
                src="/external/vector4766-0toy.svg"
                className={styles['pesoicon']}
              />
              <span className={styles['text029']}>Plano Free</span>
            </div>
            <div className={styles['pacote']}>
              <div className={styles['container']}>
                <div className={styles['topico']}>
                  <img
                    alt="aprovado3812"
                    src="/external/aprovado3812-8n9o.svg"
                    className={styles['aprovado']}
                  />
                  <span className={styles['text001']}>
                    <span className={styles['text002']}>
                      Posição do anúncio:
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>3º</span>
                  </span>
                </div>
                <div className={styles['topico01']}>
                  <img
                    alt="recusado3812"
                    src="/external/recusado3812-4ayo.svg"
                    className={styles['recusado']}
                  />
                  <span className={styles['text004']}>
                    <span>Selo de contato verificado</span>
                  </span>
                </div>
                <div className={styles['topico02']}>
                  <img
                    alt="aprovado3812"
                    src="/external/aprovado3812-8n9o.svg"
                    className={styles['aprovado01']}
                  />
                  <span className={styles['text006']}>
                    <span>Atendimento presencial</span>
                  </span>
                </div>
                <div className={styles['topico03']}>
                  <img
                    alt="recusado3812"
                    src="/external/recusado3812-4ayo.svg"
                    className={styles['recusado1']}
                  />
                  <span className={styles['text008']}>
                    <span>Atendimento on-line</span>
                  </span>
                </div>
                <div className={styles['topico04']}>
                  <img
                    alt="aprovado3812"
                    src="/external/aprovado3812-8n9o.svg"
                    className={styles['aprovado02']}
                  />
                  <span className={styles['text010']}>
                    <span className={styles['text011']}>
                      Até
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span className={styles['text012']}>1</span>
                    <span> categorias de treino</span>
                  </span>
                </div>
                <div className={styles['topico05']}>
                  <img
                    alt="aprovado3812"
                    src="/external/aprovado3812-8n9o.svg"
                    className={styles['aprovado03']}
                  />
                  <span className={styles['text014']}>
                    <span className={styles['text015']}>1</span>
                    <span> idioma</span>
                  </span>
                </div>
                <div className={styles['topico06']}>
                  <img
                    alt="recusado3812"
                    src="/external/recusado3812-4ayo.svg"
                    className={styles['recusado2']}
                  />
                  <span className={styles['text017']}>
                    <span>Link para suas redes</span>
                  </span>
                </div>
                <div className={styles['topico07']}>
                  <img
                    alt="aprovado3812"
                    src="/external/aprovado3812-8n9o.svg"
                    className={styles['aprovado04']}
                  />
                  <span className={styles['text019']}>
                    <span className={styles['text020']}>
                      Galeria (até
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span className={styles['text021']}>4</span>
                    <span> fotos)</span>
                  </span>
                </div>
                <div className={styles['topico08']}>
                  <img
                    alt="recusado3812"
                    src="/external/recusado3812-4ayo.svg"
                    className={styles['recusado3']}
                  />
                  <span className={styles['text023']}>
                    <span>Sem alunos avançados</span>
                  </span>
                </div>
                <div className={styles['topico08']}>
                  <img
                    alt="recusado3812"
                    src="/external/recusado3812-4ayo.svg"
                    className={styles['recusado3']}
                  />
                  <span className={styles['text023']}>
                    <span>Anúncio em destaque</span>
                  </span>
                </div>
                <div className={styles['topico09']}>
                  <img
                    alt="recusado3812"
                    src="/external/recusado3812-4ayo.svg"
                    className={styles['recusado4']}
                  />
                  <span className={styles['text025']}>
                    <span>Oferece apoio nutricional</span>
                  </span>
                  <img
                    alt="Vector3812"
                    src="/external/vector3812-l75p.svg"
                    className={styles['appleicon']}
                  />
                </div>
                <div className={styles['topico08']}>
                  <img
                    alt="recusado3812"
                    src="/external/recusado3812-4ayo.svg"
                    className={styles['recusado3']}
                  />
                  <span className={styles['text023']}>
                    <span>Página exclusiva</span>
                  </span>
                </div>
              </div>
              <div className={styles['valor']}>
                <span className={styles['text027']}>
                  <span>Gratuito</span>
                </span>
              </div>
            </div>
          </div>
          <div className={styles['plano-card']}>
            <div className={styles['cabecalho1']}>
              <img
                alt="Vector4766"
                src="/external/vector4766-llha.svg"
                className={styles['pesoicon1']}
              />
              <span className={styles['text029']}>
                <span>Plano Aquecimento</span>
              </span>
            </div>
            <div className={styles['pacote1']}>
              <div className={styles['container1']}>
                <div className={styles['topico10']}>
                  <img
                    alt="aprovado3812"
                    src="/external/aprovado3812-8n9o.svg"
                    className={styles['aprovado05']}
                  />
                  <span className={styles['text031']}>
                    <span className={styles['text032']}>
                      Posição do anúncio:
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>2º</span>
                  </span>
                </div>
                <div className={styles['topico11']}>
                  <img
                    alt="aprovado3812"
                    src="/external/aprovado3812-8n9o.svg"
                    className={styles['aprovado06']}
                  />
                  <span className={styles['text034']}>
                    <span>Selo de contato verificado</span>
                  </span>
                </div>
                <div className={styles['topico12']}>
                  <img
                    alt="aprovado3812"
                    src="/external/aprovado3812-8n9o.svg"
                    className={styles['aprovado07']}
                  />
                  <span className={styles['text036']}>
                    <span>Atendimento presencial</span>
                  </span>
                </div>
                <div className={styles['topico13']}>
                  <img
                    alt="aprovado3812"
                    src="/external/aprovado3812-8n9o.svg"
                    className={styles['aprovado08']}
                  />
                  <span className={styles['text038']}>
                    <span>Atendimento on-line</span>
                  </span>
                </div>
                <div className={styles['topico14']}>
                  <img
                    alt="aprovado3812"
                    src="/external/aprovado3812-8n9o.svg"
                    className={styles['aprovado09']}
                  />
                  <span className={styles['text040']}>
                    <span className={styles['text041']}>
                      Até
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span className={styles['text042']}>4</span>
                    <span> categorias de treino</span>
                  </span>
                </div>
                <div className={styles['topico15']}>
                  <img
                    alt="aprovado3812"
                    src="/external/aprovado3812-8n9o.svg"
                    className={styles['aprovado10']}
                  />
                  <span className={styles['text044']}>
                    <span className={styles['text045']}>Até</span>
                    <span className={styles['text046']}> 2</span>
                    <span> idiomas</span>
                  </span>
                </div>
                <div className={styles['topico16']}>
                  <img
                    alt="Vector3812"
                    src="/external/aprovado3812-8n9o.svg"
                    className={styles['vector']}
                  />
                  <span className={styles['text048']}>
                    <span>Link para:</span>
                  </span>
                  <div className={styles['redes']}>
                    <img
                      src="/external/image1333812-gwfn-200h.png"
                      alt="image1333812"
                      className={styles['tiktok']}
                    />
                    <img
                      src="/external/image1343812-6fjk-200h.png"
                      alt="image1343812"
                      className={styles['linkedin']}
                    />
                    <img
                      alt="pastedImage"
                      src="/external/pastedimage-0gl2-200h.png"
                      className={styles['rede']}
                    />
                  </div>
                </div>
                <div className={styles['topico17']}>
                  <img
                    src="/external/aprovado3812-8n9o.svg"
                    alt="aprovado3812"
                    className={styles['aprovado11']}
                  />
                  <span className={styles['text050']}>
                    <span className={styles['text051']}>
                      Galeria (até
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span className={styles['text052']}>6</span>
                    <span className={styles['text053']}>
                      {' '}
                      fotos e
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span className={styles['text054']}>2</span>
                    <span> vídeos)</span>
                  </span>
                </div>
                <div className={styles['topico08']}>
                  <img
                    alt="recusado3812"
                    src="/external/recusado3812-4ayo.svg"
                    className={styles['recusado3']}
                  />
                  <span className={styles['text023']}>
                    <span>Sem alunos avançados</span>
                  </span>
                </div>
                <div className={styles['topico18']}>
                  <img
                    src="/external/recusado3812-4ayo.svg"
                    alt="recusado3812"
                    className={styles['recusado5']}
                  />
                  <span className={styles['text056']}>
                    <span>Anúncio em destaque</span>
                  </span>
                </div>
                <div className={styles['topico19']}>
                  <img
                    alt="recusado3812"
                    src="/external/recusado3812-4ayo.svg"
                    className={styles['recusado6']}
                  />
                  <span className={styles['text058']}>
                    <span>Oferece apoio nutricional</span>
                  </span>
                  <img
                    src="/external/vector3812-l75p.svg"
                    alt="Vector3812"
                    className={styles['appleicon1']}
                  />
                </div>
                <div className={styles['topico08']}>
                  <img
                    alt="recusado3812"
                    src="/external/recusado3812-4ayo.svg"
                    className={styles['recusado3']}
                  />
                  <span className={styles['text023']}>
                    <span>Página exclusiva</span>
                  </span>
                </div>
              </div>
              <div className={styles['valor1']}>
                <div className={styles['preco']}>
                  {/* <div className={styles['valor-anterior']}>
                  <span className={styles['text060']}>
                    <span className={styles['text061']}>R$ 29,00</span>
                    <span>/mês</span>
                  </span>
                  <div className={styles['risco']}></div>
                </div>
                <span className={styles['text063']}>
                  <span className={styles['text064']}>
                    R$ 19,00
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span className={styles['text065']}>/mês</span>
                </span> */}
                  {/* MUDANÇA */}
                  <div className={styles["valor-anterior"]}>
                    <span className={styles["text060"]}>
                      <span>A partir de </span>
                      <span className={styles["text061"]}>
                        {plans
                          ? formatCurrency(
                            plans.find(
                              (plano) =>
                                plano.name === "Plano Aquecimento"
                            )?.price?.quarterly / 3
                          )
                          : ""}
                      </span>
                    </span>
                  </div>
                  <span className={styles["text063"]}>
                    <span className={styles["text064"]}>
                      {plans
                        ? formatCurrency(
                          plans.find(
                            (plano) =>
                              plano.name === "Plano Aquecimento"
                          )?.pricePromotion?.annually / 12
                        )
                        : ""}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: " ",
                        }}
                      />
                    </span>
                    <span className={styles["text065"]}>/mês</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles['plano-card']}>
            <div className={styles['cabecalho2']}>
              <img
                alt="Vector4766"
                src="/external/vector4766-xpmv.svg"
                className={styles['pesoicon2']}
              />
              <span className={styles['text066']}>
                <span>Plano Elite</span>
              </span>
            </div>
            <div className={styles['pacote2']}>
              <div className={styles['container2']}>
                <div className={styles['topico20']}>
                  <img
                    alt="aprovado3812"
                    src="/external/aprovado3812-8n9o.svg"
                    className={styles['aprovado12']}
                  />
                  <span className={styles['text068']}>
                    <span className={styles['text069']}>
                      Posição do anúncio:
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span>1º</span>
                  </span>
                </div>
                <div className={styles['topico21']}>
                  <img
                    alt="aprovado3812"
                    src="/external/aprovado3812-8n9o.svg"
                    className={styles['aprovado13']}
                  />
                  <span className={styles['text071']}>
                    <span>Selo de contato verificado</span>
                  </span>
                </div>
                <div className={styles['topico22']}>
                  <img
                    src="/external/aprovado3812-8n9o.svg"
                    alt="aprovado3812"
                    className={styles['aprovado14']}
                  />
                  <span className={styles['text073']}>
                    <span>Atendimento presencial</span>
                  </span>
                </div>
                <div className={styles['topico23']}>
                  <img
                    src="/external/aprovado3812-8n9o.svg"
                    alt="aprovado3812"
                    className={styles['aprovado15']}
                  />
                  <span className={styles['text075']}>
                    <span>Atendimento on-line</span>
                  </span>
                </div>
                <div className={styles['topico24']}>
                  <img
                    src="/external/aprovado3812-8n9o.svg"
                    alt="aprovado3812"
                    className={styles['aprovado16']}
                  />
                  <span className={styles['text077']}>
                    <span className={styles['text078']}>
                      Até
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span className={styles['text079']}>8</span>
                    <span> categorias de treino</span>
                  </span>
                </div>
                <div className={styles['topico25']}>
                  <img
                    src="/external/aprovado3812-8n9o.svg"
                    alt="aprovado3812"
                    className={styles['aprovado17']}
                  />
                  <span className={styles['text081']}>
                    <span className={styles['text082']}>Até</span>
                    <span className={styles['text083']}> 4</span>
                    <span> idiomas</span>
                  </span>
                </div>
                <div className={styles['topico26']}>
                  <img
                    src="/external/aprovado3812-8n9o.svg"
                    alt="Vector3812"
                    className={styles['vector1']}
                  />
                  <span className={styles['text085']}>
                    <span>Link para:</span>
                  </span>
                  <div className={styles['redes1']}>
                    <img
                      alt="image1303812"
                      src="/external/image1303812-013-200h.png"
                      className={styles['insta']}
                    />
                    <img
                      src="/external/image1313812-i59-200h.png"
                      alt="image1313812"
                      className={styles['face']}
                    />
                    <img
                      src="/external/image1333812-gwfn-200h.png"
                      alt="image1333812"
                      className={styles['tiktok1']}
                    />
                    <img
                      alt="image1343812"
                      src="/external/image1343812-6fjk-200h.png"
                      className={styles['linkedin1']}
                    />
                    <img
                      alt="pastedImage"
                      src="/external/pastedimage-0gl2-200h.png"
                      className={styles['rede1']}
                    />
                  </div>
                </div>
                <div className={styles['topico27']}>
                  <img
                    src="/external/aprovado3812-8n9o.svg"
                    alt="aprovado3812"
                    className={styles['aprovado18']}
                  />
                  <span className={styles['text087']}>
                    <span className={styles['text088']}>
                      Galeria (até
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span className={styles['text089']}>20</span>
                    <span className={styles['text090']}>
                      {' '}
                      fotos e
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                    <span className={styles['text091']}>8</span>
                    <span> vídeos)</span>
                  </span>
                </div>
                <div className={styles['topico08']}>
                  <img
                    src="/external/aprovado3812-8n9o.svg"
                    alt="aprovado3812"
                    className={styles['aprovado15']}
                  />
                  <span className={styles['text075']}>
                    <span>Anuncie para alunos avançados</span>
                  </span>
                </div>
                <div className={styles['topico28']}>
                  <img
                    src="/external/aprovado3812-8n9o.svg"
                    alt="aprovado3812"
                    className={styles['aprovado19']}
                  />
                  <div className={styles['frame1171277085']}>
                    <span className={styles['text093']}>
                      <span>Anúncio em</span>
                    </span>
                    <div className={styles['frame1171277054']}>
                      <span className={styles['text095']}>
                        <span>DESTAQUE</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className={styles['topico29']}>
                  <img
                    alt="aprovado3812"
                    src="/external/aprovado3812-8n9o.svg"
                    className={styles['aprovado20']}
                  />
                  <span className={styles['text097']}>
                    <span>Oferece apoio nutricional</span>
                  </span>
                  <img
                    alt="Vector3812"
                    src="/external/vector3812-l75p.svg"
                    className={styles['appleicon2']}
                  />
                </div>
                <div className={styles['topico23']}>
                  <img
                    src="/external/aprovado3812-8n9o.svg"
                    alt="aprovado3812"
                    className={styles['aprovado15']}
                  />
                  <span className={styles['text075']}>
                    <span>Página exclusiva</span>
                  </span>
                </div>
              </div>
              <div className={styles['valor2']}>
                <div className={styles['preco1']}>
                  {/* <div className={styles['valor-anterior1']}>
                  <span className={styles['text099']}>
                    <span>R$ 119,80</span>
                    <span>/mês</span>
                  </span>
                  <div className={styles['risco1']}></div>
                </div>
                <span className={styles['text102']}>
                  <span className={styles['text103']}>
                    R$ 59,90
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <span className={styles['text104']}>/mês</span>
                </span> */}
                  {/* MUDANÇA */}
                  <div className={styles["valor-anterior1"]}>
                    <span className={styles["text060"]}>
                      <span>A partir de </span>
                      <span className={styles["text061"]}>
                        {plans
                          ? formatCurrency(
                            plans.find(
                              (plano) => plano.name === "Plano Elite"
                            )?.price?.annually / 12
                          )
                          : ""}
                      </span>
                    </span>
                  </div>
                  <span className={styles["text063"]}>
                    <span className={styles["text064"]}>
                      {plans
                        ? formatCurrency(
                          plans.find(
                            (plano) => plano.name === "Plano Elite"
                          )?.pricePromotion?.annually / 12
                        )
                        : ""}
                    </span>
                    <span className={styles["text065"]}>/mês</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Link to="/cadastro" className={styles['btn-cta']} onClick={() => {
        window.scrollTo(0, 0);
      }}>Criar meu anúncio agora</Link>
      <Rodape />
    </div>
  )
}

export default PlanosPublico;
