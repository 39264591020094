import React from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import styles from './not-found.module.css'

const NotFound = (props) => {
  return (
    <div className={styles['not-found-container']}>
      <Helmet>
        <title>404 - Página não encontrada</title>
      </Helmet>
      <div className={styles['logo']}>
        <Link to="/" className={styles['text-logo']}>
          <span className={styles['text']}>personal</span>
          <span className={styles['text01']}>trainer</span>
          <span className={styles['text02']}>.com.br</span>
        </Link>
      </div>
      <h3 className={styles['not-found-title']}>OOPS! PÁGINA NÃO ENCONTRADA</h3>
      <div className={styles['not-found-container1']}>
        <h1 className={styles['not-found-text1']}>404</h1>
      </div>
      <div className={styles['not-found-container2']}>
        <h2 className={styles['not-found-text2']}>
          A PÁGINA QUE VOCÊ TENTOU ACESSAR NÃO EXISTE EM NOSSO SITE.
        </h2>
      </div>
    </div>
  );
};

export default NotFound
