import axios from "axios";

export async function payPlan(paymentData) {
  const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/payments`,
      { ...paymentData },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            "ptr_145769130149"
          )}`,
        },
      }
  );
  console.log(response.data)
  return response.data.charges[0].last_transaction
}
