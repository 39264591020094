import React, { useState } from 'react'

import PropTypes from 'prop-types'

import './inspiracao.css'

const Inspiracao = (props) => {
  const [ProibidoLinks, setProibidoLinks] = useState(false)
  const [ExemplosVisible, setExemplosVisible] = useState(false)
  return (
    <div className={`inspiracao-frame1171277072 ${props.rootClassName} `}>
      <div className="inspiracao-inspirese">
        <button
          onClick={() => setExemplosVisible(!ExemplosVisible)}
          className="inspiracao-botao-exemplos"
        >
          <span className="inspiracao-text">
            <span className="">Inspire-se com exemplos de texto</span>
          </span>
          {ExemplosVisible && (
            <img
              alt="Group1171276758I378"
              src="/external/group1171276758i378-0atm.svg"
              className="inspiracao-arrowicon"
            />
          )}
          {!ExemplosVisible && (
            <img
              alt="pastedImage"
              src="/external/pastedimage-cgeb-200h.png"
              className="inspiracao-lampicon"
            />
          )}
        </button>
        {ProibidoLinks && (
          <span className="inspiracao-text-proibido-links">
            <span className="">Não é permitido links.</span>
          </span>
        )}
      </div>
      {ExemplosVisible && (
        <div className="inspiracao-exemplos">
          <div className="inspiracao-topico">
            <span className="inspiracao-text3"> •  </span>
            <span className="inspiracao-text4">{props.TextTopico1}</span>
          </div>
          <div className="inspiracao-topico1">
            <span className="inspiracao-text5"> •  </span>
            <span className="inspiracao-text6">{props.TextTopico2}</span>
          </div>
          <div className="inspiracao-topico2">
            <span className="inspiracao-text7"> •  </span>
            <span className="inspiracao-text8">{props.TextTopico3}</span>
          </div>
        </div>
      )}
    </div>
  )
}

Inspiracao.defaultProps = {
  rootClassName: '',
  TextTopico2: 'Exemplo...',
  TextTopico3: 'Exemplo...',
  TextTopico1: 'Exemplo...',
}

Inspiracao.propTypes = {
  rootClassName: PropTypes.string,
  TextTopico2: PropTypes.string,
  TextTopico3: PropTypes.string,
  TextTopico1: PropTypes.string,
}

export default Inspiracao
