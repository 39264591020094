import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import axios from 'axios';

import Input from '../Input/input'
import styles from './denunciar.module.css'
import { useSelector } from 'react-redux';

const Denunciar = (props) => {

  const [motivo,setMotivo] = useState('')

  const denunciarPerfil = async () => {
        if(!motivo || !props.anuncio) {
          if(props.fecharPopup) props.fecharPopup()
          toast.error("Para denunciar você precisa escrever algo.")
          return;
        }
        if(motivo.length < 10){
          if(props.fecharPopup) props.fecharPopup();
          toast.error("O motivo da denúncia deve ter pelo menos 10 caracteres!");
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/trainer-ads/report-ad`, {
                reason: motivo,
                trainerAdId: props.adId
            });
            if (response.status === 201) {
                toast.success("Denúncia enviada!");
            }
        } catch (error) {
          console.log(error)
            if (error.response && error.response.data) {
                toast.error(error.response.data.errors[0]);
            } else {
                toast.error('Erro ao enviar denúncia.');
            }
        }

        if(props.fecharPopup) props.fecharPopup()
    }


  useEffect(() => {
    setMotivo('')
  },[])
  return (
    <div className={` ${styles['denunciar']}`}>
      <div className={styles['container']}>
        <div className={styles['cabecalho']}>
          <span className={styles['text']}>
            <span>Denunciar</span>
          </span>
          <img
            alt="pastedImage"
            src="/external/pastedimage-yee-200h.png"
            className={styles['fecharicon']}
            onClick={() => {
              if(props.fecharPopup) {
                props.fecharPopup()
              }
            }}
          />
        </div>
        <div className={styles['motivo']}>
              <Input 
                Label="Motivo da denúncia"
                value={motivo}
                onChange={setMotivo}
                tipo={2}
              />
        </div>
        <button className={styles['botao-denunciar']} onClick={denunciarPerfil}>
          <span className={styles['text4']}>
            <span>Denunciar perfil</span>
          </span>
        </button>
      </div>
    </div>
  )
}

export default Denunciar
