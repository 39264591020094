import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import Header from "../../components/Header/header";
import Input from "../../components/Input/input";
import Rodape from "../../components/Rodape/rodape";
import styles from "./contato.module.css";

const Contato = (props) => {
  const [formData, setFormData] = useState({
    email: "",
    assunto: "",
    descricao: "",
  });

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   try {
  //     const response = await axios.post('https://us-central1-personal-trainer-8a383.cloudfunctions.net/api/email/contato', formData);
  //     if (response.status === 200) {
  //       toast.success(response.data.message);
  //       setFormData({
  //         email: '',
  //         assunto:'',
  //         descricao:''
  //       })
  //     }
  //   } catch (error) {
  //     if (error.response && error.response.data && error.response.data.error) {
  //       toast.error(error.response.data.error);
  //     } else {
  //       toast.error('Erro ao enviar e-mail');
  //     }
  //   }
  // };

  const handleSubmit = async (e) => {
    if (isContactValid()) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/email/send-contact-email`,
          {
            email: formData.email,
            subject: formData.subject,
            description: formData.descricao,
          }
        );

        if (response.status === 200) {
          toast.success("E-mail enviado com sucesso!");
          setFormData({
            email: "",
            assunto: "",
            descricao: "",
          });
        }
      } catch (error) {
        toast.error("Erro ao enviar email.");
        console.error(error);
      }
    }
  };

  const isContactValid = () => {
    if (formData.email === "") {
      toast.error("Insira um e-mail válido.");
      return false;
    }
    if (formData.assunto === "") {
      toast.error("Insira um assunto.");
      return false;
    }
    if (formData.descricao === "" && formData.descricao.length <= 10) {
      toast.error("Insira um descrição com no minimo 20 caracteres.");
      return false;
    }

    return true;
  };

  return (
    <div className={styles["container"]}>
      <Header />
      <div className={styles["container1"]}>
        <div className={styles["conteudo"]}>
          <div className={styles["contato"]}>
            <span className={styles["text"]}>
              <span>Contato</span>
            </span>
            <img
              alt="pastedImage"
              src="/external/pastedimage-7v6t-200w.png"
              className={styles["emailicon"]}
            />
            <span className={styles["text2"]}>
              <span>Fale conosco por e-mail</span>
            </span>
            <div className={styles["contatoemail"]}>
              <span className={styles["text4"]}>
                <span>atendimento@personaltrainer.com.br</span>
              </span>
            </div>
          </div>
          <div onSubmit={handleSubmit} className={styles["email"]}>
            <span className={styles["text6"]}>
              <span>
                Descreva seu problema e envie para o nosso suporte. Você
                receberá suas respostas pelo e-mail cadastrado.
              </span>
            </span>
            <div className={styles["inputs"]}>
              <Input
                Label="Seu e-mail"
                value={formData.email}
                onChange={(v) => {
                  setFormData({
                    ...formData,
                    email: v,
                  });
                }}
              ></Input>
              <Input
                Label="Assunto"
                value={formData.assunto}
                onChange={(v) => {
                  setFormData({
                    ...formData,
                    assunto: v,
                  });
                }}
              ></Input>
              <Input
                Label="Descrição"
                tipo={2}
                value={formData.descricao}
                onChange={(v) => {
                  setFormData({
                    ...formData,
                    descricao: v,
                  });
                }}
              ></Input>
            </div>
            <div className={styles["botao-enviar"]}>
              <button
                onClick={() => handleSubmit()}
                className={styles["buttons"]}
              >
                <span className={styles["text8"]}>Enviar</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Rodape></Rodape>
    </div>
  );
};

export default Contato;
