import React, { useState, useEffect } from 'react'

import Radio from '../Radio/radio'
import styles from './filtro.module.css'

const Filtro = (props) => {
  const [checked,setChecked] = useState(props.radio || false);

  const setValue = (bool) => {
    if(props.hasOwnProperty("filtroValue")) props.filtroValue(props.text,bool);
  }


  useEffect(() => {
    setChecked(props.radio)
  },[props.radio])

  return (
    <div className={` ${styles['frame1171277060']}`} onClick={() => {
      if(props.onClick) props.onClick()
    }}>
      <span className={styles['text']}>{props.text}</span>
      <Radio setValue={setValue} valor={checked}></Radio>
    </div>
  )
}

export default Filtro
