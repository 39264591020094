import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ChatComponent = () => {
  const rotasComChat=[
    "/painel/meu-anuncio",
    "/painel/avaliacoes",
    "/painel/minha-conta",
    "/painel/planos",
    "/painel/estatisticas",
    "/painel/suporte"
  ]
  const location = useLocation()
  
  useEffect(() => {
    const BASE_URL = "https://chat.romper360.com.br";
    const script = document.createElement("script");
    script.src = `${BASE_URL}/packs/js/sdk.js`;
    script.defer = true;
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      window.chatwootSDK.run({
        websiteToken: 'XF4guijtb2dKaK1oefiVmwZi',
        baseUrl: BASE_URL
      });
    };

    const chatWidgetHolder = document.querySelector(".woot-widget-holder")
    const chatBubbleHolder = document.querySelector(".woot--bubble-holder")

    if(rotasComChat.includes(location.pathname)) {
      if(chatWidgetHolder) chatWidgetHolder.style.display = 'block'
      if(chatBubbleHolder) chatBubbleHolder.style.display = 'block'
    } else {
      console.log(chatWidgetHolder, chatBubbleHolder)
      if(chatWidgetHolder) chatWidgetHolder.style.display = 'none'
      if(chatBubbleHolder) chatBubbleHolder.style.display = 'none'
    }

    return () => {
      document.body.removeChild(script);
    };
  }, [location]);

  return (
    <div>
      {/* This div can be used as a placeholder for the chat */}
    </div>
  );
};

export default ChatComponent;
