import styles from "./planos.module.css";
import Radio from "../../../components/Radio/radio";
import { formatCurrency } from "../../../utils";


export function CheckOutPage({ currentPlan, selectedTime, setSelectedTime, paymentMethod, setPaymentMethod, handlePay }) {

  const handleSelectPayMethod = (method, planTime) => () => {
    if (paymentMethod.method === method && paymentMethod.planTime === planTime) {
      return setPaymentMethod({})
    }

    return setPaymentMethod({
      method: method,
      planTime: planTime,
    })

  }

  return (
    <div className={styles["container-checkout"]}>
      <span className={styles["text124"]}>
        <span>{currentPlan.name}</span>
      </span>
      <div className={styles["ciclo"]}>
        <span className={styles["text126"]}>
          <span>Escolha o ciclo de cobrança</span>
        </span>
        <div className={styles["opcoes1"]}>
          <div
            onClick={() => setSelectedTime("annually")}
            className={styles["anual"]}
          >
            <div className={styles["titulo"]}>
              <span className={styles["text128"]}>
                <span>Anual</span>
              </span>
              <div className={styles["economize"]}>
                <span className={styles["text130"]}>
                  <span>
                    Economize
                    <span
                      dangerouslySetInnerHTML={{
                        __html: " ",
                      }}
                    />
                  </span>
                  <span className={styles["text132"]}>{formatCurrency(4 * currentPlan.pricePromotion.quarterly - currentPlan.pricePromotion.annually)}</span>
                </span>
              </div>
            </div>

            <span className={styles["text133"]}>
              <div className={styles['container-text-promocional']}>
                <span className={styles['text-preco-cinza-cortado']}>{formatCurrency(currentPlan.price.annually)}</span>
                <span className={styles['text-promocao-lancamento']}>Promoção de lançamento</span>
              </div>

              <span className={styles["text134"]}>
                {formatCurrency(currentPlan.pricePromotion.annually)}
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span className={styles["text135"]}>
                à vista - Equivale a{" "}
                {formatCurrency(currentPlan.pricePromotion.annually / 12)}/mês
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            {selectedTime === "annually" && (
              <div className={styles["formas-pagamento"]}>
                <div
                  onClick={handleSelectPayMethod("creditCard", "annually")}
                  className={styles["cartao1"]}
                >
                  <div className={styles["container06"]}>
                    <img
                      alt="pastedImage"
                      src="/external/pastedimage-dpt-200h.png"
                      className={styles["cardicon"]}
                    />
                    <span className={styles["text137"]}>
                      <span>Cartão</span>
                    </span>
                    <Radio
                      value={paymentMethod.method === "creditCard" && paymentMethod.planTime === "annually"}
                    ></Radio>
                  </div>
                </div>
                <div
                  onClick={handleSelectPayMethod("pix", "annually")}
                  className={styles["pix"]}
                >
                  <div className={styles["container07"]}>
                    <img
                      alt="pastedImage"
                      src="/external/pastedimage-9rda-200h.png"
                      className={styles["pixicon"]}
                    />
                    <span className={styles["text139"]}>
                      <span>PIX</span>
                    </span>
                    <Radio value={paymentMethod.method === "pix" && paymentMethod.planTime === "annually"}></Radio>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            onClick={() => setSelectedTime("semiannually")}
            className={styles["semestral"]}
          >
            <div className={styles["titulo1"]}>
              <span className={styles["text141"]}>
                <span>Semestral</span>
              </span>
              <div className={styles["frame1171277086"]}>
                <span className={styles["text143"]}>
                  <span className={styles["text144"]}>
                    Economize
                    <span
                      dangerouslySetInnerHTML={{
                        __html: " ",
                      }}
                    />
                  </span>
                  <span className={styles["text145"]}>{formatCurrency(2 * (2 * currentPlan.pricePromotion.quarterly - currentPlan.pricePromotion.semiAnnually))}</span>
                </span>
              </div>
            </div>
            <span className={styles["text146"]}>
              <div className={styles['container-text-promocional']}>
                <span className={styles['text-preco-cinza-cortado']}>{formatCurrency(currentPlan.price.semiAnnually)}</span>
                <span className={styles['text-promocao-lancamento']}>Promoção de lançamento</span>
              </div>
              <span className={styles["text147"]}>
                {formatCurrency(currentPlan.pricePromotion.semiAnnually)}
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span className={styles["text148"]}>
                à vista - Equivale a {" "}
                {formatCurrency(currentPlan.pricePromotion.semiAnnually / 6)}/mês
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
            </span>
            {selectedTime === "semiannually" && (
              <div className={styles["formas-pagamento"]}>
                <div
                  onClick={handleSelectPayMethod("creditCard", "semiAnnually")}
                  className={styles["cartao1"]}
                >
                  <div className={styles["container08"]}>
                    <img
                      alt="pastedImage"
                      src="/external/pastedimage-dpt-200h.png"
                      className={styles["cardicon1"]}
                    />
                    <span className={styles["text150"]}>
                      <span>Cartão</span>
                    </span>
                    <Radio
                      value={
                        paymentMethod.method === "creditCard" &&
                        paymentMethod.planTime === "semiAnnually"
                      }
                    ></Radio>
                  </div>
                </div>
                <div
                  onClick={handleSelectPayMethod("pix", "semiAnnually")}
                  className={styles["pix"]}
                >
                  <div className={styles["container09"]}>
                    <img
                      alt="pastedImage"
                      src="/external/pastedimage-9rda-200h.png"
                      className={styles["pixicon1"]}
                    />
                    <span className={styles["text152"]}>
                      <span>PIX</span>
                    </span>
                    <Radio
                      value={
                        paymentMethod.method === "pix" &&
                        paymentMethod.planTime === "semiAnnually"
                      }
                    ></Radio>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            onClick={() => setSelectedTime("quarterly")}
            className={styles["mensal"]}
          >
            <div className={styles["titulo2"]}>
              <span className={styles["text154"]}>
                <span>Trimestral</span>
              </span>
            </div>
            <span className={styles["text156"]}>
              <div className={styles['container-text-promocional']}>
                <span className={styles['text-preco-cinza-cortado']}>{formatCurrency(currentPlan.price.quarterly)}</span>
                <span className={styles['text-promocao-lancamento']}>Promoção de lançamento</span>
              </div>
              <span>
                {formatCurrency(currentPlan.pricePromotion.quarterly)}
                <span
                  dangerouslySetInnerHTML={{
                    __html: " ",
                  }}
                />
              </span>
              <span className={styles["text158"]}>à vista - Equivale a {formatCurrency(currentPlan.pricePromotion.quarterly / 3)}/mês</span>
            </span>
            {selectedTime === "quarterly" && (
              <div className={styles["formas-pagamento"]}>
                <div
                  onClick={handleSelectPayMethod("creditCard", "quarterly")}
                  className={styles["cartao1"]}
                >
                  <div className={styles["container10"]}>
                    <img
                      alt="pastedImage"
                      src="/external/pastedimage-dpt-200h.png"
                      className={styles["cardicon2"]}
                    />
                    <span className={styles["text159"]}>
                      <span>Cartão</span>
                    </span>
                    <Radio
                      value={
                        paymentMethod.method === "creditCard" &&
                        paymentMethod.planTime === "quarterly"
                      }
                    ></Radio>
                  </div>
                </div>
                <div
                  onClick={handleSelectPayMethod("pix", "quarterly")}
                  className={styles["pix"]}
                >
                  <div className={styles["container11"]}>
                    <img
                      alt="pastedImage"
                      src="/external/pastedimage-9rda-200h.png"
                      className={styles["pixicon2"]}
                    />
                    <span className={styles["text161"]}>
                      <span>PIX</span>
                    </span>
                    <Radio
                      value={
                        paymentMethod.method === "pix" &&
                        paymentMethod.planTime === "quarterly"
                      }
                    ></Radio>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* <button className={styles["botao-cupom"]}>
          <span className={styles["text163"]}>
            <span>Inserir código promocional</span>
          </span>
          <img
            alt="pastedImage"
            src="/external/pastedimage-mj5f-200h.png"
            className={styles["arrowicon"]}
          />
        </button> */}
        <button onClick={handlePay} className={styles["botao-contratar"]}>
          <span className={styles["text165"]}>
            <span>Contratar</span>
          </span>
        </button>
      </div>
    </div>
  )
}