import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import InputMask from "react-input-mask";
import { Autocomplete, Checkbox } from "@mui/joy";

import styles from "./input.module.css";

const Input = (props) => {
  const Limite = props.Limite || null;
  const Label = props.Label || null;
  const BotaoPlanos = props.BotaoPlanos || false;
  const tipo = props.tipo ? props.tipo : 1;
  const bloquearQuebraLinha = props.bloquearQuebraLinha ? true : false;
  const preValue = props.pre || "";
  const [erro, setErro] = useState(props.erro || null);
  const [caracteresDigitados, setCaracteresDigitados] = useState(0);

  const [inputValue, setInputValue] = useState(props.value || "");
  const [focused, setFocused] = useState(false);
  const [mask, setMask] = useState(props.mask);
  const [passVisible, setPassvisible] = useState(false)

  const isUrlValid = (url) => {
    const urlPattern = /^(https?:\/\/)?(www\.)?[\w.-]+\.\w{2,}$/i;
    return urlPattern.test(url);
  };

  const handleInputChange = (e) => {
    let valorDigitado = e.target.value;

    const valorFormatado = valorDigitado.replace(/ +/g, " ");

    const valorSemQuebrasExcedentes = bloquearQuebraLinha
      ? valorFormatado.replace(/\n{1,}/g, "\n")
      : valorFormatado;

    const caracteresDigitados = valorSemQuebrasExcedentes.replace(
      /\s/g,
      ""
    ).length;

    if (props.restringir) {
      const tiposDeRestricao = {
        "numero": /^[0-9]+$/,
        "monetario": /^\d+(,)?(\d{1,2})?$/
      }

      const restricaoEhPadrao = Object.keys(tiposDeRestricao).includes(props.restringir)
      console.log(restricaoEhPadrao)

      const allowedCharacters = restricaoEhPadrao ?
        tiposDeRestricao[props.restringir] :
        /^(?!.*\.\.)([a-zA-Z0-9.])+$/;

      console.log(allowedCharacters, valorSemQuebrasExcedentes, preValue, !allowedCharacters.test(valorSemQuebrasExcedentes.replace(preValue, "")))
      if (
        !allowedCharacters.test(valorSemQuebrasExcedentes.replace(preValue, ""))
      ) {
        setInputValue(preValue)
        if (props.setExterno) props.setExterno(preValue);
        return;
      }

    }

    if (Limite && caracteresDigitados > Limite) {
      setErro(`O limite de caracteres é ${Limite}`);
    } else {
      setErro(null);
      setInputValue(valorSemQuebrasExcedentes);
      if (props.setExterno) props.setExterno(valorSemQuebrasExcedentes)
      props.onChange && props.onChange(valorSemQuebrasExcedentes);
      setCaracteresDigitados(caracteresDigitados);
    }

  };

  const handleInputBlur = () => {
    if (props.validator && !props.validator(inputValue).valid) {
      setErro(props.validator(inputValue).errorMessage)
      return
    }

    setFocused(false)

    if (props.onBlur) props.onBlur()
  }

  useEffect(() => {
    // console.log('effect')
    if (props.value != inputValue && props.useValue) {
      // console.log('entrou')
      setInputValue(props.value)
    }
  })

  useEffect(() => {
    if (preValue) {
      if (focused && !props.readOnly) {
        //setInputValue(preValue);
      } else {
        if (inputValue === preValue) {
          setInputValue("");
          props.onChange ? props.onChange("") : "";
        }
      }
    }

    if (focused && props.disabled && props.disabled()) {
      toast.error(props.disabled());
    }


  }, [focused, preValue]);

  useEffect(() => {
    if (typeof props.value === "string") {
      setInputValue(props.value || "");
      if (props.value) {
        const caracteresDigitadosInicial = props.value.replace(
          /\s/g,
          ""
        ).length;
        setCaracteresDigitados(caracteresDigitadosInicial);
      }
    }
  }, [props.value]);

  return (
    <div className={` ${styles["frame1171274941"]}`} style={props.style}>
      {Label && <span className={styles["text"]}>{props.Label}</span>}
      {Limite && (
        <span className={styles["text-limite"]}>
          <span>
            {caracteresDigitados}/{Limite}
          </span>
        </span>
      )}
      {tipo === 1 && !mask && (
        <input
          type={(passVisible || !props.isPass) ? "text" : "password"}
          placeholder={props.Placeholder}
          className={`${styles[props.customClassName] ?? styles["input"]} ${erro ? styles["erro"] : ""} ${props.grayedDisable && props.readOnly ? styles["disabled-input"] : ""}`}
          {...props}
          value={inputValue}
          onBlur={() => {
            handleInputBlur();
          }}
          disabled={props.readOnly}
          onFocus={() => setFocused(true)}
          onChange={(e) => {
            if (!props.disabled || (props.disabled && !props.disabled())) {
              handleInputChange(e);
              if (e.target.value) setErro("");
            }
          }}
          onKeyDown={(e) => {
            if (props.keyPress) {
              props.keyPress(e);
            }

            if (!inputValue.includes(preValue)) {
              setInputValue(preValue);
              if (props.setExterno) props.setExterno(preValue)
            }
          }}
        />
      )}
      {mask && tipo === 1 && (
        <InputMask
          readOnly={props.readOnly}
          mask={mask}
          type={(passVisible || !props.isPass) ? "text" : "password"}
          placeholder={props.Placeholder}
          className={`${styles[props.customClassName] ?? styles["input"]} ${erro ? styles["erro"] : ""} ${props.grayedDisable && props.readOnly ? styles["disabled-input"] : ""}`}
          value={inputValue}
          onBlur={() => {
            handleInputBlur();
          }}
          {...props}
          onFocus={() => setFocused(true)}
          onChange={(e) => {
            if (!props.disabled || (props.disabled && !props.disabled())) {
              handleInputChange(e);
              if (e.target.value) setErro("");
            }
          }}
          onKeyDown={(e) => {
            if (props.keyPress) {
              props.keyPress(e);
            }

            if (!inputValue.includes(preValue)) {
              setInputValue(preValue);
              if (props.setExterno) props.setExterno(preValue)
            }
          }}
        />
      )}
      {tipo === 2 && (
        <textarea
          {...props}
          rows="6"
          placeholder={props.TextAreaPlaceholder}
          value={inputValue}
          disabled={props.readOnly}
          onBlur={() => {
            handleInputBlur();
            if (props.url && !isUrlValid(inputValue)) {
              setInputValue("");
            }
          }}
          onFocus={() => setFocused(true)}
          onChange={(e) => {
            handleInputChange(e);
            if (e.target.value) setErro("");
          }}
          onKeyDown={(e) => {
            if (!inputValue.includes(preValue)) {
              setInputValue(preValue);
              if (props.setExterno) props.setExterno(preValue)
            }
          }}
          className={`${styles["textarea"]} ${props.grayedDisable && props.readOnly ? styles["disabled-input"] : ""}`}
        ></textarea>
      )}
      {tipo === 3 && (
        <input
          type="text"
          placeholder={props.PopupPlaceholder}
          className={styles["input-popup"]}
        />
      )}
      {tipo === 4 && (
        <input
          type="text"
          placeholder={props.ElitePlaceholder}
          className={styles["input1"]}
        />
      )}
      {tipo === 4 && (
        <img
          alt="Vector4125"
          src="/vector4125-xagi.svg"
          className={styles["crownicon"]}
        />
      )}
      {tipo === 5 && (
        <Autocomplete
          {...props}
          sx={{
            gap: "10px",
            height: "100%",
            maxHeight: 40,
            borderColor: "#D8DFE8",
            backgroundColor: "white",
            fontFamily: "Poppins",
            padding: "8px 16px",
            outline: "none",
            borderRadius: 8,
          }}
          fullWidth
        />
      )}
      {props.isPass && (
        <img
          onClick={() => setPassvisible(vis => !vis)}
          alt="Vector4125"
          src={`${passVisible ? "/external/eye-slash-regular.svg" :"/external/eye-regular.svg"}`}
          className={styles["eye-icon"]}
          style={{top: Label ? '43px' : 'auto'}}
        />
      )}
      {BotaoPlanos && (
        <Link to="/planos" className={styles["navlink"]}>
          <div className={styles["botao-planos"]}>
            <span className={styles["text2"]}>
              <span>Adicione mais idiomas</span>
            </span>
            <img
              alt="Vector3781"
              src="/vector3781-9mso.svg"
              className={styles["crownicon1"]}
            />
          </div>
        </Link>
      )}
      {erro && <span className={styles["text4"]}>{erro}</span>}
    </div>
  );
};

export default Input;
