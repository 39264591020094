import React, { useState, useEffect } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setUsuarioData } from '../../redux/actions/usuarioActions'

import MenuLogado from '../MenuLogado/menu-logado'
import MenuAdmin from '../MenuAdmin/menu-admin'
import Busca from '../Busca/busca'

import styles from './header.module.css'

const Header = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const usuario = useSelector((state) => {
    return state.usuario
  });
  const logado = usuario !== null;

  const [menuMobileAberto, setMenuMobileAberto] = useState(false)
  const [isHomePage, setIsHomePage] = useState(location.pathname === '/');

  const LinkAnuncieGratis = () => (
    <span className={styles['text05']} onClick={() => {
      if (logado) {
        if (usuario.role === "admin") {
          history.push("/admin/usuarios")
        } else {
          history.push('/painel/meu-anuncio');
        }
      } else {
        history.push('/seja-anunciante');
      }
    }}>
      <span>
        {logado
          ? usuario.role === 'admin'
            ? 'Gerenciar usuários'
            : 'Meu anúncio'
          : <span dangerouslySetInnerHTML={{ __html: 'Anuncie <span style="color:#00CC66">grátis</span>' }}></span>
        }
      </span>
    </span>
  )

  const toggleMenu = () => {
    setMenuMobileAberto((prevState) => !prevState);
  };

  const deslogar = () => {
    localStorage.removeItem("ptr_145769130149");
    sessionStorage.removeItem("anuncio_rascunho");
    sessionStorage.removeItem("is_anuncio_rascunho");
    dispatch(setUsuarioData(null))
    history.push("/")
  };

  useEffect(() => {
    setIsHomePage(location.pathname === '/');
    window.scrollTo(0, 0)
  }, [location]);

  useEffect(() => {
    if (menuMobileAberto) {
      document.querySelector("body").style.overflow = "hidden"
    } else {
      document.querySelector("body").style.overflow = "initial"
    }

  }, [menuMobileAberto]);

  return (
    <div className={styles['container']}>
      <div className={styles['container1']}>
        <div className={styles['logo']}>
          <Link to="/" className={styles['text-logo']}>
            <img className="logo" src="/external/logo.png" />
          </Link>
        </div>
        {!isHomePage && <Busca></Busca>}
        <div className={styles['container2']}>

          <div className={styles['menu-desktop']}>
            <a href="https://blog.personaltrainer.com.br/" target="_blank" className={styles['text05']}>Blog</a>
            <LinkAnuncieGratis />
            <div className={styles['botao-entrar']} onClick={() => {
              if (logado) {
                if (usuario.role === "admin") {
                  history.push('/admin')
                } else {
                  history.push('/painel')
                }
              } else {
                history.push('/login')
              }
            }}>
              <span className={styles['text07']}>
                <span>{logado ? 'Painel' : 'Entrar'}</span>
              </span>
              <img
                alt="PerfilI101"
                src="/external/perfili101-1frh.svg"
                className={styles['perfilicon']}
              />
            </div>
          </div>

          {logado && (
            <button
              className={` ${styles['botao-sair-conta']} button`}
              onClick={deslogar}
            >
              <span className={styles['text09']}>
                <span>Sair</span>
              </span>
              <img
                alt="VectorI381"
                src="/external/vectori381-0m7t.svg"
                className={styles['vector']}
              />
            </button>
          )}
          <div className={styles['menu-mobile']}>
            <svg viewBox="0 0 1024 1024" className={styles['icon']} onClick={toggleMenu}>
              <path d="M810.667 725.333h-597.333c-47.061 0-85.333 38.272-85.333 85.333s38.272 85.333 85.333 85.333h597.333c47.061 0 85.333-38.272 85.333-85.333s-38.272-85.333-85.333-85.333z"></path>
              <path d="M810.667 426.667h-597.333c-47.061 0-85.333 38.272-85.333 85.333s38.272 85.333 85.333 85.333h597.333c47.061 0 85.333-38.272 85.333-85.333s-38.272-85.333-85.333-85.333z"></path>
              <path d="M810.667 128h-597.333c-47.061 0-85.333 38.272-85.333 85.333s38.272 85.333 85.333 85.333h597.333c47.061 0 85.333-38.272 85.333-85.333s-38.272-85.333-85.333-85.333z"></path>
            </svg>
            {logado && menuMobileAberto && usuario?.role !== 'admin' && (
              <div className={styles['menu-aberto-logado']}>
                <img
                  alt="pastedImage"
                  src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/e49f105b-e962-4123-a278-127fb6b41a2f/9fbd3d33-9983-47a7-8030-f00f5eafe3cd?org_if_sml=1488"
                  className={styles['closeicon']}
                  onClick={toggleMenu}
                />
                <MenuLogado />
                <div className={styles['sair']} onClick={deslogar}>
                  <img
                    alt="Vector4124"
                    src="/external/vectori381-0m7t.svg"
                    className={styles['sairicon']}
                  />
                  <span className={styles['text22']}>
                    <span>Sair</span>
                  </span>
                </div>
              </div>
            )}
            {!logado && menuMobileAberto && (
              <div className={styles['menu-aberto-mobile']}>
                <div className={styles['fechar']} onClick={toggleMenu}>
                  <img
                    alt="pastedImage"
                    src="/external/pastedimage-30yr7-200w.png"
                    className={styles['fecharicon']}
                  />
                </div>
                <Link to="/login" className={styles['entrar']}>
                  <span className={styles['text24']}>
                    <span>Entrar</span>
                  </span>
                  <img
                    alt="PerfilI412"
                    src="/external/perfili412-sg1m.svg"
                    className={styles['perfilicon2']}
                  />
                </Link>
                <LinkAnuncieGratis />
                <a href="https://blog.personaltrainer.com.br/" target="_blank" className={styles['text05']}>Blog</a>

              </div>
            )}
            {menuMobileAberto && usuario?.role === 'admin' && (
              <div className={styles['menu-aberto-admin']}>
                <img
                  alt="pastedImage"
                  src="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/e49f105b-e962-4123-a278-127fb6b41a2f/9fbd3d33-9983-47a7-8030-f00f5eafe3cd?org_if_sml=1488"
                  className={styles['closeicon']}
                  onClick={toggleMenu}
                />
                <MenuAdmin />
                <div className={styles['sair']} onClick={deslogar}>
                  <img
                    alt="Vector4124"
                    src="/external/vectori381-0m7t.svg"
                    className={styles['sairicon']}
                  />
                  <span className={styles['text22']}>
                    <span>Sair</span>
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header