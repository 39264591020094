import React from "react";
import styles from "./loader.module.css";

const Loader = (props) => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 9999,
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="48"
        height="48"
        viewBox="0 0 24 24"
      >
        <path
          d="M3.67755 6.29032H1.98268C1.51899 6.29032 1.13524 6.67742 1.13524 7.14516V10.8548H0.287808C0.127915 10.8548 0 10.9839 0 11.1452V12.8548C0 13.0161 0.127915 13.1452 0.287808 13.1452H1.13524V16.8548C1.13524 17.3226 1.51899 17.7097 1.98268 17.7097H3.67755C4.14124 17.7097 4.52498 17.3226 4.52498 16.8548V7.14516C4.52498 6.66129 4.15723 6.29032 3.67755 6.29032ZM23.7122 10.8548H22.8648V7.14516C22.8648 6.67742 22.481 6.29032 22.0173 6.29032H20.3225C19.8588 6.29032 19.475 6.67742 19.475 7.14516V16.8548C19.475 17.3226 19.8588 17.7097 20.3225 17.7097H22.0173C22.481 17.7097 22.8648 17.3226 22.8648 16.8548V13.1452H23.7122C23.8721 13.1452 24 13.0161 24 12.8548V11.1452C24 10.9839 23.8721 10.8548 23.7122 10.8548ZM17.8121 4H15.7495C15.1739 4 14.7262 4.3871 14.7262 4.85484V10.8548H9.27382V4.85484C9.27382 4.3871 8.81013 4 8.23451 4H6.18787C5.61226 4 5.16456 4.3871 5.16456 4.85484V19.129C5.16456 19.5968 5.62825 19.9839 6.18787 19.9839H8.23451C8.81013 19.9839 9.25783 19.5968 9.25783 19.129V13.1452H14.7262V19.1452C14.7262 19.6129 15.1899 20 15.7495 20H17.7961C18.3718 20 18.8195 19.6129 18.8195 19.1452V4.85484C18.8354 4.3871 18.3718 4 17.8121 4Z"
          fill="#FCA600"
        />
        {/* <animateTransform
          attributeName="transform"
          type="rotate"
          from="0 0 0"
          to="360 0 0"
          dur="1.5s"
          repeatCount="indefinite"
        /> */}
        <animateTransform
          attributeName="transform"
          type="translate "
          from="0 24"
          to="0 0"
          dur="1.5s"
          repeatCount="indefinite"
        />
      </svg>
    </div>
  );
};

export default Loader;
