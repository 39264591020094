import React, { useState, useEffect } from "react";
import CountUp from "react-countup";
import axios from "axios";

import Header from "../../../components/Header/header";
import MenuLogado from "../../../components/MenuLogado/menu-logado";
import Rodape from "../../../components/Rodape/rodape";
import styles from "./estatisticas.module.css";

const Estatisticas = (props) => {
  const formatNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const [meses, setMeses] = useState([]);
  const [mesSelecionado, setMesSelecionado] = useState("");
  const [showMeses, setShowMeses] = useState(false);
  const [estatisticas, setEstatisticas] = useState({});

  const gerarUltimosMeses = () => {
    const meses = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ];

    const dataAtual = new Date();
    let mesAtual = dataAtual.getMonth();
    let anoAtual = dataAtual.getFullYear();
    const resultado = [];

    for (let i = 0; i < 6; i++) {
      resultado.push(`${meses[mesAtual]}/${anoAtual}`);
      mesAtual -= 1;
      if (mesAtual < 0) {
        mesAtual = 11;
        anoAtual -= 1;
      }
    }

    return resultado.reverse();
  };

  useEffect(() => {
    const mesesResult = gerarUltimosMeses();
    if (mesesResult && mesesResult.length > 0) {
      setMeses(mesesResult);
      setMesSelecionado(mesesResult[mesesResult.length - 1]);
    }
  }, []);

  useEffect(() => {
    const converteMesFormato = (mesStr) => {
      const meses = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ];
      const [mes, ano] = mesStr.split("/");
      const mesIndex = meses.indexOf(mes) + 1;
      const mesFormatado = mesIndex < 10 ? `0${mesIndex}` : `${mesIndex}`;
      return `${ano}-${mesFormatado}`;
    };

    const obterEstatisticas = async () => {
      try {
        const mesFormatado = converteMesFormato(mesSelecionado);
        const [ano, mes] = mesFormatado.split("-");
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/ad-stats`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                "ptr_145769130149"
              )}`,
            },
            params: {
              month: mes,
              year: ano,
            },
          }
        );
        setEstatisticas(response.data.data);
      } catch (error) {
        setEstatisticas({});
        console.error("Erro ao obter estatísticas:", error);
      }
    };

    if (mesSelecionado) {
      obterEstatisticas();
    }
  }, [mesSelecionado]);

  return (
    <div className={styles["container"]}>
      <Header />
      <div className={styles["container1"]}>
        {window.innerWidth > 991 && <MenuLogado></MenuLogado>}
        <div className={styles["container2"]}>
          <div className={styles["estatisticas"]}>
            <div className={styles["cabecalho"]}>
              <span className={styles["text"]}>
                <span>Estatísticas</span>
              </span>
              <div
                className={styles["mes-so-desk"]}
                onClick={() => setShowMeses(!showMeses)}
                style={
                  showMeses
                    ? {
                        borderRadius: "8px 8px 0 0",
                        borderBottomWidth: "0",
                      }
                    : {}
                }
              >
                <div className={styles["input"]}>
                  <span className={styles["text02"]}>
                    <span>{mesSelecionado}</span>
                  </span>
                  <img
                    alt="Vector4617"
                    src="/external/vector4617-lfe.svg"
                    className={styles["arrowicon"]}
                    style={showMeses ? { transform: "rotate(180deg)" } : {}}
                  />
                </div>
                {showMeses && (
                  <div className={styles["lista-meses"]}>
                    {meses.map(
                      (mes, i) =>
                        mesSelecionado !== mes && (
                          <span
                            key={i}
                            onClick={() => {
                              setMesSelecionado(mes);
                            }}
                          >
                            {mes}
                          </span>
                        )
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className={styles["infos"]}>
              <div className={styles["impressoes-cliques"]}>
                <div className={styles["impressoes"]}>
                  <span className={styles["text04"]}>
                    <span>Exibições de anúncio</span>
                  </span>
                  <span className={styles["text-numero"]}>
                    <CountUp
                      start={0}
                      end={estatisticas?.impressions || 0}
                      duration={1.5}
                      formattingFn={formatNumber}
                    />
                  </span>
                  <span className={styles["text06"]}>
                    Quantas vezes o anúncio foi exibido para os usuários.
                  </span>
                </div>
                <div className={styles["cliques"]}>
                  <span className={styles["text07"]}>
                    <span>Visitas ao anúncio</span>
                  </span>
                  <span className={styles["text-numero1"]}>
                    <span>
                      <CountUp
                        start={0}
                        end={estatisticas?.visits || 0}
                        duration={1.5}
                        formattingFn={formatNumber}
                      />
                    </span>
                  </span>
                  <span className={styles["text10"]}>
                    <span>Quantos usuários visitaram o anúncio.</span>
                  </span>
                </div>
              </div>
              <div className={styles["telefone-whats"]}>
                <div className={styles["telefone"]}>
                  <span className={styles["text12"]}>
                    <span>Cliques para ver telefone</span>
                  </span>
                  <span className={styles["text14"]}>
                    <span>
                      <CountUp
                        start={0}
                        end={estatisticas?.phoneClicks || 0}
                        duration={1.5}
                        formattingFn={formatNumber}
                      />
                    </span>
                  </span>
                  <span className={styles["text16"]}>
                    <span>
                      Quantos usuários clicaram para visualizar o telefone
                      completo.
                    </span>
                  </span>
                </div>
                <div className={styles["whatsapp"]}>
                  <span className={styles["text18"]}>
                    <span>Cliques no link do Whatsapp</span>
                  </span>
                  <span className={styles["text20"]}>
                    <span>
                      <CountUp
                        start={0}
                        end={estatisticas?.whatsappClicks || 0}
                        duration={1.5}
                        formattingFn={formatNumber}
                      />
                    </span>
                  </span>
                  <span className={styles["text22"]}>
                    <span>
                      Quantos usuários clicaram no link de contato do whatsapp.
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Rodape rootClassName="root-class-name10"></Rodape>
    </div>
  );
};

export default Estatisticas;
