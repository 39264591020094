import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUsuarioData } from "../../redux/actions/usuarioActions";
import axios from "axios";
import { toast } from "react-toastify";

import Input from "../../components/Input/input";
import Rodape from "../../components/Rodape/rodape";
import styles from "./login.module.css";

const Login = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [confirmarSenha, setConfirmarSenha] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [passwordCheck, setPasswordCheck] = useState({
    uppercase: false,
    lowercase: false,
    number: false,
    special: false,
    minimal: false
  });

  const { pathname } = props.location;
  let type;
  if (pathname.split("/").includes("login")) type = "Login";
  if (pathname.split("/").includes("cadastro")) type = "Cadastro";
  if (pathname.split("/").includes("redefinir-senha")) type = "Redefinir Senha";
  if (pathname.split("/").includes("login-social")) type = "Login";

  // Verificar se usuário já está logado


  const enviarComEnter = (event) => {
    if (event.key === "Enter") {
      setIsButtonDisabled(true);
      type === "Login" ? emailLogin() : cadastrar();
    }
  };

  const cadastroSucesso = async (email, senha) => {
    toast.success("Cadastro realizado com sucesso");
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/auth/login`,
      { email: email, password: senha }
    );
    const { token } = response.data;
    localStorage.setItem("ptr_145769130149", token);
    sessionStorage.removeItem("anuncio_rascunho");
    sessionStorage.removeItem("is_anuncio_rascunho");
    const userData = await getUserData(token);
    history.push("/painel");
  };

  const cadastrar = async () => {
    if (nome.split(" ").length < 2) {
      toast.error("Insira o nome completo");
      setIsButtonDisabled(false);
      return;
    }

    if (senha !== "" && senha !== confirmarSenha) {
      toast.error("As senhas não conferem");
      setIsButtonDisabled(false);
      return;
    }

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/register`,
        {
          fullName: nome,
          email: email,
          password: senha,
          confirmPassword: confirmarSenha,
        }
      );
      if (res.status === 201) {
        cadastroSucesso(email, senha);
        const { usuario } = res.data;
        dispatch(setUsuarioData(usuario));
        // history.push("/painel");
      }
    } catch (error) {
      console.log(error);
      if (error.response.data.errors) {
        let errorMessage =
          error.response.data.errors[
          Object.keys(error.response.data.errors)[0]
          ];
        toast.error(errorMessage);
      } else {
        toast.error("Ocorreu um erro ao tentar fazer cadastro.");
      }
    }

    setIsButtonDisabled(false);
  };

  const getUserData = async (token) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/users/user-data`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            user: {
              _id: token,
            },
          },
        }
      );
      const { user, trainerAd } = response.data.data;

      const result = {
        ...user,
        trainerAd,
      };

      dispatch(setUsuarioData(result));
    } catch (error) {
      throw new Error("Erro ao renovar token: " + error);
    }
  };

  const emailLogin = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/login`,
        { email: email, password: senha }
      );
      const { token, role } = response.data;
      localStorage.setItem("ptr_145769130149", token);
      sessionStorage.removeItem("anuncio_rascunho");
      sessionStorage.removeItem("is_anuncio_rascunho");
      console.log(response.data)
      window.location.reload();
    } catch (error) {
      console.log(error);
      let errorMessage = error.response.data.message
        ? error.response.data.message
        : "Erro ao fazer o login!";
      if (error.response.status === 429) {
        errorMessage = "Muitas tentativas tente novamente outra hora.";
      }
      toast.error(errorMessage);
    }

    setIsButtonDisabled(false);
  };

  const googleLogin = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/api/auth/google`;
  };

  const facebookLogin = () => {
    window.location.href = `${process.env.REACT_APP_API_URL}/api/auth/facebook`;
  };

  const getSocialLoginToken = async () => {
    try {
      // Pegar parâmetro "token da URL"
      let token = new URL(document.URL).searchParams.get("token");
      localStorage.setItem("ptr_145769130149", token);
      sessionStorage.removeItem("anuncio_rascunho");
      sessionStorage.removeItem("is_anuncio_rascunho");
      const userData = await getUserData(token);
      history.push("/painel");
    } catch (error) {
      console.log(error);
      let errorMessage = error.message
        ? error.message
        : "Erro ao fazer o login!";
      toast.error(errorMessage);
    }

    setIsButtonDisabled(false);
  };
  if (pathname.split("/").includes("login-social")) getSocialLoginToken();

  const redefinirSenha = async () => {
    try {
      if (email === "") {
        toast.error("Insira o e-mail para redefinir a senha");
        return;
      }
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/send-reset-password-email`,
        { email }
      );
      toast.success(response.data.message);
    } catch (error) {
      toast.error(
        error.response
          ? error.response.data.message
          : "Erro ao solicitar redefinição de senha"
      );
    }
  };

  const alterarSenha = async (password) => {
    try {
      const queryParams = new URLSearchParams(location.search);
      const tokenRedefinicao = queryParams.get("token");
      if (!tokenRedefinicao) {
        toast.error("Token de redefinição de senha não encontrado");
        setIsButtonDisabled(false);
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/auth/reset-password-by-token`,
        {
          password,
          token: tokenRedefinicao,
        }
      );
      toast.success(response.data.message);
      history.push("/login");
    } catch (error) {
      toast.error(
        error.response ? error.response.data.message : "Erro ao alterar senha"
      );
    }

    setIsButtonDisabled(false);
  };

  const handlePasswordChecking = (value) => {
    let checking = passwordCheck;

    if (value == "") {
      setPasswordCheck({
        uppercase: false,
        lowercase: false,
        number: false,
        special: false,
        minimal: false
      });
      setSenha("");
    } else {
      // Check Número
      if (value.match(/[0-9]/g) == null) {
        checking.number = false;
      } else {
        checking.number = true;
      }

      // Check Letra maiúscula
      if (value.match(/[A-Z]/g) == null) {
        checking.uppercase = false;
      } else {
        checking.uppercase = true;
      }

      // Check Letra minúscula
      if (value.match(/[a-z]/g) == null) {
        checking.lowercase = false;
      } else {
        checking.lowercase = true;
      }

      // Check Caractere especial
      if (value.match(/[^a-zA-Z0-9_]/g) == null) {
        checking.special = false;
      } else {
        checking.special = true;
      }

      // Check Mínimo 8 caracteres
      if (value.length < 8) {
        checking.minimal = false;
      } else {
        checking.minimal = true;
      }

      setPasswordCheck(checking);
      setSenha(value);
    }
  };

  useEffect(() => {
    setNome("");
    setEmail("");
    setSenha("");
    setConfirmarSenha("");
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={styles["container"]}>
      <div className={styles["container1"]}>
        <div className={styles["logo"]}>
          <Link to="/" className={styles["text-logo"]}>
            <img className="logo" src="/external/logo.png" />
          </Link>
        </div>
        <div className={styles["entrar"]}>
          <div className={styles["formulario"]}>
            <span className={styles["text"]}>
              <span>
                {type === "Login"
                  ? "Entrar"
                  : type === "Cadastro"
                    ? "Criar conta"
                    : type === "Redefinir Senha"
                      ? "Redefinir senha"
                      : ""}
              </span>
            </span>
            <div className={styles["inputs"]} onKeyDown={enviarComEnter}>
              {type === "Cadastro" && (
                <Input
                  Placeholder="Nome completo"
                  value={nome}
                  onChange={setNome}
                ></Input>
              )}
              {type !== "Redefinir Senha" && (
                <Input
                  Placeholder="E-mail"
                  value={email}
                  onChange={setEmail}
                ></Input>
              )}
              <Input
                Placeholder={`${type === "Redefinir Senha" ? "Nova senha" : "Senha"
                  }`}
                value={senha}
                // onChange={setSenha}
                onChange={(props) => {
                  handlePasswordChecking(props);
                }}
                isPass="true"
              ></Input>
              <div
                className={
                  senha.length > 0 && (type == "Cadastro" || type == "Redefinir Senha")
                    ? styles["password-hints"]
                    : styles["password-hints-hide"]
                }
              >
                <div
                  className={
                    passwordCheck.minimal
                      ? styles["password-ok"]
                      : styles["password-not-ok"]
                  }
                >
                  8 caracteres
                </div>
                <div
                  className={
                    passwordCheck.uppercase
                      ? styles["password-ok"]
                      : styles["password-not-ok"]
                  }
                >
                  Letra maiúscula
                </div>
                <div
                  className={
                    passwordCheck.lowercase
                      ? styles["password-ok"]
                      : styles["password-not-ok"]
                  }
                >
                  Letra minúscula
                </div>
                <div
                  className={
                    passwordCheck.number
                      ? styles["password-ok"]
                      : styles["password-not-ok"]
                  }
                >
                  Número {passwordCheck.length}
                </div>
                <div
                  className={
                    passwordCheck.special
                      ? styles["password-ok"]
                      : styles["password-not-ok"]
                  }
                >
                  Caractere especial
                </div>
              </div>
              {type !== "Login" && (
                <Input
                  Placeholder={`${type === "Redefinir Senha"
                    ? "Confirmar nova senha"
                    : "Confirmar senha"
                    }`}
                  isPass="true"
                  value={confirmarSenha}
                  onChange={setConfirmarSenha}
                ></Input>
              )}
            </div>
            <button
              onClick={() => {
                setIsButtonDisabled(true);
                if (type === "Login") {
                  emailLogin();
                } else if (type === "Cadastro") {
                  cadastrar();
                } else if (type === "Redefinir Senha") {
                  alterarSenha(senha);
                }
              }}
              className={`${styles["botao-entrar"]} btn`}
              disabled={isButtonDisabled}
            >
              {isButtonDisabled ? (
                <div>
                  <span className={styles["text02"]}>
                    {type === "Login"
                      ? "Entrando..."
                      : type === "Cadastro"
                        ? "Criando..."
                        : "Salvando..."}
                  </span>
                  <div className="spinner"></div>
                </div>
              ) : (
                <span className={styles["text02"]}>
                  <span>
                    {type === "Login"
                      ? "Entrar"
                      : type === "Cadastro"
                        ? "Criar conta"
                        : type === "Redefinir Senha"
                          ? "Alterar"
                          : ""}
                  </span>
                </span>
              )}
            </button>
            {type === "Login" && (
              <span className={styles["text-login"]} onClick={redefinirSenha}>
                <span>Esqueci minha senha</span>
              </span>
            )}
            {type === "Cadastro" && (
              <span className={styles["text-cadastro"]}>
                <span>
                  Ao clicar em Criar conta, você concorda com nossos
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <a
                  href="/termos-de-uso"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles["text06"]}
                >
                  Termos de Uso
                </a>
                <span>
                  {" "}
                  e
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
                <a
                  href="/politica-de-privacidade"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={styles["text08"]}
                >
                  Política de Privacidade
                </a>
                <span>.</span>
              </span>
            )}
            {type !== "Redefinir Senha" && (
              <div className={styles["linhas-ou"]}>
                <div className={styles["group561"]}>
                  {/* <img
                    alt="Caminho444764"
                    src="/external/caminho444764-x8m.svg"
                    className={styles["caminho44"]}
                  />
                  <img
                    alt="Caminho454764"
                    src="/external/caminho454764-wcm3.svg"
                    className={styles["caminho45"]}
                  /> */}
                </div>
                <span className={styles["text10"]}>ou</span>
              </div>
            )}
            {type !== "Redefinir Senha" && (
              <div className={styles["botoes-redes"]}>
                <div onClick={googleLogin} className={styles["google"]}>
                  <img
                    alt="GoogleGLogosvg4764"
                    src="/external/googleglogosvg4764-vu-200w.png"
                    className={styles["googleimg"]}
                  />
                  <span className={styles["text11"]}>
                    <span>Entrar com o Google</span>
                  </span>
                  <img
                    alt="Rectangle2534764"
                    src="/external/rectangle2534764-208-200h.png"
                    className={styles["espacobranco"]}
                  />
                </div>
                {/* {<div onClick={facebookLogin} className={styles["face"]}>
                  <img
                    alt="pastedImage"
                    src="/external/pastedimage-94md-200h.png"
                    className={styles["faceimg"]}
                  />
                  <span className={styles["text13"]}>
                    <span>Entrar com o Facebook</span>
                  </span>
                  <img
                    alt="Rectangle2534764"
                    src="/external/rectangle2534764-i8yo-200h.png"
                    className={styles["espacobranco1"]}
                  />
                </div>} */}
              </div>
            )}
          </div>
          {type === "Login" && (
            <div className={styles["cadastrar"]}>
              <span className={styles["text15"]}>
                <span>Ainda não tem uma conta?</span>
              </span>
              <Link to="/cadastro" className={styles["botao-cadastrar"]}>
                <span className={styles["text17"]}>
                  <span>Cadastre-se aqui</span>
                </span>
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-o154-200h.png"
                  className={styles["arrowicon"]}
                />
              </Link>
            </div>
          )}
          {type === "Cadastro" && (
            <div className={styles["cadastrar"]}>
              <span className={styles["text15"]}>
                <span>Já possui uma conta?</span>
              </span>
              <Link to="/login" className={styles["botao-cadastrar"]}>
                <span className={styles["text17"]}>
                  <span>Entre por aqui</span>
                </span>
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-o154-200h.png"
                  className={styles["arrowicon"]}
                />
              </Link>
            </div>
          )}
        </div>
      </div>
      {type !== "Redefinir Senha" && <Rodape></Rodape>}
    </div>
  );
};

export default Login;
