import TextCinza from "./TextCinza";
import styles from "../regulamento.module.css"
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const ImagemPromocao = ({ title, imgUrl, altText, isFeed }) => {
    const location = useLocation()
    
    //função para donwload da imagem
    const download = e => {
        const fullURL = window.location.href;
        const imgFullUrl = fullURL.replace(location.pathname,imgUrl)
        fetch(imgFullUrl, {
            method: "GET",
            headers: {}
        })
            .then(response => {
                response.arrayBuffer().then(function (buffer) {
                    const url = window.URL.createObjectURL(new Blob([buffer]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "image.png"); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                });
            })
            .catch(err => {
                console.log(err);
            });
    };

    return (
        <div className={styles['img-como-participar']}>
            <TextCinza>{title}</TextCinza>
            <img className={isFeed ? styles['img-feed'] : styles['img-stories']} src={imgUrl} alt={altText} />
            <div className={styles['botao-baixar']} onClick={e => download(e)}>
                <p>{`Baixar${isFeed ? ' imagem' : ''}`}</p>
                <img src="/external/orange-chevron-down.svg" />
            </div>
        </div>
    )
}

export default ImagemPromocao