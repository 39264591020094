import { SET_ANUNCIO_DATA } from '../actions/anuncioActions';

const initialState = null;

const anuncioReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ANUNCIO_DATA:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

export default anuncioReducer;
