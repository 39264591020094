import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";

import Input from "../Input/input";
import styles from "./popup-reprovar.module.css";

const PopupReprovar = (props) => {
  const [motivo, setMotivo] = useState("");

  return (
    <div className={styles["pagarcomcarto"]}>
      <div className={styles["container"]}>
        <div className={styles["titulo"]}>
          <span className={styles["text"]}>Reprovar anunciante</span>
          <img
            alt="Vector4897"
            src="/external/vector4897-4cpm.svg"
            className={styles["closeicon"]}
            onClick={() => {
              props.closePopup();
            }}
          />
        </div>
        <div className={styles["form"]}>
          <span>Tem certeza que deseja reprovar o anunciante?</span>
          <div className={styles["motivo"]}>
            <Input
              Label="Motivo"
              value={motivo}
              onChange={setMotivo}
              tipo={2}
            />
          </div>
          <div style={{ display: "flex", gap: 10 }}>
            <button
              className={styles["botao-comprar-grey"]}
              onClick={() => props.closePopup()}
            >
              <span className={styles["text1"]}>
                <span>Cancelar</span>
              </span>
            </button>
            <button
              className={styles["botao-comprar"]}
              onClick={async () => {
                if(motivo.length < 10){
                  toast.error("O motivo precisa ter pelo menos 10 caracteres.")
                  props.closePopup();
                } else {
                  await props.handleDisaprove("Reprovado", motivo);
                }
              }}
            >
              <span className={styles["text1"]}>
                <span>Reprovar</span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupReprovar;
