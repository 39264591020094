import React from 'react'
import { Link } from 'react-router-dom'

import styles from './rodape.module.css'

const Rodape = (props) => {
  return (
    <div className={` ${styles['rodape']} `}>
      <div className={styles['conteudo']}>
        <div className={styles['opcoes']}>
          <Link to="/contato" className={styles['text']}>
            <span>Contato</span>
          </Link>
          <Link to="/termos-de-uso" className={styles['text02']}>
            <span>Termos de uso</span>
          </Link>
          <Link to="/politica-de-privacidade" className={styles['text04']}>
            <span>Privacidade</span>
          </Link>
          <Link to="/planos" className={styles['text04']}>
            <span>Planos e Preços</span>
          </Link>
          <Link to="/faq" className={styles['text06']}>
            <span>Perguntas frequentes</span>
          </Link>
          <Link to="/sobre-nos" className={styles['text08']}>
            <span>Sobre nós</span>
          </Link>
        </div>
        <span className={styles['text10']}>
          <span>
            <span>© 2021 - ROMPER Administradora de marcas EIRELI</span>
            <br></br>
            <span>
              Rua Maringá, 2076 - Bairro São Cristóvão. CEP: 30190-924 -
              Cascavel - PR. CNPJ: 18.461.120/0001-51
            </span>
          </span>
        </span>
      </div>
    </div>
  )
}


export default Rodape
