import React from "react";

import MenuAdminDesk from "../../../components/MenuAdmin/menu-admin";
import AdmFiltros from "../../../components/adm-filtros";
import Anunciante from "../../../components/Anunciante/anunciante";
import Input from "../../../components/Input/input";
import Rodape from "../../../components/Rodape/rodape";
import Header from "../../../components/Header/header";
import styles from "./adm-usuarios.module.css";
import { Option, Select } from "@mui/joy";

import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { MoonLoader } from "react-spinners";
import { toast } from "react-toastify";
import axios from "axios";
import AnuncioView from "../../../components/Forms/anuncio";
import MyData from "../../../components/myData/index";
import { getPlans } from "../../../services/server/Plans";

const AdmUsuarios = (props) => {
  const history = useHistory();
  const [page, setPage] = useState("");

  const [viewAdPage, setViewAdPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState(null);
  const [nameFilter, setNameFilter] = useState("");
  const [ad, setAd] = useState();
  const [user, setUser] = useState();
  const [profilePage, setProfilePage] = useState(false);
  const [statusFilter, setStatusFilter] = useState(null);
  const [planoFilter, setPlanoFilter] = useState(null);
  const [adStatusFilter, setAdStatusFilter] = useState(null);
  const [userPlan, setUserPlan] = useState({});
  const [plans, setPlans] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [ordersOpen, setOrdersOpen] = useState(false);
  const [orderDirection, setOrderDirection] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/adm/users`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("ptr_145769130149")}`,
          },
          params: {
            search: nameFilter.length > 0 ? nameFilter : null,
            planName: planoFilter,
            profileStatus: statusFilter,
            adStatus: adStatusFilter,
            page: currentPage,
            orderDirection: orderDirection,
            orderBy:
              orderDirection !== null
                ? "date"
                : orderBy !== null
                ? "mostAccessed"
                : null,
          },
        }
      );
      const pages = [];
      for (let i = 0; i < response.data.totalPages; i++) {
        pages.push(i + 1);
      }
      setLoading(false);
      setPages(pages);
      setUsers(response.data.data)        
      setTotalResults(response.data.totalResults);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  useEffect(() => {
    getPlans()
      .then(res => setPlans(res))
  }, [])

  useEffect(() => {
    (async function () {
      if (!loading && users === null) {
        setLoading(true);
        await fetchUsers();
      }
    })();
  }, [users]);

  useEffect(() => {
    (async function () {
      setLoading(true);
      await fetchUsers();
    })();
  }, [
    statusFilter,
    planoFilter,
    adStatusFilter,
    orderDirection,
    orderBy,
    currentPage,
  ]);

  useEffect(() => {
    (async function () {
      await fetchUsers();
    })();
  }, [nameFilter]);

  const handleAd = (ad) => {
    if (ad !== null) {
      history.push(`/admin/usuarios?ad=${ad.username}`)
      setAd(ad);
      setViewAdPage(true);
    } else {
      toast.error(
        "Este usuário não possui um anúncio ou a aprovação está pendente."
      );
    }
  };
  history.listen(setPage);
  useEffect((props) => {
    if(page?.search == "") {
      setViewAdPage(false)
    }
  }, [page])

  const handleProfile = (user) => {
    setProfilePage(true);
    setUser(user);
  };

  const getUserPlan = (planId) => {
    for (let p of plans) {
      if (p._id === planId) {
        return p;
      }
    }
    return null;
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100%",
        }}
      >
        <MoonLoader size={32} color={"#fba600"} loading={true} />
      </div>
    );
  }

  return (
    <div className={styles["container"]}>
      <Header />
      <div className={styles["container01"]}>
        {window.innerWidth > 991 && (
          <MenuAdminDesk rootClassName="root-class-name3"></MenuAdminDesk>
        )}
        <div className={styles["container02"]}>
          <div className={styles["anunciantes"]}>
            {!viewAdPage && !profilePage && (
              <>
                <div className={styles["cabecalho"]}>
                  <div className={styles["titulo"]}>
                    <span className={styles["text"]}>Anunciantes</span>
                    <span className={styles["textqntd"]}>
                      {totalResults || 0}
                    </span>
                  </div>
                  <div className={styles["buscar"]}>
                    <input
                      type="text"
                      placeholder="Buscar"
                      className={styles["textinput"]}
                      value={nameFilter}
                      onChange={(e) => setNameFilter(e.target.value)}
                    />
                    <img
                      alt="image"
                      src="/external/caminho13169-phir.svg"
                      className={styles["iconbusca"]}
                    />
                  </div>
                  <div className="adm-filtros-botoes1">
                    <button
                      onClick={() => {
                        setFiltersOpen(false);
                        setOrdersOpen(!ordersOpen);
                      }}
                      className="adm-filtros-botao-ordenar"
                    >
                      <span className="adm-filtros-text">
                        <span className="">Ordenar</span>
                      </span>
                      <img
                        alt="OrdenarI122"
                        src="/external/ordenari122-6p3.svg"
                        className="adm-filtros-ordenaricon"
                      />
                    </button>
                    <button
                      onClick={() => {
                        setOrdersOpen(false);
                        setFiltersOpen(!filtersOpen);
                      }}
                      className="adm-filtros-botao-filtrar"
                    >
                      <span className="adm-filtros-text2">
                        <span className="">Filtrar</span>
                      </span>
                      <img
                        alt="filtrarI807"
                        src="/external/filtrari807-fa7v.svg"
                        className="adm-filtros-filtrar"
                      />
                    </button>
                  </div>
                </div>
                {filtersOpen && (
                  <div className={styles["filtros-especificos"]}>
                    <Select
                      sx={{ width: 200 }}
                      value={planoFilter}
                      onChange={(e, nv) => {
                        setCurrentPage(1);
                        setPlanoFilter(nv);
                      }}
                      placeholder="Nome do Plano"
                    >
                      {plans.map((p, idx) => {
                        return <Option value={p.name}>{p.name}</Option>;
                      })}
                      <Option value={null}>Todos</Option>
                    </Select>
                    <Select
                      value={statusFilter}
                      sx={{ width: 200 }}
                      onChange={(e, n) => {
                        setCurrentPage(1);
                        setStatusFilter(n);
                      }}
                      placeholder="Status do Usuário"
                    >
                      <Option value="Ativo">Verificado</Option>
                      <Option value="Inativo">Não verificado</Option>
                      <Option value="Bloqueado">Bloqueado</Option>
                      <Option value={null}>Todos</Option>
                    </Select>
                    <Select
                      value={adStatusFilter}
                      sx={{ width: 200 }}
                      onChange={(e, n) => {
                        setCurrentPage(1);
                        setAdStatusFilter(n);
                      }}
                      placeholder="Status do Anúncio"
                    >
                      <Option value="Análise">Análise</Option>
                      <Option value="Aprovado">Aprovado</Option>
                      <Option value="Reprovado">Reprovado</Option>
                      <Option value={null}>Todos</Option>
                    </Select>
                  </div>
                )}
                {ordersOpen && (
                  <div className={styles["filtros-especificos"]}>
                    <Select
                      sx={{ width: 200 }}
                      value={orderDirection}
                      onChange={(e, nv) => {
                        setOrderDirection(nv);
                      }}
                      placeholder="Data"
                    >
                      <Option value="desc">Mais recentes</Option>
                      <Option value="asc">Mais antigos</Option>
                    </Select>
                    <Select
                      sx={{ width: 200 }}
                      value={orderBy}
                      onChange={(e, nv) => {
                        setOrderBy(nv);
                      }}
                      placeholder="Acessos"
                    >
                      <Option value="mostAccessed">Mais acessados</Option>
                      <Option value={null}>Todos</Option>
                    </Select>
                  </div>
                )}
                <div className={styles["anunciantes-lista"]}>
                  {users?.filter(user => user.role !== "admin").map((user, idx) => { //remover o metodo filter dessa linha se desejar todos usuários incluindo admins
                    let p = null;
                    if (user?.role !== "admin")
                      p = getUserPlan(user?.userPlan?.planId);
                      
                    return (
                      <Anunciante
                        plan={p}
                        showPlan
                        key={idx}
                        handleProfile={() => handleProfile(user)}
                        handleAd={() => handleAd(user?.trainerAd)}
                        user={{
                          ...user,
                          ad:
                            user.trainerAd !== null
                              ? { ...user.trainerAd }
                              : null,
                        }}
                      />
                    );
                  })}
                  {users?.length === 0 && (
                    <span className={[styles["text"]]}>
                      Usuários não encotrados
                    </span>
                  )}
                </div>
                <div className={styles["paginacao"]}>
                  {pages?.map((p) => {
                    return (
                      <div
                        key={p}
                        onClick={() => setCurrentPage(p)}
                        className={
                          currentPage === p
                            ? styles["page-atual"]
                            : styles["page"]
                        }
                      >
                        <span className={styles["text31"]}>{p}</span>
                      </div>
                    );
                  })}
                </div>
              </>
            )}
            {viewAdPage && <AnuncioView adDetails={ad} />}
            {profilePage && <MyData user={user} />}
          </div>
        </div>
      </div>
      <Rodape rootClassName="root-class-name18"></Rodape>
    </div>
  );
};

export default AdmUsuarios;
