import { combineReducers } from 'redux';
import usuarioReducer from './reducers/usuarioReducer';
import anuncioReducer from './reducers/anuncioReducer';
import buscaReducer from './reducers/buscaReducer';

const rootReducer = combineReducers({
  usuario: usuarioReducer,
  anuncio: anuncioReducer,
  busca: buscaReducer
});

export default rootReducer;
