import AnuncioPendente from "../../../components/AnuncioPendente";
import MenuAdminDesk from "../../../components/MenuAdmin/menu-admin";
import AdmFiltros from "../../../components/adm-filtros";
import AnuncioView from "../../../components/Forms/anuncio";
import Rodape from "../../../components/Rodape/rodape";
import Header from "../../../components/Header/header";
import styles from "./adm-novos-anuncios.module.css";
import { Select, Option } from "@mui/joy";
import axios from "axios";
import { getPlans } from "../../../services/server/Plans"

import { useState, useEffect, useMemo } from "react";
import { MoonLoader } from "react-spinners";
import { toast } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";

const AdmNovosAnuncios = (props) => {
  const history = useHistory();
  const [pendingAds, setPendingAds] = useState(null);
  const [loading, setLoading] = useState(false);
  const [adDetails, setAdDetails] = useState({ username: "", plan: "" });
  const [selectedAd, setSelectedAd] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pages, setPages] = useState([]);
  const [results, setResults] = useState(0);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [orderOpen, setOrderOpen] = useState(false);
  const [orderDirection, setOrderDirection] = useState("desc");
  const [adIsNewFilter, setAdIsNewFilter] = useState(null);
  const [planNameFilter, setPlanNameFilter] = useState(null);

  const [viewAdPage, setViewAdPage] = useState(false);
  const [plans, setPlans] = useState([])

  const { search } = useLocation();
  useEffect(() => {
    const regex = /[?&]username=([^&]+)/;
    const match = regex.exec(search);
    if (match && pendingAds?.length > 0) {
      const trainedAdInView = pendingAds.find(currentAd => currentAd.username === match[1])
      if (trainedAdInView) {
        setAdDetails(trainedAdInView);
        setViewAdPage(true);
      }
    } else {
      setViewAdPage(false)
    }
  }, [pendingAds, search])

  useEffect(() => {
    getPlans()
      .then(res => setPlans(res))
      .catch(err => toast.error(err))
  }, [])

  const getUserPlan = (planId) => plans.find(p => p._id === planId)

  const fetchPendingAds = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/adm/pending-ads`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("ptr_145769130149")}`,
          },
          params: {
            planNameFilter: planNameFilter,
            adIsNew: adIsNewFilter,
            page: currentPage,
            orderDirection: orderDirection
          },
        }
      );

      const p = [];
      for (let i = 0; i < response.data.totalPages; i++) {
        p.push(i + 1);
      }
      setPages(p);
      if (response.status === 200) {
        setResults(response.data.totalResults);
        setPendingAds(response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    (async function () {
      if (pendingAds === null) {
        setLoading(true);
        await fetchPendingAds();
        setLoading(false);
      }
    })();
  }, [pendingAds, viewAdPage]);

  useEffect(() => {
    (async function () {
      setLoading(true);
      await fetchPendingAds();
      setLoading(false);
    })();
  }, [viewAdPage]);

  const handleSeeAd = (trainerAd) => {
    const { username, plan } = trainerAd
    // history.push(`/admin/novos-anuncios?username=${username}&plan=${plan ?? "Plano Free"}`)
    window.open(`/admin/novos-anuncios?username=${username}&plan=${plan ?? "Plano Free"}`, "_blank")
    // setAdDetails(trainerAd);
    // setViewAdPage(true);
  };

  const goBack = () => {
    setViewAdPage(false);
  };

  useEffect(() => {
    (async function () {
      setLoading(true);
      await fetchPendingAds();
      setLoading(false);
    })();
  }, [planNameFilter, orderDirection, adIsNewFilter]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100%",
        }}
      >
        <MoonLoader size={32} color={"#fba600"} loading={true} />
      </div>
    );
  }

  return (
    <div className={styles["container"]}>
      <Header />
      <div className={styles["container1"]}>
        {window.innerWidth > 991 && (
          <MenuAdminDesk rootClassName="root-class-name3"></MenuAdminDesk>
        )}
        <div className={styles["container2"]}>
          <div className={styles["novos-anuncios"]}>
            {!viewAdPage && (
              <div>
                <div className={styles["cabecalho"]}>
                  <div className={styles["titulo"]}>
                    <span className={styles["text"]}>Novos anúncios</span>
                    <span className={styles["textqntd"]}>{results || 0}</span>
                  </div>
                  <div className="adm-filtros-botoes1">
                    <button
                      onClick={() => {
                        setFiltersOpen(false);
                        setOrderOpen(!orderOpen);
                      }}
                      className="adm-filtros-botao-ordenar"
                    >
                      <span className="adm-filtros-text">
                        <span className="">Ordenar</span>
                      </span>
                      <img
                        alt="OrdenarI122"
                        src="/external/ordenari122-6p3.svg"
                        className="adm-filtros-ordenaricon"
                      />
                    </button>
                    <button
                      onClick={() => {
                        setOrderOpen(false);
                        setFiltersOpen(!filtersOpen);
                      }}
                      className="adm-filtros-botao-filtrar"
                    >
                      <span className="adm-filtros-text2">
                        <span className="">Filtrar</span>
                      </span>
                      <img
                        alt="filtrarI807"
                        src="/external/filtrari807-fa7v.svg"
                        className="adm-filtros-filtrar"
                      />
                    </button>
                  </div>
                </div>
                {filtersOpen && (
                  <div className={styles["filtros-especificos"]}>
                    <Select
                      sx={{ width: 200 }}
                      value={planNameFilter}
                      onChange={(e, nv) => {
                        setCurrentPage(1);
                        setPlanNameFilter(nv);
                      }}
                      placeholder="Nome do Plano"
                    >
                      {plans.map((p, idx) => {
                        return <Option value={p.name}>{p.name}</Option>;
                      })}
                      <Option value={null}>Todos</Option>
                    </Select>
                    <Select
                      value={adIsNewFilter}
                      sx={{ width: 200 }}
                      onChange={(e, n) => {
                        setCurrentPage(1);
                        setAdIsNewFilter(n);
                      }}
                      placeholder="Tipo de anúncio"
                    >
                      <Option value={true}>Novo</Option>
                      <Option value={false}>Atualização</Option>
                      <Option value={null}>Todos</Option>
                    </Select>
                  </div>
                )}
                {orderOpen && (
                  <div className={styles["filtros-especificos"]}>
                    <Select
                      sx={{ width: 200 }}
                      value={orderDirection}
                      onChange={(e, nv) => {
                        setOrderDirection(nv);
                        setCurrentPage(1);
                        // setPlanNameFilter(nv);
                      }}
                      placeholder="Ordernar por data"
                    >
                      <Option value="desc">Mais recentes</Option>
                      <Option value="asc">Mais antigos</Option>
                    </Select>
                  </div>
                )}
                <div className={styles["novos-anuncios1"]}>
                  {pendingAds &&
                    pendingAds.map((ad, idx) => {
                      const adPlan = getUserPlan(ad.userId?.userPlan?.planId)

                      return (
                        <AnuncioPendente
                          key={idx}
                          handleSeeAd={handleSeeAd}
                          ad={ad}
                          plan={adPlan}
                        />
                      );
                    })}
                </div>
                <div className={styles["paginacao"]}>
                  {pages.map((p, idx) => {
                    return (
                      <div
                        key={idx}
                        onClick={() => setCurrentPage(p)}
                        className={
                          currentPage === p
                            ? styles["page-atual"]
                            : styles["page"]
                        }
                      >
                        <span className={styles["text31"]}>{p}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
          {viewAdPage && (
            <div className={styles["novos-anuncios"]}>
              <AnuncioView isNewAd goBack={goBack} adDetails={adDetails} />
            </div>
          )}
        </div>
      </div>
      <Rodape rootClassName="root-class-name17"></Rodape>
    </div>
  );
};

export default AdmNovosAnuncios;
