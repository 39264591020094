import React, { useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";

import Input from "../Input/input";
import styles from "./popup-link-youtube.module.css";

const PopupLinkYoutube = (props) => {
  const [link, setLink] = useState("");

  return (
    <div className={styles["pagarcomcarto"]}>
      <div className={styles["container"]}>
        <div className={styles["titulo"]}>
          <span className={styles["text"]}>Enviar video de youtube</span>
          <img
            alt="Vector4897"
            src="/external/vector4897-4cpm.svg"
            className={styles["closeicon"]}
            onClick={() => {
              props.closePopup();
            }}
          />
        </div>
        <div className={styles["form"]}>
          <span>Insira abaixo o link do seu vídeo do youtube</span>
          <div className={styles["motivo"]}>
            <Input
              Label="Link"
              value={link}
              onChange={setLink}
              tipo={1}
            />
          </div>
          <div style={{ display: "flex", gap: 10 }}>
            <button
              className={styles["botao-comprar-grey"]}
              onClick={() => props.closePopup()}
            >
              <span className={styles["text1"]}>
                <span>Cancelar</span>
              </span>
            </button>
            <button
              className={styles["botao-comprar"]}
              onClick={() => {
                props.handleSendYTLink(link);
              }}
            >
              <span className={styles["text1"]}>
                <span>Enviar</span>
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupLinkYoutube;
