import React, { useState, useEffect } from "react";
import styles from "./radio.module.css";

const Radio = (props) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (props?.value) {
      setIsChecked(props.value);
    } else {
      setIsChecked(false);
    }
  }, [props.value]);

  useEffect(() => {
    if (props?.valor) {
      setIsChecked(props.valor);
    } else {
      setIsChecked(false);
    }
  }, [props.valor]);

  const handleClick = () => {
    if (!props.value && !props.valor) {
      setIsChecked(true);
    } else {
      setIsChecked(!isChecked);
    }
  };

  return (
    <div className={` ${styles["container"]}`}>
      {!isChecked && (
        // <div onClick={handleClick} className={styles["unselected"]}></div>
        <div className={styles["unselected"]}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.5"
              y="0.5"
              width="19"
              height="19"
              rx="7.5"
              fill="white"
            />
            <rect
              x="0.5"
              y="0.5"
              width="19"
              height="19"
              rx="7.5"
              stroke="#7D8A98"
            />
          </svg>
        </div>
      )}
      {isChecked && (
        <div className={styles["selected"]}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.5"
              y="0.5"
              width="19"
              height="19"
              rx="7.5"
              fill="white"
            />
            <rect
              x="0.5"
              y="0.5"
              width="19"
              height="19"
              rx="7.5"
              stroke="#FFC24B"
            />
            <path
              d="M13.9546 5.85607L8.39893 11.5368L6.04536 9.13029C5.58105 8.65553 4.81254 8.65553 4.34823 9.13029C3.88392 9.60505 3.88392 10.3909 4.34823 10.8656L7.55037 14.1398C7.79053 14.3854 8.09473 14.5 8.39893 14.5C8.70313 14.5 9.00734 14.3854 9.2475 14.1398L15.6518 7.5914C16.1161 7.11664 16.1161 6.33083 15.6518 5.85607C15.1875 5.38131 14.4189 5.38131 13.9546 5.85607Z"
              fill="#FCA600"
            />
          </svg>
        </div>
        // <img
        //   src="/external/pastedimage-by-200h.png"
        //   onClick={handleClick}
        //   className={styles["selected"]}
        // />
      )}
    </div>
  );
};

export default Radio;
