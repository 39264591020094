import React, { useState, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'

import styles from './menu-admin.module.css'

const MenuAdminDesk = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [menuAtivo,setMenuAtivo] = useState('');

  const handleMenuClick = (menuItem) => {
    history.push(`/admin/${menuItem}`)
  }

  useEffect(() => {
    let path = location.pathname.split("/");
    setMenuAtivo(path)
  },[])


  return (
    <div
      className={` ${styles['menu-lateral-desk']}`}
    >
      <div className={`${styles['navlink']} ${menuAtivo.includes('novos-anuncios') ? styles['ativo'] : ''}`} onClick={() => {
        handleMenuClick("novos-anuncios")
      }}>
        <div className={styles['novos-anuncios']}>
          <svg viewBox="0 0 1024 1024" className={styles['plusicon']}>
            <path d="M768 426.667h-170.667v-170.667c0-47.104-38.229-85.333-85.333-85.333s-85.333 38.229-85.333 85.333l3.029 170.667h-173.696c-47.104 0-85.333 38.229-85.333 85.333s38.229 85.333 85.333 85.333l173.696-3.029-3.029 173.696c0 47.104 38.229 85.333 85.333 85.333s85.333-38.229 85.333-85.333v-173.696l170.667 3.029c47.104 0 85.333-38.229 85.333-85.333s-38.229-85.333-85.333-85.333z"></path>
          </svg>
          <span className={styles['text']}>Novos anúnc.</span>
        </div>
      </div>
      {/* <div className={`${styles['navlink']} ${menuAtivo.includes("anuncios") ? styles['ativo'] : ''}`} onClick={() => {
        handleMenuClick("anuncios")
      }}>
        <div className={styles['novos-anuncios']}>
          <img
            src="/external/vectori476-fovf.svg"
            alt="VectorI476"
            className={styles['megaphoneicon']}
            style={ menuAtivo.includes("anuncios") ? { display: 'none' } : {} }
          />
          <img
            src="/external/pastedimage-3si-200w.png"
            alt="pastedImage"
            className={styles['megaphonelaranja']}
            style={ !menuAtivo.includes("anuncios") ? { display: 'none' } : {} }
          />
          <span className={styles['text']}>
            <span>Anúncios</span>
            <br></br>
          </span>
        </div>
      </div> */}
      <div className={`${styles['navlink']} ${menuAtivo.includes('usuarios') ? styles['ativo'] : ''}`} onClick={() => {
        handleMenuClick("usuarios")
      }}>
        <div className={styles['novos-anuncios']}>
          <img
            alt="Perfil2I476"
            src="/external/perfil2i476-i03.svg"
            className={styles['perfilicon']}
            style={ menuAtivo.includes('usuarios') ? { display: 'none' } : {} }
          />
          <img
            alt="pastedImage"
            src="/external/pastedimage-13qd-200w.png"
            className={styles['perfillaranja']}
            style={ !menuAtivo.includes('usuarios') ? { display: 'none' } : {} }
          />
          <span className={styles['text']}>Usuários</span>
        </div>
      </div>
      {/* 
        <div className={`${styles['navlink']} ${menuAtivo === 'verificacao' ? styles['ativo'] : ''}`} onClick={() => {
        handleMenuClick("verificacao")
      }}>
        <div className={styles['novos-anuncios']}>
        <img
          alt="pastedImage"
          src="/external/pastedimage-pbea-200w.png"
          className={styles['checkicon']}
          style={ menuAtivo === "verificacao" ? { display: 'none' } : {} }
        />
        <img
          alt="pastedImage"
          src="/external/pastedimage-r2eo-200w.png"
          className={styles['checklaranja']}
          style={ menuAtivo !== "verificacao" ? { display: 'none' } : {} }
        />
        <span className={styles['text']}>Verificação</span>
        </div>
      </div>
      */}
      <div className={`${styles['navlink']} ${menuAtivo.includes('denuncias') ? styles['ativo'] : ''}`} onClick={() => {
        handleMenuClick("denuncias")
      }}>
        <div className={styles['novos-anuncios']}>
          <img
            src="/external/pastedimage-uwmq-200w.png"
            alt="pastedImage"
            className={styles['attentionicon']}
            style={ menuAtivo.includes('denuncias') ? { display: 'none' } : {} }
          />
          <img
            src="/external/pastedimage-o0qh-200w.png"
            alt="pastedImage"
            className={styles['attentionlaranja']}
            style={ !menuAtivo.includes('denuncias') ? { display: 'none' } : {} }
          />
          <span className={styles['text']}>Denúncias</span>
        </div>
      </div>
    </div>
  )
}

export default MenuAdminDesk
