import React from "react";

import PropTypes from "prop-types";

import styles from "./anunciante.module.css";
import TagPlan from "../TagPlan";

const Anunciante = (props) => {
  const userVerified = props.user?.emailVerified && props.user?.phoneVerified
  return (
    <div
      style={props.isReport ? { borderRadius: "16px 16px 0px 0px" } : {}}
      className={` ${styles["anunciante"]} ${styles[props.rootClassName]} `}
    >
      <div className={styles["foto-plano"]}>
        <img
          alt="image"
          src={props.user?.ad?.photo || "/external/default-img.svg"}
          className={styles["image"]}
        />
        {props.showPlan && <TagPlan plan={props.plan}/>}
      </div>
      <div className={styles["infos-usuario"]}>
        <div className={styles["container"]}>
          <span className={styles["text-nome"]}>
            <span>{props?.user?.fullName}</span>
          </span>
          <div className={styles["email"]}>
            <img
              alt="Vector4766"
              src="/external/vector4766-scz6.svg"
              className={styles["emailicon"]}
            />
            <span className={styles["text03"]}>{props?.user?.email}</span>
          </div>
          <div className={styles["telefone"]}>
                <img
                  alt="Vector4766"
                  src="/external/telefone2932-n2wc.svg"
                  className={styles["phoneicon"]}
                />
                <span className={styles["text04"]}>{props.user?.phone || "(00) 00000-0000"}</span>
          </div>
          <span className={styles["text-data-entrada"]}>
            <span>Entrou em</span>
            <span>
              <span
                dangerouslySetInnerHTML={{
                  __html: " ",
                }}
              />
            </span>
            <span>
              {new Date(props.user.createdAt).toLocaleDateString("pt-BR")}
            </span>
          </span>
        </div>
        <div className={styles["container1"]}>
          <div className={styles["status-usuario"]}>
            {!userVerified && (
              <img
                alt="image"
                src="/external/recusadoi476-sf2g.svg"
                className={styles["iconchecked"]}
              />
            )}
            {userVerified && (
              <img
                alt="image"
                src="/external/aprovado3811-a99k.svg"
                className={styles["iconchecked"]}
              />
            )}
            <span className={styles["text08"]}>
              {!userVerified ? "Não verificado" : "Verificado"}
            </span>
          </div>
          <div
            onClick={() => props.handleProfile()}
            className={styles["botao-ver-perfil"]}
          >
            <span className={styles["text09"]}>Ver perfil</span>
            <img
              alt="image"
              src="/external/pastedimage-i96s-200h.png"
              className={styles["iconarrow"]}
            />
          </div>
        </div>
      </div>

      {props.user?.ad !== null ? (
        <div className={styles["infos-anuncio"]}>
          <span className={styles["text-nome1"]}>{""}</span>
          <div className={styles["container2"]}>
            <div className={styles["impressoes"]}>
              <span className={styles["text10"]}>Impressões</span>
              <span className={styles["qntd"]}>
                {props.user?.ad?.stats?.totalImpressions || 0}
              </span>
            </div>
            <div className={styles["divisor"]}></div>
            <div className={styles["cliques"]}>
              <span className={styles["text11"]}>Cliques</span>
              <span className={styles["qntd1"]}>
                {props.user?.ad?.stats?.totalClicks || 0}
              </span>
            </div>
            <div className={styles["divisor1"]}></div>
            <div className={styles["conversoes"]}>
              <span className={styles["text12"]}>Conversões</span>
              <div className={styles["qntds"]}>
                <span className={styles["qntd2"]}>
                  {props.user?.ad?.stats?.totalConversions || 0}
                </span>
              </div>
            </div>
          </div>
          <div className={styles["container3"]}>
            <div className={styles["status-anuncio"]}>
              {(props.user?.ad && props.user?.ad?.status === "Aprovado") ||
              props.adStatus === "Aprovado" ? (
                <img
                  alt="image"
                  src="/external/aprovado3811-a99k.svg"
                  className={styles["iconchecked1"]}
                />
              ) : (
                ""
              )}
              {(props.user?.ad && props.user?.ad?.status === "Reprovado") ||
              props.adStatus === "Reprovado" ? (
                <img
                  alt="image"
                  src="/external/recusadoi476-sf2g.svg"
                  className={styles["iconcloqueado1"]}
                />
              ) : (
                ""
              )}
              <span className={styles["text13"]}>
                {props?.user?.ad || props?.adStatus
                  ? `Anúncio ${
                      props.user?.ad?.status?.toLowerCase() ||
                      props.adStatus?.toLowerCase()
                    }`
                  : "Anúncio não existente"}
              </span>
            </div>
            <div
              onClick={() => props.handleAd()}
              className={styles["botao-ver-anuncio"]}
            >
              <span className={styles["text14"]}>
                <span>Ver anúncio</span>
                <br></br>
              </span>
              <img
                alt="image"
                src="/external/pastedimage-s49-200h.png"
                className={styles["iconarrow1"]}
              />
            </div>
          </div>
        </div>
      ) : <div className={styles["infos-anuncio"]}>
          <span className={styles["text12"]}>Sem anúncio</span>
        </div>}
    </div>
  );
};

Anunciante.defaultProps = {
  rootClassName: "",
};

Anunciante.propTypes = {
  rootClassName: PropTypes.string,
};

export default Anunciante;
