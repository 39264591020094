import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'

import styles from './menu-logado.module.css'
import { useSelector } from 'react-redux';

const MenuLogadoDesk = (props) => {
  const history = useHistory();
  const location = useLocation();
  const usuario = useSelector((state) => state.usuario);
  const [menuAtivo,setMenuAtivo] = useState('');

  const { address, cpf, phone, phoneVerified, emailVerified, trainerAd } = usuario
  const { cep, city, streetAddress, state, number } = address ?? {}
  const infoUsuarioPreenchidas = usuario && address && cep && city && streetAddress && state && number && cpf && phone && phoneVerified && emailVerified
  const anuncianteAprovado = trainerAd?.status === 'Aprovado' || (trainerAd?.status === 'Análise' && !trainerAd?.isNew)
  // console.log("Usuário preenchido: ", infoUsuarioPreenchidas, usuario, address, cep, city, streetAddress, state, number, cpf, phone, phoneVerified, emailVerified)
  // console.log("Anuncio aprovado" , anuncianteAprovado)

  const handleMenuClick = (menuItem) => {
    history.push(`/painel/${menuItem}`)
  }

  useEffect(() => {
    let path = location.pathname.split("/");
    if(path.length > 0) path = path[path.length - 1];

    setMenuAtivo(path)
  },[])

  return (
    <div
      className={` ${styles['menu-lateral-desk']} `}
    >
      {anuncianteAprovado && <div className={`${styles['navlink']} ${menuAtivo === 'estatisticas' ? styles['ativo'] : ''}`} onClick={() => {
        handleMenuClick("estatisticas")
      }}>
        <div className={styles['estatisticas']}>
          <svg viewBox="0 0 1024 1024" className={styles['dashboardicon']}>
            <path d="M554 128h342v256h-342v-256zM554 896v-426h342v426h-342zM128 896v-256h342v256h-342zM128 554v-426h342v426h-342z"></path>
          </svg>
          <span className={styles['text']}>
            <span>Estatísticas</span>
          </span>
        </div>
      </div>}
      {infoUsuarioPreenchidas && <div className={`${styles['navlink']} ${menuAtivo === 'meu-anuncio' ? styles['ativo'] : ''}`} onClick={() => {
        handleMenuClick("meu-anuncio")
      }}>
        <div className={styles['estatisticas']}>
            <img
              src="/external/pastedimage-3si-200w.png"
              alt="pastedImage"
              className={styles['megaphonelaranja']}
              style={ menuAtivo !== "meu-anuncio" ? { display: 'none' } : {} }
            />
           <img
            src="/external/vectori476-fovf.svg"
            alt="vectorI476"
            className={styles['megaphoneicon']}
            style={ menuAtivo === "meu-anuncio" ? { display: 'none' } : {} }
            />
          <span className={styles['text']}>
            <span>Meu anúncio</span>
          </span>
        </div>
      </div>}
      {anuncianteAprovado && <div className={`${styles['navlink']} ${menuAtivo === 'avaliacoes' ? styles['ativo'] : ''}`} onClick={() => {
        handleMenuClick("avaliacoes")
      }}>
        <div className={styles['estatisticas']}>
        <img
          src="/external/estrelaavaliaoi476-7o2.svg"
          alt="EstrelaavaliaoI476"
          className={styles['staricon']}
          style={ menuAtivo === "avaliacoes" ? { display: 'none' } : {} }
        />
        <img
        src="/external/pastedimage-45w-200w.png"
        alt="pastedImage"
        className={styles['starlaranja']}     
        style={ menuAtivo !== "avaliacoes" ? { display: 'none' } : {} }
   
        />

        <span className={styles['text']}>
          <span>Avaliações</span>
        </span>
        </div>
      </div>}
      <div className={`${styles['navlink']} ${menuAtivo === 'minha-conta' ? styles['ativo'] : ''}`} onClick={() => {
        handleMenuClick("minha-conta")
      }}>
        <div className={styles['estatisticas']}> 
          <img
          alt="pastedImage"
          src="/external/pastedimage-13qd-200w.png"
          className={styles['perfillaranja']}
          style={ menuAtivo !== "minha-conta" ? { display: 'none' } : {} }
        />
          <img
            src="/external/perfil2i476-i03.svg"
            alt="Perfil2I476"
            className={styles['perfilicon']}
            style={ menuAtivo === "minha-conta" ? { display: 'none' } : {} }
          />
          <span className={styles['text']}>
            <span>Minha conta</span>
          </span>
          </div>
      </div>
      {infoUsuarioPreenchidas && <div className={`${styles['navlink']} ${menuAtivo === 'planos' ? styles['ativo'] : ''}`} onClick={() => {
        handleMenuClick("planos")
      }}>
        <div className={styles['estatisticas']}> 
          <img
          src="/external/pastedimage-ne-200w.png"
          alt="pastedImage"
          className={styles['crownlaranja']}
          style={ menuAtivo !== "planos" ? { display: 'none' } : {} }
        />
          <img
            src="/external/vectori476-2chl.svg"
            alt="VectorI476"
            className={styles['crownicon']}
            style={ menuAtivo === "planos" ? { display: 'none' } : {} }
          />
          <span className={styles['text']}>
            <span>Planos</span>
          </span>
        </div>
      </div>}
      <div className={`${styles['navlink']} ${menuAtivo === 'suporte' ? styles['ativo'] : ''}`} onClick={() => {
        handleMenuClick("suporte")
      }}>
        <div className={styles['estatisticas']}> 
          <img
          alt="pastedImage"
          src="/external/pastedimage-7mv5-200w.png"
          className={styles['suportlaranja']}
          style={ menuAtivo !== "suporte" ? { display: 'none' } : {} }
        />
          <img
            src="/external/vectori476-xkq8.svg"
            alt="VectorI476"
            className={styles['suporticon']}
            style={ menuAtivo === "suporte" ? { display: 'none' } : {} }
          />          
          <span className={styles['text']}>
            <span>Suporte</span>
          </span>
        </div>
      </div>
    </div>
  )
}

export default MenuLogadoDesk
