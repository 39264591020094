import PopupBloquear from "../PopupBloquear/popup-bloquear";
import styles from "./myData.module.css";
import Input from "../Input/input";
import { toast } from "react-toastify";
import { useState } from "react";
import axios from "axios";

const MyData = (props) => {
  const [user, setUser] = useState(props.user);
  const [blockPopup, setBlockPopup] = useState(false);
  console.log(user);
  const blockUser = async (userId) => {
    if (props.user?.role !== "admin") {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/api/adm/user/${userId}/block`,
          {
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                "ptr_145769130149"
              )}`,
            },
          }
        );
        if (response.status === 200) {
          setUser(response.data.data);
          setBlockPopup(false);
          if(user.status === "Bloqueado"){
            toast.success("Usuário desbloqueado com sucesso!");
          } else {
            toast.success("Usuário bloqueado com sucesso!");
          }
        }
      } catch (error) {
        console.error(error);
        setBlockPopup(false);
        if(user.status === "Inativo" || user.status === "Verificado"){
          toast.error("Erro ao bloquear usuário!");
        } else {
          toast.error("Erro ao desbloquar usuário");
        }
      }
    } else {
      setBlockPopup(false);
      toast.error("Não é possível bloquear um admin!");
    }
  };

  return (
    <div className={styles["container2"]}>
      <div className={styles["minha-conta"]}>
        <div className={styles["cabecalho"]}>
          <span className={styles["text"]}>Perfil do usuário</span>
          {user?.status !== "Bloqueado" && (
            <button
              onClick={() => setBlockPopup(true)}
              className={styles["botao-reprovar"]}
            >
              <span className={styles["text41"]}>
                <span>Bloquear</span>
              </span>
              <img
                alt="recusadoI476"
                src="/external/recusadoi476-sf2g.svg"
                className={styles["recusadoicon"]}
              />
            </button>
          )}
          {user?.status === "Bloqueado" && (
            <button
              onClick={() => setBlockPopup(true)}
              className={styles["botao-reprovar"]}
            >
              <span className={styles["text41"]}>
                <span>Desbloquear</span>
              </span>
              <img
                alt="recusadoI476"
                src="/external/aprovado3812-1ff.svg"
                className={styles["recusadoicon"]}
              />
            </button>
          )}
        </div>
        <div className={styles["header"]}>
          <div className={styles["header-left"]}>
            <img
              alt="image"
              src={user?.trainerAd?.photo || "/external/default-img.svg"}
              className={styles["image"]}
            />
            <div className={styles["user-info"]}>
              {props?.d && (
                <div
                  className={
                    plan?.name === "Plano Free"
                      ? styles["plano"]
                      : styles["plano-elite"]
                  }
                >
                  {plan?.name === "Plano Free" && (
                    <img
                      alt="image"
                      src="/external/vector4766-0toy.svg"
                      className={styles["icon-free"]}
                    />
                  )}
                  {plan?.name === "Plano Aquecimento" && (
                    <img
                      alt="Vector4766"
                      src="/external/pastedimage-0egb-1500w.png"
                      className={styles["pesoaqueciicon"]}
                    />
                  )}
                  {plan?.name === "Plano Elite" && (
                    <img
                      alt="Vector4766"
                      src="/external/vector4766-i44.svg"
                      className={styles["pesoeliteicon"]}
                    />
                  )}
                  <span
                    className={
                      plan?.name === "Plano Free"
                        ? styles["text1"]
                        : styles["text"]
                    }
                  >
                    <span>{plan?.name || "Admin"}</span>
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className={styles["header-right"]}>
            <span className={styles["text12"]}>{`Entrou em: ${new Date(
              user?.createdAt
            ).toLocaleDateString("pt-BR")}`}</span>
          </div>
        </div>
        <div className={styles["meus-dados"]}>
          <span className={styles["text01"]}>
            <span>Dados do personal</span>
          </span>
          <div className={styles["inputs"]}>
            <div className={styles["coluna"]}>
              <Input
                Label="Nome completo"
                value={user?.fullName}
                readOnly
              ></Input>
              <Input
                Label="CPF"
                mask="999.999.999-99"
                Placeholder="000.000.000-00"
                value={user?.cpf || ""}
                readOnly
              ></Input>
              <Input
                Label="Telefone"
                mask="+55 (99) 99999-9999"
                Placeholder="+55 (00) 00000-0000"
                value={user?.phone || ""}
                readOnly
              ></Input>
            </div>
            <div className={styles["coluna1"]}>
              <Input
                Label="CEP"
                Placeholder="00000-000"
                mask="99999-999"
                value={user?.address?.cep || ""}
                readOnly
              ></Input>
              <div className={styles["endereco-numero"]}>
                <Input
                  Label="Endereço"
                  Placeholder="Rua"
                  value={user?.address?.streetAddress || ""}
                  readOnly
                ></Input>
                <Input
                  Placeholder="N°"
                  value={user?.address?.number}
                  readOnly
                ></Input>
              </div>
              <div className={styles["cidade-uf"]}>
                <Input
                  Label="Cidade"
                  Placeholder="Cidade"
                  value={user?.address?.city || ""}
                  readOnly
                ></Input>
                <Input
                  Placeholder="UF"
                  value={user?.address?.state || ""}
                  readOnly
                ></Input>
              </div>
            </div>
          </div>
        </div>
        <div className={styles["verificacao"]}>
          <span className={styles["text05"]}>
            <span>Verificação e ativação de conta</span>
          </span>
          <div className={styles["verificar-telefone"]}>
            <div
              style={{
                width: "100%",
              }}
            >
              <Input
                readOnly
                Label="Telefone"
                value={user?.phone}
                mask="+55 (99) 99999-9999"
                Placeholder="+55 (00) 00000-0000"
              ></Input>
            </div>
            {user?.isPhoneVerified && user?.phone === telefone ? (
              <div className={styles["verificado"]}>
                <span className={styles["text09"]}>
                  <span>Verificado</span>
                </span>
                <img
                  alt="aprovado3811"
                  src="/external/aprovado3811-a99k.svg"
                  className={styles["aprovado"]}
                />
              </div>
            ) : (
              <button
                disabled={true}
                className={`${styles["botao-verificar"]}`}
                onClick={() => {
                  if (confirmationResult) {
                    handleVerifyCode();
                  } else {
                    handleSendCode();
                  }
                }}
              >
                <span className={styles["text11"]}>Salvar</span>
              </button>
            )}
          </div>
          <div className={styles["verificar-email"]}>
            <Input Label="E-mail" value={user?.email} readOnly></Input>
            {user?.emailVerificado ? (
              <div className={styles["verificado1"]}>
                <span className={styles["text13"]}>
                  <span>Verificado</span>
                </span>
                <img
                  alt="aprovado3811"
                  src="/external/aprovado3811-a99k.svg"
                  className={styles["aprovado1"]}
                />
              </div>
            ) : (
              <button
                disabled={true}
                className={`${styles["botao-verificar"]}`}
              >
                <span className={styles["text11"]}>
                  <span>Salvar</span>
                </span>
              </button>
            )}
          </div>
        </div>
        {blockPopup && (
          <PopupBloquear
            isUnblock={user.status === "Bloqueado"}
            handleReport={() => blockUser(user._id, "Bloqueado")}
            closePopup={() => setBlockPopup(false)}
          />
        )}
      </div>
    </div>
  );
};

export default MyData;
