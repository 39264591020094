import React from 'react'

import Header from '../../components/Header/header'
import Rodape from '../../components/Rodape/rodape'
import styles from './sobre-nos.module.css'

const SobreNos = (props) => {
  return (
    <div className={styles['container']}>
      <Header />
      <div className={styles['container1']}>
        <div className={styles['conteudo']}>
          <div className={styles['sobre-nos']}>
            <div className={styles['espaco-mobile']}></div>
            <div className={styles['texto']}>
              <span className={styles['text']}>Sobre nós</span>
              <span className={styles['text01']}>
                <span>
                  Contratar um personal trainer é um investimento direto em sua
                  saúde e bem-estar. O auxílio especializado ajuda você a
                  alcançar seus objetivos de maneira mais eficaz, evitando
                  lesões e otimizando seu tempo. Os resultados, frequentemente,
                  são mais rápidos e duradouros. Pense no valor, não apenas no
                  preço.
                </span>
              </span>
            </div>
            <div className={styles['espaco-desk']}></div>
          </div>
          <div className={styles['missao-visao-valores']}>
            <div className={styles['missao-visao']}>
              <div className={styles['missao']}>
                <span className={styles['text03']}>
                  <span>Missão</span>
                </span>
                <span className={styles['text05']}>
                  Revitalizar o mercado dos personal trainers, oferecendo um
                  espaço exclusivo para que esses profissionais se destaquem e
                  se conectem com clientes que buscam seus serviços. Almejamos
                  criar um elo firme e confiável entre aqueles que ensinam e os
                  que desejam aprender.
                </span>
              </div>
              <div className={styles['visao']}>
                <span className={styles['text06']}>
                  <span>Visão</span>
                </span>
                <span className={styles['text08']}>
                  <span>
                    Ser a plataforma referência no Brasil para encontrar e
                    anunciar serviços de personal trainers, guiada pela
                    qualidade, confiança e excelência em cada conexão realizada.
                  </span>
                </span>
              </div>
            </div>
            <div className={styles['valores']}>
              <span className={styles['text10']}>
                <span>Valores</span>
              </span>
              <span className={styles['text12']}>
                <span>
                  • Integridade: Promovemos relações honestas e transparentes.
                </span>
                <br></br>
                <span>• </span>
                <span>
                  Inovação: Estamos constantemente em busca de aprimorar nossa
                  plataforma e serviços.
                </span>
                <br></br>
                <span>• </span>
                <span>
                  Compromisso: Estamos dedicados a maximizar o sucesso de cada
                  personal trainer e cliente que utiliza nossa plataforma.
                </span>
                <br></br>
                <span>• </span>
                <span>
                  Excelência: Perseguimos a perfeição em cada detalhe,
                  garantindo a melhor experiência possível.
                </span>
                <br></br>
                <span>• </span>
                <span>
                  Respeito: Valorizamos cada indivíduo, respeitando suas
                  singularidades e objetivos.
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <Rodape></Rodape>
    </div>
  )
}


export default SobreNos
