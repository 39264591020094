const AWS = require("aws-sdk");
// import AWS from "aws-sdk";

const s3Config = {
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
  bucketName: process.env.REACT_APP_AWS_S3_BUCKET,
};

const s3 = new AWS.S3({
  accessKeyId: s3Config.accessKeyId,
  secretAccessKey: s3Config.secretAccessKey,
  region: s3Config.region,
});

function readFile(file) {
  let reader = new FileReader();
  console.log("readFile triggered");
  return new Promise((resolve, reject) => {
    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = () => {
      reject(null);
    };
  });
}

export async function uploadToS3(file) {
  if (!file) {
    throw new Error("Nenhum arquivo selecionado.");
  }

  let buffer = await readFile(file);
  if (buffer == null) {
    throw new Error("Erro ao processar o arquivo selecioando.");
  }

  const params = {
    Bucket: s3Config.bucketName,
    Key: file.name,
    Body: buffer,
    ContentType: file.type,
  };

  try {
    const { Location } = await s3.upload(params).promise();
    return Location;
  } catch (error) {
    console.error("Erro no upload para Amazon S3:", error);
    throw new Error(`Falha no upload: ${error.message}`);
  }
}
