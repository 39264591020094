import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { MoonLoader } from "react-spinners";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

import { Provider, useDispatch, useSelector } from "react-redux";
import { setUsuarioData } from "./redux/actions/usuarioActions";
import store from "./redux/store";
import "./style.css";
import MeuAnuncio from "./views/Painel/MeuAnuncio/meu-anuncio";
import Home from "./views/Home/home";
import AdmUsuarios from "./views/Admin/Usuarios/adm-usuarios";
import MinhaConta from "./views/Painel/MinhaConta/minha-conta";
import PoliticadePrivacidade from "./views/PoliticaDePrivacidade/politicade-privacidade";
import FAQ from "./views/Faq/faq";
import Contato from "./views/Contato/contato";
import Perfil from "./views/Perfil/perfil";
import AdmNovosAnuncios from "./views/Admin/NovosAnuncios/adm-novos-anuncios";
import Suporte from "./views/Painel/Suporte/suporte";
import Login from "./views/Login/login";
import PersonalTrainers from "./views/PersonalTrainers/personaltrainers";
import TermosdeUso from "./views/TermosUso/termosde-uso";
import PoliticaDoSorteio from "./views/PoliticaDoSorteio/politica-do-sorteio";
import Planos from "./views/Painel/Planos/planos";
import Estatisticas from "./views/Painel/Estatisticas/estatisticas";
import SobreNos from "./views/SobreNos/sobre-nos";
import AdmDenuncias from "./views/Admin/Denuncias/adm-denuncias";
import Avaliacoes from "./views/Painel/Avaliacoes/avaliacoes";
import Avaliacao from "./views/Avaliacao/avaliacao";
import NotFound from "./views/NotFound/not-found";
import SejaAnunciante from "./views/SejaAnunciante/seja-anunciante";
import PlanosPublico from "./views/Planos/planos";
import AuthRedirect from "./views/AuthRedirect/auth-redirect";
import ChatComponent from "./components/SnippetChat";
import Regulamento from "./views/RegulamentoSorteio/regulamento";

const App = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.usuario);
  const [plans, setPlans] = useState([]);
  const [currentPlan, setCurrentPlan] = useState([]);

  const ProtectedRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) =>
          user && user.role === "user" ? (
            <Component {...props} />
          ) : (
            <Redirect to="/login" />
          )
        }
      />
    );
  };

  const LoginRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) =>
          !user ? (
            <Component {...props} />
          ) : (
            user.role === "admin" ? <Redirect to="/admin" /> : <Redirect to="/painel" />
          )
        }
      />
    );
  };

  const AdminRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) =>
          user && user.role === "admin" ? (
            <Component {...props} />
          ) : (
            <NotFound />
          )
        }
      />
    );
  };

  const fetchUser = async () => {
    try {
      if (!localStorage.getItem("ptr_145769130149")) {
        throw new Error();
      }

      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/users/user-data`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("ptr_145769130149")}`,
          },
          params: {
            user: {
              _id: localStorage.getItem("ptr_145769130149"),
            },
          },
        }
      );

      const { user, trainerAd, reviews } = res.data.data;
      const result = {
        ...user,
        trainerAd,
        reviews,
      };

      dispatch(setUsuarioData(result));
    } catch (erro) {
      localStorage.removeItem("ptr_145769130149");
      console.error("Erro ao renovar token", erro.message);
    }
  };

  useEffect(() => {
    (async function () {
      setLoading(true);
      await fetchUser();
      setLoading(false);
    })();
  }, [dispatch]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100%",
        }}
      >
        <MoonLoader size={32} color={"#fba600"} loading={true} />
      </div>
    );
  }

  return (
    <Router>
      <ToastContainer hideProgressBar={true} />
      <ChatComponent/>
      <Switch>
        <Route component={AuthRedirect} exact path="/auth-reg" />
        <Route component={Home} exact path="/" />
        <Route
          component={PoliticadePrivacidade}
          exact
          path="/politica-de-privacidade"
        />
        <Route
          component={Regulamento}
          exact
          path="/regulamento-sorteio"
        />
        <Route exact component={FAQ} path="/faq" />
        <Route exact component={SejaAnunciante} path="/seja-anunciante" />
        <Route exact component={PlanosPublico} path="/planos" />
        <Route exact component={Contato} path="/contato" />
        <Route exact component={Perfil} path="/personal/:slug" />
        <Route exact render={(props) => <Perfil {...props} paginaExlusiva={true}/>} path="/exclusive-ad/:slug" /> {/* rota temporaria */}
        {/* <Route exact component={Login} path="/login" /> */}
        <LoginRoute
          exact
          component={Login}
          path="/login"
        />
        {/* <Route exact component={Login} path="/cadastro" /> */}
        <LoginRoute
          exact
          component={Login}
          path="/cadastro"
        />
        <Route exact component={Login} path="/redefinir-senha" />
        <Route exact component={Login} path="/login-social" />
        <Route exact component={PersonalTrainers} path="/busca" />
        <Route exact component={TermosdeUso} path="/termos-de-uso" />
        <Route exact component={PoliticaDoSorteio} path="/politica-do-sorteio" />
        <Route exact component={SobreNos} path="/sobre-nos" />
        <Route exact component={Avaliacao} path="/review/:uid" />
        <ProtectedRoute
          exact
          component={MeuAnuncio}
          path="/painel/meu-anuncio"
        />
        <ProtectedRoute
          exact
          component={Avaliacoes}
          path="/painel/avaliacoes"
        />
        <ProtectedRoute
          exact
          component={MinhaConta}
          path="/painel/minha-conta"
        />
        <ProtectedRoute component={Planos} exact path="/painel/planos" />
        <ProtectedRoute
          exact
          component={Estatisticas}
          path="/painel/estatisticas"
        />
        <ProtectedRoute exact component={Suporte} path="/painel/suporte" />
        {user && user.role === "user" && 
          (user.cpf && user.address) ? 
          (<Redirect exact from="/painel" to='/painel/estatisticas' />) :
          (<Redirect exact from="/painel" to='/painel/minha-conta' />)
        }
        <AdminRoute exact component={AdmUsuarios} path="/admin/usuarios" />
        <AdminRoute
          exact
          component={AdmNovosAnuncios}
          path="/admin/novos-anuncios"
        />
        <AdminRoute component={AdmDenuncias} path="/admin/denuncias" />
        {user && user.role === "admin" && (
          <Redirect exact from="/admin" to="/admin/novos-anuncios" />
        )}
        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
    </Router>
  );
};

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("app")
);
