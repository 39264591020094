import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setUsuarioData } from "../../../redux/actions/usuarioActions";
import { toast } from "react-toastify";
import axios, { all } from "axios";

import MenuLogado from "../../../components/MenuLogado/menu-logado";
import Input from "../../../components/Input/input";
import Rodape from "../../../components/Rodape/rodape";
import PopupAlterarSenha from "../../../components/PopupAlterarSenha/popup-alterar-senha";
import Header from "../../../components/Header/header";

import styles from "./minha-conta.module.css";
import { Link } from "react-router-dom/cjs/react-router-dom";

const MinhaConta = (props) => {
  const dispatch = useDispatch();
  const usuario = useSelector((state) => state.usuario);
  const [nome, setNome] = useState("");
  const [cpf, setCpf] = useState("");
  const [cep, setCep] = useState("");
  const [endereco, setEndereco] = useState("");
  const [numero, setNumero] = useState("");
  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("");
  // const [telefone, setTelefone] = useState("");
  const [email, setEmail] = useState("");
  const [emailVerified, setEmailVerified] = useState(
    usuario?.emailVerified ?? false
  );
  const [phone, setPhone] = useState("");
  const [phoneVerified, setPhoneVerified] = useState(
    usuario?.phoneVerified ?? false
  );
  // const [tel, setTel] = useState("");
  const [popupAlterarSenhaVisible, setPopupAlterarSenhaVisible] =
    useState(false);
  const [verificationCodeEmail, setVerificationCodeEmail] = useState("");
  const [confirmationResultEmail, setConfirmationResultEmail] = useState(false);
  const [verificandoEmail, setVerificandoEmail] = useState(false);
  const [verificationCodeSMS, setVerificationCodeSMS] = useState("");
  const [confirmationResultSMS, setConfirmationResultSMS] = useState(false);
  const [verificandoTelefone, setVerificandoTelefone] = useState(false);
  const [emptyFieldsState, setEmptyFields] = useState([]);

  const sendVerificationEmail = async () => {
    setVerificandoEmail(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/users/send-verification-email`,
        {
          email: email,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("ptr_145769130149")}`,
          },
        }
      );

      toast.success("E-mail enviado com sucesso!");
      setVerificationCodeEmail(response?.verificationToken);
      setVerificandoEmail(false);
      setConfirmationResultEmail(true);
    } catch (error) {
      console.log(error);
      toast.error("Erro ao verificar email: ", error);
      setVerificandoEmail(false);
    }
  };

  const handleVerifyCodeEmail = async () => {
    try {
      // console.log("verificationCodeEmail: " + verificationCodeEmail);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/users/verify-email`,
        { verificationToken: verificationCodeEmail },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("ptr_145769130149")}`,
          },
        }
      );

      // console.log(response);

      if (response.data.verified) {
        setVerificationCodeEmail("");
        setConfirmationResultEmail(false);
        setEmailVerified(true);
        window.location.reload(false);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      // console.error(error);
      toast.error("Erro ao processar o código.");
    }
  };

  const handleSendCode = async () => {
    setVerificandoTelefone(true);
    try {
      console.log("Phone: " + phone);
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/users/send-verification-code`,
        { phone: phone.replaceAll(/[^\d]/g, "") },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("ptr_145769130149")}`,
          },
        }
      );

      setVerificandoTelefone(false);
      setConfirmationResultSMS(true);
      toast.success("Código enviado com sucesso!");
    } catch (error) {
      console.error(error);
      setVerificandoTelefone(false);
      toast.error("Erro ao enviar código.");
    }
  };

  const handleVerifyCodeSMS = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/users/verify-phone`,
        { code: verificationCodeSMS },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("ptr_145769130149")}`,
          },
        }
      );

      if (response.data.verified) {
        setConfirmationResultSMS(false);
        setVerificationCodeSMS("");
        setPhoneVerified(true);
        toast.success(response.data.message);
        window.location.reload(false);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error("Erro ao processar o código.");
    }
  };

  const salvarDados = async () => {
    // Verificar se todos os inputs estão preenchidos
    const { allSet, emptyFields } = inputsFilled(true);
    if (!allSet) {
      toast.error(
        `Todos as informações devem ser preenchidas. Os seguintes campos estão vazios: ${emptyFields.join(
          ", "
        )}`
      );
      setEmptyFields(emptyFields);
      return;
    }

    // Verificar cpf válido
    if (isCpfValid(cpf)) {
      try {
        // Verificar CPF repetido
        let duplicate = await checkDuplicateCpf(cpf);
        if (duplicate.status == "failed") {
          throw new Error(duplicate.message);
        }

        // Verificar existência de telefone
        let checkPhone = await checkDuplicatePhone(
          phone.replaceAll(/[^\d]/gm, "")
        );
        if (checkPhone.status == "failed") {
          throw new Error(checkPhone.message);
        }

        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/api/users/update-profile`,
          {
            fullName: nome,
            address: {
              cep: cep,
              streetAddress: endereco,
              city: cidade,
              state: estado,
              number: numero,
            },
            phone: phone.replace("55", ""),
            cpf: cpf,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                "ptr_145769130149"
              )}`,
            },
            params: {
              user: {
                _id: localStorage.getItem("ptr_145769130149"),
              },
            },
          }
        );

        if (response.status === 200) {
          dispatch(setUsuarioData(response.data.data));
          toast.success("Dados atualizados com sucesso!");
        }
      } catch (erro) {
        console.log(erro.message);
        if (typeof erro.message === "string") {
          toast.error(erro.message);
        } else {
          toast.error("Erro ao salvar os dados.");
        }
      }
    } else {
      toast.error("Cpf inválido!");
    }
  };

  const isCpfValid = (numero) => {
    if (!numero) return false;

    // Remover caracteres não numéricos
    numero = numero.replace(/\D/g, "");

    // Verificar se o número tem 11 ou 14 dígitos
    if (numero.length !== 11 && numero.length !== 14) {
      return false;
    }

    // Verificar se o número é composto por dígitos repetidos
    if (/^(\d)\1+$/.test(numero)) {
      return false;
    }

    // Calcular os dígitos verificadores para CPF ou CNPJ
    let soma = 0;
    let resto = 0;
    let dv1 = 0;
    let dv2 = 0;

    if (numero.length === 11) {
      // CPF
      // Calcular o primeiro dígito verificador
      for (let i = 0; i < 9; i++) {
        soma += numero[i] * (10 - i);
      }
      resto = soma % 11;
      dv1 = resto < 2 ? 0 : 11 - resto;

      // Calcular o segundo dígito verificador
      soma = 0;
      for (let i = 0; i < 10; i++) {
        soma += numero[i] * (11 - i);
      }
      resto = soma % 11;
      dv2 = resto < 2 ? 0 : 11 - resto;

      // Comparar os dígitos verificadores com os últimos dois dígitos do número
      return numero[9] == dv1 && numero[10] == dv2;
    } else {
      // CNPJ
      // Calcular o primeiro dígito verificador
      for (let i = 0; i < 4; i++) {
        soma += numero[i] * (5 - i);
      }
      for (let i = 4; i < 12; i++) {
        soma += numero[i] * (9 - (i - 4));
      }
      resto = soma % 11;
      dv1 = resto < 2 ? 0 : 11 - resto;

      // Calcular o segundo dígito verificador
      soma = 0;
      for (let i = 0; i < 5; i++) {
        soma += numero[i] * (6 - i);
      }
      for (let i = 5; i < 13; i++) {
        soma += numero[i] * (9 - (i - 5));
      }
      resto = soma % 11;
      dv2 = resto < 2 ? 0 : 11 - resto;

      // Comparar os dígitos verificadores com os últimos dois dígitos do número
      return numero[12] == dv1 && numero[13] == dv2;
    }
  };

  const inputsFilled = (saving = false) => {
    let emptyFields = [];

    if (nome == "") emptyFields.push("Nome completo");
    if (cpf == "") emptyFields.push("CPF");
    if (cep == "") emptyFields.push("CEP");
    if (endereco == "") emptyFields.push("Endereço");
    if (numero == "") emptyFields.push("Número");
    if (cidade == "") emptyFields.push("Cidade");
    if (estado == "") emptyFields.push("Estado");
    if (phone == "") emptyFields.push("Telefone");

    return {
      allSet: emptyFields.length === 0,
      emptyFields,
    };
  };

  const checkDuplicatePhone = async (phone) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/users/check-duplicate-phone`,
      {
        phone,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ptr_145769130149")}`,
        },
      }
    );

    if (response.status == 200) {
      return response.data;
    } else {
      throw new Error("Erro ao processar telefone.");
    }
  };

  const checkDuplicateCpf = async (cpf) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/users/check-duplicate-cpf`,
      {
        cpf,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ptr_145769130149")}`,
        },
      }
    );

    if (response.status == 200) {
      return response.data;
    } else {
      throw new Error("Erro ao processar CPF.");
    }
  };

  const closePopup = () => {
    setPopupAlterarSenhaVisible(false);
  };

  useEffect(() => {
    if (popupAlterarSenhaVisible) {
      document.querySelector("body").style.overflow = "hidden";
    } else {
      document.querySelector("body").style = "";
    }
  }, [popupAlterarSenhaVisible]);

  useEffect(() => {
    if (
      cep &&
      cep.replace(/\D/g, "").length === 8 &&
      usuario?.endereco?.cep !== cep
    ) {
      fetch(`https://viacep.com.br/ws/${cep.replace(/\D/g, "")}/json/`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Algo deu errado");
          }
          return response.json();
        })
        .then((data) => {
          if (data.erro) {
            throw new Error("CEP inválido");
          }
          setEndereco(data.logradouro);
          setCidade(data.localidade);
          setEstado(data.uf);
        })
        .catch((error) => {
          console.error("Erro ao buscar CEP:", error);
          setCep("");
          toast.error("O CEP digitado é inválido.");
        });
    }
  }, [cep]);

  useEffect(() => {
    if (usuario.fullName) setNome(usuario.fullName);
    if (usuario.email) setEmail(usuario.email);
    if (usuario.address) {
      if (usuario.address.cep) setCep(usuario.address.cep);
      if (usuario.address.streetAddress)
        setEndereco(usuario.address.streetAddress);
      if (usuario.address.number) setNumero(usuario.address.number);
      if (usuario.address.city) setCidade(usuario.address.city);
      if (usuario.address.state) setEstado(usuario.address.state);
    }
    if (usuario.phone) setPhone(usuario.phone);
    if (usuario.phoneVerified) setPhoneVerified(true);
    if (usuario.emailVerified) setEmailVerified(true);
    setCpf(usuario.cpf);
  }, [usuario]);

  return (
    <div className={styles["container"]}>
      <Header />
      {popupAlterarSenhaVisible && (
        <PopupAlterarSenha closePopup={closePopup} />
      )}
      <div className={styles["container1"]}>
        {window.innerWidth > 991 && <MenuLogado></MenuLogado>}
        <div className={styles["container2"]}>
          <div className={styles["minha-conta"]}>
            <div className={styles["cabecalho"]}>
              <span className={styles["text"]}>Minha conta</span>
            </div>
            <div className={styles["meus-dados"]}>
              <div className={styles["row-container"]}>
                <span className={styles["text01"]}>
                  <span>Meus dados</span>
                </span>
                {!inputsFilled().allSet && (
                  <div
                    className={`${styles["card-cinza"]} ${styles["card-vermelho"]}`}
                  >
                    <p>Preencha seus dados e valide sua conta para anunciar.</p>
                  </div>
                )}
              </div>
              <div className={styles["inputs"]}>
                <div className={styles["coluna"]}>
                  <Input
                    Label="Nome completo"
                    value={nome}
                    onChange={setNome}
                    style={
                      emptyFieldsState.includes("Nome completo")
                        ? { borderColor: "#a22020" }
                        : {}
                    }
                    readOnly={usuario?.fullName}
                    grayedDisable={true}
                  ></Input>
                  <Input
                    Label="CPF"
                    mask="999.999.999-99"
                    Placeholder="000.000.000-00"
                    value={cpf}
                    onChange={setCpf}
                    style={
                      emptyFieldsState.includes("CPF")
                        ? { borderColor: "#a22020" }
                        : {}
                    }
                    readOnly={usuario?.cpf}
                    grayedDisable={true}
                  ></Input>
                  <Input
                    Label="Telefone"
                    mask="+55 (99) 99999-9999"
                    Placeholder="+55 (00) 00000-0000"
                    value={phone}
                    onChange={setPhone}
                    style={
                      emptyFieldsState.includes("Telefone")
                        ? { borderColor: "#a22020" }
                        : {}
                    }
                  ></Input>
                </div>
                <div className={styles["coluna1"]}>
                  <Input
                    Label="CEP"
                    Placeholder="00000-000"
                    mask="99999-999"
                    value={cep}
                    onChange={setCep}
                    style={
                      emptyFieldsState.includes("CEP")
                        ? { borderColor: "#a22020" }
                        : {}
                    }
                  ></Input>
                  <div className={styles["endereco-numero"]}>
                    <Input
                      Label="Endereço"
                      Placeholder="Rua"
                      value={endereco}
                      onChange={setEndereco}
                      style={
                        emptyFieldsState.includes("Endereço")
                          ? { borderColor: "#a22020" }
                          : {}
                      }
                    ></Input>
                    <Input
                      Placeholder="N°"
                      value={numero}
                      onChange={setNumero}
                      style={
                        emptyFieldsState.includes("Número")
                          ? { borderColor: "#a22020" }
                          : {}
                      }
                    ></Input>
                  </div>
                  <div className={styles["cidade-uf"]}>
                    <Input
                      Label="Cidade"
                      Placeholder="Cidade"
                      value={cidade}
                      onChange={setCidade}
                      style={
                        emptyFieldsState.includes("Cidade")
                          ? { borderColor: "#a22020" }
                          : {}
                      }
                    ></Input>
                    <Input
                      Placeholder="UF"
                      value={estado}
                      onChange={setEstado}
                      style={
                        emptyFieldsState.includes("Estado")
                          ? { borderColor: "#a22020" }
                          : {}
                      }
                    ></Input>
                  </div>
                </div>
              </div>
              <div className={styles["row-container"]}>
                <div className={styles["card-cinza"]}>
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7 1.4C3.90721 1.4 1.4 3.90721 1.4 7C1.4 10.0928 3.90721 12.6 7 12.6C10.0928 12.6 12.6 10.0928 12.6 7C12.6 3.90721 10.0928 1.4 7 1.4ZM0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7ZM6.26514 4.2C6.26514 3.8134 6.57854 3.5 6.96514 3.5H7.03486C7.42146 3.5 7.73486 3.8134 7.73486 4.2V4.27C7.73486 4.65605 7.42231 4.96923 7.03626 4.97L6.96654 4.97014C6.78065 4.97051 6.60224 4.89692 6.47066 4.76561C6.33908 4.63429 6.26514 4.45603 6.26514 4.27014V4.2ZM7 5.6C7.3866 5.6 7.7 5.9134 7.7 6.3V9.8C7.7 10.1866 7.3866 10.5 7 10.5C6.6134 10.5 6.3 10.1866 6.3 9.8V6.3C6.3 5.9134 6.6134 5.6 7 5.6Z"
                      fill="#2D3E50"
                    />
                  </svg>
                  <p>Estes dados não ficam públicos em seu anúncio.</p>
                </div>
                <button
                  className={styles["botao-salvar"]}
                  onClick={salvarDados}
                >
                  <span className={styles["text03"]}>
                    <span>{`${
                      usuario?.address && usuario?.cpf && usuario?.phone
                        ? "Editar"
                        : "Salvar"
                    }`}</span>
                  </span>
                </button>
              </div>
            </div>
            <div className={styles["verificacao"]}>
              <div className={styles["cabecalho-verificacao"]}>
                <span className={styles["text05"]}>
                  <span>Verificação e ativação de conta</span>
                </span>
                {(!emailVerified || !phoneVerified) && (
                  <span className={styles["card-cinza"]}>
                    <p>
                      Dificuldades com a verificação? Fale com o{" "}
                      <Link
                        to="/painel/suporte"
                        rel="noopener noreferrer"
                        target="_blank"
                        className={styles["link"]}
                      >
                        suporte!
                      </Link>
                    </p>
                  </span>
                )}
              </div>
              <div className={styles["verificar-telefone"]}>
                <div id="recaptcha-container" style={{ display: "none" }}></div>
                <div
                  style={{
                    width: "100%",
                    display: confirmationResultSMS ? "block" : "none",
                  }}
                >
                  <Input
                    Label="Código de verificação"
                    Placeholder="Insira o código recebido via SMS"
                    value={verificationCodeSMS}
                    onChange={setVerificationCodeSMS}
                    mask={null}
                  ></Input>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: !confirmationResultSMS ? "block" : "none",
                  }}
                >
                  <Input
                    Label="Telefone"
                    value={phone}
                    onChange={setPhone}
                    mask="+55 (99) 99999-9999"
                    style={confirmationResultSMS ? { display: "none" } : {}}
                  ></Input>
                </div>
                {phoneVerified ? ( //usuario?.phoneVerified && usuario?.phone === telefone
                  <div className={styles["verificado"]}>
                    <span className={styles["text09"]}>
                      <span>Verificado</span>
                    </span>
                    <img
                      alt="aprovado3811"
                      src="/external/aprovado3811-a99k.svg"
                      className={styles["aprovado"]}
                    />
                  </div>
                ) : (
                  <button
                    disabled={verificandoTelefone}
                    className={`${styles["botao-verificar"]} ${
                      verificandoTelefone ? styles["disabled"] : ""
                    }`}
                    onClick={() => {
                      if (confirmationResultSMS) {
                        handleVerifyCodeSMS();
                      } else {
                        handleSendCode();
                      }
                    }}
                  >
                    <span className={styles["text07"]}>
                      <span>
                        {verificandoTelefone ? "Aguarde..." : "Verificar"}
                      </span>
                    </span>
                    {verificandoTelefone && <div className="spinner"></div>}
                  </button>
                )}
              </div>
              <div className={styles["verificar-email"]}>
                <div
                  style={{
                    width: "100%",
                    display: confirmationResultEmail ? "block" : "none",
                  }}
                >
                  <Input
                    Label="Código de verificação"
                    Placeholder="Insira o código recebido via Email"
                    value={verificationCodeEmail}
                    onChange={setVerificationCodeEmail}
                    mask={null}
                  ></Input>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: !confirmationResultEmail ? "block" : "none",
                  }}
                >
                  <Input
                    Label="E-mail"
                    value={email}
                    onChange={setEmail}
                  ></Input>
                </div>
                {emailVerified ? ( //usuario?.emailVerificado
                  <div className={styles["verificado1"]}>
                    <span className={styles["text13"]}>
                      <span>Verificado</span>
                    </span>
                    <img
                      alt="aprovado3811"
                      src="/external/aprovado3811-a99k.svg"
                      className={styles["aprovado1"]}
                    />
                  </div>
                ) : (
                  <button
                    disabled={verificandoEmail}
                    className={`${styles["botao-verificar"]} ${
                      verificandoEmail ? styles["disabled"] : ""
                    }`}
                    onClick={() => {
                      if (confirmationResultEmail) {
                        handleVerifyCodeEmail();
                      } else {
                        sendVerificationEmail();
                      }
                    }}
                  >
                    <span className={styles["text11"]}>
                      <span>
                        {verificandoEmail ? "Aguarde..." : "Verificar"}
                      </span>
                    </span>
                    {verificandoEmail && <div className="spinner"></div>}
                  </button>
                )}
              </div>
            </div>
            <button
              className={styles["botao-alterar-senha"]}
              onClick={() => {
                setPopupAlterarSenhaVisible(true);
              }}
            >
              <span className={styles["text15"]}>
                <span>Alterar senha</span>
              </span>
              <img
                alt="chaveI381"
                src="/external/chavei381-rh4b.svg"
                className={styles["chave"]}
              />
            </button>
          </div>
        </div>
      </div>
      <Rodape></Rodape>
    </div>
  );
};

export default MinhaConta;
