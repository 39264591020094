import React, {
  useState,
  useRef,
  useEffect,
  useReducer,
  useCallback,
} from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAnuncioData } from "../../../redux/actions/anuncioActions";

import { compareTwoStrings, findBestMatch } from "string-similarity";
import { toast } from "react-toastify";
import { Player } from "video-react";
import "video-react/dist/video-react.css";

import Header from "../../../components/Header/header";
import MenuLogado from "../../../components/MenuLogado/menu-logado";
import Toggle from "../../../components/toggle";
import Input from "../../../components/Input/input";
import Radio from "../../../components/Radio/radio";
import Inspiracao from "../../../components/inspiracao";
import Rodape from "../../../components/Rodape/rodape";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import PopupAviso from "../../../components/PopupAviso/popup-aviso";
import styles from "./meu-anuncio.module.css";
import Loader from "../../../components/Loader/loader";

import axios from "axios";
import { fetchLocationById } from "../../../services/server/locations";
import { getMunicipios } from "../../../services/brasilApi";
import { capitalizeWords, formatCurrency } from "../../../utils";
import { setUsuarioData } from "../../../redux/actions/usuarioActions";
import Dropdown from "react-bootstrap/Dropdown";

import { uploadToS3 } from "../../../services/s3";
import { searchMunicipios } from "../../../services/brasilApi";
import YoutubeEmbed from "../../../components/YoutubeEmbed/YoutubeEmbed";
import PopupLinkYoutube from "../../../components/PopupEnviarLinkYoutube/popup-link-youtube";

const SortableVideo = SortableElement(({ value, index, deletarItem, idx }) => {
  return (
    <div className={styles["video-enviado"]}>
      {/* <Player className={styles["video"]} src={value.url}></Player> */}
      <YoutubeEmbed className={styles["video"]} src={value.url}></YoutubeEmbed>
      <button className={styles["botao-deletar1"]} onClick={deletarItem} />
    </div>
  );
});

const SortableImage = SortableElement(({ value, index, deletarItem }) => {
  const handleClickIncon = (e) => {
    e.stopPropagation();
    toast.success("clicou icon");
  };
  return (
    <div className={styles["foto"]}>
      <img
        src={value.url}
        alt={`Foto ${index + 1}`}
        className={styles["foto-galeria"]}
      />
      <button className={styles["botao-deletar1"]} onClick={deletarItem} />
    </div>
  );
});

const Container = SortableContainer(({ children }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
      {children}
    </div>
  );
});

const limitesPlanos = {
  "Plano Free": {
    nome: "Plano Free",
    servicos: 1,
    online: false,
    avancado: false,
    idiomas: 1,
    redes: [],
    galeria: {
      fotos: 4,
      videos: 0,
    },
    apoioNutricional: false,
    urlPersonalizada: false,
  },
  "Plano Aquecimento": {
    nome: "Plano Aquecimento",
    servicos: 4,
    online: true,
    avancado: true,
    idiomas: 2,
    redes: ["linkedin", "tiktok", "website"],
    galeria: {
      fotos: 6,
      videos: 2,
    },
    apoioNutricional: false,
    urlPersonalizada: true,
  },
  "Plano Elite": {
    nome: "Plano Elite",
    servicos: 8,
    online: true,
    avancado: true,
    idiomas: 4,
    redes: ["instagram", "facebook", "tiktok", "linkedin", "website"],
    galeria: {
      fotos: 20,
      videos: 8,
    },
    apoioNutricional: true,
    urlPersonalizada: true,
  },
};

const idiomas = [
  "Africâner",
  "Aimará",
  "Akan",
  "Albanês",
  "Alemão",
  "Amárico",
  "Árabe",
  "Armênio",
  "Assamês",
  "Azerbaijano",
  "Bambara",
  "Basco",
  "Bengali",
  "Bhojpuri",
  "Bielorrusso",
  "Birmanês",
  "Bósnio",
  "Búlgaro",
  "Canarim",
  "Catalão",
  "Cazaque",
  "Cebuano",
  "Chinês",
  "Cingalês",
  "Coreano",
  "Corso",
  "Croata",
  "Curdo",
  "Curdo central",
  "Dinamarquês",
  "Divehi",
  "Dogri",
  "Eslovaco",
  "Esloveno",
  "Espanhol",
  "Esperanto",
  "Estoniano",
  "Ewe",
  "Filipino",
  "Finlandês",
  "Francês",
  "Frísio ocidental",
  "Gaélico escocês",
  "Galego",
  "Galês",
  "Georgiano",
  "Goan Konkani",
  "Grego",
  "Guarani",
  "Guzerate",
  "Haitiano",
  "Hauçá",
  "Havaiano",
  "Hebraico",
  "Híndi",
  "Hmong",
  "Holandês",
  "Húngaro",
  "Igbo",
  "Iídiche",
  "Ilocano",
  "Indonésio",
  "Inglês",
  "Iorubá",
  "Irlandês",
  "Islandês",
  "Italiano",
  "Japonês",
  "Javanês",
  "Khmer",
  "Krio",
  "Laosiano",
  "Latim",
  "Letão",
  "Lingala",
  "Lituano",
  "Luganda",
  "Lushai",
  "Luxemburguês",
  "Macedônio",
  "Maithili",
  "Malaiala",
  "Malaio",
  "Malgaxe",
  "Maltês",
  "Manipuri",
  "Maori",
  "Marati",
  "Mongol",
  "Nepalês",
  "Nianja",
  "Norueguês",
  "Oriá",
  "Oromo",
  "Panjabi",
  "Pashto",
  "Persa",
  "Polonês",
  "Português",
  "Quíchua",
  "Quiniaruanda",
  "Quirguiz",
  "Romeno",
  "Russo",
  "Samoano",
  "Sânscrito",
  "Sérvio",
  "Sindi",
  "Somali",
  "Soto do sul",
  "Soto setentrional",
  "Suaíli",
  "Sueco",
  "Sundanês",
  "Tadjique",
  "Tailandês",
  "Tâmil",
  "Tártaro",
  "Tcheco",
  "Télugo",
  "Tigrínia",
  "Tsonga",
  "Turco",
  "Turcomeno",
  "Ucraniano",
  "Uigur",
  "Urdu",
  "Uzbeque",
  "Vietnamita",
  "Xhosa",
  "Xona",
  "Zulu",
];

const ufs = [
  { state: "AC", region: "Norte" },
  { state: "AL", region: "Nordeste" },
  { state: "AM", region: "Norte" },
  { state: "AP", region: "Norte" },
  { state: "BA", region: "Nordeste" },
  { state: "CE", region: "Nordeste" },
  { state: "DF", region: "Centro-Oeste" },
  { state: "ES", region: "Sudeste" },
  { state: "GO", region: "Centro-Oeste" },
  { state: "MA", region: "Nordeste" },
  { state: "MG", region: "Sudeste" },
  { state: "MS", region: "Centro-Oeste" },
  { state: "MT", region: "Centro-Oeste" },
  { state: "PA", region: "Norte" },
  { state: "PB", region: "Nordeste" },
  { state: "PE", region: "Nordeste" },
  { state: "PI", region: "Nordeste" },
  { state: "PR", region: "Sul" },
  { state: "RJ", region: "Sudeste" },
  { state: "RN", region: "Nordeste" },
  { state: "RO", region: "Norte" },
  { state: "RR", region: "Norte" },
  { state: "RS", region: "Sul" },
  { state: "SC", region: "Sul" },
  { state: "SE", region: "Nordeste" },
  { state: "SP", region: "Sudeste" },
  { state: "TO", region: "Norte" },
];

const MeuAnuncio = (props) => {
  const anuncioState = useSelector((state) => state.anuncio);
  const usuario = useSelector((state) => state.usuario);
  const username =
    usuario.fullName
      .toLowerCase()
      .replaceAll(" ", "-")
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .slice(0, 24) +
    "-" +
    Math.floor(Math.random() * (9999 - 1000) + 1000);

  const history = useHistory();
  const dispatch = useDispatch();
  const [anuncio, setAnuncio] = useState(
    usuario.trainerAd ||
    JSON.parse(sessionStorage.getItem("anuncio_rascunho")) || {
      status: null,
      username: username,
      photo: null,
      personalName: null,
      gender: null,
      briefDescription: null,
      biography: null,
      qualifications: null,
      socialNetworks: {
        instagram: null,
        facebook: null,
        tiktok: null,
        linkedin: null,
        website: null,
      },
      services: [],
      methods: null,
      appointment: {
        type: [],
        genderPreference: [],
        ageGroup: [],
        skillLevel: [],
        locationType: [],
        availableDays: { monday: false },
        availableTime: { morning: false, afternoon: false, evening: false },
      },
      pricing: {
        sessionDetails: null,
        online: null,
        inPerson: null,
      },
      media: [],
      languages: ["Português"],
      location: null,
    }
  );
  const [services, setServices] = useState([]);
  const [idioma, setIdioma] = useState("");
  const inputFileRef = useRef(null);
  const inputGaleriaRef = useRef(null);
  const inputVideoRef = useRef(null);
  const [options, setOptions] = useState([]);
  const [location, setLocation] = useState({ municipality: "", state: "" });
  const [cidadeDigitada, setCidadeDigitada] = useState("")
  const [open, setOpen] = useState(false);
  const [plans, setPlans] = useState(null);
  const [userPlan, setUserPlan] = useState({
    _id: "65cf63dd8eecf42f262885b1",
    name: "Plano Free",
    price: {
      quarterly: 0,
      semiAnnually: 0,
      annually: 0,
    },
    pricePromotion: {
      quarterly: 0,
      semiAnnually: 0,
      annually: 0,
    },
    adPosition: 3,
    verifiedContact: false,
    inPersonService: true,
    onlineService: false,
    trainingCategoriesLimit: 1,
    languagesLimit: 1,
    socialLinks: [],
    galleryPhotosLimit: 4,
    galleryVideosLimit: 0,
    featuredAd: false,
    premiumServices: false,
    exclusivePage: false,
    __v: 0,
  });
  const [videos, setVideos] = useState([]);
  const [images, setImages] = useState([]);
  const [isReproved, setIsReproved] = useState(anuncio.status === "Reprovado");

  const [collections, setCollections] = useState([[], []]);

  const [showLoader, setShowLoader] = useState(false);
  const [showPopUpYoutube, setShowPopUpYoutube] = useState(false)

  const toggleCategoria = (categoriaTitle, categoriaId) => {
    const listaPlanosComApoioNutricional = plans
      .filter((plan) => limitesPlanos[plan.name]?.apoioNutricional)
      .map((plan) => plan.name);

    //retirar da lista de serviços se for desmarcar
    if (anuncio.services.includes(categoriaId))
      return setAnuncio({
        ...anuncio,
        services: anuncio.services.filter((servico) => servico !== categoriaId),
      });

    //checar se é apoio nutricional e, se for, só permitir se for um plano que permita
    if (
      categoriaId === "000000010000000000000000" &&
      !limitesPlanos[userPlan.name]?.apoioNutricional
    )
      return toast.error(
        `Opção disponível apenas para assinantes do${listaPlanosComApoioNutricional.length > 1 ? "s" : ""
        } plano${listaPlanosComApoioNutricional.length > 1 ? "s" : ""
        } ${listaPlanosComApoioNutricional.join(
          `${listaPlanosComApoioNutricional.length === 2 ? " e " : ","}`
        )}`
      );

    //checar numero de serviços e negar adição se for acima do permitido
    const numServicesAtual = anuncio.services.length;
    const limitServices = userPlan?.trainingCategoriesLimit;

    if (numServicesAtual >= limitServices)
      return toast.error(
        `Você só pode selecionar ${limitesPlanos[userPlan.name]?.servicos
        } serviços no Plano ${userPlan.name}`
      );

    //adicionar serviço
    const updatedServicos = [...anuncio.services, categoriaId];

    setAnuncio({ ...anuncio, services: updatedServicos });
  };

  const toggleAnuncioStatus = async (newToggleValue) => {
    if (anuncio.status !== "Aprovado") return;
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/api/trainer-ads/toggleActive`,
        {
          isActive: newToggleValue,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("ptr_145769130149")}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success("Anúncio atualizado com sucesso!");
        dispatch(
          setAnuncioData({
            ...anuncioState,
            isActive: response?.data?.data.isActive,
          })
        );
      }
    } catch (error) {
      console.error(error);
      if (error.response.data.message)
        return toast.error(error.response.data.message);
      if (error.response.data.errors) {
        let errorMessage =
          error.response.data.errors[
          Object.keys(error.response.data.errors)[0]
          ];
        toast.error(errorMessage);
      } else {
        toast.error("Ocorreu um erro ao atualizar o anúncio.");
      }
    }
  };

  const fetchServices = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/trainer-services`
      );

      if (response.status === 200) {
        setServices(response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const uploadItem = async (event, isProfilePhoto) => {
    setShowLoader(true);

    try {
      if (event.target.files.length == 0) {
        throw new Error("Nenhum arquivo selecionado.");
      }

      const file = event.target.files[0];
      const type = file.type.split("/")[0];
      const planosGaleria = [];

      if (!isProfilePhoto) {
        if (
          (type == "video" && file.size / 1024 / 1024 > 16) ||
          (type == "image" && file.size / 1024 / 1024 > 2)
        ) {
          throw new Error("Arquivo muito grande.");
        }
      }

      const plano = limitesPlanos[userPlan.name];
      for (const nomePlano in limitesPlanos) {
        if (
          limitesPlanos.hasOwnProperty(nomePlano) &&
          limitesPlanos[nomePlano].galeria.fotos === images.length
        ) {
          planosGaleria.push(limitesPlanos[nomePlano].nome);
        }
      }

      if (userPlan.galleryPhotosLimit <= images.length) {
        inputGaleriaRef.current.value = "";
        if (type === "image" || !type) {
          toast.error(
            `Você ${userPlan.galleryPhotosLimit === 0
              ? "não pode adicionar"
              : "só pode adicionar " + userPlan.galleryPhotosLimit
            } fotos no ${userPlan.name}`
          );
        } else {
          toast.error(
            `Você ${userPlan.galleryVideosLimit === 0
              ? "não pode adicionar"
              : "só pode adicionar " + userPlan.galeryVideosLimit
            } vídeos no ${userPlan.name}`
          );
        }
      } else {
        try {
          var fileUrl = await uploadToS3(file);

          if (fileUrl) {
            if (isProfilePhoto) {
              setAnuncio({
                ...anuncio,
                photo: fileUrl,
              });
            } else {
              setAnuncio({
                ...anuncio,
                media: [
                  ...anuncio.media,
                  {
                    type: type,
                    url: fileUrl,
                  },
                ],
              });
            }
          }
        } catch (error) {
          throw new Error("O upload do arquivo deu errado");
        }
      }
    } catch (error) {
      console.error(error.message);
      return toast.error(error.message);
    } finally {
      setShowLoader(false);
    }
  };

  const addIdioma = (paramIdioma) => {
    //encontrar idioma na lista de idiomas
    const trimmedInput = paramIdioma.trim().toLocaleLowerCase();

    if (trimmedInput === "") {
      toast.error("Digite um idioma válido.");
      return;
    }

    const matches = findBestMatch(
      trimmedInput,
      idiomas.map((i) => i.toLowerCase())
    );

    if (matches.bestMatch.rating < 0.5) {
      toast.error(
        "Idioma não reconhecido. Tente digitar o idioma mais próximo."
      );
      return;
    }

    const closestLanguage = idiomas[matches.bestMatchIndex];

    //limpar input idioma
    setIdioma("");

    //adicionar idioma caso já não esteja na lista de idiomas do cliente e o plano permita
    if (!anuncio.languages.includes(closestLanguage)) {
      const numberOfLanguagesAd = anuncio.languages.length;
      const limitOfLanguagesPlan = limitesPlanos[userPlan?.name]?.idiomas;
      if (numberOfLanguagesAd >= limitOfLanguagesPlan)
        return toast.error(
          `Você só pode adicionar ${limitOfLanguagesPlan} idioma${limitOfLanguagesPlan > 1 ? "s" : ""
          } no plano ${userPlan?.name}`
        );

      setAnuncio({
        ...anuncio,
        languages: [...anuncio.languages, closestLanguage],
      });
    }
  };

  const deletarItem = (itemType, index) => {
    if (itemType === "image") {
      const newImages = [...collections[1]];
      newImages.splice(index, 1);
      setAnuncio({
        ...anuncio,
        media: [...collections[0], ...newImages],
      });
    } else {
      const newVideos = [...collections[0]];
      const deletedVideo = newVideos.splice(index, 1);
      console.log(deletedVideo, index)
      setAnuncio({
        ...anuncio,
        media: [...newVideos, ...collections[1]],
      });
    }
  };

  const publicarAnuncio = async () => {
    if (usuario.cpf && usuario.address) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/trainer-ads`,
          anuncio,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                "ptr_145769130149"
              )}`,
            },
          }
        );

        if (response.status === 201) {
          sessionStorage.setItem("is_anuncio_rascunho", false);
          toast.success("Anúncio criado! Aguarde a confirmação de um admin.");
          if (response?.data?.data)
            dispatch(
              setUsuarioData({ ...usuario, trainerAd: response.data.data })
            );

          window.scrollTo(0, 0);
          if (response?.data?.data) {
            dispatch(setAnuncioData(response.data.data));
          }
        }

        if (response.status === 400) {
          throw new Error();
        }
      } catch (error) {
        console.error(error);
        let errorMessage =
          error.response.data.errors[
          Object.keys(error.response.data.errors)[0]
          ];
        if (errorMessage && typeof errorMessage === "string")
          return toast.error(errorMessage);
        toast.error("Erro ao publicar anúncio!");
      }
    } else {
      history.push("minha-conta");
      toast.error(
        "Preencha as informações da sua conta antes de publicar um anúncio!"
      );
    }
  };

  const updateAd = async () => {
    if (usuario.cpf && usuario.address) {
      try {
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/api/trainer-ads`,
          anuncio,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem(
                "ptr_145769130149"
              )}`,
            },
          }
        );
        if (response?.data?.data)
          dispatch(
            setUsuarioData({ ...usuario, trainerAd: response.data.data })
          );
        toast.success("Anúncio atualizado. Aguarde a aprovação de um admin!");
        window.scrollTo(0, 0);
      } catch (error) {
        console.error(error);
        if (error.response.data.errors?.length > 0 && error?.response?.data?.errors[0]) {
          let errorMessage =
            error.response?.data?.errors[0][
            Object.keys(error.response.data.errors[0])[0]
            ];
          return toast.error(errorMessage);
        }

        if (error?.response?.data?.errors?.media)
          return toast.error(error?.response?.data?.errors?.media);

        if (error?.response?.data?.message) return toast.error(error.response.data.message)
      }
    } else {
      history.push("minha-conta");
      toast.error(
        "Preencha as informações da sua conta antes de publicar um anúncio!"
      );
    }
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const getLocation = async (id) => {
    // console.log("Recuperando localização: " + id)
    if (!id || typeof id != "string") {
      return { municipality: "", state: "" }
    }
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/location/${id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("ptr_145769130149")}`,
        },
      }
    );
    if (response.status == 200) {
      // console.log("Localização: ", response.data.data);
      return response.data.data
    } else {
      return { municipality: "", state: "" }
    }
  };

  // Efeito para carregar os tipos de serviço do personal
  useEffect(() => {
    if (services.length === 0) {
      (async function () {
        await fetchServices();
      })();
    }
  }, []);

  useEffect(() => {
    if (anuncioState) {
      setAnuncio({
        ...anuncio,
        ...anuncioState,
      });
    }
    separeteMedia();
  }, [anuncioState]);

  useEffect(() => {
    separeteMedia();
    // Definir como editado
    sessionStorage.setItem("anuncio_rascunho", JSON.stringify(anuncio));
    sessionStorage.setItem("is_anuncio_rascunho", true);
  }, [anuncio]);

  // Efeito para carregar informações dos planos de assinatura
  useEffect(() => {
    (async function () {
      if (plans === null) {
        await fetchPlans();
      }
    })();
  }, [plans]);

  // Efeito para carregar o plano do usuário
  useEffect(() => {
    getUserPlan();
  }, [plans]);

  // Efeito para carregar as cidades do dropdown e inicial state
  useEffect(async () => {
    console.log("anuncio.location", anuncio?.location)
    // window.teste = anuncio;
    let locationObject = await getLocation(anuncio?.location);
    setLocation(locationObject);
    setOptions(await searchMunicipios(""));
    setAnuncio({...anuncio, location: locationObject});
    // console.log("anuncio: ", anuncio);
  }, [])

  const fetchPlans = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/plans/plans`
      );
      setPlans(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getUserPlan = () => {
    if (plans && usuario.userPlan) {
      for (let p of plans) {
        if (p._id === usuario.userPlan.planId) {
          setUserPlan(p);
        }
      }
    }
  };

  const onDragEnd = ({ oldIndex, newIndex, collection }) => {
    const cl = [...collections];

    const items = reorder(collections[collection], oldIndex, newIndex);
    cl[collection] = items;
    if (collection === 0) {
      setAnuncio({
        ...anuncio,
        media: [...items, ...collections[1]],
      });
    } else {
      setAnuncio({
        ...anuncio,
        media: [...collections[0], ...items],
      });
    }
  };

  const separeteMedia = () => {
    const images = [];
    const videos = [];

    for (let m of anuncio.media) {
      if (m.type === "video") {
        videos.push(m);
      } else {
        images.push(m);
      }
    }

    setCollections([videos, images]);
  };

  function isInputRedesDisabled(rede) {
    {
      const planosArray = [];

      for (const nomePlano in limitesPlanos) {
        if (
          limitesPlanos.hasOwnProperty(nomePlano) &&
          limitesPlanos[nomePlano].redes.includes(rede)
        ) {
          planosArray.push(limitesPlanos[nomePlano].nome);
        }
      }

      if (planosArray.length > 0 && !planosArray.includes(userPlan.name)) {
        return `Opção disponível apenas para assinantes do${planosArray.length > 1 ? "s" : ""
          } plano${planosArray.length > 1 ? "s" : ""} ${planosArray.join(
            `${planosArray.length === 2 ? " e " : ","}`
          )}`;
      } else {
        return false;
      }
    }
  }

  //efeito para encaminhar para dados da conta caso ainda não tenha cadastrado
  useEffect(() => {
    if (!usuario.cpf || !usuario.address) {
      history.push("minha-conta");
      toast.error(
        "Preencha as informações da sua conta antes de publicar um anúncio!"
      );
    }
  }, []);

  const handleSendYTLink = (link) => {
    const getEmbedLink = (pLink) => {
      //checar se o link já é de embed video
      const regexIsEmbed = /^(https?:\/\/)?(www\.)?(youtube\.com\/embed\/|youtu\.be\/)/;
      const linkIsEmbedLink = regexIsEmbed.test(pLink)
      if (linkIsEmbedLink) return pLink

      //achar id do video para criar link de embed
      const regexFindVideoId = /[?&]v=([^&]+)/;
      const match = regexFindVideoId.exec(pLink);
      const videoId = match ? match[1] : null;
      if (videoId) return `https://www.youtube.com/embed/${videoId}`

      //se não conseguiu gerar link, retorna null
      return null
    }

    try {
      const embedLink = getEmbedLink(link)
      if (embedLink) {
        setAnuncio({
          ...anuncio,
          media: [
            ...anuncio.media,
            {
              type: 'video',
              url: embedLink,
            },
          ],
        });
      } else {
        toast.error('Link Inválido')
      }
      
    } catch (error) {
      toast.error(error)
    }

    setShowPopUpYoutube(false)
  }

  return (
    <div className={styles["container"]}>
      <Header />
      {showLoader ? <Loader /> : null}
      {showPopUpYoutube && <PopupLinkYoutube
        closePopup={() => setShowPopUpYoutube(false)}
        handleSendYTLink={handleSendYTLink}
      />}
      <div className={styles["container1"]}>
        {window.innerWidth > 991 && <MenuLogado></MenuLogado>}
        <div className={styles["container2"]}>
          {isReproved && (
            <PopupAviso
              rejectionReason={anuncio.rejectionReason}
              closePopup={() => setIsReproved(false)}
            />
          )}
          <div className={styles["cabecalho"]}>
            <span className={styles["text"]}>
              <span className={styles["text001"]}>Meu anúncio</span>
              <br></br>
            </span>
            <div className={styles["botoes"]}>
              {userPlan?.name === "Plano Free" && (
                <Link to="/painel/planos" className={styles["navlink"]}>
                  <div className={styles["botao-planos"]}>
                    <span className={styles["text003"]}>
                      <span>
                        Confira nossos planos
                        <span
                          dangerouslySetInnerHTML={{
                            __html: " ",
                          }}
                        />
                      </span>
                      <span className={styles["text005"]}>Premium</span>
                    </span>
                    <img
                      alt="Vector4125"
                      src="/external/vector4125-f5yi.svg"
                      className={styles["crownicon"]}
                    />
                  </div>
                </Link>
              )}
              <button
                className={styles["botao-visao-aluno"]}
                onClick={() => {
                  try {
                    if (usuario.trainerAd) {
                      if (anuncio.status !== "Aprovado")
                        throw new Error(
                          "Aguarde um administrador aprovar seu anúncio!"
                        );
                      if (!anuncio.isActive)
                        toast.info(
                          "O anúncio está inativo e não poderá ser visto por alunos até você ativá-lo"
                        );
                      history.push("/personal/" + anuncio.username);
                    } else {
                      toast.error(
                        "Aguarde um administrador aprovar seu anúncio!"
                      );
                    }
                  } catch (error) {
                    console.error(error);
                    if (error.message) return toast.error(error.message);
                    toast.error(
                      "Erro ao carregar seu anúncio. Por favor contate um admnistrador"
                    );
                  }
                }}
              >
                <span className={styles["text006"]}>
                  <span>Ver como aluno</span>
                </span>
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-m9v-200h.png"
                  className={styles["viewicon"]}
                />
              </button>
              <div
                className={`${styles["anuncio-status"]} ${anuncio.status !== "Aprovado" ? styles["togle-disabled"] : ""
                  }`}
              >
                <span className={`${styles["text008"]}`}>
                  <span>Anúncio ativo</span>
                </span>
                <Toggle
                  value={anuncio.isActive}
                  disabled={!["ativo", "inativo"].includes(anuncio.isActive)}
                  onChange={toggleAnuncioStatus}
                ></Toggle>
                <img
                  alt="trocar4587"
                  src="/external/trocar4587-bbam.svg"
                  className={styles["analiseicon"]}
                />
              </div>
            </div>
          </div>
          <div className={styles["conteudo"]}>
            <div className={styles["perfil"]}>
              <span className={styles["text010"]}>
                <span>Perfil</span>
              </span>
              <div
                className={styles["foto-perfil"]}
                onClick={() => {
                  inputFileRef.current.click();
                }}
              >
                <div className={styles["sombra"]}></div>
                <img
                  alt="pastedImage"
                  src={`${anuncio.photo
                      ? anuncio.photo
                      : "/external/pastedimage-bzsx-300h.png"
                    }`}
                  className={styles["imgpersonal"]}
                />
                <img
                  alt="Vector3781"
                  src="/external/vector3781-4bvj.svg"
                  className={styles["editaricon"]}
                />
                <input
                  type="file"
                  accept="image/jpeg, image/png"
                  ref={inputFileRef}
                  style={{ display: "none" }}
                  onChange={(event) => {
                    uploadItem(event, true);
                  }}
                />
              </div>
              <div className={styles["nome-denominacao"]}>
                <div className={styles["link-perfil-container"]}>
                  <Input
                    Label="Nome de usuário (Link do seu perfil)"
                    value={anuncio?.username}
                    className={
                      !limitesPlanos[userPlan?.name]?.urlPersonalizada
                        ? styles["social-premium"]
                        : styles["input"]
                    }
                    onChange={(v) => {
                      setAnuncio({
                        ...anuncio,
                        username: v,
                      });
                    }}
                    disabled={() => {
                      const planoUrlPersonalizada = [];
                      for (const nomePlano in limitesPlanos) {
                        if (
                          limitesPlanos.hasOwnProperty(nomePlano) &&
                          limitesPlanos[nomePlano].urlPersonalizada
                        ) {
                          planoUrlPersonalizada.push(
                            limitesPlanos[nomePlano].nome
                          );
                        }
                      }
                      if (
                        planoUrlPersonalizada.length > 0 &&
                        !planoUrlPersonalizada.includes(userPlan.name)
                      ) {
                        return `Opção customizável apenas para assinantes do${planoUrlPersonalizada.length > 1 ? "s" : ""
                          } plano${planoUrlPersonalizada.length > 1 ? "s" : ""
                          } ${planoUrlPersonalizada.join(
                            `${planoUrlPersonalizada.length === 2 ? " e " : ","}`
                          )}`;
                      } else {
                        return false;
                      }
                    }}
                  ></Input>
                  {!limitesPlanos[userPlan?.name]?.redes?.includes(
                    "facebook"
                  ) ? (
                    <Link to="/painel/planos" className={styles["navlink"]}>
                      <img
                        alt="Vector4125"
                        src="/external/vector4125-f5yi.svg"
                        className={styles["social-icon"]}
                        style={{ marginTop: "32px" }}
                      />
                    </Link>
                  ) : undefined}
                </div>

                <Input
                  Label="Nome do personal"
                  value={anuncio.personalName}
                  onChange={(v) => setAnuncio({ ...anuncio, personalName: v })}
                  Placeholder="Gilberto Medeiros - Kiko"
                ></Input>
                <div className={styles["container_cidade_uf"]}>
                  <Input
                    getOptionLabel={(item) => !item.state || item.state == "" ? "" : item.municipality + " - " + item.state}
                    // groupBy={(item) => item.state}
                    isOptionEqualToValue={(item) => item.municipality}
                    Label="Cidade"
                    open={open}
                    onOpen={() => {
                      setOpen(true);
                    }}
                    onClose={() => {
                      setOpen(false);
                    }}
                    options={options}
                    noOptionsText="Cidade não encontrada para esta busca"
                    value={location || { municipality: "", state: "" }}
                    onInputChange={async (e, v) => {
                      setOptions(await searchMunicipios(v));
                    }}
                    onBlur={(e, v) => {

                    }}
                    onChange={(e, v) => {
                      setLocation(v ?? { municipality: "", state: "" });
                      anuncio.location = {
                        municipality: v?.municipality,
                        state: v?.state,
                        region: v?.region
                      }
                      console.log("location: ", v);
                    }}
                    placeholder="Digite o nome da sua cidade e selecione-a da lista..."
                    tipo={5}
                  ></Input>
                </div>
                {false && (
                  <div className={styles["denominacao"]}>
                    <span className={styles["text012"]}>
                      <span>Denominação</span>
                    </span>
                    <div className={styles["dropdown"]}>
                      <span className={styles["text014"]}>
                        <span>Personal trainer</span>
                      </span>
                      <img
                        alt="Group11712767583781"
                        src="/external/group11712767583781-mxzy.svg"
                        className={styles["arrowicon"]}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className={styles["genero"]}>
                <span className={styles["text016"]}>
                  <span>Seu sexo</span>
                </span>
                <div className={styles["opcoes"]}>
                  <div
                    className={styles["masculino"]}
                    onClick={() => {
                      const genero =
                        anuncio.gender === "Masculino" ? "" : "Masculino";

                      setAnuncio({
                        ...anuncio,
                        gender: genero,
                      });
                    }}
                  >
                    <Radio valor={anuncio.gender === "Masculino"}></Radio>
                    <span className={styles["text018"]}>
                      <span>Masculino</span>
                    </span>
                  </div>
                  <div
                    className={styles["feminino"]}
                    onClick={() => {
                      const genero =
                        anuncio.gender === "Feminino" ? "" : "Feminino";

                      setAnuncio({
                        ...anuncio,
                        gender: genero,
                      });
                    }}
                  >
                    <Radio valor={anuncio.gender === "Feminino"}></Radio>
                    <span className={styles["text020"]}>
                      <span>Feminino</span>
                    </span>
                  </div>
                  {/* <div
                    className={styles["outro"]}
                    onClick={() => {
                      const genero = anuncio.gender === "Outro" ? "" : "Outro";

                      setAnuncio({
                        ...anuncio,
                        gender: genero,
                      });
                    }}
                  >
                    <Radio valor={anuncio.gender === "Outro"}></Radio>
                    <span className={styles["text022"]}>
                      <span>Outro</span>
                    </span>
                  </div> */}
                </div>
              </div>
              <div className={styles["descrio"]}>
                <Input
                  Label="Descrição breve"
                  Limite={190}
                  tipo={2}
                  bloquearQuebraLinha={true}
                  value={anuncio.briefDescription}
                  onChange={(v) =>
                    setAnuncio({ ...anuncio, briefDescription: v })
                  }
                  TextAreaPlaceholder="Apresente sua paixão e experiência, conectando-se genuinamente com quem busca seus serviços."
                ></Input>
                <Inspiracao
                  TextTopico1='"Juntos, transformamos sua rotina e conquistamos seus objetivos. Seja perder peso, ganhar músculo ou aumentar a saúde. Estou aqui para orientá-lo em cada passo!"'
                  TextTopico2='"Seja em parques, academias ou no conforto do seu lar, estou comprometido a criar o treino perfeito para você. Perca peso, ganhe músculo, sinta-se incrível!"'
                  TextTopico3='"Priorizando sua saúde e bem-estar. A jornada para o melhor de você começa aqui! Treinos adaptados, foco no seu objetivo e horários flexíveis. Vamos juntos?"'
                ></Inspiracao>
              </div>
              <div className={styles["biografia"]}>
                <Input
                  Label="Biografia"
                  Limite={750}
                  tipo={2}
                  value={anuncio.biography}
                  bloquearQuebraLinha={true}
                  onChange={(v) => setAnuncio({ ...anuncio, biography: v })}
                  TextAreaPlaceholder="A biografia de um personal trainer é uma ferramenta vital para estabelecer uma conexão com potenciais clientes e destacar o que o diferencia dos demais profissionais."
                ></Input>
                <Inspiracao
                  TextTopico1='”Com mais de 10 anos de experiência em fitness, sou formado em Educação Física pela Universidade X e possuo certificações internacionais em treinamento funcional e HIIT. Meu objetivo é garantir que você alcance seus resultados de forma segura e eficaz. Meus treinos são baseados em evidências científicas e sempre atualizados."'
                  TextTopico2='"Minha missão é inspirar e transformar vidas. Não acredito apenas em treinos, mas em mudar mentalidades. Ao longo de minha jornada, ajudei mais de 500 pessoas a redescobrir seu amor por atividades físicas. Juntos, nós podemos alcançar qualquer meta que você se propuser."'
                  TextTopico3='"Especialista em reabilitação e condicionamento físico, dedico-me a ajudar pessoas na recuperação de lesões e melhora da qualidade de vida. Com uma abordagem holística e focada na individualidade de cada cliente, procuro oferecer o melhor para reconstruir a confiança no próprio corpo."'
                ></Inspiracao>
              </div>
              <div className={styles["qualificacoes"]}>
                <Input
                  Label="Qualificações"
                  Limite={750}
                  tipo={2}
                  value={anuncio.qualifications}
                  bloquearQuebraLinha={true}
                  onChange={(v) =>
                    setAnuncio({ ...anuncio, qualifications: v })
                  }
                  TextAreaPlaceholder="Descreva aqui suas formações e experiências profissionais."
                ></Input>
                <Inspiracao
                  TextTopico1='"Graduado em Educação Física pela Universidade X (2015). Mestre em Biomecânica pela Universidade Y (2018). Participação em 5 congressos nacionais e 3 internacionais. Autor de artigos sobre treinamento de força."'
                  TextTopico2='"Certificado em Treinamento Funcional pelo Instituto Z (2017). Instrutor de Pilates Nível II (2019). Especialização em HIIT pela Academia W (2020). Curso de aprimoramento em Yoga e Mindfulness (2021)."'
                  TextTopico3='"Ex-atleta de alto rendimento em corrida de média distância. Graduado em Educação Física com ênfase em Esportes (2016). Certificação em Treinamento de Performance pela Associação Q. Coach de maratonas e triatlos."'
                ></Inspiracao>
              </div>
              <div className={styles["redes-sociais"]}>
                <span className={styles["text024"]}>
                  <span>Suas redes</span>
                </span>
                <div className={styles["inputs"]}>
                  <div className={styles["insta"]}>
                    <img
                      alt="image1303781"
                      src="/external/image1303781-mnuo-200h.png"
                      className={styles["instaimg"]}
                    />
                    <Input
                      className={
                        !limitesPlanos[userPlan?.name]?.redes?.includes(
                          "instagram"
                        )
                          ? styles["social-premium"]
                          : styles["input"]
                      }
                      pre="https://www.instagram.com/"
                      restringir="true"
                      value={anuncio?.socialNetworks?.instagram}
                      disabled={() => isInputRedesDisabled("instagram")}
                      onChange={(v) =>
                        setAnuncio({
                          ...anuncio,
                          socialNetworks: {
                            ...anuncio.socialNetworks,
                            instagram: v,
                          },
                        })
                      }
                    ></Input>
                    {!limitesPlanos[userPlan?.name]?.redes?.includes(
                      "instagram"
                    ) && (
                        <Link to="/painel/planos" className={styles["navlink"]}>
                          <img
                            alt="Vector4125"
                            src="/external/vector4125-f5yi.svg"
                            className={styles["social-icon"]}
                          />
                        </Link>
                      )}
                  </div>
                  <div className={styles["face"]}>
                    <img
                      alt="image1313781"
                      src="/external/image1313781-lopp-200h.png"
                      className={styles["faceimg"]}
                    />
                    <Input
                      className={
                        !limitesPlanos[userPlan?.name]?.redes?.includes(
                          "facebook"
                        )
                          ? styles["social-premium"]
                          : styles["input"]
                      }
                      pre="https://www.facebook.com/"
                      restringir="true"
                      value={anuncio.socialNetworks.facebook}
                      disabled={() => isInputRedesDisabled("facebook")}
                      onChange={(v) =>
                        setAnuncio({
                          ...anuncio,
                          socialNetworks: {
                            ...anuncio.socialNetworks,
                            facebook: v,
                          },
                        })
                      }
                    ></Input>
                    {!limitesPlanos[userPlan?.name]?.redes?.includes(
                      "facebook"
                    ) ? (
                      <Link to="/painel/planos" className={styles["navlink"]}>
                        <img
                          alt="Vector4125"
                          src="/external/vector4125-f5yi.svg"
                          className={styles["social-icon"]}
                        />
                      </Link>
                    ) : undefined}
                  </div>
                  <div className={styles["tiktok"]}>
                    <img
                      alt="image1333781"
                      src="/external/image1333781-dfu4-200h.png"
                      className={styles["ttkimg"]}
                    />
                    <Input
                      className={
                        !limitesPlanos[userPlan?.name]?.redes?.includes(
                          "tiktok"
                        )
                          ? styles["social-premium"]
                          : styles["input"]
                      }
                      pre="https://www.tiktok.com/@"
                      restringir="true"
                      value={anuncio.socialNetworks.tiktok}
                      disabled={() => isInputRedesDisabled("tiktok")}
                      onChange={(v) =>
                        setAnuncio({
                          ...anuncio,
                          socialNetworks: {
                            ...anuncio.socialNetworks,
                            tiktok: v,
                          },
                        })
                      }
                    ></Input>
                    {!limitesPlanos[userPlan?.name]?.redes?.includes(
                      "linkedin"
                    ) && (
                        <Link to="/painel/planos" className={styles["navlink"]}>
                          <img
                            alt="Vector4125"
                            src="/external/vector4125-f5yi.svg"
                            className={styles["social-icon"]}
                          />
                        </Link>
                      )}
                  </div>
                  <div className={styles["linked-in"]}>
                    <img
                      alt="image1323781"
                      src="/external/image1323781-m09-200h.png"
                      className={styles["linkedinimg"]}
                    />
                    <Input
                      className={
                        !limitesPlanos[userPlan?.name]?.redes?.includes(
                          "linkedin"
                        )
                          ? styles["social-premium"]
                          : styles["input"]
                      }
                      pre="https://www.linkedin.com/in/"
                      restringir="true"
                      value={anuncio.socialNetworks.linkedin}
                      disabled={() => isInputRedesDisabled("linkedin")}
                      onChange={(v) =>
                        setAnuncio({
                          ...anuncio,
                          socialNetworks: {
                            ...anuncio.socialNetworks,
                            linkedin: v,
                          },
                        })
                      }
                    ></Input>
                    {!limitesPlanos[userPlan?.name]?.redes?.includes(
                      "tiktok"
                    ) && (
                        <Link to="/painel/planos" className={styles["navlink"]}>
                          <img
                            alt="Vector4125"
                            src="/external/vector4125-f5yi.svg"
                            className={styles["social-icon"]}
                          />
                        </Link>
                      )}
                  </div>
                  <div className={styles["rede"]}>
                    <img
                      alt="pastedImage"
                      src="/external/pastedimage-i5e-200h.png"
                      className={styles["redeimg"]}
                    />
                    <Input
                      className={
                        !limitesPlanos[userPlan?.name]?.redes?.includes(
                          "website"
                        )
                          ? styles["social-premium"]
                          : styles["input"]
                      }
                      pre="https://"
                      url="true"
                      disabled={() => isInputRedesDisabled("website")}
                      value={anuncio.socialNetworks.website}
                      onChange={(v) =>
                        setAnuncio({
                          ...anuncio,
                          socialNetworks: {
                            ...anuncio.socialNetworks,
                            website: v,
                          },
                        })
                      }
                    ></Input>
                    {!limitesPlanos[userPlan?.name]?.redes?.includes(
                      "website"
                    ) && (
                        <Link to="/painel/planos" className={styles["navlink"]}>
                          <img
                            alt="Vector4125"
                            src="/external/vector4125-f5yi.svg"
                            className={styles["social-icon"]}
                          />
                        </Link>
                      )}
                  </div>
                </div>
              </div>
            </div>
            <div className={styles["servicos"]}>
              <span className={styles["text026"]}>
                <span>
                  Serviços
                  <span
                    dangerouslySetInnerHTML={{
                      __html: " ",
                    }}
                  />
                </span>
              </span>
              <div className={styles.categorias}>
                <div className={styles.cabecalho01}>
                  <div className={styles.titulo}>
                    <span className={styles.text028}>
                      <span>Você pode ajudar nos seguintes objetivos:</span>
                    </span>
                    <span className={styles["text-plano-free"]}>
                      <span>
                        Máximo {limitesPlanos[userPlan?.name]?.servicos}{" "}
                        {limitesPlanos[userPlan?.name]?.servicos == 1
                          ? "categoria"
                          : "categorias"}{" "}
                        no plano {userPlan?.name}
                      </span>
                    </span>
                  </div>
                  {userPlan?.name !== "Plano Elite" && (
                    <Link to="/painel/planos" className={styles.navlink1}>
                      <div className={styles["botao-planos1"]}>
                        <span className={styles.text031}>
                          <span>Selecionar mais categorias</span>
                        </span>
                        <img
                          alt="Vector4126"
                          src="/external/vector4126-6u62.svg"
                          className={styles.crownicon1}
                        />
                      </div>
                    </Link>
                  )}
                </div>
                <div className={styles.opcoes1}>
                  {services.map((categoria, index) => (
                    <div
                      key={index}
                      className={
                        categoria.premium
                          ? styles[
                          userPlan?.name !== "Plano Elite"
                            ? "opcao-elite"
                            : "opcao-elite-off-border"
                          ]
                          : styles.opcao
                      }
                      onClick={() =>
                        toggleCategoria(categoria.title, categoria._id)
                      }
                    >
                      <div className={styles.cabecalho02}>
                        <span className={styles.text033}>
                          {categoria.title}
                        </span>
                        {categoria.premium &&
                          userPlan?.name !== "Plano Elite" && (
                            <Link
                              to="/painel/planos"
                              className={styles.navlink2}
                            >
                              <img
                                alt="Vector4126"
                                src="/external/vector4126-ugb.svg"
                                className={styles.crownicon2}
                              />
                            </Link>
                          )}
                        <Radio
                          valor={anuncio?.services.includes(categoria._id)}
                        />
                      </div>
                      <span className={styles.text034}>
                        {categoria.description}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles["metodos"]}>
                <Input
                  Label="Métodos e filosofia de treinamento"
                  Limite={750}
                  tipo={2}
                  bloquearQuebraLinha={true}
                  value={anuncio?.trainingMethods}
                  onChange={(v) => {
                    setAnuncio({
                      ...anuncio,
                      trainingMethods: v,
                    });
                  }}
                  TextAreaPlaceholder="Cada personal tem sua abordagem e métodos. É relevante saber se esses métodos estão alinhados com o que o cliente procura."
                ></Input>
                <Inspiracao
                  TextTopico1='"Cada corpo é único, e por isso, minha filosofia é criar planos de treino totalmente adaptados às necessidades e objetivos individuais. Através de uma avaliação inicial detalhada, desenho rotinas que evoluem conforme o progresso e feedback do cliente."'
                  TextTopico2='"Meu foco é no treinamento funcional, preparando o corpo para desempenhar atividades do dia a dia com eficiência e sem lesões. Utilizo movimentos compostos e naturais, muitas vezes com o próprio peso do corpo, promovendo força, flexibilidade e equilíbrio."'
                  TextTopico3='"Inspirado pelos treinamentos de alto rendimento, minha filosofia busca desenvolver uma mentalidade de atleta em meus clientes. Isso envolve disciplina, determinação e um foco intenso em objetivos claros. Os treinos são desafiadores, mas com minha orientação e sua dedicação, as metas serão alcançadas."'
                ></Inspiracao>
              </div>
            </div>
            <div className={styles["atendimento"]}>
              <span className={styles["text060"]}>
                <span>Atendimento</span>
              </span>
              <div className={styles["tipo-atendimento"]}>
                <span className={styles["text062"]}>
                  <span>Tipo de atendimento</span>
                </span>
                <div className={styles["opcoes2"]}>
                  <div
                    className={styles["presencial"]}
                    onClick={() => {
                      if (anuncio.appointment.type.includes("Presencial")) {
                        const updatedAtendimentos =
                          anuncio.appointment.type.filter(
                            (t) => t !== "Presencial"
                          );
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            type: updatedAtendimentos,
                          },
                        });
                      } else {
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            type: [...anuncio.appointment.type, "Presencial"],
                          },
                        });
                      }
                    }}
                  >
                    <Radio
                      valor={anuncio.appointment.type.includes("Presencial")}
                    ></Radio>
                    <span className={styles["text064"]}>
                      <span>Presencial</span>
                    </span>
                  </div>
                  <div
                    className={
                      userPlan?.name === "Plano Free"
                        ? styles["online"]
                        : styles["presencial"]
                    }
                    onClick={() => {
                      if (anuncio.appointment.type.includes("On-line")) {
                        const updatedAtendimentos =
                          anuncio.appointment.type.filter(
                            (t) => t !== "On-line"
                          );
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            type: updatedAtendimentos,
                          },
                        });
                      } else {
                        if (limitesPlanos[userPlan.name]?.online) {
                          setAnuncio({
                            ...anuncio,
                            appointment: {
                              ...anuncio.appointment,
                              type: [...anuncio.appointment.type, "On-line"],
                            },
                          });
                        } else {
                          const planosOnlineArray = [];

                          for (const nomePlano in limitesPlanos) {
                            if (
                              limitesPlanos.hasOwnProperty(nomePlano) &&
                              limitesPlanos[nomePlano].online
                            ) {
                              planosOnlineArray.push(
                                limitesPlanos[nomePlano].nome
                              );
                            }
                          }

                          toast.error(
                            `Opção disponível apenas para assinantes do${planosOnlineArray.length > 1 ? "s" : ""
                            } plano${planosOnlineArray.length > 1 ? "s" : ""
                            } ${planosOnlineArray.join(
                              `${planosOnlineArray.length === 2 ? " e " : ","}`
                            )}`
                          );
                        }
                      }
                    }}
                  >
                    <Radio
                      valor={anuncio.appointment.type.includes("On-line")}
                    ></Radio>

                    <span className={styles["text066"]}>On-line</span>
                    {userPlan?.name === "Plano Free" && (
                      <Link to="/painel/planos" className={styles["navlink3"]}>
                        <img
                          alt="premium"
                          src="/external/vector4184-u5s.svg"
                          className={styles["crownicon3"]}
                        />
                      </Link>
                    )}
                  </div>
                </div>
              </div>
              <div className={styles["genero-alunos"]}>
                <span className={styles["text067"]}>
                  Gênero que você atende
                </span>
                <div className={styles["opcoes3"]}>
                  <div
                    className={styles["homens"]}
                    onClick={() => {
                      if (
                        anuncio.appointment?.genderPreference?.includes(
                          "Homens"
                        )
                      ) {
                        const updatedGeneros =
                          anuncio.appointment.genderPreference.filter(
                            (t) => t !== "Homens"
                          );
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            genderPreference: updatedGeneros,
                          },
                        });
                      } else {
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            genderPreference: [
                              ...anuncio.appointment.genderPreference,
                              "Homens",
                            ],
                          },
                        });
                      }
                    }}
                  >
                    <Radio
                      valor={anuncio.appointment.genderPreference.includes(
                        "Homens"
                      )}
                    ></Radio>
                    <span className={styles["text068"]}>
                      <span>Homens</span>
                      <br></br>
                    </span>
                  </div>
                  <div
                    className={styles["mulheres"]}
                    onClick={() => {
                      if (
                        anuncio.appointment.genderPreference.includes(
                          "Mulheres"
                        )
                      ) {
                        const updatedGeneros =
                          anuncio.appointment.genderPreference.filter(
                            (t) => t !== "Mulheres"
                          );
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            genderPreference: updatedGeneros,
                          },
                        });
                      } else {
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            genderPreference: [
                              ...anuncio.appointment.genderPreference,
                              "Mulheres",
                            ],
                          },
                        });
                      }
                    }}
                  >
                    <Radio
                      valor={anuncio.appointment.genderPreference.includes(
                        "Mulheres"
                      )}
                    ></Radio>
                    <span className={styles["text071"]}>
                      <span>Mulheres</span>
                      <br></br>
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles["idade-alunos"]}>
                <span className={styles["text074"]}>Idade (Alunos)</span>
                <div className={styles["opcoes4"]}>
                  <div
                    className={styles["criancas"]}
                    onClick={() => {
                      if (anuncio.appointment.ageGroup.includes("Jovens")) {
                        const updatedIdades =
                          anuncio.appointment.ageGroup.filter(
                            (t) => t !== "Jovens"
                          );
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            ageGroup: updatedIdades,
                          },
                        });
                      } else {
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            ageGroup: [
                              ...anuncio.appointment.ageGroup,
                              "Jovens",
                            ],
                          },
                        });
                      }
                    }}
                  >
                    <Radio
                      valor={anuncio.appointment.ageGroup.includes("Jovens")}
                    ></Radio>
                    <span className={styles["text075"]}>
                      <span>Jovens (12-17)</span>
                      <br></br>
                    </span>
                  </div>
                  <div
                    className={styles["adultos"]}
                    onClick={() => {
                      if (anuncio.appointment.ageGroup.includes("Adultos")) {
                        const updateIdades =
                          anuncio.appointment.ageGroup.filter(
                            (t) => t !== "Adultos"
                          );
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            ageGroup: updateIdades,
                          },
                        });
                      } else {
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            ageGroup: [
                              ...anuncio.appointment.ageGroup,
                              "Adultos",
                            ],
                          },
                        });
                      }
                    }}
                  >
                    <Radio
                      valor={anuncio.appointment.ageGroup.includes("Adultos")}
                    ></Radio>
                    <span className={styles["text078"]}>
                      <span>Adultos (18-50)</span>
                      <br></br>
                    </span>
                  </div>
                  <div
                    className={styles["idosos"]}
                    onClick={() => {
                      if (anuncio.appointment.ageGroup.includes("Idosos")) {
                        const updatedIdades =
                          anuncio.appointment.ageGroup.filter(
                            (t) => t !== "Idosos"
                          );
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            ageGroup: updatedIdades,
                          },
                        });
                      } else {
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            ageGroup: [
                              ...anuncio.appointment.ageGroup,
                              "Idosos",
                            ],
                          },
                        });
                      }
                    }}
                  >
                    <Radio
                      valor={anuncio.appointment.ageGroup.includes("Idosos")}
                    ></Radio>
                    <span className={styles["text081"]}>
                      <span>Idosos (51 acima)</span>
                      <br></br>
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles["nivel-alunos"]}>
                <span className={styles["text084"]}>
                  Nível de desenvolvimento dos alunos
                </span>
                <div className={styles["opcoes5"]}>
                  <div
                    className={styles["iniciante"]}
                    onClick={() => {
                      if (
                        anuncio.appointment.skillLevel.includes("Iniciante")
                      ) {
                        const updatedNiveis =
                          anuncio.appointment.skillLevel.filter(
                            (t) => t !== "Iniciante"
                          );
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            skillLevel: updatedNiveis,
                          },
                        });
                      } else {
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            skillLevel: [
                              ...anuncio.appointment.skillLevel,
                              "Iniciante",
                            ],
                          },
                        });
                      }
                    }}
                  >
                    <Radio
                      valor={anuncio.appointment.skillLevel.includes(
                        "Iniciante"
                      )}
                    ></Radio>
                    <span className={styles["text085"]}>
                      <span>Iniciante</span>
                      <br></br>
                    </span>
                  </div>
                  <div
                    className={styles["intermediario"]}
                    onClick={() => {
                      if (
                        anuncio.appointment.skillLevel.includes("Intermediário")
                      ) {
                        const updatedNiveis =
                          anuncio.appointment.skillLevel.filter(
                            (t) => t !== "Intermediário"
                          );
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            skillLevel: updatedNiveis,
                          },
                        });
                      } else {
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            skillLevel: [
                              ...anuncio.appointment.skillLevel,
                              "Intermediário",
                            ],
                          },
                        });
                      }
                    }}
                  >
                    <Radio
                      valor={anuncio.appointment.skillLevel.includes(
                        "Intermediário"
                      )}
                    ></Radio>
                    <span className={styles["text088"]}>
                      <span>Intermediário</span>
                      <br></br>
                    </span>
                  </div>
                  <div
                    className={
                      userPlan?.name === "Plano Free"
                        ? styles["online"]
                        : styles["avancado"]
                    }
                    onClick={() => {
                      if (anuncio.appointment.skillLevel.includes("Avançado")) {
                        const updatedNiveis =
                          anuncio.appointment.skillLevel.filter(
                            (t) => t !== "Avançado"
                          );
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            skillLevel: updatedNiveis,
                          },
                        });
                      } else {
                        if (limitesPlanos[userPlan.name]?.avancado) {
                          setAnuncio({
                            ...anuncio,
                            appointment: {
                              ...anuncio.appointment,
                              skillLevel: [
                                ...anuncio.appointment.skillLevel,
                                "Avançado",
                              ],
                            },
                          });
                        } else {
                          const planosOnlineArray = [];

                          for (const nomePlano in limitesPlanos) {
                            if (
                              limitesPlanos.hasOwnProperty(nomePlano) &&
                              limitesPlanos[nomePlano].avancado
                            ) {
                              planosOnlineArray.push(
                                limitesPlanos[nomePlano].nome
                              );
                            }
                          }

                          toast.error(
                            `Opção disponível apenas para assinantes do${planosOnlineArray.length > 1 ? "s" : ""
                            } plano${planosOnlineArray.length > 1 ? "s" : ""
                            } ${planosOnlineArray.join(
                              `${planosOnlineArray.length === 2 ? " e " : ","}`
                            )}`
                          );
                        }
                      }
                    }}
                  >
                    <Radio
                      valor={anuncio.appointment.skillLevel.includes(
                        "Avançado"
                      )}
                    ></Radio>
                    <span className={styles["text091"]}>
                      <span>Avançado</span>
                    </span>
                    {userPlan?.name === "Plano Free" && (
                      <Link to="/painel/planos" className={styles["navlink3"]}>
                        <img
                          alt="premium"
                          src="/external/vector4184-u5s.svg"
                          className={styles["crownicon3"]}
                        />
                      </Link>
                    )}
                  </div>
                </div>
              </div>
              <div className={styles["periodo-dia-semana"]}>
                <span className={styles["text094"]}>
                  Período e dias de atendimento
                </span>
                <div className={styles["opcoes6"]}>
                  <div
                    className={styles["manha"]}
                    onClick={() => {
                      if (anuncio.appointment.availableTime.morning) {
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            availableTime: {
                              ...anuncio.appointment.availableTime,
                              morning: false,
                            },
                          },
                        });
                      } else {
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            availableTime: {
                              ...anuncio.appointment.availableTime,
                              morning: true,
                            },
                          },
                        });
                      }
                    }}
                  >
                    <Radio
                      valor={anuncio.appointment.availableTime.morning}
                    ></Radio>
                    <span className={styles["text095"]}>
                      <span>Manhã</span>
                      <br></br>
                    </span>
                  </div>
                  <div
                    className={styles["tarde"]}
                    onClick={() => {
                      if (anuncio.appointment.availableTime.afternoon) {
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            availableTime: {
                              ...anuncio.appointment.availableTime,
                              afternoon: false,
                            },
                          },
                        });
                      } else {
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            availableTime: {
                              ...anuncio.appointment.availableTime,
                              afternoon: true,
                            },
                          },
                        });
                      }
                    }}
                  >
                    <Radio
                      valor={anuncio.appointment.availableTime.afternoon}
                    ></Radio>
                    <span className={styles["text098"]}>
                      <span>Tarde</span>
                      <br></br>
                    </span>
                  </div>
                  <div
                    className={styles["noite"]}
                    onClick={() => {
                      if (anuncio.appointment.availableTime.evening) {
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            availableTime: {
                              ...anuncio.appointment.availableTime,
                              evening: false,
                            },
                          },
                        });
                      } else {
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            availableTime: {
                              ...anuncio.appointment.availableTime,
                              evening: true,
                            },
                          },
                        });
                      }
                    }}
                  >
                    <Radio
                      valor={anuncio.appointment.availableTime.evening}
                    ></Radio>
                    <span className={styles["text101"]}>
                      <span>Noite</span>
                      <br></br>
                    </span>
                  </div>
                  <div className={styles["separador"]}></div>
                  <div
                    className={styles["segunda"]}
                    onClick={() => {
                      if (anuncio.appointment.availableDays.monday) {
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            availableDays: {
                              ...anuncio.appointment.availableDays,
                              monday: false,
                            },
                          },
                        });
                      } else {
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            availableDays: {
                              ...anuncio.appointment.availableDays,
                              monday: true,
                            },
                          },
                        });
                      }
                    }}
                  >
                    <Radio
                      valor={anuncio.appointment.availableDays.monday}
                    ></Radio>
                    <span className={styles["text104"]}>
                      <span>Segunda</span>
                      <br></br>
                    </span>
                  </div>
                  <div
                    className={styles["terca"]}
                    onClick={() => {
                      if (anuncio.appointment.availableDays.tuesday) {
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            availableDays: {
                              ...anuncio.appointment.availableDays,
                              tuesday: false,
                            },
                          },
                        });
                      } else {
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            availableDays: {
                              ...anuncio.appointment.availableDays,
                              tuesday: true,
                            },
                          },
                        });
                      }
                    }}
                  >
                    <Radio
                      valor={anuncio.appointment.availableDays.tuesday}
                    ></Radio>
                    <span className={styles["text107"]}>
                      <span>Terça</span>
                      <br></br>
                    </span>
                  </div>
                  <div
                    className={styles["quarta"]}
                    onClick={() => {
                      if (anuncio.appointment.availableDays.wednesday) {
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            availableDays: {
                              ...anuncio.appointment.availableDays,
                              wednesday: false,
                            },
                          },
                        });
                      } else {
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            availableDays: {
                              ...anuncio.appointment.availableDays,
                              wednesday: true,
                            },
                          },
                        });
                      }
                    }}
                  >
                    <Radio
                      valor={anuncio.appointment.availableDays.wednesday}
                    ></Radio>
                    <span className={styles["text110"]}>
                      <span>Quarta</span>
                      <br></br>
                    </span>
                  </div>
                  <div
                    className={styles["quinta"]}
                    onClick={() => {
                      if (anuncio.appointment.availableDays.thursday) {
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            availableDays: {
                              ...anuncio.appointment.availableDays,
                              thursday: false,
                            },
                          },
                        });
                      } else {
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            availableDays: {
                              ...anuncio.appointment.availableDays,
                              thursday: true,
                            },
                          },
                        });
                      }
                    }}
                  >
                    <Radio
                      valor={anuncio.appointment.availableDays.thursday}
                    ></Radio>
                    <span className={styles["text113"]}>
                      <span>Quinta</span>
                      <br></br>
                    </span>
                  </div>
                  <div
                    className={styles["sexta"]}
                    onClick={() => {
                      if (anuncio.appointment.availableDays.friday) {
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            availableDays: {
                              ...anuncio.appointment.availableDays,
                              friday: false,
                            },
                          },
                        });
                      } else {
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            availableDays: {
                              ...anuncio.appointment.availableDays,
                              friday: true,
                            },
                          },
                        });
                      }
                    }}
                  >
                    <Radio
                      valor={anuncio.appointment.availableDays.friday}
                    ></Radio>
                    <span className={styles["text116"]}>
                      <span>Sexta</span>
                      <br></br>
                    </span>
                  </div>
                  <div
                    className={styles["sabado"]}
                    onClick={() => {
                      if (anuncio.appointment.availableDays.saturday) {
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            availableDays: {
                              ...anuncio.appointment.availableDays,
                              saturday: false,
                            },
                          },
                        });
                      } else {
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            availableDays: {
                              ...anuncio.appointment.availableDays,
                              saturday: true,
                            },
                          },
                        });
                      }
                    }}
                  >
                    <Radio
                      valor={anuncio.appointment.availableDays.saturday}
                    ></Radio>
                    <span className={styles["text119"]}>
                      <span>Sábado</span>
                      <br></br>
                    </span>
                  </div>
                  <div
                    className={styles["domingo"]}
                    onClick={() => {
                      if (anuncio.appointment.availableDays.sunday) {
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            availableDays: {
                              ...anuncio.appointment.availableDays,
                              sunday: false,
                            },
                          },
                        });
                      } else {
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            availableDays: {
                              ...anuncio.appointment.availableDays,
                              sunday: true,
                            },
                          },
                        });
                      }
                    }}
                  >
                    <Radio
                      valor={anuncio.appointment.availableDays.sunday}
                    ></Radio>
                    <span className={styles["text122"]}>
                      <span>Domingo</span>
                      <br></br>
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles["onde-atendo"]}>
                <span className={styles["text125"]}>Onde atendo</span>
                <div className={styles["opcoes7"]}>
                  <div
                    className={styles["local-publico"]}
                    onClick={() => {
                      if (
                        anuncio.appointment.locationType.includes(
                          "Local público"
                        )
                      ) {
                        const updatedLocal =
                          anuncio.appointment.locationType.filter(
                            (t) => t !== "Local público"
                          );
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            locationType: updatedLocal,
                          },
                        });
                      } else {
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            locationType: [
                              ...anuncio.appointment.locationType,
                              "Local público",
                            ],
                          },
                        });
                      }
                    }}
                  >
                    <Radio
                      valor={anuncio.appointment.locationType.includes(
                        "Local público"
                      )}
                    ></Radio>
                    <span className={styles["text126"]}>
                      <span>Local público</span>
                      <br></br>
                    </span>
                  </div>
                  <div
                    className={styles["casa-aluno"]}
                    onClick={() => {
                      if (
                        anuncio.appointment.locationType.includes(
                          "Casa do aluno"
                        )
                      ) {
                        const updatedLocal =
                          anuncio.appointment.locationType.filter(
                            (t) => t !== "Casa do aluno"
                          );
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            locationType: updatedLocal,
                          },
                        });
                      } else {
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            locationType: [
                              ...anuncio.appointment.locationType,
                              "Casa do aluno",
                            ],
                          },
                        });
                      }
                    }}
                  >
                    <Radio
                      valor={anuncio.appointment.locationType.includes(
                        "Casa do aluno"
                      )}
                    ></Radio>
                    <span className={styles["text129"]}>
                      <span>Casa do aluno</span>
                      <br></br>
                    </span>
                  </div>
                  <div
                    className={styles["academias"]}
                    onClick={() => {
                      if (
                        anuncio.appointment.locationType.includes("Academias")
                      ) {
                        const updatedLocal =
                          anuncio.appointment.locationType.filter(
                            (t) => t !== "Academias"
                          );
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            locationType: updatedLocal,
                          },
                        });
                      } else {
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            locationType: [
                              ...anuncio.appointment.locationType,
                              "Academias",
                            ],
                          },
                        });
                      }
                    }}
                  >
                    <Radio
                      valor={anuncio.appointment.locationType.includes(
                        "Academias"
                      )}
                    ></Radio>
                    <span className={styles["text132"]}>
                      <span>Academias</span>
                      <br></br>
                    </span>
                  </div>
                  <div
                    className={styles["local-proprio"]}
                    onClick={() => {
                      if (
                        anuncio.appointment.locationType.includes(
                          "Local próprio"
                        )
                      ) {
                        const updatedLocal =
                          anuncio.appointment.locationType.filter(
                            (t) => t !== "Local próprio"
                          );
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            locationType: updatedLocal,
                          },
                        });
                      } else {
                        setAnuncio({
                          ...anuncio,
                          appointment: {
                            ...anuncio.appointment,
                            locationType: [
                              ...anuncio.appointment.locationType,
                              "Local próprio",
                            ],
                          },
                        });
                      }
                    }}
                  >
                    <Radio
                      valor={anuncio.appointment.locationType.includes(
                        "Local próprio"
                      )}
                    ></Radio>
                    <span className={styles["text135"]}>
                      <span>Local próprio</span>
                      <br></br>
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles["idiomas"]}>
                <Input
                  Label="Seus idiomas"
                  placeholder="Digite seu idioma"
                  value={idioma}
                  getOptionLabel={(item) => item}
                  isOptionEqualToValue={(item) => item}
                  options={idiomas}
                  tipo={5}
                  inputValue={idioma}
                  onInputChange={(event, value) => {
                    if (value) setIdioma(value);
                  }}
                  onChange={(e, v) => {
                    if (v) {
                      addIdioma(v);
                    }
                    setIdioma("");
                  }}
                ></Input>
                {anuncio.languages.length > 0 && (
                  <div className={styles["idiomas-adicionados"]}>
                    {anuncio.languages.map((idioma, index) => (
                      <div
                        key={index}
                        className={styles["idioma"]}
                        onClick={() => {
                          const updatedIdiomas = anuncio.languages.filter(
                            (i) => i !== idioma
                          );

                          setAnuncio({
                            ...anuncio,
                            languages: updatedIdiomas,
                          });
                        }}
                      >
                        <span className={styles["text138"]}>
                          <span>{idioma}</span>
                        </span>
                        <img
                          alt="Xfechar3781"
                          src="/external/xfechar3781-zjsp.svg"
                          className={styles["removericon"]}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <div className={styles["planos"]}>
              <span className={styles["text146"]}>
                <span>Preços e planos</span>
              </span>
              <div className={styles["valores"]}>
                <div className={styles["infos-pagamento"]}>
                  <Input
                    Limite={750}
                    bloquearQuebraLinha={true}
                    tipo={2}
                    value={anuncio.pricing.sessionDetails}
                    onChange={(v) => {
                      setAnuncio({
                        ...anuncio,
                        pricing: {
                          ...anuncio.pricing,
                          sessionDetails: v,
                        },
                      });
                    }}
                    TextAreaPlaceholder="Quanto custa uma sessão avulsa? Existem pacotes com descontos? Quais as formas de pagamento?"
                  ></Input>
                  <Inspiracao
                    TextTopico1='"Plano Básico: 3 sessões semanais, avaliação física mensal, planilha de treino e suporte online - R$300/mês. Plano Avançado: 5 sessões semanais, acompanhamento nutricional, avaliações quinzenais e coaching mental - R$500/mês."'
                    TextTopico2='"Pacote Bronze: 10 sessões (validade de 2 meses) - R$700. Pacote Prata: 20 sessões com 2 sessões bônus (3 meses) - R$1.400. Pacote Ouro: 30 sessões + 5 bônus, acompanhamento nutricional (4 meses) - R$2.100."'
                    TextTopico3='"Sessão Avulsa: R$80. Planos Online: R$200/mês (inclui 4 videoaulas, planilha de treino e consultas semanais). Desconto de 10% para pagamentos trimestrais. Aulas em grupo disponíveis sob consulta."'
                  ></Inspiracao>
                </div>
                <div className={styles["valor-hora"]}>
                  <span className={styles["text148"]}>
                    <span>Valor por hora:</span>
                  </span>
                  <div className={styles["pricing-container"]}>
                    <div className={styles["presencial1"]}>
                      <span className={styles["text150"]}>
                        <span>Presencial - A partir de</span>
                      </span>
                      <div className={styles["container3"]}>
                        <Input
                          style={{ width: 120 }}
                          Placeholder="R$ 0/h"
                          pre="R$ "
                          useValue={true}
                          value={`R$ ${anuncio?.pricing?.inPerson ?? 0
                            }`.replace(".", ",")}
                          onChange={(v) => {
                            const valorDigitado = v.match(
                              /^R\$ \d+(?:,)?(?:\d{1,2})?/g
                            );
                            const valorTratado = valorDigitado?.length
                              ? valorDigitado[0]
                                .replace("R$ ", "")
                                .replace(",", ".")
                              : valorDigitado;
                            setAnuncio({
                              ...anuncio,
                              pricing: {
                                ...anuncio.pricing,
                                inPerson: valorTratado,
                              },
                            });
                          }}
                          onBlur={() => {
                            setAnuncio({
                              ...anuncio,
                              pricing: {
                                ...anuncio.pricing,
                                inPerson: Number(anuncio?.pricing?.inPerson),
                              },
                            });
                          }}
                        ></Input>
                      </div>
                    </div>
                    <div
                      className={
                        userPlan?.name === "Plano Free"
                          ? styles["online1"]
                          : styles["presencial1"]
                      }
                    >
                      <span className={styles["text152"]}>
                        <span>Online - A partir de</span>
                      </span>
                      <div className={styles["container3"]}>
                        <Input
                          style={{ width: 120 }}
                          Placeholder="R$ 0/h"
                          pre="R$ "
                          // restringir="numero"
                          useValue={true}
                          disabled={() => {
                            const planosOnlineArray = [];

                            for (const nomePlano in limitesPlanos) {
                              if (
                                limitesPlanos.hasOwnProperty(nomePlano) &&
                                limitesPlanos[nomePlano].online
                              ) {
                                planosOnlineArray.push(
                                  limitesPlanos[nomePlano].nome
                                );
                              }
                            }

                            if (
                              planosOnlineArray.length > 0 &&
                              !planosOnlineArray.includes(userPlan?.name)
                            ) {
                              return `Opção disponível apenas para assinantes do${planosOnlineArray.length > 1 ? "s" : ""
                                } plano${planosOnlineArray.length > 1 ? "s" : ""
                                } ${planosOnlineArray.join(
                                  `${planosOnlineArray.length === 2 ? " e " : ","
                                  }`
                                )}`;
                            } else {
                              return false;
                            }
                          }}
                          // value={`R$ ${anuncio?.pricing?.online
                          //     ? anuncio?.pricing?.online
                          //     : "0,00"
                          //   }`}
                          value={`R$ ${anuncio?.pricing?.online ?? 0}`.replace(
                            ".",
                            ","
                          )}
                          onChange={(v) => {
                            // setAnuncio({
                            //   ...anuncio,
                            //   pricing: {
                            //     ...anuncio.pricing,
                            //     online:
                            //       Number(v.replace(/\D/g, "")) == 0
                            //         ? null
                            //         : Number(v.replace(/\D/g, "")),
                            //   },
                            // });
                            const valorTeste = v.match(
                              /^R\$ \d+(?:,)?(?:\d{1,2})?/g
                            );
                            const valorTratado = valorTeste?.length
                              ? valorTeste[0]
                                .replace("R$ ", "")
                                .replace(",", ".")
                              : valorTeste; //Number(v.replace(',', '.').replace(/[^0-9,.]/g, ""))
                            setAnuncio({
                              ...anuncio,
                              pricing: {
                                ...anuncio.pricing,
                                online: valorTratado,
                              },
                            });
                          }}
                          onBlur={() => {
                            setAnuncio({
                              ...anuncio,
                              pricing: {
                                ...anuncio.pricing,
                                online: Number(anuncio?.pricing?.online),
                              },
                            });
                          }}
                        ></Input>
                        {userPlan?.name === "Plano Free" && (
                          <Link
                            to="/painel/planos"
                            className={styles["navlink4"]}
                          >
                            <img
                              alt="Vector4126"
                              src="/external/vector4126-u5i.svg"
                              className={styles["crownicon4"]}
                            />
                          </Link>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <p className={styles["text-config"]}>
                  Caso você não informe um preço, ficará marcado como{" "}
                  <strong>"A combinar"</strong>
                </p>
              </div>
            </div>
            <div className={styles["midia"]}>
              <div className={styles["cabecalho13"]}>
                <span className={styles["text154"]}>Galeria</span>
                {userPlan?.name !== "Plano Elite" && (
                  <Link to="/painel/planos" className={styles["navlink5"]}>
                    <div className={styles["botao-planos2"]}>
                      <span className={styles["text155"]}>
                        Adicione mais fotos e vídeos
                      </span>
                      <img
                        alt="Vector4125"
                        src="/external/vector4125-i1y.svg"
                        className={styles["crownicon5"]}
                      />
                    </div>
                  </Link>
                )}
              </div>
              <Container axis="xy" onSortEnd={onDragEnd}>
                {collections.map((m, cIdx) => {
                  if (cIdx == 0) {
                    return (
                      <div key={cIdx} className={styles["enviar-video"]}>
                        <input
                          type="file"
                          accept=".mp4"
                          ref={inputVideoRef}
                          style={{ display: "none" }}
                          onChange={(e) => uploadItem(e, false)}
                        />
                        <div className={styles["btn-info"]}>
                          <button
                            className={`${styles["botao-enviar"]} ${userPlan?.name === "Plano Free"
                                ? styles["togle-disabled"]
                                : ""
                              }`}
                            disabled={userPlan?.name === "Plano Free"}
                            onClick={(e) => {
                              if (m.length >= userPlan.galleryVideosLimit)
                                return toast.warn(
                                  `Você já atingiu o máximo de vídeos na galeria para o plano ${userPlan.name}`
                                );
                              // inputVideoRef.current.click();
                              setShowPopUpYoutube(true)
                            }}
                          >
                            <span className={styles["text156"]}>
                              Enviar vídeo
                            </span>
                            <img
                              alt="pastedImage"
                              src="/external/pastedimage-i96s-200h.png"
                              className={styles["arrowicon1"]}
                            />
                            <img
                              alt="pastedImage"
                              src="/external/pastedimage-e4pp-200h.png"
                              className={styles["plusicon"]}
                            />
                          </button>
                          <p>
                            {userPlan?.name === "Plano Free"
                              ? "Você não pode enviar vídeos no Plano Free"
                              : `${collections[cIdx].length}/${userPlan?.galleryVideosLimit || 0
                              } vídeos`}
                          </p>
                        </div>
                        <span className={styles["text-config"]}>
                          <span>Configuração do vídeo: MP4, até 16 mb.</span>
                        </span>
                        <div className={styles["videos-enviados"]}>
                          {m.map((v, index) => {
                            console.log(v, index)
                            return (
                              <SortableVideo
                                collection={cIdx}
                                deletarItem={() =>
                                  deletarItem("video", index)
                                }
                                index={index}
                                idx={index}
                                value={v}
                                key={"v" + index}
                              ></SortableVideo>
                            );
                          })}
                        </div>
                      </div>
                    );
                  } else {
                    return (
                      <div className={styles["enviar-foto"]}>
                        <input
                          type="file"
                          accept="image/png, image/jpeg"
                          ref={inputGaleriaRef}
                          style={{ display: "none" }}
                          onChange={(event) => {
                            uploadItem(event, false);
                          }}
                        />
                        <div className={styles["btn-info"]}>
                          <button
                            className={styles["botao-enviar1"]}
                            onClick={(e) => {
                              if (m.length >= userPlan.galleryPhotosLimit)
                                return toast.warn(
                                  `Você já atingiu o máximo de fotos na galeria para o plano ${userPlan.name}`
                                );
                              inputGaleriaRef.current.click();
                            }}
                          >
                            <span className={styles["text158"]}>
                              Enviar foto
                            </span>
                            <img
                              alt="pastedImage"
                              src="/external/pastedimage-i96s-200h.png"
                              className={styles["arrowicon2"]}
                            />
                            <img
                              alt="pastedImage"
                              src="/external/pastedimage-e4pp-200h.png"
                              className={styles["plusicon1"]}
                            />
                          </button>
                          <p>
                            {`${anuncio.media.filter((i) => i.type === "image")
                                .length
                              }/${userPlan?.galleryPhotosLimit || 0} fotos`}
                          </p>
                        </div>
                        <span className={styles["text-config1"]}>
                          <span>
                            Configuração da imagem: PNG, JPG, até 2 mb.
                          </span>
                        </span>
                        <div className={styles["fotos-enviadas"]}>
                          {m.map((i, index) => (
                            <SortableImage
                              deletarItem={() => {
                                deletarItem("image", index);
                              }}
                              collection={cIdx}
                              value={i}
                              index={index}
                            />
                          ))}
                        </div>
                      </div>
                    );
                  }
                })}
              </Container>
            </div>
            <button
              className={styles["botao-publicar"]}
              onClick={async () => {
                if (usuario.trainerAd) {
                  await updateAd();
                } else {
                  await publicarAnuncio();
                }
              }}
            >
              <span className={styles["text160"]}>
                <span>
                  {usuario.trainerAd ? "Atualizar anúncio" : "Publicar anúncio"}
                </span>
              </span>
              <img
                alt="pastedImage"
                src="/external/pastedimage-zlar-200h.png"
                className={styles["arrowicon3"]}
              />
            </button>
          </div>
        </div>
      </div>
      <Rodape></Rodape>
    </div>
  );
};

export default MeuAnuncio;
