import React, { useState, useEffect } from 'react'

import './toggle.css'

const Toggle = ({ value, onChange, disabled }) => {
  const [isChecked, setIsChecked] = useState(value || false)

  const toggleValue = () => {
    let newValue = isChecked;
    
    if(!disabled) {
      newValue = !isChecked;
      setIsChecked(newValue);
    }

    if (onChange) {
      onChange(newValue);
    }
  };

  useEffect(() => {
    setIsChecked(value);
}, [value]);

  return (
    <div className="toggle-group1171276778">
        <img
        alt="pastedImage"
        src={isChecked ? "/external/pastedimage-4pke-1500h.png" : "/external/pastedimage-9zhp-1500h.png"}
        className={isChecked ? "toggle-check" : "toggle-uncheck"}
        onClick={toggleValue}
      />
    </div>
  )
}

export default Toggle
