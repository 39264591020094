import React, { useEffect, useState, useReducer } from "react";
import axios from "axios";

import Input from "../../components/Input/input";
import styles from "./popup-pagar.module.css";
import { formatCurrency } from "../../utils";
import { payPlan } from "../../services/payPlan";
import { toast } from "react-toastify";
import { QRCodeSVG } from "qrcode.react";
import copy from "copy-to-clipboard";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";
import { Checkbox } from "@mui/joy";
import Loader from "../Loader/loader";

const PopupPagar = (props) => {
  //definindo states gerais
  const usuario = useSelector((state) => state.usuario);
  const [isPix, setIsPix] = useState(props.isPix);
  const [dadosPix, setDadosPix] = useState({});
  const [payData, setPayData] = useState({
    plan_id: props.currentPlan._id,
    payment_method: {
      method: props.paymentMethod.method.replace("creditCard", "credit_card"),
      planTime: props.paymentMethod.planTime,
    },
    isPromotion: true,
  });
  const [parcelamentoSelecionado, setParcelamentoSelecionado] = useState({
    parcelas: 1,
    valorParcela: props.Valor,
  });
  const [dadosCartao, setDadosCartao] = useState({
    cardId: usuario.creditCards[0]?.idPayPlatform ?? "",
    cardNumber: "",
    holderName: "",
    holderDocument: "",
    expiration: "",
    cvv: "",
    parcelas: parcelamentoSelecionado.parcelas,
    address: {
      cep: "",
      streetAddress: "",
      number: "",
      city: "",
      state: "",
      compl: "",
    },
  });
  const [formCreditCardVisible, setFormCreditCardVisible] = useState(
    usuario.creditCards.length === 0
  );
  const [selectedCard, setSelectedCard] = useState(usuario.creditCards[0]);
  const [saveCard, setSaveCard] = useState(false);
  const [_, forceUpdate] = useReducer((x) => x + 1, 0);

  // criando as opções de parcelamento
  const parcelamentoMaxDeCadaPediodo = {
    quarterly: 2,
    semiAnnually: 4,
    annually: 8,
  };
  const parcelamentoMaximo =
    parcelamentoMaxDeCadaPediodo[props.paymentMethod.planTime];
  const opcoesParcelamento = [];
  for (let i = 1; i <= parcelamentoMaximo; i++) {
    opcoesParcelamento.push({
      parcelas: i,
      valorParcela: props.Valor / i,
    });
  }

  //definindo funções auxiliares
  const copyToClipboard = (value, msg) => {
    copy(value);
    toast.info(msg);
  };

  const setCampoDadosCartao = (campo) => async (valor) => {
    console.log("Campo: ", campo);
    console.log("Valor: ", valor);
    let dados = dadosCartao;
    console.log(dados);
    if (["cep", "streetAddress", "number", "city", "state", "compl"].includes(campo)) {
      if (campo === "cep") {
        dados["address"][campo] = valor.replace("-", "");
        if (valor.replace("-", "").replaceAll("_", "").length == 8) {
          try {
            axios(
              `https://viacep.com.br/ws/${dados["address"][campo].replaceAll("_", "")}/json/`
            )
            .then((response) => {
              console.log(response.data);
              dados.address.state = response.data.uf;
              dados.address.city = response.data.localidade;
              dados.address.streetAddress = response.data.logradouro;
              setDadosCartao(dados);
              forceUpdate();
            });
          } catch (error) {
            console.error("Erro ao pegar o CEP: " + error.message);
            setDadosCartao(dados);
          }
        }
      }
      if (campo !== "cep") {
        dados["address"][campo] = valor
        setDadosCartao(dados);
      };
    } else {
      if (campo == "holderName") {
        dados[campo] = valor.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      } else {
        dados[campo] = valor;
      }
      setDadosCartao(dados);
    }
  };

  const validarFormCartao = () => {
    const validatorOK = { valid: true, message: "" };

    //verificando se pagamento será feito com card id
    if (dadosCartao.cardId && !formCreditCardVisible) return validatorOK;

    //desestruturando variáveis
    const { cardNumber, holderName, cvv, expiration, address } = dadosCartao;
    const { cep, streetAddress, number, city, state } = address;
    const [expMonth, expYear] = dadosCartao.expiration
      .split("/")
      .map((item) => Number(item));

    //verificando se todos os campos estão preenchidos
    const todosCamposPreenchidos =
      cardNumber &&
      holderName &&
      cvv &&
      expiration &&
      cep &&
      streetAddress &&
      number &&
      city &&
      state;
    if (!todosCamposPreenchidos)
      return { valid: false, message: "Por favor preencha todos os campos" };

    //verificando número de digitos de cartão
    const numDigitosCartao = cardNumber.replace(/[^\d]/g, "").length;
    if (numDigitosCartao < 16)
      return { valid: false, message: "Número de cartão inválido" };

    //verificando número de digitos cvv
    if (cvv.length < 3) return { valid: false, message: "CVV inválido" };

    //verificar número de digitos validade
    if (expiration.replace(/[^\d]/g, "").length < 4)
      return { valid: false, message: "data de validade incorreta" };

    //verificar se mês da validade está entre 1 e 12
    if (expMonth < 1 || expMonth > 12)
      return { valid: false, message: "data de validade incorreta" };

    //verificar se data de validade é posterior a data atual
    const today = new Date();
    const validareEhDeAnoAnterior = 2000 + expYear < today.getFullYear();
    const cartaoDesseAno = 2000 + expYear == today.getFullYear();
    const cartaDeMesAnterior = expMonth < today.getMonth() + 1;
    if (validareEhDeAnoAnterior || (cartaoDesseAno && cartaDeMesAnterior))
      return { valid: false, message: "cartão vencido" };

    //verificar se UF é valida
    const ufs = [
      "AC",
      "AL",
      "AP",
      "AM",
      "BA",
      "CE",
      "DF",
      "ES",
      "GO",
      "MA",
      "MT",
      "MS",
      "MG",
      "PA",
      "PB",
      "PR",
      "PE",
      "PI",
      "RJ",
      "RN",
      "RS",
      "RO",
      "RR",
      "SC",
      "SP",
      "SE",
      "TO",
    ];
    const ufValida = ufs.includes(state.toUpperCase());
    if (!ufValida) return { valid: false, message: "Estado inválido" };

    return validatorOK;
  };
  const criarCorpoChamadaAPI = () => {
    //Validação
    const validacao = validarFormCartao();
    if (!validacao.valid) throw new Error(validacao.message);

    const docUser = usuario.cpf.replace(/[^\d]/g, "");
    const [expMonth, expYear] = dadosCartao.expiration.split("/");
    const corpo = {
      ...payData,
      isPromotion: true,
      creditCardData: {
        ...dadosCartao,
        cardNumber: dadosCartao.cardNumber.replace(/[^\d]/g, ""),
        holderDocument: docUser,
        expMonth,
        expYear,
      },
      saveCreditCard: saveCard,
    };
    if (formCreditCardVisible) corpo.creditCardData.cardId = null;

    console.log(corpo);
    return corpo;
  };
  const postCriarPedidoCartao = () => {
    try {
      const body = criarCorpoChamadaAPI();
      payPlan(body)
        .then((res) => {
          console.log(res);
          toast.success("Pagamento enviado com sucesso");
          props.closePopup(true);
        })
        .catch((err) => {
          toast.error(err.message);
          console.log(err);
        });
    } catch (error) {
      toast.error(error.message);
      console.log(error);
    }
  };
  const validatorValidade = (valorInput) => {
    const mes = valorInput.split("/")[0];
    return {
      valid: mes >= 0 && mes <= 12,
      errorMessage: "Data inválida",
    };
  };
  const handleCompartilhar = async () => {
    try {
      await navigator.share({
        title: `Chave Pix para pagamento do plano ${props.currentPlan.name} no site PersonalTrainer.com.br`,
        text: `Chave: ${dadosPix.chavePix}`,
        url: dadosPix.qrCode,
      });
    } catch (error) {
      console.error("Algo deu errado ao compartilhar:", error);
      toast.error(`Algo deu errado ao compartilhar: ${error}`);
    }
  };

  //efeito que faz chamada a plataforma de pagamento para que crie a cobrança pix e carregue no popup, caso o meio de pagamento seja pix
  useEffect(() => {
    if (isPix) {
      payPlan(payData)
        .then((res) => {
          setDadosPix({
            chavePix: res.qr_code,
            qrCode: res.qr_code,
          });
        })
        .catch((err) => {
          props.closePopup();
          toast.error(`Falha no pagamento${" " + err}`);
        });
      console.log("useEffect isPix");
    }
  }, [isPix]);

  // Identificar navegador
  const browser = navigator?.userAgentData?.brands[0] ?? "Firefox";

  return (
    <div
      className={` ${styles["pagarcomcarto"]} ${styles[props.rootClassName]} `}
    >
      <div className={styles["container"]}>
        <div className={styles["titulo"]}>
          <span className={styles["text"]}>
            <span>Pagar com {`${isPix ? "Pix" : "cartão de crédito"}`}</span>
          </span>
          <img
            alt="Vector4897"
            src="/external/vector4897-4cpm.svg"
            className={styles["closeicon"]}
            onClick={() => {
              props.closePopup();
            }}
          />
        </div>
        <span className={styles["text-valor"]}>
          {formatCurrency(Number(props.Valor))}
        </span>
        {!isPix && (
          <div className={styles["cartao"]}>
            <div className={styles["inputs"]}>
              {formCreditCardVisible && (
                <div className={styles["inputs"]}>
                  <Input
                    placeholder="Titular do cartão"
                    tipo={1}
                    customClassName="input-popup"
                    setExterno={setCampoDadosCartao("holderName")}
                  ></Input>
                  <Input
                    placeholder="Número do cartão"
                    mask="9999 9999 9999 9999"
                    tipo={1}
                    customClassName="input-popup"
                    setExterno={setCampoDadosCartao("cardNumber")}
                  ></Input>
                  <div className={styles["cvv-validade"]}>
                    <Input
                      placeholder="CVV"
                      mask="9999"
                      maskChar=""
                      tipo={1}
                      customClassName="input-popup"
                      setExterno={setCampoDadosCartao("cvv")}
                    ></Input>
                    <Input
                      placeholder="Validade"
                      tipo={1}
                      customClassName="input-popup"
                      setExterno={setCampoDadosCartao("expiration")}
                      validator={validatorValidade}
                      mask="99/99"
                    ></Input>
                  </div>
                </div>
              )}

              {!formCreditCardVisible && (
                <Dropdown className={styles["dropdown-parcelas"]}>
                  <Dropdown.Toggle>
                    <span className={styles["text02"]}>
                      <span>{`Cartão final ${
                        selectedCard.lastDigits ??
                        usuario.creditCards[0].lastDigits
                      }`}</span>
                    </span>

                    <img
                      alt="Vector4897"
                      src="/external/vector4897-dg.svg"
                      className={styles["arrowicon"]}
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {usuario.creditCards.map((cartao) => {
                      return (
                        <Dropdown.Item
                          className={styles["text02"]}
                          key={cartao.idPayPlatform}
                          onClick={() => {
                            console.log(dadosCartao);
                            setSelectedCard(cartao);
                            return setCampoDadosCartao("cardId")(
                              cartao.idPayPlatform
                            );
                          }}
                        >
                          {`Cartão final ${cartao.lastDigits}`}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              )}

              <Dropdown className={styles["dropdown-parcelas"]}>
                <Dropdown.Toggle>
                  <span className={styles["text02"]}>
                    <span>{`${
                      parcelamentoSelecionado.parcelas
                    }X ${formatCurrency(
                      parcelamentoSelecionado.valorParcela
                    )}`}</span>
                  </span>

                  <img
                    alt="Vector4897"
                    src="/external/vector4897-dg.svg"
                    className={styles["arrowicon"]}
                  />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {opcoesParcelamento.map((opcao) => {
                    return (
                      <Dropdown.Item
                        className={styles["text02"]}
                        key={`parcelamento${opcao.parcelas}x${opcao.valorParcela}`}
                        onClick={() => {
                          setCampoDadosCartao("parcelas")(opcao.parcelas);
                          return setParcelamentoSelecionado(opcao);
                        }}
                      >
                        {`${opcao.parcelas}X ${formatCurrency(
                          opcao.valorParcela
                        )}`}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>

              {formCreditCardVisible && (
                <div className={styles["inputs"]}>
                  <Input
                    placeholder="CEP"
                    tipo={1}
                    mask="99999-999"
                    customClassName="input-popup"
                    setExterno={setCampoDadosCartao("cep")}
                  ></Input>
                  <div className={styles["numero-rua"]}>
                    <Input
                      style={{ maxWidth: "110px" }}
                      placeholder="Nº"
                      // restringir="numero"
                      tipo={1}
                      customClassName="input-popup"
                      setExterno={setCampoDadosCartao("number")}
                    ></Input>
                    <Input
                      placeholder="Rua"
                      tipo={1}
                      customClassName="input-popup"
                      value={dadosCartao.address.streetAddress}
                      setExterno={setCampoDadosCartao("streetAddress")}
                    ></Input>
                  </div>
                  <Input
                    placeholder="Complemento"
                    tipo={1}
                    customClassName="input-popup"
                    setExterno={setCampoDadosCartao("compl")}
                  ></Input>
                  <div className={styles["cidade-uf"]}>
                    <Input
                      placeholder="Cidade"
                      value={dadosCartao.address.city}
                      tipo={1}
                      customClassName="input-popup"
                      setExterno={setCampoDadosCartao("city")}
                    ></Input>
                    <Input
                      style={{ maxWidth: "60px" }}
                      mask="aa"
                      placeholder="UF"
                      tipo={1}
                      customClassName="input-popup"
                      value={dadosCartao.address.state}
                      setExterno={setCampoDadosCartao("state")}
                    ></Input>
                  </div>
                </div>
              )}

              {!formCreditCardVisible && (
                <button
                  className={styles["botao-comprar"]}
                  onClick={() => setFormCreditCardVisible(true)}
                >
                  <span className={styles["text04"]}>
                    <span>Adicionar Novo Cartão</span>
                    <br></br>
                  </span>
                </button>
              )}

              {formCreditCardVisible && (
                <div className={styles.checkbox}>
                  <Checkbox
                    label="Salvar Cartão"
                    checked={saveCard}
                    onChange={() => setSaveCard((status) => !status)}
                  />
                </div>
              )}
            </div>
            <button
              className={styles["botao-comprar"]}
              onClick={() => postCriarPedidoCartao()}
            >
              <span className={styles["text04"]}>
                <span>Comprar</span>
                <br></br>
              </span>
            </button>
          </div>
        )}
        {isPix && (
          <div className={styles["pix"]}>
            {dadosPix?.qrCode ? <QRCodeSVG
              value={dadosPix.qrCode}
              className={styles["qr-code-gerado"]}
            /> : 
              <Loader/>
            }
            <span className={styles["text-chave-pix"]}>
              <span className={styles["text07"]}>Chave PIX</span>
              <br></br>
              <span>{dadosPix.chavePix}</span>
            </span>
            <div className={styles["botoes"]}>
              <button
                className={styles["botao-copiar"]}
                onClick={() =>
                  copyToClipboard(dadosPix.chavePix, "Chave copiada")
                }
              >
                <span className={styles["text10"]}>
                  <span>Copiar (PIX copia e cola)</span>
                </span>
              </button>
              {browser != "Firefox" && (
                <button
                  className={styles["botao-compartilhar"]}
                  onClick={handleCompartilhar}
                >
                  <span className={styles["text12"]}>
                    <span>Compartilhar chave PIX</span>
                  </span>
                </button>
              )}
            </div>
            <span className={styles["text14"]}></span>
          </div>
        )}
        <div className={styles["ambiente-seguro"]}>
          <img
            alt="Vector4897"
            src="/external/vector4897-es1q.svg"
            className={styles["seloicon"]}
          />
          <span className={styles["text16"]}>
            <span>Ambiente seguro</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PopupPagar;
