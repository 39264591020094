import React from 'react'

import Header from '../../components/Header/header'
import Rodape from '../../components/Rodape/rodape'
import styles from './politica-do-sorteio.module.css'

const PoliticaDoSorteio = (props) => {
  return (
    <div className={styles['container']}>
      <Header />
      <div className={styles['container1']}>
        <div className={styles['conteudo']}>
          <span className={styles['text']}>
            <span>Política do Sorteio</span>
            <br></br>
          </span>
          <span className={styles['text03']}>
          <br></br>
          <strong>Em breve...</strong>
          <br />
          Aguarde mais informações sobre essa oportuniade.
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          </span>
        </div>
      </div>
      <Rodape></Rodape>
    </div>
  )
}

export default PoliticaDoSorteio
