import React from "react";

import Header from "../../../components/Header/header";
import MenuAdminDesk from "../../../components/MenuAdmin/menu-admin";
import AdmFiltros from "../../../components/adm-filtros";
import Anunciante from "../../../components/Anunciante/anunciante";
import Rodape from "../../../components/Rodape/rodape";
import styles from "./adm-denuncias.module.css";
import MyData from "../../../components/myData";
import PopupBloquear from "../../../components/PopupBloquear/popup-bloquear";
import { useState, useEffect } from "react";
import { MoonLoader } from "react-spinners";
import { toast } from "react-toastify";
import axios from "axios";
import AnuncioView from "../../../components/Forms/anuncio";

const AdmDenuncias = (props) => {
  const [viewAdPage, setViewAdPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState(null);
  const [ad, setAd] = useState();
  const [user, setUser] = useState();
  const [profilePage, setProfilePage] = useState(false);
  const [pages, setPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [results, setResults] = useState(0);

  const fetchReports = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/adm/reports`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("ptr_145769130149")}`,
          },
          params: {
            limit: 10,
            page: currentPage,
          },
        }
      );
      const pages = [];
      for (let i = 1; i <= response.data.totalPages; i++) {
        pages.push(i);
      }
      setResults(response.data.totalResults);
      setPages(pages);
      setReports(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteReport = async (reportId) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/adm/report/${reportId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("ptr_145769130149")}`,
          },
        }
      );
      if (response.status === 200) {
        toast.success("Denúncia ignorada com sucesso!");
      }
      await fetchReports();
    } catch (error) {
      console.erreor(error);
    }
  };

  const handleAd = (report) => {
    setViewAdPage(true);
    setAd(report);
  };

  const handleProfile = (user) => {
    setUser(user);
    setProfilePage(true);
  };

  useEffect(() => {
    if (reports === null) {
      (async function () {
        setLoading(true);
        await fetchReports();
        setLoading(false);
      })();
    }
  }, [reports]);

  useEffect(() => {
    (async function () {
      setLoading(true);
      await fetchReports();
      setLoading(false);
    })();
  }, [currentPage]);

  const getUserPlan = (planId) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/plans/plans`
        );
        let plan;
        for (let p of response.data.data) {
          if (p._id === planId) {
            plan = p;
          }
        }
        resolve(plan);
      } catch (error) {
        reject(error);
      }
    });
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100%",
        }}
      >
        <MoonLoader size={32} color={"#fba600"} loading={true} />
      </div>
    );
  }

  return (
    <div className={styles["container"]}>
      <Header rootClassName="root-class-name"></Header>
      <div className={styles["container1"]}>
        {window.innerWidth > 991 && (
          <MenuAdminDesk rootClassName="root-class-name3"></MenuAdminDesk>
        )}
        <div className={styles["container2"]}>
          <div className={styles["container3"]}>
            {!viewAdPage && !profilePage && (
              <>
                <div className={styles["cabecalho"]}>
                  <div className={styles["titulo"]}>
                    <span className={styles["text"]}>Denúncias</span>
                    <span className={styles["textqntd"]}>{results || 0}</span>
                  </div>
                  {/* <AdmFiltros rootClassName="root-class-name4"></AdmFiltros> */}
                </div>
                <div className={styles["denuncias"]}>
                  {reports &&
                    reports.map((report, idx) => {
                      return (
                        <div key={idx} className={styles["denuncia"]}>
                          <Anunciante
                            isReport
                            handleProfile={() =>
                              handleProfile(report.trainerAd)
                            }
                            handleAd={() => handleAd(report.trainerAd)}
                            adStatus={report.trainerAd.status}
                            user={{
                              createdAt: report.createdAt,
                              photo: report.trainerAd.photo,
                              ad: { ...report.trainerAd },
                              ...report.trainerAd.userId,
                            }}
                          />
                          <div className={styles["motivo"]}>
                            <span className={styles["text03"]}>
                              <span>Denúncia</span>
                            </span>
                            <span className={styles["text05"]}>
                              <span>{report.reason}</span>
                            </span>
                            <button
                              onClick={() => handleDeleteReport(report._id)}
                              className={styles["botao-ignorar"]}
                            >
                              <span className={styles["text07"]}>Ignorar</span>
                            </button>
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div className={styles["paginacao"]}>
                  {pages.length > 0 &&
                    pages.map((p) => {
                      return (
                        <div
                          key={p}
                          onClick={() => setCurrentPage(p)}
                          className={
                            currentPage === p
                              ? styles["page-atual"]
                              : styles["page"]
                          }
                        >
                          <span className={styles["texte18"]}>{p}</span>
                        </div>
                      );
                    })}
                </div>
              </>
            )}
            {viewAdPage && (
              <div className={styles["novos-anuncios"]}>
                <AnuncioView isReport adDetails={ad} />
              </div>
            )}
            {profilePage && (
              <MyData
                user={{
                  _id: user.userId,
                  ad: {
                    ...user,
                  },
                }}
              />
            )}
          </div>
        </div>
      </div>
      <Rodape rootClassName="root-class-name19"></Rodape>
    </div>
  );
};

export default AdmDenuncias;
