import httpServer from "./httpInstance";

export async function getPlans() {

    const response = await httpServer.get(
        `plans/plans`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              "ptr_145769130149"
            )}`,
          },
        }
    );
    
    return response.data.data
}