import TextCinza from "./TextCinza";
import styles from "../regulamento.module.css"

const SecaoRegulamentoSorteio = () => (
    <div className={`${styles['secao-regulamento-sorteio']} ${styles['secao']}`}>
        <h2 className={styles['titulo-secao']}>Regulamento do sorteio</h2>

        <div>
            <TextCinza>1. DADOS DA EMPRESA PROMOTORA:</TextCinza>
            <TextCinza>ROMPER ADMINISTRADORA DE MARCAS LTDA, situada na Rua Maringá, 2076 - Bairro São Cristóvão, CEP: 30190-924, Cascavel - PR, inscrita no CNPJ/MF sob o nº 18.461.120/0001-51, denominada, adiante, apenas, como Promotora.</TextCinza>
        </div>
        <div>
            <TextCinza>1.1. ÁREA DE EXECUÇÃO DA PROMOÇÃO:</TextCinza>
            <TextCinza>Em todo Território Nacional.</TextCinza>
        </div>
        <div>
            <TextCinza>1.2. PRAZO DE EXECUÇÃO:</TextCinza>
            <TextCinza>Período da Promoção: 21/04/2024 a 28/12/2024.</TextCinza>
        </div>
        <div>
            <TextCinza>2. DESCRIÇÃO E QUANTIFICAÇÃO DOS PRÊMIOS:</TextCinza>
            <TextCinza>O presente regulamento tem como objeto estabelecer as normas para a premiação no sorteio de 1 iPhone 15 128GB na cor preta e R$ 1.500,00 (um mil e quinhentos reais) em produtos de suplemento, o sorteio será realizado dia 28 de dezembro de 2024, através do Instagram – PersonalTrainer.com.br</TextCinza>
        </div>
        <div>
            <TextCinza>2.1. Os R$ 1.500,00 em suplementos serão divididos em seis vales de R$ 250,00 cada um, que devem ser utilizados na loja [Nutricertta](https://www.nutricertta.com.br/).</TextCinza>
        </div>
        <div>
            <TextCinza>2.2. Os prêmios são intransferíveis e não podem ser trocados por valor em dinheiro ou por outro modelo.</TextCinza>
        </div>
        <div>
            <TextCinza>3. PARTICIPAÇÃO</TextCinza>
            <TextCinza>3.1. Para participar do sorteio, o participante deve:</TextCinza>
            <ol>
                <li><TextCinza>Estar cadastrado com seu anúncio no site www.personaltrainer.com.br;</TextCinza></li>
                <li><TextCinza>Ter seu perfil verificado e com seu anúncio ativo até a data do sorteio;</TextCinza></li>
                <li><TextCinza>Publicar um post público em seu Instagram utilizando a arte disponível para download na página do sorteio, incluindo a hashtag #personaltrainer.com.br;</TextCinza></li>
                <li><TextCinza>Ser maior de 18 anos;</TextCinza></li>
                <li><TextCinza>Residir em território nacional.</TextCinza></li>
            </ol>
        </div>
        <div>
            <TextCinza>3.2. Não poderão inscrever-se, concorrer, nem participar da promoção os colaboradores das empresas direta ou indiretamente envolvidas na organização deste sorteio, funcionários, executivos, diretores da promotora e/ou organizadora.</TextCinza>
        </div>
        <div>
            <TextCinza>4. APURAÇÃO DO SORTEIO E DIVULGAÇÃO DO SORTEADO</TextCinza>
            <TextCinza>A apuração do sorteio e divulgação do nome do sorteado ocorrerão no dia 28 de dezembro de 2024, através do Instagram – Personal Trainer às 10:00hrs, na cidade de Cascavel – PR, em transmissão ao vivo.</TextCinza>
        </div>
        <div>
            <TextCinza>4.1. O sorteado será contatado através dos dados cadastrais informados no momento da inscrição na plataforma do Personal Trainer, por telefone e e-mail, e terá o seu nome divulgado nas mídias sociais da página.</TextCinza>
        </div>
        <div>
            <TextCinza>4.2. Em caso de falha técnica ou problema que impeça a realização do sorteio, ou caso o sorteado não atenda aos requisitos, será realizado um novo sorteio no dia 25 de janeiro de 2025, excluindo as pessoas que já foram sorteadas.</TextCinza>
        </div>
        <div>
            <TextCinza>4.3. Não haverá empate. Se o sorteado não cumprir todos os requisitos, um novo sorteio será realizado até que um ganhador que cumpra todos os requisitos seja selecionado.</TextCinza>
        </div>
        <div>
            <TextCinza>5. ENTREGA DO PRÊMIO</TextCinza>
            <TextCinza>5.1. A entrega do prêmio será feita diretamente no site onde será feita a compra dos produtos, já no nome do ganhador e nas informações fornecidas pelo ganhador.</TextCinza>
        </div>
        <div>
            <TextCinza>5.2. O prêmio é pessoal, intransferível e será entregue ao contemplado, livre e desembaraçado de qualquer ônus, no prazo máximo de 30 (trinta) dias corridos, a contar da data de validação de sua contemplação pela auditoria do Sorteio, desde que atendidos todos os requisitos apresentados neste Regulamento.</TextCinza>
        </div>
        <div>
            <TextCinza>5.3. Os sorteados não poderão trocar o prêmio por valor em dinheiro ou por outro modelo.</TextCinza>
        </div>
        <div>
            <TextCinza>5.4. Caso o ganhador não seja encontrado ou não retire o prêmio dentro do prazo de 30 dias, perde automaticamente o direito ao prêmio.</TextCinza>
        </div>
        <div>
            <TextCinza>6. DISPOSIÇÕES GERAIS</TextCinza>
            <TextCinza>6.1. Quaisquer dúvidas, divergências ou situações não previstas no regulamento serão julgadas e decididas de forma soberana e irrecorrível pela promotora Romper.</TextCinza>
        </div>
        <div>
            <TextCinza>6.2. A promotora não poderá ser responsabilizada por inscrições atrasadas, enviadas erroneamente, incompletas, incorretas, inválidas ou imprecisas que tornem impossível o contato e/ou a entrega do prêmio.</TextCinza>
        </div>
        <div>
            <TextCinza>6.3. O promotor não se responsabiliza por nenhuma falha técnica de transmissão, problemas de acesso à internet ou qualquer caso fortuito ou de força maior que possam impedir a participação do usuário.</TextCinza>
        </div>
        <div>
            <TextCinza>6.4. A participação neste sorteio implica na aceitação e total concordância com as regras dispostas no presente regulamento.</TextCinza>
        </div>
        <div>
            <TextCinza>6.5. A promotora reserva-se o direito de alterar o presente regulamento, a qualquer momento, mediante aviso prévio aos participantes e desde que não lhes cause prejuízos.</TextCinza>
        </div>
        <div>
            <TextCinza>6.6. A promotora poderá utilizar a imagem e o nome do ganhador para fins de divulgação do sorteio, respeitando os direitos de privacidade dos participantes.</TextCinza>
        </div>
        <div>
            <TextCinza>7. FORO</TextCinza>
            <TextCinza>7.1. Fica eleito o foro da Comarca de Cascavel - Paraná para dirimir quaisquer questões oriundas deste Regulamento.</TextCinza>
        </div>
        <div>
            <TextCinza>---</TextCinza>
        </div>

    </div>
)

export default SecaoRegulamentoSorteio