import PopupReprovar from "../PopupReprovar/popup-reprovar";
import PopupAprovar from "../PopupAprovar/popup-aprovar";
import { useState, useEffect } from "react";
import "video-react/dist/video-react.css";
import { Player } from "video-react";
import { toast } from "react-toastify";
import Input from "../Input/input";
import PopupBloquear from "../PopupBloquear/popup-bloquear";
import axios from "axios";

import styles from "./anuncio.module.css";
import { useHistory } from "react-router-dom";

const AnuncioView = (props) => {
  const anuncio = props.adDetails;
  const [ad, setAd] = useState(null);
  const [approvePopup, setApprovePopup] = useState(false);
  const [reprovePopup, setReprovePopup] = useState(false);
  const [blockPopup, setBlockPopup] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (ad === null) {
      (async function () {
        await fetchData();
      })();
    }
  }, [ad]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/adm/ad/${props.adDetails.username}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("ptr_145769130149")}`,
          },
        }
      );
      if (response.status === 200) {
        setAd(response.data.data);
      }
    } catch (error) {
      toast.error("Erro ao obter dados");
      console.error(error);
      props.goBack();
    }
  };

  const setAdStatus = async (statusId, rejectionReason) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/adm/ad/${props.adDetails.username}/status`,
        {
          status: statusId,
          rejectionReason,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("ptr_145769130149")}`,
          },
        }
      );
      if (response.status === 200) {
        if (statusId === "Aprovado") {
          toast.success("Anúncio aprovado!");
          setApprovePopup(false);
          props.goBack();
        } else {
          toast.success("Anúncio reprovado!");
          setReprovePopup(false);
          props.goBack();
        }
      }
    } catch (error) {
      toast.error("Erro ao atualizar anunciante!");
      console.error(error);
    }
  };

  const obterDetalhesAtendimento = () => {
    const detalhes = [];

    if (anuncio?.appointment?.type) {
      if (anuncio.appointment.type.length === 2) {
        detalhes.push("Presencial / On-line");
      } else if (anuncio.appointment.type.length === 1) {
        detalhes.push(anuncio.appointment.type[0]);
      }
    }

    if (anuncio?.appointment?.genderPreference) {
      if (anuncio.appointment.genderPreference.length === 2) {
        detalhes.push("Homens e Mulheres");
      } else if (anuncio.appointment.genderPreference.length === 1) {
        detalhes.push(anuncio.appointment.genderPreference[0]);
      }
    }

    if (anuncio?.appointment?.ageGroup) {
      const mapeamentoIdades = {
        Crianças: "Crianças de 12 a 17 anos",
        Adultos: "Adultos de 18 a 50 anos",
        Idosos: "Idosos de 51 anos ou mais",
      };
      anuncio.appointment?.ageGroup.forEach((idade, idx) => {
        if (mapeamentoIdades[idx]) {
          detalhes.push(mapeamentoIdades[idx]);
        }
      });
    }

    if (anuncio?.appointment?.skillLevel?.length > 0) {
      detalhes.push(anuncio.appointment.skillLevel.join(" / "));
    }

    if (anuncio?.appointment?.availableDays && Object.values(anuncio?.appointment?.availableDays).filter(value=>value).length > 0) {
      const availableDaysArray = Object.entries(anuncio?.appointment?.availableDays).filter(entry=>entry[1])
      const abreviacoes = {
        monday: "Seg.",
        tuesday: "Ter.",
        wednesday: "Qua.",
        thursday: "Qui.",
        friday: "Sex.",
        saturday: "Sab.",
        sunday: "Dom."
      };
      const availableDaysAbreviados = availableDaysArray.map(day => abreviacoes[day[0]])
      console.log()
      

      detalhes.push(availableDaysAbreviados.join(" "));
    }

    if (anuncio?.appointment?.availableTime && Object.values(anuncio?.appointment?.availableTime).filter(value=>value).length > 0) {
      const availableTimesArray = Object.entries(anuncio?.appointment?.availableTime).filter(entry=>entry[1])
      const traducoes = {
        morning:"Manhã",
        afternoon:"Tarde",
        evening:"Noite"
      }
      detalhes.push(availableTimesArray.map(time=>traducoes[time[0]]))
    }

    if (anuncio?.appointment?.locationType) {
      const mapeamentoLocal = {
        "Local público": "Atendimento em Local Público",
        "Casa do aluno": "Atendimento na sua casa",
        Academias: "Atendimento na sua academia",
        "Local próprio": "Atendimento no meu Local",
      };
      anuncio.appointment?.locationType.forEach((local) => {
        if (mapeamentoLocal[local]) {
          detalhes.push(mapeamentoLocal[local]);
        }
      });
    }

    return detalhes;
  };

  const blockUser = async (userId) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/adm/user/${userId}/block`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("ptr_145769130149")}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("Usuário bloqueado com sucesso!");
        setBlockPopup(false);
      }
    } catch (error) {
      setBlockPopup(false);
      console.error(error);
      toast.error("Erro ao bloquear usuário!");
    }
  };

  return (
    <>
      {approvePopup && (
        <PopupAprovar
          handleApprove={() => setAdStatus("Aprovado")}
          closePopup={() => setApprovePopup(false)}
        />
      )}
      {reprovePopup && (
        <PopupReprovar
          handleDisaprove={setAdStatus}
          closePopup={() => setReprovePopup(false)}
        />
      )}
      {blockPopup && (
        <PopupBloquear
          handleReport={() => blockUser(props.adDetails.userId._id)}
          closePopup={() => setBlockPopup(false)}
        />
      )}
      <div className={styles["cabecalho"]}>
        <div className={styles["titulo"]}>
          <span className={styles["text"]}>Anúncio</span>
          <span className={styles["textqntd1"]}></span>
        </div>
        <div className={styles["botoes-anuncio-unico"]}>
          <button
            onClick={() => window.open(`/personal/${ad?.username}`, "_blank")}
            className={styles["botao-visao-aluno"]}
          >
            <span className={styles["text39"]}>
              <span>Ver como aluno</span>
            </span>
            <img
              alt="pastedImage"
              src="/external/pastedimage-m9v-200h.png"
              className={styles["viewicon"]}
            />
          </button>
          {props.isNewAd && (
            <>
              <button
                onClick={() => setReprovePopup(true)}
                className={styles["botao-reprovar"]}
              >
                <span className={styles["text41"]}>
                  <span>Reprovar</span>
                </span>
                <img
                  alt="recusadoI476"
                  src="/external/recusadoi476-sf2g.svg"
                  className={styles["recusadoicon"]}
                />
              </button>
              <button
                onClick={() => setApprovePopup(true)}
                className={styles["botao-aprovar"]}
              >
                <span className={styles["text43"]}>
                  <span>Aprovar</span>
                </span>
                <img
                  alt="aprovadoI476"
                  src="/external/aprovadoi476-kjlf.svg"
                  className={styles["aprovadoicon"]}
                />
              </button>
            </>
          )}
        </div>
      </div>
      <div className={styles["anuncio-aprovar"]}>
        <div className={styles["info-pessoais"]}>
          <div className={styles["container3"]}>
            <div className={styles["column"]}>
              <div className={styles["container4"]}>
                <div className={styles["foto-perfil5"]}>
                  <img
                    alt="pastedImage"
                    src={ad?.photo || "/external/default-img.svg"}
                    className={styles["imgpersonal"]}
                  />
                </div>
              </div>
              {ad?.location && (
                <div className={styles["cidade"]}>
                  <img
                    alt="local2694"
                    src="/external/local2694-9dn7.svg"
                    className={styles["localicon"]}
                  />
                  <span className={styles["text046"]}>
                    <span>{ad?.location}</span>
                  </span>
                </div>
              )}
              {ad?.phone && (
                <div className={styles["numero"]}>
                  <img
                    alt="telefone2932"
                    src="/external/telefone2932-n2wc.svg"
                    className={styles["telefoneicon"]}
                  />
                  <span className={styles["numero1"]}>{ad?.phone}</span>
                </div>
              )}
            </div>
            <div className={styles["column1"]}>
              <div className={styles["infos5"]}>
                <div className={styles["nome"]}>
                  <span className={styles["text-denominacao"]}>
                    <span>Personal trainer</span>
                    <br></br>
                  </span>
                  <span className={styles["text-nome5"]}>
                    {ad?.personalName}
                  </span>
                </div>
                <span className={styles["text-descricao5"]}>
                  <span>{ad?.briefDescription}</span>
                </span>
              </div>
              {obterDetalhesAtendimento().length > 0 && (
                <div className={styles["atendimento"]}>
                  <span className={styles["text054"]}>
                    <span>Atendimento</span>
                  </span>
                  <div className={styles["infos6"]}>
                    {obterDetalhesAtendimento().map((detalhe, index) => (
                      <div key={index} className={styles["frame1171276959"]}>
                        <span className={styles["text056"]}>
                          <span>{detalhe}</span>
                        </span>
                      </div>
                    ))}

                    {ad?.languages?.length > 0 && (
                      <div className={styles["linguagem"]}>
                        <img
                          alt="Vector2694"
                          src="/external/vector2694-0p8b.svg"
                          className={styles["idiomaicon"]}
                        />
                        <span className={styles["text070"]}>
                          <span>{anuncio.languages.join(" / ")}</span>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles["redes-sociais"]}>
          <span className={styles["text072"]}>Redes</span>
          <div className={styles["inputs"]}>
            <div className={styles["insta"]}>
              <img
                alt="image1303781"
                src="/external/image1303781-mnuo-200h.png"
                className={styles["instaimg"]}
              />
              <Input
                readOnly="true"
                value={ad?.socialNetworks?.instagram}
              ></Input>
            </div>
            <div className={styles["face"]}>
              <img
                alt="image1313781"
                src="/external/image1313781-lopp-200h.png"
                className={styles["faceimg"]}
              />
              <Input
                readOnly="true"
                value={ad?.socialNetworks?.facebook}
              ></Input>
            </div>
            <div className={styles["tiktok"]}>
              <img
                alt="image1333781"
                src="/external/image1333781-dfu4-200h.png"
                className={styles["ttkimg"]}
              />
              <Input readOnly="true" value={ad?.socialNetworks?.tiktok}></Input>
            </div>
            <div className={styles["linked-in"]}>
              <img
                alt="image1323781"
                src="/external/image1323781-m09-200h.png"
                className={styles["linkedinimg"]}
              />
              <Input
                readOnly="true"
                value={ad?.socialNetworks?.linkedin}
              ></Input>
            </div>
            <div className={styles["rede"]}>
              <img
                alt="pastedImage"
                src="/external/pastedimage-i5e-200h.png"
                className={styles["redeimg"]}
              />
              <Input
                readOnly="true"
                value={ad?.socialNetworks?.website}
              ></Input>
            </div>
          </div>
        </div>
        {ad?.services && ad?.services.length > 0 && (
          <div className={styles["objetivos"]}>
            <span className={styles["text073"]}>
              <span>
                Este profissional pode ajudar você nos seguintes objetivos:
              </span>
            </span>
            <div className={styles["container5"]}>
              {ad.services.map((servico, index) => (
                <div
                  key={index}
                  className={
                    styles[
                      `objetivo${
                        servico.title === "Apoio nutricional" ? "-extra" : ""
                      }`
                    ]
                  }
                >
                  <span className={styles["text075"]}>
                    <span>{servico.title}</span>
                  </span>
                  <span className={styles["text045"]}>
                    <span>{servico.description}</span>
                  </span>
                </div>
              ))}
            </div>
          </div>
        )}
        <div className={styles["infos-sobre"]}>
          {ad?.biography && (
            <div className={styles["sobre"]}>
              <span className={styles["text085"]}>
                <span>Sobre o Personal Trainer</span>
              </span>
              <span className={styles["text087"]}>
                <span>{ad?.biography}</span>
              </span>
            </div>
          )}
          {ad?.trainingMethods && (
            <div className={styles["metodos"]}>
              <span className={styles["text089"]}>
                <span>Métodos</span>
              </span>
              <span className={styles["text091"]}>
                <span>{ad?.trainingMethods}</span>
              </span>
            </div>
          )}
          {ad?.qualifications && (
            <div className={styles["qualificacoes"]}>
              <span className={styles["text093"]}>
                <span>Qualificações</span>
              </span>
              <span className={styles["text095"]}>
                <span>{ad?.qualifications}</span>
              </span>
            </div>
          )}
          {ad?.pricing && (
            <div className={styles["planos"]}>
              <span className={styles["text104"]}>
                <span>Preços e planos</span>
              </span>
              {ad?.pricing?.details && (
                <span className={styles["text106"]}>
                  <span>{ad?.pricing?.details}</span>
                </span>
              )}
              <div className={styles["online-presencial"]}>
                {ad.pricing.online && (
                  <div className={styles["online"]}>
                    <span className={styles["text108"]}>
                      <span className={styles["text109"]}>
                        Online - a partir de
                        <span
                          dangerouslySetInnerHTML={{
                            __html: " ",
                          }}
                        />
                      </span>
                      <span className={styles["text110"]}>
                        R$ {ad?.pricing?.online}/h
                      </span>
                    </span>
                  </div>
                )}
                {ad.pricing?.inPerson && (
                  <div className={styles["presencial"]}>
                    <span className={styles["text111"]}>
                      <span className={styles["text112"]}>
                        Presencial - a partir de
                        <span
                          dangerouslySetInnerHTML={{
                            __html: " ",
                          }}
                        />
                      </span>
                      <span className={styles["text113"]}>
                        R$ {ad?.pricing?.inPerson}/h
                      </span>
                    </span>
                  </div>
                )}
                {!ad.pricing?.inPerson && !ad.pricing.online && (
                  <div className={styles["presencial"]}>
                    <span className={styles["text111"]}>
                      <span className={styles["text112"]}>A combinar</span>
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>

        <div className={styles["midia"]}>
          <span className={styles["text114"]}>
            <span>Fotos e Videos</span>
          </span>
          {ad && ad.media && ad.media.length && (
            <div className={styles["lista1"]}>
              {ad.media.map((m, idx) => {
                if (m.type === "image") {
                  return (
                    <div className={styles["foto"]}>
                      <img
                        alt="image"
                        src={m.url}
                        className={styles["foto-galeria"]}
                      />
                    </div>
                  );
                } else {
                  return (
                    <div className={styles["midia1"]}>
                      <Player>
                        <source src={m.url} />
                      </Player>
                    </div>
                  );
                }
              })}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default AnuncioView;
