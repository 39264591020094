import Header from "../../components/Header/header"
import Rodape from "../../components/Rodape/rodape"
import SecaoComoParticipar from "./components/SecaoComoParticipar"
import SecaoRegulamentoSorteio from "./components/SecaoRegulamentoSorteio"
import styles from "./regulamento.module.css"

const Regulamento = () => {
    return (
        <div className={styles['container-pagina']}>
            <Header />

            <div className={styles['corpo-pagina']}>
                <h2 className={styles['titulo-pagina']}>Promoção</h2>
                <div className={styles['divisor-titulo-mobile']}></div>
                <SecaoComoParticipar/>
                <div className={styles['divisor-titulo-mobile']}></div>
                <SecaoRegulamentoSorteio/>

            </div>

            <Rodape />
        </div>
    )
}

export default Regulamento