import { SET_USER_DATA } from '../actions/usuarioActions';

const initialState = null;

const usuarioReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      if (action.payload !== undefined) {
        return action.payload;
      }
      return state;
    default:
      return state;
  }
};


export default usuarioReducer;