import React from 'react'

import Header from '../../components/Header/header'
import Rodape from '../../components/Rodape/rodape'
import styles from './politicade-privacidade.module.css'

const PoliticadePrivacidade = (props) => {
  return (
    <div className={styles['container']}>
      <Header />
      <div className={styles['container1']}>
        <div className={styles['conteudo']}>
          <span className={styles['text']}>
            <span>Políticas de privacidade</span>
            <br></br>
          </span>
          <span className={styles['text03']}>
          <p>          1. Identificação do Controlador:<br></br>
          Nós, <strong>ROMPER ADMINISTRADORA DE MARCAS EIRELI,</strong> neste ato denominada, somente, ROMPER, inscrita no CNPJ sob o nº 18.461.120/0001-51, somos responsáveis pelo tratamento dos seus dados pessoais. Para questões relacionadas à privacidade, você pode entrar em contato conosco por meio de e-mail: atendimento@romper.com.br e telefone (45) 3306-6454.
          </p>
          <p>          2. Finalidades do Tratamento:<br></br>
          Coletamos e processamos seus dados pessoais com o propósito de prestarmos serviços de publicidade, sempre respeitando a Lei Geral de Proteção de Dados. </p>
          <p>
          3. Base Legal:<br></br>
          O tratamento dos seus dados é realizado com base na obtenção do seu consentimento, que resta explicito através da aceitação dos termos gerais de uso da plataforma, cujo manifestação pela mera utilização da mesma. 
          O usuário tem o direito de retirar o seu consentimento a qualquer momento.
          </p>
          <p>
          4. Tipos de Dados Coletados:<br></br>
          Os tipos de dados que coletamos podem incluir nome completo, endereço, CPF, e-mail informações de contato, de acordo com a necessidade da finalidade estabelecida. 
          </p>
          <p>
          5. Compartilhamento de Dados:<br></br>
          O tratamento dos dados estão limitados ao Grupo Romper, sempre com a finalidade de cumprir os objetivos mencionados na seção 2, inexistindo compartilhamento com pessoa físicas e/ ou jurídicas alheias.  
          </p>
          <p>
          6. Tempo de Retenção:<br></br>
          Seus dados serão retidos pelo tempo necessário para cumprir as finalidades mencionadas, e serão excluídos de forma segura quando não forem mais necessários, não havendo um período médio para tanto. 
          </p>
          <p>
          7. Direitos dos Titulares:<br></br>
          Você tem direito a acessar, corrigir, atualizar ou solicitar a exclusão dos seus dados pessoais. Também pode solicitar a restrição do tratamento ou a portabilidade dos seus dados. Para exercer esses direitos, entre em contato conosco por meio das informações fornecidas na seção 1.
          </p>
          <p>          Esta política de privacidade está sujeita a atualizações, e quaisquer alterações serão devidamente comunicadas a você. </p>
          <p>          Última atualização: 06 de novembro de 2023.</p>
          </span>
        </div>
      </div>
      <Rodape></Rodape>
    </div>
  )
}


export default PoliticadePrivacidade
