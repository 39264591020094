import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useParams, useHistory, useLocation } from "react-router-dom";
import axios from "axios";
import { Player } from "video-react";
import "video-react/dist/video-react.css";

import Header from "../../components/Header/header";
import Rodape from "../../components/Rodape/rodape";
import Denunciar from "../../components/Denunciar/denunciar";
import { useSelector } from "react-redux";
import styles from "./perfil.module.css";
import { toast } from "react-toastify";
import { formatPhoneNumber } from "../../utils";

const objetivos = {
  Emagrecimento:
    "Focada na perda de peso de forma saudável e eficaz. Promova resultados duradouros melhorando a autoestima e bem-estar.",
  "Músculo e Definição":
    "Treinos elaborados para trabalhar os mais variados grupos musculares e favorecer a hipertrofia muscular.",
  "Fitness Total e Dinâmico":
    "Treinos que combinam diferentes modalidades, garantindo condicionamento completo, melhorando sua resistência, força e agilidade.",
  "Preparo para Eventos":
    "Treinamentos focados em preparação para competições, maratonas e concursos (teste de aptidão física).",
  "Flexibilidade e bem-estar":
    "Para aprimoramento de postura, flexibilidade e equilíbrio, através de métodos como Yoga, Pilates e outros.",
  Recuperação:
    "Treinos especializados que focam na reabilitação, recuperação de lesões e retorno seguro às atividades.",
  "Maternidade Ativa":
    "Treinos seguros e eficazes para gestantes e mães recentes, mantendo a saúde em dia.",
  "Treinamento esportivo":
    "Eleva atletas e entusiastas ao próximo nível, focando em técnicas e desempenho para esportes específicos.",
  "Treino Sênior":
    "Promova saúde e vitalidade na terceira idade, com treinos pensados no conforto, segurança e benefícios para os idosos.",
  "Fitness Infantil e adolescente":
    "Treinos adequados para crianças e adolescentes (até 17 anos), promovem diversão e desenvolvimento físico.",
  "Apoio nutricional":
    "Ofereça suporte nutricional, com orientações personalizadas, garantindo que a dieta complemente seus treinos de exercícios físicos.",
};

const Perfil = (props) => {
  const usuario = useSelector((state) => state.usuario);
  const { slug } = useParams();
  const history = useHistory();
  const [anuncio, setAnuncio] = useState({});
  const [redesMobileVisible, setRedesMobileVisible] = useState(false);
  const [popupDenunciaVisible, setPopupDenunciaVisible] = useState(false);
  const [verNumeroCompleto, setVerNumeroCompleto] = useState(false);
  const [palavrasChaves, setPalavrasChaves] = useState([]);
  const [itensGaleria, setItensGaleria] = useState([]);
  const [phone, setPhone] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [otherAds, setOtherAds] = useState([]);
  const [galeriaSlider, setGaleriaSlider] = useState({
    deslocamentoMaximo: null,
    deslocamento: null,
    firstItem: null,
    itemAtual: null,
    quantidadePaginas: null,
    direcao: null,
    active: false,
  });
  const [comentariosSlider, setComentariosSlider] = useState({
    deslocamentoMaximo: null,
    deslocamento: null,
    firstItem: null,
    itemAtual: null,
    quantidadePaginas: null,
    direcao: null,
    active: false,
  });
  const [reviews, setReviews] = useState([]);
  const [averageRating, setAverageRating] = useState(0)
  const carrosselRef = useRef(null);
  const carrosselAvaliacoesRef = useRef(null);
  const listaAvaliacoesRef = useRef(null);

  const getMunicipality = (location) => {
    if (location) {
      let [split, ...second] = location.split(" ");
      second = second.join(" ");

      return split;
    }
    return null;
  };

  const fetchOthersAds = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/trainer-ads`,
        {
          params: {
            // city: getMunicipality(anuncio?.location),
          },
        }
      );
      setOtherAds(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const obterPrecoFormatado = () => {
    if (!anuncio?.pricing) {
      return "Valor a combinar";
    }

    const { inPerson, online } = anuncio.pricing;

    if ((typeof inPerson !== "number" && typeof online !== "number") || (inPerson === 0 && online === 0)) {
      return "Valor a combinar";
    }

    let valorMenor =
      typeof inPerson !== "number"
        ? online
        : typeof online !== "number"
        ? inPerson
        : Math.min(inPerson, online);

    return (
      <>
        A partir de{" "}
        <strong>
          R$ {new Intl.NumberFormat("pt-BR").format(valorMenor)}/h
        </strong>
      </>
    );
  };

  const obterDetalhesAtendimento = () => {
    const detalhes = [];

    if (anuncio?.appointment?.type) {
      if (anuncio.appointment.type.length === 2) {
        detalhes.push("Presencial / On-line");
      } else if (anuncio.appointment.type.length === 1) {
        detalhes.push(anuncio.appointment.type[0]);
      }
    }

    if (anuncio?.appointment?.genderPreference) {
      if (anuncio.appointment.genderPreference.length === 2) {
        detalhes.push("Homens e Mulheres");
      } else if (anuncio.appointment.genderPreference.length === 1) {
        detalhes.push(anuncio.appointment.genderPreference[0]);
      }
    }

    if (anuncio?.appointment?.ageGroup) {
      const mapeamentoIdades = {
        Crianças: "Crianças de 12 a 17 anos",
        Adultos: "Adultos de 18 a 50 anos",
        Idosos: "Idosos de 51 anos ou mais",
      };
      anuncio.appointment.ageGroup.forEach((idade) => {
        if (mapeamentoIdades[idade]) {
          detalhes.push(mapeamentoIdades[idade]);
        }
      });
    }

    if (anuncio?.atendimento?.skillLevel?.length > 0) {
      detalhes.push(anuncio.appointment.skillLevel.join(" / "));
    }

    if (anuncio?.appointment?.availableDays && Object.values(anuncio?.appointment?.availableDays).filter(value=>value).length > 0) {
      const availableDaysArray = Object.entries(anuncio?.appointment?.availableDays).filter(entry=>entry[1])
      const abreviacoes = {
        monday: "Seg.",
        tuesday: "Ter.",
        wednesday: "Qua.",
        thursday: "Qui.",
        friday: "Sex.",
        saturday: "Sab.",
        sunday: "Dom."
      };
      const availableDaysAbreviados = availableDaysArray.map(day => abreviacoes[day[0]])
      console.log()
      

      detalhes.push(availableDaysAbreviados.join(" "));
    }

    if (anuncio?.appointment?.availableTime && Object.values(anuncio?.appointment?.availableTime).filter(value=>value).length > 0) {
      const availableTimesArray = Object.entries(anuncio?.appointment?.availableTime).filter(entry=>entry[1])
      const traducoes = {
        morning:"Manhã",
        afternoon:"Tarde",
        evening:"Noite"
      }
      detalhes.push(availableTimesArray.map(time=>traducoes[time[0]]).join('/'))
    }

    if (anuncio?.appointment?.locationType) {
      const mapeamentoLocal = {
        "Local público": "Atendimento em Local Público",
        "Casa do aluno": "Atendimento na sua casa",
        Academias: "Atendimento na sua academia",
        "Local próprio": "Atendimento no meu Local",
      };
      anuncio.appointment.locationType.forEach((local) => {
        if (mapeamentoLocal[local]) {
          detalhes.push(mapeamentoLocal[local]);
        }
      });
    }

    return detalhes;
  };

  const handleCompartilhar = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: `Personal trainer${` em ${anuncio.location}` || ""}`,
          text: "Dá uma olhada nos métodos de treinamento dele:",
          url: `https://personaltrainer.com.br/personal/${anuncio.username}`,
        });
      } catch (error) {
        console.error("Algo deu errado ao compartilhar:", error);
      }
    } else {
      copiarTexto(
        `https://personaltrainer.com.br/personal/${anuncio.username}`
      );
      toast.success("Link do perfil copiado para a área de transferência!");
    }
  };

  const copiarTexto = (text) => {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
  };

  const verNumeroTelefone = () => {
    setVerNumeroCompleto(true);

    contabilizarTelefone();
  };

  const gerarPalavrasChaves = () => {
    const tags = [];

    if (anuncio.personalName) {
      tags.push(`Personal trainer ${anuncio.personalName}`);
    }

    if (anuncio.appointment.type) {
      anuncio.appointment.type.forEach((tipo) => {
        tags.push(`Personal trainer ${tipo.toLowerCase()}`);
      });
    }

    const cidade = anuncio.location;
    if (cidade) {
      tags.push(`Personal trainer em ${cidade}`);
    }

    if (anuncio.services) {
      anuncio.services.forEach((servico) => {
        tags.push(`Personal trainer ${servico.title.toLowerCase()}`);
      });
    }

    const generoMap = {
      Masculino: "homem",
      Feminino: "mulher",
    };
    const genero = generoMap[anuncio.gender];
    if (genero) {
      tags.push(`Personal trainer ${genero}`);
    }

    const atendimentoGeneroMap = {
      Homens: "homem",
      Mulheres: "mulher",
    };
    if (anuncio.appointment.genderPreference) {
      anuncio.appointment.genderPreference.forEach((g) => {
        const atendimentoGenero = atendimentoGeneroMap[g];
        if (atendimentoGenero) {
          tags.push(`Personal trainer para ${atendimentoGenero}`);
        }
      });
    }

    const idadeMap = {
      Crianças: "criança",
      Adultos: "adulto",
      Idosos: "idoso",
    };
    if (anuncio.appointment.ageGroup) {
      anuncio.appointment.ageGroup.forEach((idade) => {
        const idadeDescricao = idadeMap[idade];
        if (idadeDescricao) {
          tags.push(`Personal trainer para ${idadeDescricao}`);
        }
      });
    }

    const nivelMap = {
      Iniciante: "treino iniciante",
      Intermediário: "treino intermediário",
      Avançado: "treino avançado",
    };
    if (anuncio.appointment.skillLevel) {
      anuncio.appointment.skillLevel.forEach((nivel) => {
        const nivelDescricao = nivelMap[nivel];
        if (nivelDescricao) {
          tags.push(`Personal trainer para ${nivelDescricao}`);
        }
      });
    }

    const periodoMap = {
      Manhã: "de manhã",
      Tarde: "de tarde",
      Noite: "de noite",
    };
    // if (anuncio.appointment.horarios.periodos) {
    //     anuncio.appointment.horarios.periodos.forEach(periodo => {
    //         const periodoDescricao = periodoMap[periodo];
    //         if (periodoDescricao) {
    //             tags.push(`Personal trainer ${periodoDescricao}`);
    //         }
    //     });
    // }

    if (anuncio.languages) {
      anuncio.languages.forEach((idioma) => {
        tags.push(`Personal trainer que fala ${idioma.toLowerCase()}`);
      });
    }

    if (tags.length > 0) setPalavrasChaves(tags);
  };

  const gerarGaleria = () => {
    setItensGaleria(anuncio.media);
  };

  const slide = (direction) => {
    if (direction === 1) {
      setGaleriaSlider({
        ...galeriaSlider,
        itemAtual:
          galeriaSlider.itemAtual === itensGaleria.length - 1
            ? galeriaSlider.firstItem
            : galeriaSlider.itemAtual + 1,
        deslocamento:
          galeriaSlider.itemAtual === itensGaleria.length - 1
            ? 0
            : galeriaSlider.deslocamento,
        direcao:
          galeriaSlider.itemAtual === itensGaleria.length - 1 ? "prev" : "next",
      });
    } else {
      setGaleriaSlider({
        ...galeriaSlider,
        itemAtual:
          galeriaSlider.itemAtual <= galeriaSlider.firstItem
            ? itensGaleria.length - 1
            : galeriaSlider.itemAtual - 1,
        deslocamento:
          galeriaSlider.itemAtual <= galeriaSlider.firstItem
            ? galeriaSlider.deslocamentoMaximo
            : galeriaSlider.deslocamento,
        direcao:
          galeriaSlider.itemAtual <= galeriaSlider.firstItem ? "next" : "prev",
      });
    }
  };

  const slideComentarios = (direction) => {
    if (direction === 1) {
      setComentariosSlider({
        ...comentariosSlider,
        itemAtual:
          comentariosSlider.itemAtual === anuncio?.avaliacoes?.lista?.length - 1
            ? comentariosSlider.firstItem
            : comentariosSlider.itemAtual + 1,
        deslocamento:
          comentariosSlider.itemAtual === anuncio?.avaliacoes?.lista?.length - 1
            ? 0
            : comentariosSlider.deslocamento,
        direcao:
          comentariosSlider.itemAtual === anuncio?.avaliacoes?.lista?.length - 1
            ? "prev"
            : "next",
      });
    } else {
      setComentariosSlider({
        ...comentariosSlider,
        itemAtual:
          comentariosSlider.itemAtual <= comentariosSlider.firstItem
            ? anuncio?.avaliacoes?.lista?.length - 1
            : comentariosSlider.itemAtual - 1,
        deslocamento:
          comentariosSlider.itemAtual <= comentariosSlider.firstItem
            ? comentariosSlider.deslocamentoMaximo
            : comentariosSlider.deslocamento,
        direcao:
          comentariosSlider.itemAtual <= comentariosSlider.firstItem
            ? "next"
            : "prev",
      });
    }
  };

  const contabilizarTelefone = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/ad-stats/increment-stat`,
        {
          trainerAd: anuncio._id,
          metric: "phoneClicks",
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const contabilizarWhatsapp = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/ad-stats/increment-stat`,
        {
          trainerAd: anuncio._id,
          metric: "whatsappClicks",
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const contabilizarVisitas = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/ad-stats/increment-stat`,
        {
          trainerAd: anuncio._id,
          metric: "visits",
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAllReviews = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/reviews/reviews-by-trainer`,
        {
          params: {
            username: anuncio.username,
            page: currentPage,
          },
        }
      );
      console.log(reviews, response.data.data)
      setReviews(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    (async function () {
      await fetchAllReviews();
      await fetchOthersAds();
    })();
  }, [anuncio]);

  useEffect(() => {
    if (anuncio?.username) {
      contabilizarVisitas();
    }
  }, [anuncio?.username]);

  useEffect(() => {
    if (carrosselRef.current) {
      const items = carrosselRef.current.children;
      const ultrapassou =
        galeriaSlider.deslocamento +
          items[galeriaSlider.itemAtual]?.offsetWidth >
        galeriaSlider.deslocamentoMaximo;

      if (galeriaSlider.itemAtual === items.length - 1 && !ultrapassou) {
        setGaleriaSlider({
          ...galeriaSlider,
          itemAtual: galeriaSlider.firstItem,
          direcao: "prev",
        });
      } else {
        if (galeriaSlider.direcao === "next") {
          setGaleriaSlider({
            ...galeriaSlider,
            deslocamento: ultrapassou
              ? galeriaSlider.deslocamentoMaximo
              : galeriaSlider.itemAtual === galeriaSlider.firstItem
              ? galeriaSlider.firstItem
              : galeriaSlider.deslocamento +
                items[galeriaSlider.itemAtual]?.offsetWidth,
          });
        } else {
          setGaleriaSlider({
            ...galeriaSlider,
            deslocamento:
              galeriaSlider.itemAtual === galeriaSlider.firstItem
                ? 0
                : galeriaSlider.deslocamento -
                  items[galeriaSlider.itemAtual + 1]?.offsetWidth,
          });
        }
      }
    }
  }, [galeriaSlider.itemAtual]);

  useEffect(() => {
    if (galeriaSlider.deslocamento !== null) {
      carrosselRef.current.style.marginLeft = `-${galeriaSlider.deslocamento}px`;
    }
  }, [galeriaSlider.deslocamento]);

  useEffect(() => {
    if (itensGaleria.length > 0 && carrosselRef.current) {
      const items = carrosselRef.current.children;
      let maxWidth = 0;
      let paginas = 0;
      let primeiroItemIndex = 0;

      if (Object.values(galeriaSlider).some((el) => el === null)) {
        Array.from(items).forEach((item, i) => {
          if (i === 0) {
            maxWidth += item?.offsetWidth;
          } else {
            maxWidth += item?.offsetWidth + 10;
            if (maxWidth > carrosselRef.current?.offsetWidth) {
              paginas += 1;
              if (!primeiroItemIndex) primeiroItemIndex = i;
            }
          }
        });

        let setData = galeriaSlider;

        if (galeriaSlider.deslocamentoMaximo === null)
          setData.deslocamentoMaximo =
            maxWidth - carrosselRef.current?.offsetWidth;
        if (galeriaSlider.itemAtual === null)
          setData.itemAtual = primeiroItemIndex - 1;
        if (galeriaSlider.quantidadePaginas === null)
          setData.quantidadePaginas = paginas;
        if (galeriaSlider.deslocamento === null) setData.deslocamento = 0;
        if (galeriaSlider.firstItem === null)
          setData.firstItem = primeiroItemIndex - 1;
        setGaleriaSlider(setData);

        if (setData.quantidadePaginas) {
          setGaleriaSlider({
            ...galeriaSlider,
            active: true,
          });
        }
      }
    }
  }, [itensGaleria]);

  useEffect(() => {
    if (carrosselAvaliacoesRef.current) {
      const items = carrosselAvaliacoesRef.current.children;
      const ultrapassou =
        comentariosSlider.deslocamento +
          items[comentariosSlider.itemAtual]?.offsetWidth >
        comentariosSlider.deslocamentoMaximo;

      if (comentariosSlider.itemAtual === items.length - 1 && !ultrapassou) {
        setComentariosSlider({
          ...comentariosSlider,
          itemAtual: comentariosSlider.firstItem,
          direcao: "prev",
        });
      } else {
        if (comentariosSlider.direcao === "next") {
          setComentariosSlider({
            ...comentariosSlider,
            deslocamento: ultrapassou
              ? comentariosSlider.deslocamentoMaximo
              : comentariosSlider.itemAtual === comentariosSlider.firstItem
              ? comentariosSlider.firstItem
              : comentariosSlider.deslocamento +
                items[comentariosSlider.itemAtual]?.offsetWidth,
          });
        } else {
          setComentariosSlider({
            ...comentariosSlider,
            deslocamento:
              comentariosSlider.itemAtual === comentariosSlider.firstItem
                ? 0
                : comentariosSlider.deslocamento -
                  items[comentariosSlider.itemAtual + 1]?.offsetWidth,
          });
        }
      }
    }
  }, [comentariosSlider.itemAtual]);

  useEffect(() => {
    if (comentariosSlider.deslocamento !== null) {
      carrosselAvaliacoesRef.current.style.marginLeft = `-${comentariosSlider.deslocamento}px`;
    }
  }, [comentariosSlider.deslocamento]);

  useEffect(() => {
    if (
      anuncio?.avaliacoes?.lista?.length > 0 &&
      carrosselAvaliacoesRef.current
    ) {
      const items = carrosselAvaliacoesRef.current.children;
      let maxWidth = 0;
      let paginas = 0;
      let primeiroItemIndex = 0;

      if (Object.values(comentariosSlider).some((el) => el === null)) {
        Array.from(items).forEach((item, i) => {
          if (i === 0) {
            maxWidth += item?.offsetWidth;
          } else {
            maxWidth += item?.offsetWidth + 16;
            if (maxWidth > carrosselAvaliacoesRef.current?.offsetWidth) {
              paginas += 1;
              if (!primeiroItemIndex) primeiroItemIndex = i;
            }
          }
        });

        let setData = comentariosSlider;

        if (comentariosSlider.deslocamentoMaximo === null)
          setData.deslocamentoMaximo =
            maxWidth - carrosselAvaliacoesRef.current?.offsetWidth;
        if (comentariosSlider.itemAtual === null)
          setData.itemAtual = primeiroItemIndex - 1;
        if (comentariosSlider.quantidadePaginas === null)
          setData.quantidadePaginas = paginas;
        if (comentariosSlider.deslocamento === null) setData.deslocamento = 0;
        if (comentariosSlider.firstItem === null)
          setData.firstItem = primeiroItemIndex - 1;
        setComentariosSlider(setData);

        if (setData.quantidadePaginas) {
          setComentariosSlider({
            ...comentariosSlider,
            active: true,
          });
        }
      }
    }
  }, [anuncio?.avaliacoes?.lista]);

  useEffect(() => {
    const fetchAnuncio = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/trainer-ads/${slug.replace(
            "slug=",
            ""
          )}`,
          {
            params: { userRole: usuario?.role }
          }
        );
        const responseAd = response.data.data
        console.log(responseAd, response.data)
        const publicAd = responseAd.isActive && responseAd.status === 'Aprovado'
        if(!publicAd && usuario.role !== 'admin' && usuario._id !== responseAd.userId) throw new Error('Anúncio bloqueado')

        setAnuncio(responseAd);
      } catch (error) {
        console.log(error);
        history.push("/404");
      }
    };

    if (slug) {
      fetchAnuncio();
    }
  }, [slug, history]);

  useEffect(() => {
    if (popupDenunciaVisible) {
      document.querySelector("body").style.overflow = "hidden";
    } else {
      document.querySelector("body").style = "";
    }
  }, [popupDenunciaVisible]);

  useEffect(() => {
    if (Object.values(anuncio).filter((v) => v !== null).length > 0) {
      if (palavrasChaves.length === 0) gerarPalavrasChaves();
      if (anuncio?.media.length > 0) {
        gerarGaleria();
      }
    }
  }, [anuncio]);

  const handleTel = async () => {
    setPhone(anuncio?.userDetails?.phone);

    await contabilizarTelefone();
  };

  useEffect(() => {
    if(reviews && reviews.length > 0) {
      setAverageRating(reviews.reduce((prev, curr) => prev.rating + curr.rating, {rating: 0})/reviews.length)
    }
  }, [reviews])

  return anuncio && Object.keys(anuncio)?.length > 0 ? (
    <div className={styles["container"]}>
      <Helmet>
        <meta name="keywords" content={palavrasChaves.join(", ")} />
        <title>{`Personal Trainer ${anuncio.personalName} ${
          anuncio.location || ""
        }`}</title>
        <meta
          name="description"
          content={`Encontre o melhor personal trainer em ${
            anuncio?.location
          }. Especializad${
            anuncio.gender === "Feminino" ? "a" : "o"
          } em: ${anuncio.services?.join(", ")}.`}
        />
      </Helmet>

      {!(props.paginaExlusiva && anuncio?.planDetails?.exclusivePage) && <Header />}
      <div className={`${styles["container1"]} ${(props.paginaExlusiva && anuncio?.planDetails?.exclusivePage) ? styles["container1-paginaExclusiva"] : ''}`}>
        <div className={ styles["perfil-trainer"] }>
          <div className={styles["info-pessoais"]}>
            <div className={styles["container2"]}>
              <div className={styles["column"]}>
                <div className={styles["container3"]}>
                  <div className={styles["foto-perfil"]}>
                    <img
                      alt="pastedImage"
                      src={anuncio?.photo}
                      className={styles["imgpersonal"]}
                    />
                  </div>
                </div>
                {anuncio?.planDetails?.verifiedContact && (
                  <div className={styles["verificado-desk"]}>
                    <img
                      alt="registros3401"
                      src="/external/registros3401-dfalv.svg"
                      className={styles["verificadoicon"]}
                    />
                    <span className={styles["text"]}>
                      <span>Perfil verificado</span>
                    </span>
                  </div>
                )}
                <div className={styles["nome-local-mobile"]}>
                  <span className={styles["text-denominacao"]}>
                    <span>Personal trainer em {anuncio.location || ""}</span>
                    <br></br>
                  </span>
                  <span className={styles["text-nome"]}>
                    {anuncio.personalName}
                  </span>
                </div>
                <div className={styles["avaliacao-cidade"]}>
                  {reviews.length > 0 && (
                    <div
                      className={styles["avaliacao"]}
                      onClick={() => {
                        if (listaAvaliacoesRef.current) {
                          listaAvaliacoesRef.current.scrollIntoView({
                            behavior: "smooth",
                          });
                        }
                      }}
                    >
                      <div className={styles["nota"]}>
                        <img
                          alt="Vector2694"
                          src="/external/vector2694-q0n.svg"
                          className={styles["vector"]}
                        />
                        <span className={styles["text004"]}>
                          {averageRating.toFixed(1).replace(".", ",")}
                          <span
                            dangerouslySetInnerHTML={{
                              __html: " ",
                            }}
                          />
                        </span>
                      </div>
                      <div className={styles['textquantidade']}>{reviews.length} avaliações</div>
                    </div>
                  )}
                  {anuncio?.location && (
                    <div className={styles["cidade"]}>
                      <img
                        alt="local2694"
                        src="/external/local2694-9dn7.svg"
                        className={styles["localicon"]}
                      />
                      <span className={styles["text006"]}>
                        <span>{anuncio?.location}</span>
                      </span>
                    </div>
                  )}
                </div>
                {anuncio?.userDetails?.phone && (
                  <div className={styles["numero-desk"]}>
                    <img
                      alt="telefone2932"
                      src="/external/telefone2932-n2wc.svg"
                      className={styles["telefoneicon"]}
                    />
                    <span className={styles["numero"]}>
                      {verNumeroCompleto
                        ? formatPhoneNumber(anuncio.userDetails.phone)
                        : `${formatPhoneNumber(anuncio.userDetails.phone).substring(0, 5)}...`}
                    </span>
                    {!verNumeroCompleto && (
                      <span
                        className={styles["botaovertelefone"]}
                        onClick={verNumeroTelefone}
                      >
                        <span>Ver telefone</span>
                      </span>
                    )}
                  </div>
                )}

                <div className={styles["container4"]}>
                  <div
                    className={styles["redes-abertas-mobile"]}
                    style={{ gap: redesMobileVisible ? "8px" : "0px" }}
                  >
                    <img
                      alt="Xfechar3612"
                      src="/external/xfechar3612-qc35.svg"
                      className={styles["xfechar"]}
                      style={
                        redesMobileVisible
                          ? { opacity: "1", visibility: "visible" }
                          : {}
                      }
                      onClick={() => {
                        setRedesMobileVisible(false);
                      }}
                    />
                    {anuncio?.socialNetworks?.website && (
                      <div
                        className={styles["rede"]}
                        onClick={() => {
                          if (redesMobileVisible) {
                            window.open(anuncio?.socialNetworks?.website);
                          } else {
                            setRedesMobileVisible(true);
                          }
                        }}
                      >
                        <img
                          alt="Ellipse2182382"
                          src="/external/ellipse2182382-1gou-200h.png"
                          className={styles["ellipse218"]}
                        />
                        <img
                          alt="IconsBoldnetwork2382"
                          src="/external/iconsboldnetwork2382-xpcw.svg"
                          className={styles["icons-boldnetwork"]}
                        />
                      </div>
                    )}
                    {anuncio?.socialNetworks?.tiktok && (
                      <img
                        alt="image1332382"
                        src="/external/image1332382-g1v8-200h.png"
                        className={styles["tiktok"]}
                        style={{
                          marginLeft: redesMobileVisible ? "0px" : "-8px",
                        }}
                        onClick={() => {
                          if (redesMobileVisible) {
                            window.open(anuncio?.socialNetworks?.tiktok);
                          } else {
                            setRedesMobileVisible(true);
                          }
                        }}
                      />
                    )}
                    {anuncio?.socialNetworks.instagram && (
                      <img
                        alt="image1302382"
                        src="/external/image1302382-713u-200h.png"
                        className={styles["insta"]}
                        style={{
                          marginLeft: redesMobileVisible ? "0px" : "-8px",
                        }}
                        onClick={() => {
                          if (redesMobileVisible) {
                            window.open(anuncio?.socialNetworks?.instagram);
                          } else {
                            setRedesMobileVisible(true);
                          }
                        }}
                      />
                    )}
                    {anuncio?.socialNetworks?.facebook && (
                      <img
                        alt="image1312382"
                        src="/external/image1312382-t3qq-200h.png"
                        className={styles["face"]}
                        style={{
                          marginLeft: redesMobileVisible ? "0px" : "-8px",
                        }}
                        onClick={() => {
                          if (redesMobileVisible) {
                            window.open(anuncio?.socialNetworks?.facebook);
                          } else {
                            setRedesMobileVisible(true);
                          }
                        }}
                      />
                    )}
                    {anuncio?.socialNetworks?.instagram && (
                      <img
                        alt="image1342382"
                        src="/external/image1342382-bc8n-200h.png"
                        className={styles["linke-in"]}
                        style={{
                          marginLeft: redesMobileVisible ? "0px" : "-8px",
                        }}
                        onClick={() => {
                          if (redesMobileVisible) {
                            window.open(anuncio?.socialNetworks?.linkedin);
                          } else {
                            setRedesMobileVisible(true);
                          }
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className={styles["column1"]}>
                <div className={styles["infos"]}>
                  <div className={styles["nome-valor"]}>
                    <div className={styles["nome-desk"]}>
                      {anuncio?.location && (
                        <span className={styles["text-denominacao1"]}>
                          <span>
                            Personal trainer em {anuncio.location || ""}
                          </span>
                        </span>
                      )}
                      <span className={styles["text-nome1"]}>
                        {anuncio.personalName}
                      </span>
                    </div>
                    <div className={styles["valor"]}>
                      <span className={styles["text012"]}>
                        <span className={styles["text014"]}>
                          {obterPrecoFormatado()}
                        </span>
                      </span>
                    </div>
                  </div>
                  <span className={styles["text-descricao"]}>
                    <span>{anuncio.briefDescription}</span>
                  </span>
                </div>
                {obterDetalhesAtendimento().length > 0 && (
                  <div className={styles["atendimento"]}>
                    <span className={styles["text019"]}>
                      <span>Atendimento</span>
                    </span>
                    <div className={styles["infos1"]}>
                      {obterDetalhesAtendimento().map((detalhe, index) => (
                        <div key={index} className={styles["frame1171276959"]}>
                          <span className={styles["text021"]}>
                            <span>{detalhe}</span>
                          </span>
                        </div>
                      ))}

                      {anuncio.languages?.length > 0 && (
                        <div className={styles["linguagem"]}>
                          <img
                            alt="Vector2694"
                            src="/external/vector2694-0p8b.svg"
                            className={styles["idiomaicon"]}
                          />
                          <span className={styles["text035"]}>
                            <span>{anuncio.languages.join(" / ")}</span>
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className={styles["contato"]}>
                  {anuncio?.userDetails?.phone && (
                    <button
                      className={styles["botao-wpp"]}
                      onClick={async () => {
                        window.open(
                          `https://api.whatsapp.com/send?phone=${anuncio?.userDetails?.phone.replace(
                            /\D/g,
                            ""
                          )}`
                        );
                        await contabilizarWhatsapp();
                      }}
                    >
                      <span className={styles["text037"]}>
                        <span>Falar com o personal</span>
                      </span>
                      <img
                        alt="WhatsAppI269"
                        src="/external/whatsappi269-mgp.svg"
                        className={styles["whats-appicon"]}
                      />
                    </button>
                  )}
                  {anuncio?.socialNetworks &&
                    Object.values(anuncio?.socialNetworks)?.filter(
                      (value) => value != null
                    )?.length > 0 && (
                      <div className={styles["redes-sociais"]}>
                        {anuncio.socialNetworks.instagram && (
                          <a
                            target="_blank"
                            href={anuncio.socialNetworks.instagram}
                          >
                            <img
                              alt="image1302694"
                              src="/external/image1302694-nc3-200h.png"
                              className={styles["insta1"]}
                            />
                          </a>
                        )}
                        {anuncio.socialNetworks.facebook && (
                          <a
                            target="_blank"
                            href={anuncio.socialNetworks.facebook}
                          >
                            <img
                              alt="image1312694"
                              src="/external/image1312694-28qs-200h.png"
                              className={styles["face1"]}
                            />
                          </a>
                        )}
                        {anuncio.socialNetworks.tiktok && (
                          <a
                            target="_blank"
                            href={anuncio.socialNetworks.tiktok}
                          >
                            <img
                              alt="image1332694"
                              src="/external/image1332694-flsx-200h.png"
                              className={styles["tiktok1"]}
                            />
                          </a>
                        )}
                        {anuncio.socialNetworks.linkedin && (
                          <a
                            target="_blank"
                            href={anuncio.socialNetworks.linkedin}
                          >
                            <img
                              alt="image1322694"
                              src="/external/image1322694-qax-200h.png"
                              className={styles["linked-in"]}
                            />
                          </a>
                        )}
                        {anuncio.socialNetworks.website && (
                          <a
                            target="_blank"
                            href={anuncio.socialNetworks.website}
                          >
                            <div className={styles["rede1"]}>
                              <img
                                alt="Ellipse2182694"
                                src="/external/ellipse2182694-zllt-200h.png"
                                className={styles["ellipse2181"]}
                              />
                              <img
                                alt="IconsBoldnetwork2694"
                                src="/external/iconsboldnetwork2694-qd6f.svg"
                                className={styles["icons-boldnetwork1"]}
                              />
                            </div>
                          </a>
                        )}
                      </div>
                    )}
                  {anuncio?.userDetails?.phone && <div className={styles["numero-mobile"]}>
                    <img
                      alt="telefone2932"
                      src="/external/telefone2932-n2wc.svg"
                      className={styles["telefoneicon1"]}
                    />
                    <span className={styles["numero"]}>
                      {verNumeroCompleto
                        ? formatPhoneNumber(anuncio?.userDetails?.phone)
                        : `${formatPhoneNumber(anuncio?.userDetails?.phone).substring(0, 5)}...`}
                    </span>
                    {!verNumeroCompleto && (
                      <span
                        className={styles["botaovertelefone"]}
                        onClick={verNumeroTelefone}
                      >
                        <span>Ver telefone</span>
                      </span>
                    )}
                  </div>}
                </div>
              </div>
            </div>
            <div className={styles["compartilhar-denunciar"]}>
              <button
                className={styles["botao-compartilhar"]}
                onClick={handleCompartilhar}
              >
                <img
                  alt="compartilharI269"
                  src="/external/compartilhari269-y5zg.svg"
                  className={styles["compartilharicon"]}
                />
              </button>
              <button
                className={styles["botao-denunciar"]}
                onClick={() => {
                  if (usuario?.role !== "admin") {
                    setPopupDenunciaVisible(true);
                  } else {
                    toast.error("Você não pode denunciar o usuário!");
                  }
                }}
              >
                <img
                  alt="alertaI269"
                  src="/external/alerta.svg"
                  className={styles["alertaicon"]}
                />
              </button>
            </div>
          </div>
          {anuncio?.services && anuncio.services.length > 0 && (
            <div className={styles["objetivos"]}>
              <span className={styles["text041"]}>
                <span>
                  Este profissional pode ajudar você nos seguintes objetivos:
                </span>
              </span>
              <div className={styles["container5"]}>
                {anuncio.services?.map((servico, index) => (
                  <div
                    key={index}
                    className={
                      styles[
                        `objetivo${
                          servico.title === "Apoio nutricional" ? "-extra" : ""
                        }`
                      ]
                    }
                  >
                    <span className={styles["text043"]}>
                      <span>{servico.title}</span>
                    </span>
                    <span className={styles["text045"]}>
                      {servico.description}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}
          <div className={styles["sobre"]}>
            <div className={styles["container6"]}>
              {anuncio?.biography && (
                <div className={styles["sobre1"]}>
                  <span className={styles["text065"]}>
                    <span>Sobre o Personal Trainer</span>
                  </span>
                  <span className={styles["text067"]}>
                    <span>{anuncio.biography}</span>
                  </span>
                </div>
              )}
              {anuncio?.trainingMethods && (
                <div className={styles["metodos"]}>
                  <span className={styles["text069"]}>
                    <span>Métodos</span>
                  </span>
                  <span className={styles["text071"]}>
                    <span>{anuncio.trainingMethods}</span>
                  </span>
                </div>
              )}
              {anuncio?.qualifications && (
                <div className={styles["qualificacoes"]}>
                  <span className={styles["text073"]}>
                    <span>Qualificações</span>
                  </span>
                  <span className={styles["text075"]}>
                    <span>{anuncio.qualifications}</span>
                  </span>
                </div>
              )}
              {anuncio?.pricing &&
                (anuncio.pricing?.sessionDetails ||
                  (anuncio?.princing &&
                    Object.values(anuncio.pricing).filter((v) => v !== null)
                      .length > 0)) && (
                  <div className={styles["planos"]}>
                    <span className={styles["text084"]}>
                      <span>Preços e planos</span>
                    </span>
                    <span className={styles["text086"]}>
                      <span>{anuncio.pricing?.sessionDetails}</span>
                    </span>
                    <div className={styles["online-presencial"]}>
                      {anuncio?.pricing?.online && (
                        <div className={styles["online"]}>
                          <span className={styles["text088"]}>
                            <span className={styles["text089"]}>
                              Online - a partir de
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: " ",
                                }}
                              />
                            </span>
                            <span className={styles["text090"]}>
                              R$ {anuncio.pricing?.online}/h
                            </span>
                          </span>
                        </div>
                      )}
                      <br></br>
                      {anuncio.pricing?.inPerson && (
                        <div className={styles["presencial"]}>
                          <span className={styles["text091"]}>
                            <span className={styles["text092"]}>
                              Presencial - a partir de
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: " ",
                                }}
                              />
                            </span>
                            <span className={styles["text093"]}>
                              R$ {anuncio.pricing?.inPerson}/h
                            </span>
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                )}
            </div>
            <span className={styles["text094"]}>
              <span>Fotos e Vídeos</span>
            </span>
            <div className={styles["midias"]}>
              {galeriaSlider.quantidadePaginas > 0 && (
                <div
                  className={styles["prev-carrossel"]}
                  onClick={() => slide(-1)}
                >
                  <svg viewBox="0 0 1024 1024" className={styles["arrowicon"]}>
                    <path d="M658 708l-60 60-256-256 256-256 60 60-196 196z"></path>
                  </svg>
                </div>
              )}
              <div style={{ overflow: "hidden", width: "100%" }}>
                <div className={styles["carrossel"]} ref={carrosselRef}>
                  {itensGaleria.map((item, index) => (
                    <div
                      key={index}
                      className={
                        styles[`midia${item.type === "image" ? "1" : ""}`]
                      }
                    >
                      {item.type === "video" ? (
                        <Player>
                          <source src={item?.url} />
                        </Player>
                      ) : (
                        <img
                          alt="image"
                          src={item?.url}
                          className={styles["image"]}
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
              {galeriaSlider.quantidadePaginas > 0 && (
                <div
                  className={styles["next-carrossel"]}
                  onClick={() => slide(1)}
                >
                  <img
                    alt="Group11712767582694"
                    src="/external/group11712767582694-434g.svg"
                    className={styles["arrowicon1"]}
                  />
                </div>
              )}
            </div>
            {
              <div className={styles["avaliacoes"]} ref={listaAvaliacoesRef}>
                {/* <div className={styles["avaliacao1"]}>
                  <span className={styles["text096"]}>
                    <span>Avaliações</span>
                  </span>
                  <div className={styles["nota1"]}>
                    <div className={styles["estrelas"]}>
                      <img
                        alt="Vector2694"
                        src="/external/vector2694-xhe.svg"
                        className={styles["vector1"]}
                      />
                      <span className={styles["text098"]}>
                        <span>
                          {anuncio.avaliacoes.media
                            .toFixed(1)
                            .replace(".", ",")}
                          <span
                            dangerouslySetInnerHTML={{
                              __html: " ",
                            }}
                          />
                        </span>
                      </span>
                    </div>
                    <span className={styles["textquantidade1"]}>
                      <span>{anuncio.avaliacoes.quantidade} avaliações</span>
                    </span>
                  </div>
                </div> */}
                {reviews && reviews.length > 0 && (
                  <div className={styles["comentarios"]}>
                    <div
                      className={styles["prev"]}
                      onClick={() => slideComentarios(-1)}
                    >
                      <svg
                        viewBox="0 0 1024 1024"
                        className={styles["arrowicon2"]}
                      >
                        <path d="M658 708l-60 60-256-256 256-256 60 60-196 196z"></path>
                      </svg>
                    </div>
                    <div style={{ width: "100%", overflow: "hidden" }}>
                      <div
                        className={styles["carrossel1"]}
                        ref={carrosselAvaliacoesRef}
                      >
                        {reviews?.map((av, index) => {
                          return (
                            <div key={index} className={styles["comentario"]}>
                              <div className={styles["info-user"]}>
                                <span className={styles["text101"]}>
                                  <span>{av?.reviewer.name}</span>
                                </span>
                                <div className={styles["nota-dada"]}>
                                  <img
                                    alt="Vector2694"
                                    src="/external/vector2694-ul6.svg"
                                    className={styles["staricon"]}
                                  />
                                  <span className={styles["text103"]}>
                                    <span>
                                      {av?.rating}
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: " ",
                                        }}
                                      />
                                    </span>
                                  </span>
                                </div>
                              </div>
                              <span className={styles["text105"]}>
                                {av?.comment}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div
                      className={styles["next"]}
                      onClick={() => slideComentarios(1)}
                    >
                      <img
                        alt="Group11712767582694"
                        src="/external/group11712767582694-6lmj.svg"
                        className={styles["arrowicon3"]}
                      />
                    </div>
                  </div>
                )}
              </div>
            }
            {palavrasChaves.length > 0 && (
              <span className={styles["text-palavraschave"]}>
                <span>Palavras-chave</span>
              </span>
            )}
            {palavrasChaves.length > 0 && (
              <div className={`${styles["palavras-chave"]} ${(props.paginaExlusiva && anuncio?.planDetails?.exclusivePage) ? styles["palavras-chave-paginaExclusiva"] : ''}`}>
                {palavrasChaves.map((pc, index) => (
                  <div key={index} className={styles["tag"]}>
                    <span className={styles["text119"]}>{pc}</span>
                  </div>
                ))}
              </div>
            )}
            {!(props.paginaExlusiva && anuncio?.planDetails?.exclusivePage) && <>
              <div className={styles["atencao"]}>
                <img
                  alt="pastedImage"
                  src="/external/pastedimage-yo7l-200h.png"
                  className={styles["atencaoicon"]}
                />
                <span className={styles["text140"]}>
                  <span>Atenção!</span>
                </span>
              </div>
              <span className={styles["text142"]}>
                <span>
                  Este perfil é autônomo e o personaltrainer.com.br não se
                  responsabiliza pelo conteúdo ou métodos apresentados. A
                  responsabilidade integral recai sobre o personal trainer titular
                  do perfil.
                </span>
              </span>
            </>}
          </div>
        </div>
        {!anuncio?.planDetails?.featuredAd && !(props.paginaExlusiva && anuncio?.planDetails?.exclusivePage) && otherAds.length > 0 && (
          <div className={styles["anuncios-no-elite"]}>
            <div className={styles["trainersindicados"]}>
              <span className={styles["text144"]}>
                <span>Personal trainers de {"todo o Brasil"} indicados</span>
              </span>
            </div>
            <div className={styles["trainers"]}>
              <img
                alt="pastedImage"
                src="/external/pastedimage-ss3g-200w.png"
                className={styles["arrowicon4"]}
              />
              <div className={styles["carrossel2"]}>
                {otherAds.map((ad, index) => {
                  if (ad.username !== anuncio.username) {
                    return (
                      <Link
                        to={`/personal/${ad?.username}`}
                        key={index}
                        className={
                          ad?.planDetails.featuredAd
                            ? styles["trainer"]
                            : styles["trainer1"]
                        }
                      >
                        {ad.planDetails?.featuredAd && (
                          <div className={styles["destaque"]}>
                            <span className={styles["text146"]}>
                              <span>DESTAQUE</span>
                            </span>
                          </div>
                        )}
                        {ad.planDetails?.verifiedContact && (
                          <div className={styles["verificado"]}>
                            <span className={styles["text148"]}>
                              <span>Verificado</span>
                            </span>
                            <img
                              alt="registrosI341"
                              src="/external/registrosi341-3nfq.svg"
                              className={styles["registros"]}
                            />
                          </div>
                        )}
                        <div className={styles["foto-perfil4"]}>
                          <img
                            alt="pastedImage"
                            src={ad.photo}
                            className={styles["foto3"]}
                          />
                        </div>
                        <span className={styles["nome"]}>
                          {ad?.personalName}
                        </span>
                        <span className={styles["descricao"]}>
                          <span style={{ textOverflow: "ellipsis" }}>
                            {ad?.briefDescription.substring(0, 20) + "..."}
                          </span>
                        </span>
                        <div className={styles["extras"]}>
                          <div className={styles["infos2"]}>
                            <div className={styles["avaliacao2"]}>
                              <img
                                alt="VectorI341"
                                src="/external/vectori341-6ccc.svg"
                                className={styles["estrelaicon"]}
                              />
                              <span className={styles["textnota"]}>
                                {ad?.rating.average}
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: " ",
                                  }}
                                />
                              </span>
                              <span className={styles["textqnt"]}>
                                <span>({ad?.rating.quantity})</span>
                              </span>
                            </div>
                            {ad?.planDetails.premiumServices && (
                              <div className={styles["apoio-nutricional"]}>
                                <img
                                  alt="VectorI341"
                                  src="/external/vectori341-b41.svg"
                                  className={styles["appleicon"]}
                                />
                                <span className={styles["text152"]}>
                                  <span>Ap. nutricional</span>
                                </span>
                              </div>
                            )}
                          </div>
                          <div className={styles["valor1"]}>
                            <span className={styles["text154"]}>
                              <span className={styles["text155"]}>
                                {ad?.pricingStartingFrom ? "A partir de" : "A combinar"}
                              </span>
                              <span className={styles["text156"]}>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: " ",
                                  }}
                                />
                              </span>
                              {ad?.pricingStartingFrom && (
                                <span className={styles["text157"]}>
                                  R$ {ad?.pricingStartingFrom}/h
                                </span>
                              )}
                            </span>
                          </div>
                        </div>
                      </Link>
                    );
                  }
                })}
              </div>
              <img
                alt="Vector2161"
                src="/external/vector2161-jx.svg"
                className={styles["arrowicon5"]}
              />
            </div>
          </div>
        )}
      </div>
      <Rodape></Rodape>
      {popupDenunciaVisible && (
        <Denunciar
          anuncio={{
            id: anuncio?.id || null,
            slug: anuncio?.username || null,
            nome: anuncio?.personalName || null,
            descricao: anuncio?.briefDescription || null,
            fotoPerfil: anuncio?.photo || null,
          }}
          adId={anuncio?._id}
          fecharPopup={() => {
            setPopupDenunciaVisible(false);
          }}
        ></Denunciar>
      )}
    </div>
  ) : (
    <div></div>
  );
};

export default Perfil;
