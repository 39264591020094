import React, { useState } from 'react'

import PropTypes from 'prop-types'

import './adm-filtros.css'

const AdmFiltros = (props) => {
  const [Busca, setBusca] = useState(false)
  return (
    <div className={`adm-filtros-botoes ${props.rootClassName} `}>
      {Busca && (
        <div className="adm-filtros-busca">
          <input
            type="text"
            placeholder={props.PlaceholderBusca}
            className="adm-filtros-textinput"
          />
          <img
            alt="lupa1228"
            src="/external/lupa1228-wt5m.svg"
            className="adm-filtros-lupa"
          />
        </div>
      )}
      {/* <div className="adm-filtros-botoes1">
        <button className="adm-filtros-botao-ordenar">
          <span className="adm-filtros-text">
            <span className="">Ordenar</span>
          </span>
          <img
            alt="OrdenarI122"
            src="/external/ordenari122-6p3.svg"
            className="adm-filtros-ordenaricon"
          />
        </button>
        <button className="adm-filtros-botao-filtrar">
          <span className="adm-filtros-text2">
            <span className="">Filtrar</span>
          </span>
          <img
            alt="filtrarI807"
            src="/external/filtrari807-fa7v.svg"
            className="adm-filtros-filtrar"
          />
        </button>
      </div> */}
    </div>
  )
}

AdmFiltros.defaultProps = {
  PlaceholderBusca: 'Buscar',
  rootClassName: '',
}

AdmFiltros.propTypes = {
  PlaceholderBusca: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default AdmFiltros
